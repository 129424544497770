import { FETCH_VIDEOS_DATA_REQUEST, SET_VIDEO_STATUS } from "./types";

export const fetchVideosData = (data, callback) => ({
  type: FETCH_VIDEOS_DATA_REQUEST,

  payload: data,
  callback,
});

export const setVideoPlayOption = (url, data) => {
  return {
    type: SET_VIDEO_STATUS,
    videoType: url,
    payload: data,
  };
};
