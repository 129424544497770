const countLines = (target, lineHeight = 1.2) => {
  const style = window.getComputedStyle(target, null);
  let height = parseInt(style.getPropertyValue("height"));
  const font_size = parseInt(style.getPropertyValue("font-size"));
  let line_height = parseInt(style.getPropertyValue("line-height"));
  const box_sizing = style.getPropertyValue("box-sizing");

  if (isNaN(line_height)) line_height = font_size * lineHeight;

  if (box_sizing === "border-box") {
    var padding_top = parseInt(style.getPropertyValue("padding-top"));
    var padding_bottom = parseInt(style.getPropertyValue("padding-bottom"));
    var border_top = parseInt(style.getPropertyValue("border-top-width"));
    var border_bottom = parseInt(style.getPropertyValue("border-bottom-width"));
    height = height - padding_top - padding_bottom - border_top - border_bottom;
  }
  var lines = Math.ceil(height / line_height);
  return lines;
};

export default countLines;
