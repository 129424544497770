import React from "react";
import Brand from "../../assets/images/mac.svg";
import Playstore from "../../assets/images/playstore.png";
import cx from "classnames";

const AppStoreLinks = ({ className = "" }) => (
  <div className={cx("app-store-links-wrapper", className)}>
    <a
      href="https://play.google.com/store/apps/details?id=in.wishhealth.doc.android"
      target="_blank"
      rel="noopener noreferrer"
      className="app-store-link"
    >
      <div className="d-flex align-items-center">
        <img src={Playstore} alt="playstore" />
        <div>
          <p className="mb-0">Get it On</p>
          <h4 className="mb-0">Google Play</h4>
        </div>
      </div>
    </a>
    <a
      href="https://apps.apple.com/in/app/wishhealth-for-doctors/id1497794891"
      className="app-store-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="d-flex align-items-center">
        <img src={Brand} alt="brand" />
        <div>
          <p className="mb-0">Available on the</p>
          <h4 className="mb-0">App Store</h4>
        </div>
      </div>
    </a>
  </div>
);

export default AppStoreLinks;
