export const isProduction = true;
export const config = {
  oldWebsiteUrl: isProduction
    ? process.env.REACT_APP_OLD_PROD_URL
    : process.env.REACT_APP_OLD_TEST_DEV_URL,
  apiUrl: isProduction
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_TEST,
  apiUrlInnow8: isProduction
    ? process.env.REACT_APP_API_URL_PROD_INNOW8
    : process.env.REACT_APP_API_URL_TEST_INNOW8,
};
