const {
  FETCH_DOCTOR_DATA_BY_ID_FAILED,
  FETCH_DOCTOR_DATA_BY_ID_REQUEST,
  FETCH_DOCTOR_DATA_BY_ID_SUCCESS,
  FETCH_DOCTOR_DETAILS_REQUEST,
  FETCH_DOCTOR_DETAILS_SUCCESS,
  FETCH_DOCTOR_DETAILS_FAILED,
  FETCH_DOCTOR_REVIEWS_SUCCESS
} = require("./types");

const initialState = {
  doctorsDataById: {},
  doctorDetails: {},
  doctorReviews: {}
};

const DoctorInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCTOR_DATA_BY_ID_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_DOCTOR_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        doctorsDataById: action.doctorsDataById || {},
      };
    }
    case FETCH_DOCTOR_DATA_BY_ID_FAILED: {
      return {
        ...state,
      };
    }

    case FETCH_DOCTOR_DETAILS_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_DOCTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        doctorDetails: action.doctorDetails || {},
      };
    }
    case FETCH_DOCTOR_DETAILS_FAILED: {
      return {
        ...state,
      };
    }
    case FETCH_DOCTOR_REVIEWS_SUCCESS: {
      return {
        ...state,
        doctorReviews: action.doctorReviews || {},
      };
    }
    default: {
      return state;
    }
  }
};

export default DoctorInfoReducer;
