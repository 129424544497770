import React, { useState } from "react";
import Loader from "../../../loader";

import { titleCase } from "../../../common/stringFunction";
import { extractChar } from '../../../util/string';
import VideoIcon from "../../../assets/images/dashboard/video-icon.png";
import Calendar from "../../../assets/images/dashboard/calendar.png";
import Delete from "../../../assets/images/dashboard/delete.png";
import Edit from "../../../assets/images/dashboard/edit.png";
import { withRouter } from "react-router-dom";
import { Button, Input } from "reactstrap";
import Avatar from "../../../assets/images/avatar.png";
import DeleteModal from "../../../common/deleteModal";
import TickCircle from "../../../assets/images/dashboard/tick-circle.png";
import Arrows from "../../../assets/images/dashboard/arrow.png";
import moment from "moment";
import {
  deleteAppointment,
  editAppointment,
} from "../../dashboard/store/action";
import {
  createPayment,
  capturePayment,
  couponCode,
  verifyCoupon,
} from "../../../components/modal/store/action";
import { isProduction } from "../../../config";
import { fetchAllAppointmentsData } from "../../dashboard/store/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import NotificationManager from "react-notifications/lib/NotificationManager";

import { fetchDoctorsDataById } from "../../../pages/doctorProfile/store/action";
const DashboardSlider = (props) => {
  const { upcomingBookings = [], allAppointments, patientDetails } = props;

  const [loading, setLoading] = useState(false);
  const [responseLoader, setResponseLoader] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bookingIdDeleteAppointment, setBookingIdDeleteAppointment] = useState(
    ""
  );
  const [idCard, setIdCard] = useState("");
  const [errorType, setErrortype] = useState("");
  const [errorText, setErrortext] = useState("");
  const [coupon, setCoupon] = useState({});
  const [currentId, setCurrentId] = useState("");

  const handleAppointment = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    let nameReplace = props.saveName.split(" ").join("");
    let lastFourDigitsPhone = props.savePhoneNumber.substr(
      props.savePhoneNumber.length - 4
    );

    let namePhone = nameReplace + lastFourDigitsPhone;

    props.history.push({
      pathname: `/patient/appointment/${namePhone}/${data && data.id}`,
    });
  };

  const handleDelete = (e, bookingId) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalOpen(!deleteModalOpen);
    setBookingIdDeleteAppointment(bookingId);
  };
  const handleDeleteAppointment = () => {
    setDeleteModalOpen(false);
    setLoading(true);
    let obj = {
      booking_id: bookingIdDeleteAppointment,
      deleted_by: "patient",
    };

    props.deleteAppointment("booking/delete", obj, (response) => {
      if (response.message === "Deleted Successfully") {
        setLoading(false);
        NotificationManager.success(response.message);

        setTimeout(() => {
          allAppointments();
        }, 500);
      }
    });
  };

  const handleEdit = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    const { wh_user, wh_clinic, id } = data;

    let specialities =
      wh_user &&
      wh_user.wh_doctor_specialities &&
      wh_user.wh_doctor_specialities.filter((item) => {
        return item.wh_speciality !== null;
      });

    let doctorsSpecialitiesReplaced =
      specialities &&
      specialities.length > 0 &&
      specialities.map((item) => {
        const data =
          item &&
          item.wh_speciality &&
          titleCase(item.wh_speciality.title).split(" ").join("-");
        return data.split("/").join("-");
      });

    let nameReplace = wh_user && wh_user.name.split(" ").join("-");
    let specialitiesReplace =
      doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");

    const { fetchDoctorsDataById } = props;
    fetchDoctorsDataById(
      `search/getDoctorClinics/${wh_user && wh_user.user_id}`,
      (value) => {
        let city =
          value && value.data && value.data.wh_city && value.data.wh_city.name;
        let obj;
        obj = {
          clinic_id: wh_clinic && wh_clinic.clinic_id,
          date: data && data.date,
          isBooked: true,
          selected: false,
          time: data && data.time,
          type: !wh_clinic ? "video" : "clinic",
        };

        props.history.push({
          pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${
            wh_user && wh_user.user_id
          }/bookingForm`,
          search: `?specialization=${specialitiesReplace}`,
          // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${
          //   wh_user && wh_user.user_id
          // }/bookingForm`,
          state: {
            clinic_details: !wh_clinic ? {} : wh_clinic,
            wh_doctor_detail: wh_user && wh_user.wh_doctor_detail,
            timeSlot: data && data.time,
            selectedDate: data && data.date,
            doctorInfoDetails: wh_user,
            data: null,
            doctorDataById:
              value.data && value.data.clinics && value.data.clinics.length > 0
                ? value.data.clinics
                : [],
            videoConsultationBooking: value.data.video_timings
              ? value.data.video_timings
              : [],
            video_timings: value.data.video_timings
              ? value.data.video_timings
              : [],
            modalOpenForEditing: true,
            video_bookings: value.data.videobookings
              ? value.data.videobookings
              : [],

            selectedTimeSlot: obj,
            type: "edit",
            booking_id: id,
            paymentDetails: data && data.payment,
          },
        });
      }
    );
  };

  const paymentHandler = (e, id, wh_user, payment, clinic_id, type) => {
    let remainingAmount =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.doc_fees -
        wh_user.wh_doctor_detail.doc_advance_fees;

    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    setIdCard(id);
    e.preventDefault();
    let dataObj;
    if (type === "total") {
      if (clinic_id === 1) {
        dataObj = {
          amount:
            wh_user && wh_user.video_timings && wh_user.video_timings.fees,
        };
      } else {
        dataObj = {
          amount:
            wh_user &&
            wh_user.wh_doctor_detail &&
            wh_user.wh_doctor_detail.doc_fees !== null
              ? remainingAmount
              : 0,
        };
      }
    } else if (type === "additional") {
      dataObj = { amount: payment && payment.additional_charges };
    } else if (type === "overall") {
      let total_fees = 0,
        advance_fees = 0,
        additional_charges = 0;
      if (
        (clinic_id === 1 && payment && !payment.advance_fees_payment_id) ||
        !payment
      ) {
        advance_fees =
          payment && payment.advance_fees
            ? payment.advance_fees
            : clinic_id !== 1
            ? wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_advance_fees !== null
              ? wh_user.wh_doctor_detail.doc_advance_fees
              : 0
            : wh_user &&
              wh_user.video_timings &&
              wh_user.video_timings.length > 0 &&
              wh_user.video_timings[0].fees !== null
            ? wh_user.video_timings[0].fees
            : 0;
      }

      additional_charges =
        payment && payment.additional_charges ? payment.additional_charges : 0;
      total_fees =
        clinic_id !== 1
          ? wh_user &&
            wh_user.wh_doctor_detail &&
            wh_user.wh_doctor_detail.doc_fees !== null
            ? wh_user.wh_doctor_detail.doc_fees
            : 0
          : 0;

      dataObj = {
        amount:
          clinic_id !== 1
            ? parseInt(total_fees) + parseInt(additional_charges)
            : parseInt(advance_fees) + parseInt(additional_charges),
      };
    } else if (type === "total+additional") {
      dataObj = {
        amount:
          wh_user &&
          wh_user.wh_doctor_detail &&
          wh_user.wh_doctor_detail.doc_fees !== null
            ? remainingAmount + parseInt(payment.additional_charges)
            : 0,
      };
    } else {
      dataObj = { amount: payment && payment.advance_fees };
    }

    props.createPayment("order/", dataObj, (response) => {
      const data = response;

      setLoading(false);
      setIdCard("");
      const options = {
        key: !isProduction
          ? "rzp_test_5G5VyL9K8BPPNJ"
          : "rzp_live_OlRwttnj1mQtYv",
        name: "Wish Health",
        description: "Search and consult doctors anywhere anytime",
        order_id: data.id,
        handler: async (response) => {
          setLoading(true);
          try {
            setLoading(false);
            setIdCard("");
            let objCreatePayment;

            if (type === "additional") {
              objCreatePayment = {
                fees_type: "additional",
                booking_id: id,
                additional_fees_payment_id: response.razorpay_payment_id,
                additional_fees: payment && payment.additional_charges,
              };
            } else if (type === "total") {
              objCreatePayment = {
                fees:
                  wh_user &&
                  wh_user.wh_doctor_detail &&
                  wh_user.wh_doctor_detail.doc_fees !== null
                    ? remainingAmount
                    : 0,
                fees_type: "fees",
                booking_id: id,
                fees_payment_id: response.razorpay_payment_id,
              };
            } else if (type === "overall") {
              objCreatePayment = {
                fees:
                  clinic_id !== 1
                    ? wh_user &&
                      wh_user.wh_doctor_detail &&
                      wh_user.wh_doctor_detail.doc_fees !== null
                      ? wh_user.wh_doctor_detail.doc_fees
                      : 0
                    : 0,
                additional_fees:
                  payment && payment.additional_charges
                    ? payment.additional_charges
                    : 0,

                fees_type: "overall",
                booking_id: id,
                payment_id: response.razorpay_payment_id,
                ignore_message: true,
              };
              if (
                clinic_id === 1 &&
                payment &&
                !payment.advance_fees_payment_id
              ) {
                objCreatePayment.advance_fees =
                  payment && payment.advance_fees
                    ? payment.advance_fees
                    : clinic_id !== 1
                    ? wh_user &&
                      wh_user.wh_doctor_detail &&
                      wh_user.wh_doctor_detail.doc_advance_fees !== null
                      ? wh_user.wh_doctor_detail.doc_advance_fees
                      : 0
                    : wh_user &&
                      wh_user.video_timings &&
                      wh_user.video_timings.length > 0 &&
                      wh_user.video_timings[0].fees !== null
                    ? wh_user.video_timings[0].fees
                    : 0;
              }
            } else if (type === "total+additional") {
              objCreatePayment = {
                fees:
                  wh_user &&
                  wh_user.wh_doctor_detail &&
                  wh_user.wh_doctor_detail.doc_fees !== null
                    ? remainingAmount + parseInt(payment.additional_charges)
                    : 0,
                fees_type: "total+additional_fees",
                booking_id: id,
                fees_payment_id: response.razorpay_payment_id,
              };
            } else {
              objCreatePayment = {
                advance_fees: payment.advance_fees,
                fees_type: "advance",
                booking_id: id,
                advance_fees_payment_id: response.razorpay_payment_id,
              };
            }

            setResponseLoader(true);
            props.capturePayment(
              "order/capturePayment",
              objCreatePayment,
              (captureResponse) => {
                if (captureResponse.message === "success") {
                  setLoading(false);
                  setIdCard("");
                  setResponseLoader(false);
                  setTimeout(() => {
                    allAppointments();
                  }, 500);
                  NotificationManager.success("Payment Successful", "", 1000);

                  setPaymentDone(true);
                } else {
                  setLoading(false);
                  setIdCard("");
                  setResponseLoader(false);
                  NotificationManager.warning("Payment Failed", "", 1000);
                }
              }
            );
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#389EEC",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
  };

  const handleInput = (e, id) => {
    setCoupon({ name: e.target.value, bookingId: id });
    setErrortext("");
    setErrortype("");
  };

  const handleCoupon = (e, id, clinic_id, wh_user, payment) => {
    let remainingAmount =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.doc_fees -
        wh_user.wh_doctor_detail.doc_advance_fees;
    setCurrentId(id);
    e.preventDefault();
    e.stopPropagation();

    setResponseLoader(true);

    if (coupon && coupon.name === undefined) {
      setErrortype("coupon");
      setErrortext(
        <p>
          <span className="text-danger">Coupon Code is empty</span>
        </p>
      );

      setResponseLoader(false);
      return;
    }

    let obj = {
      name: coupon.name,
      patient_id: props && props.saveId,
    };

    props.verifyCoupon("booking/verify-coupon", obj, (response) => {
      if (response.status === 400) {
        NotificationManager.info(response.message, "", 3000);

        setResponseLoader(false);
        setCoupon("");
      } else {
        let dataObj;
        if (clinic_id === 1) {
          dataObj = {
            name: coupon.name,
            booking_id: id,
            patient_id: props && props.savePatientId,

            advance_fees: payment && payment.advance_fees,

            fees: 0,
          };
        } else {
          dataObj = {
            name: coupon.name,
            booking_id: id,
            patient_id: props && props.saveId,
            advance_fees:
              wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_advance_fees !== 0
                ? wh_user.wh_doctor_detail.doc_advance_fees
                : 1,
            fees:
              wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_fees !== null
                ? wh_user.wh_doctor_detail.doc_fees
                : 0,
          };
        }

        props.couponCode("booking/applyCoupon", dataObj, (response) => {
          if (response.status === 400) {
            NotificationManager.info(response.message, "", 1000);

            setResponseLoader(false);
            setCoupon("");
          } else {
            NotificationManager.success(
              "Coupon applied and appointment booked successfully",
              "",
              1000
            );

            allAppointments();
            setResponseLoader(false);
            setCoupon("");
          }
        });
      }
    });
  };

  const Card = (props) => {
    const { data, patientDetails } = props;

    let timeFormat = moment(data.time, "hh:mm a");
    let timeFormatInSmall = timeFormat.format("hh:mm A");

    const { wh_user, clinic_id, wh_clinic, payment, id, date2 } = data;

    let d = moment(new Date());
    let n = moment(date2);

    let Current = d.utc().format();
    let Booked = n.utc().format();

    let degrees =
      wh_user &&
      wh_user.wh_doctor_qualifications &&
      wh_user.wh_doctor_qualifications.length > 0 &&
      wh_user.wh_doctor_qualifications.filter((item) => {
        return item.wh_qualification && item.wh_qualification.degree !== null;
      });
    let doctorDegree =
      degrees &&
      degrees.length > 0 &&
      degrees.map((item) => {
        return item.wh_qualification && item.wh_qualification.degree;
      });
    let profileImage =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic
        ? wh_user &&
          wh_user.wh_doctor_detail &&
          wh_user.wh_doctor_detail.profile_pic
        : Avatar;

    let qualification = doctorDegree && doctorDegree.join(", ");

    return (
      <div className="px-1 h-100 position-relative">
        {id === idCard && loading && <Loader />}

        <div
          className="appointment-card "
          style={id === idCard && loading ? { filter: "blur(3px)" } : {}}
        >
          <div className="appointment-info">
            <div className="profile-icon">
            {  wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic? <img src={ wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic} alt="profile" />:(
      <div className="dummy-text">
      <span>{extractChar(wh_user && wh_user.name)}</span>
    </div>)}
            </div>
            <div className="py-3">
              <h2>{wh_user && wh_user.name}</h2>
              {qualification && (
                <p className="mb-0 doctorDegree">{`(${qualification})`}</p>
              )}
            </div>
          </div>
          <div className="appointment-time">
            <div className="app-time-wrapper">
              <div className="time-wrap">
                <div className="calendar-icon">
                  <img src={Calendar} alt="calendar" />
                </div>
                <div className="w-100">
                  {clinic_id === 1 ? (
                    <p className="name">Video Appointment Time</p>
                  ) : (
                    <p className="name">Clinic Appointment Time</p>
                  )}
                  <p className="date">
                    <span className="date">{`${
                      data && data.date
                    }, ${timeFormatInSmall}`}</span>

                    <div className="updates-btn">
                      {/* {Booked > Current && (
                        <button
                          className="edit"
                          onClick={(e) => handleEdit(e, data)}
                        >
                          <img src={Edit} alt="edit" className="d-inline" />
                        </button>
                      )}
                      {Booked > Current && (
                        <button
                          className="delete"
                          onClick={(e) => handleDelete(e, data && data.id)}
                        >
                          <img src={Delete} alt="delete" className="d-inline" />
                        </button>
                      )} */}

                      <button
                        className="edit"
                        onClick={(e) => handleEdit(e, data)}
                      >
                        <img src={Edit} alt="edit" className="d-inline" />
                      </button>

                      <button
                        className="delete"
                        onClick={(e) => handleDelete(e, data && data.id)}
                      >
                        <img src={Delete} alt="delete" className="d-inline" />
                      </button>
                    </div>
                  </p>
                </div>
              </div>
              <div className="mt-3">
                {clinic_id && clinic_id === 1 ? (
                  <Button
                    className="video-call-btn"
                    disabled={
                      !payment ||
                      (payment &&
                        payment.status === "" &&
                        payment.advance_fees_payment_id === null)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.history.push({
                        pathname: "/call",
                        state: {
                          patientDetails: patientDetails,
                          doctorDetails: wh_user,
                        },
                      });
                    }}
                  >
                    <img src={VideoIcon} className="pr-1 d-inline" alt="call" />
                    Call
                  </Button>
                ) : (
                  <p className="wh_clinic mb-0">
                    {`${wh_clinic && wh_clinic.name}, ${
                      wh_clinic && wh_clinic.address
                    }`}
                  </p>
                )}
              </div>
            </div>

            {(!payment ||
              (payment &&
                payment.status === "" &&
                parseInt(payment.fees) !== 0)) && (
              <div className="coupon-code-wrapper mt-3">
                <div className="d-flex">
                  <Input
                    type="text"
                    name="coupon"
                    id={id}
                    placeholder="Enter coupon code"
                    className="mr-2"
                    onChange={(e) => handleInput(e, id)}
                    onClick={(e) => e.stopPropagation()}
                    autoFocus={
                      coupon && coupon.name && id === coupon.bookingId
                        ? true
                        : false
                    }
                    value={
                      coupon && coupon.name && id === coupon.bookingId
                        ? coupon.name
                        : ""
                    }
                    disabled={loading}
                    style={loading ? { opacity: 0.5 } : {}}
                  />

                  <Button
                    disabled={loading}
                    onClick={(e) =>
                      handleCoupon(e, id, clinic_id, wh_user, payment)
                    }
                    color="primary"
                  >
                    Apply
                  </Button>
                </div>
                {id === currentId &&
                  errorType &&
                  errorType === "coupon" &&
                  errorText}
              </div>
            )}

            {!payment && (
              <div className="advanced-payment-wrapper">
                <div className="payment-pending">
                  <p className="mb-0 mt-2">
                    (Payment Pending.
                    <a
                      href="#"
                      onClick={(e) =>
                        paymentHandler(
                          e,
                          id,
                          wh_user,
                          payment,
                          clinic_id,
                          "overall"
                        )
                      }
                    >
                      Pay Now)
                    </a>
                  </p>
                </div>
              </div>
            )}

            {payment &&
              (payment.advance_fees_payment_id === null ||
                payment.advance_fees_payment_id === "") &&
              (payment.fees_payment_id === null ||
                payment.fees_payment_id === "") && (
                <div className="advanced-payment-wrapper">
                  <div className="payment-pending">
                    <p className="mb-0 mt-2">
                      (Payment Pending.
                      <a
                        href="#"
                        onClick={(e) =>
                          paymentHandler(
                            e,
                            id,
                            wh_user,
                            payment,
                            clinic_id,
                            "overall"
                          )
                        }
                      >
                        Pay Now)
                      </a>
                    </p>
                  </div>
                </div>
              )}

            {payment &&
              payment.status !== "Paid" &&
              clinic_id !== 1 &&
              !payment.fees_payment_id &&
              payment.advance_fees_payment_id !== "" &&
              payment.advance_fees_payment_id !== null &&
              (!payment.additional_charges ||
                parseInt(payment.additional_charges) === 0) &&
              parseInt(payment.fees) !== 0 && (
                <div className="advanced-payment-wrapper">
                  <div className="advanced-payment">
                    <p className="mb-0 advance-fees">
                      Advance Fee:<span className="pl-1">Paid</span>{" "}
                      <img src={TickCircle} className="tick" alt="tick" />
                    </p>
                    <p className="mb-0">
                      <label>Outstanding Fee:</label>
                      <span className="pl-1">
                        <a
                          href="#"
                          onClick={(e) =>
                            paymentHandler(
                              e,
                              id,
                              wh_user,
                              payment,
                              clinic_id,
                              parseInt(payment.additional_charges)
                                ? "overall"
                                : "total"
                            )
                          }
                        >
                          {" "}
                          Pay Now <img alt="" src={Arrows} className="ml-2" />
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}

            {payment &&
              payment.additional_charges &&
              parseInt(payment.additional_charges) !== 0 &&
              payment.status !== "Paid" &&
              payment.fees_payment_id !== null &&
              payment.advance_fees_payment_id !== null &&
              // payment.advance_fees_payment_id !== "Paid" &&
              (payment.additional_fees_payment_id === null ||
                payment.additional_fees_payment_id === "") && (
                <div className="advanced-payment-wrapper">
                  <div className="advanced-payment">
                    <p className="mb-0 advance-fees">
                      Outstanding Fee:<span className="pl-1">Paid</span>{" "}
                      <img alt="" src={TickCircle} className="tick" />
                    </p>
                    <p className="mb-0">
                      <label>Additional Fee:</label>
                      <span className="pl-1">
                        <a
                          href="#"
                          onClick={(e) =>
                            paymentHandler(
                              e,
                              id,
                              wh_user,
                              payment,
                              clinic_id,
                              "additional"
                            )
                          }
                        >
                          {" "}
                          Pay Now{" "}
                          <img src={Arrows} className="ml-2" alt="arrow" />
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}

            {payment && //new condition
              payment.additional_charges &&
              parseInt(payment.additional_charges) !== 0 &&
              payment.status !== "Paid" &&
              (payment.fees_payment_id !== null ||
                payment.advance_fees_payment_id !== null) &&
              // payment.advance_fees_payment_id !== "Paid" &&
              (payment.additional_fees_payment_id === null ||
                payment.additional_fees_payment_id === "") &&
              !payment.fees_payment_id && (
                <div className="advanced-payment-wrapper">
                  <div className="advanced-payment">
                    <p className="mb-0 advance-fees">
                      Advance Fee:<span className="pl-1">Paid</span>{" "}
                      <img alt="" src={TickCircle} className="tick" />
                    </p>
                    <p className="mb-0">
                      <label> Outstanding and Additional Fees:</label>
                      <span className="pl-1">
                        <a
                          href="#"
                          onClick={(e) =>
                            paymentHandler(
                              e,
                              id,
                              wh_user,
                              payment,
                              clinic_id,
                              "total+additional"
                            )
                          }
                        >
                          {" "}
                          Pay Now{" "}
                          <img src={Arrows} className="ml-2" alt="arrow" />
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  upcomingBookings &&
    upcomingBookings.length > 0 &&
    upcomingBookings.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

  return (
    <div className="appointment-slider">
      {responseLoader && <Loader />}
      {upcomingBookings.length > 0 && (
        <div
          className="doc-slider d-flex"
          style={responseLoader ? { filter: "blur(3px)" } : {}}
        >
          {upcomingBookings.map((data, index) => (
            <div onClick={(e) => handleAppointment(e, data)} key={index}>
              <Card
                data={data}
                patientDetails={patientDetails}
                history={props.history}
              />
            </div>
          ))}
        </div>
      )}

      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={handleDelete}
        loading={loading}
        deleteAppointment={handleDeleteAppointment}
      />

      {/* <div
      className={`appointment-slider ${
        upcomingBookings.length > 5 ? "sliderPadding" : ""
      }`}
    >
      {upcomingBookings.length > 0 && (
        <Slider className="doc-slider" {...settings}>
          {upcomingBookings.map((data) => (
            <div className="h-100" onClick={(e) => handleAppointment(e, data)}>
              <div className="h-100">
                <Card
                  data={data}
                  patientDetails={patientDetails}
                  history={props.history}
                />
              </div>
            </div>
          ))}
        </Slider>
      )} */}

      {(upcomingBookings.length === 0 || upcomingBookings === false) && (
        <p className="text-center no-records">No Upcoming Appointments</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  savePatientId: state.BookAppointmentReducer.savePatientId,
  session: state.VideoSessionData.session,
  saveName: state.BookAppointmentReducer.saveName,
  savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
  saveId: state.BookAppointmentReducer.saveId,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDoctorsDataById,
      fetchAllAppointmentsData,
      deleteAppointment,
      editAppointment,
      createPayment,
      capturePayment,
      couponCode,
      verifyCoupon,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardSlider)
);
