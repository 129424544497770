import React, { useState } from "react";
import { Button, Container } from "reactstrap";
import Sidebar from "./sidebar";
import Logo from "../../assets/images/logo.png";
import Footer from "../layout/footer";
import { connect } from "react-redux";
import { scrollToTop } from "../../util";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withRouter, useHistory } from "react-router-dom";
import { fetchAllAppointmentsData } from "../../pages/dashboard/store/action";
import Menu from "../../assets/images/dashboard/menu.png";
import {
  clearName,
  clearPhoneNumber,
  clearReducer,
} from "../../components/layout/store/action";
const DashboardLayout = (props) => {
  const [patientDetails, setPatientDetails] = useState({});
  const history = useHistory();

  const { children } = props;
  const handleLogout = () => {
    props.clearName("");
    props.clearPhoneNumber("");
    props.clearReducer("");
    localStorage.setItem("isLoggedIn", false);
    history.push("/");
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggle = () => setSidebarOpen((prevState) => !prevState);
  return (
    <>
      {localStorage.getItem("isLoggedIn") === "true" ? (
        <div className="main-wrapper">
          <Sidebar
            patientDetails={patientDetails}
            sidebarOpen={sidebarOpen}
            toggle={toggle}
          />
          <div className="dashboard-wrapper">
            <div className="dashboard-header">
              <div className="header-content">
                <div className="mobile-logo flex-grow-1">
                  <div className="menus" onClick={toggle}>
                    <img src={Menu} alt="menu" />
                  </div>
                  <div className="flex-grow-1 text-center">
                    <Link to="/" onClick={scrollToTop}>
                      <img src={Logo} alt="logo" className="logo" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    color="bordered"
                    className="logout-btn"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
            <div className="main-dashboard">{children}</div>
          </div>
        </div>
      ) : (
        <>
          <header id="header" className="d-flex align-items-center">
            <Container>
              <Link to="/" onClick={scrollToTop}>
                <img src={Logo} alt="logo" className="logo" />
              </Link>
            </Container>
          </header>
          <div className="text-center py-5">
            <Container>{children}</Container>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    savePatientId: state.BookAppointmentReducer.savePatientId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearName,
      clearPhoneNumber,
      clearReducer,
      fetchAllAppointmentsData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
);
