import React from "react";
import { HomeBanner } from "../../components/banner";
import BookOurDoctors from "./BookOurDoctors";
import ServiceWeProvide from "./ServiceWeProvide";
import HealthIssues from "./HealthIssues";
import Features from "./Features";
import WhatsDoctorSays from "./WhatsDoctorSays";
import FAQ from "./FAQ";
import BlogsSection from "./BlogsSection";
import HospitalsDeals from "./hospitalsDeals";
import DoctorOffers from "./doctorOffers";
import HealthPackage from "./healthPackage";
import LabsTestDeals from "./labsTestDeals";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { fetchDeals } from "../../pages/home/hospitalsDeals/store/action";
import Geocode from "react-geocode";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: "",
      latitude: 0,
      longitude: 0,
      hospitalDeals: [],
      labTestDeals: [],
      doctorDeals:[],
      loading: false,
    };
  }
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          var crd = pos.coords;

          Geocode.setApiKey("AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU");

          Geocode.enableDebug();
          Geocode.fromLatLng(`${crd.latitude}`, `${crd.longitude}`).then(
            (response) => {
              const city = response.results.filter((item) => {
                return item.types[0] === "locality";
              });

              const address =
                city && city.length > 0 && city[0].formatted_address;

              this.setState({
                currentLocation: address,
                latitude: crd.latitude,
                longitude: crd.longitude,
              });
            },
            (error) => {}
          );
        },
        (err) => {},
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
    this.setState({
      loading: true,
    });

    const { fetchDeals } = this.props;
    fetchDeals("data/deals", (deal) => {
  
    
      this.setState({
        hospitalDeals: deal.data.hospital_deals,
        labTestDeals: deal.data.lab_tests_deals,
        doctorDeals:deal.data.doctor_deals,
        loading: false,
      });
    });
  }
  render() {
    const { currentLocation, latitude, longitude,hospitalDeals, loading,labTestDeals,doctorDeals } = this.state;

    return (
      <>
        <HomeBanner />
        {!window.location.href.includes("search") && (
          <>
            {" "}
            <BookOurDoctors />
            <HealthPackage />
            <DoctorOffers doctorDeals={doctorDeals} loading={loading}/>
            <HospitalsDeals hospitalDeals={hospitalDeals} loading={loading}/>
            <LabsTestDeals labTestDeals={labTestDeals} loading={loading}/>
            <ServiceWeProvide
              currentLocation={currentLocation}
              latitude={latitude}
              longitude={longitude}
            />
            {/* <HealthIssues /> */}
            <BlogsSection />
            <WhatsDoctorSays />
            <FAQ />
            <Features />
          </>
        )}
      </>
    );
  }
}



const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDeals,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);

