import { put, takeLatest, call } from "redux-saga/effects";
import {
  BOOK_APPOINTMENT_REQUEST,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAILED,
  CHECK_IF_USER_EXISTING_FAILED,
  CHECK_IF_USER_EXISTING_REQUEST,
  CHECK_IF_USER_EXISTING_SUCCESS,
  SEND_OTP_BEFORE_BOOKING_FAILED,
  SEND_OTP_BEFORE_BOOKING_SUCCESS,
  SEND_OTP_BEFORE_BOOKING_REQUEST,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  CONFIRM_BOOKING_REQUEST,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILED,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  CAPTURE_PAYMENT_REQUEST,
  CAPTURE_PAYMENT_FAILED,
  CAPTURE_PAYMENT_SUCCESS,
  COUPON_CODE_SUCCESS,
  COUPON_CODE_FAILED,
  COUPON_CODE_REQUEST,
  CITY_REQUEST,
  STATE_REQUEST,
  STATE_SUCCESS,
  STATE_FAILED,
  CITY_SUCCESS,
  CITY_FAILED,
  VERIFY_COUPON_FAILED,
  VERIFY_COUPON_SUCCESS,
  VERIFY_COUPON_REQUEST,
  FETCH_PATIENT_DETAILS_REQUEST,
  FETCH_PATIENT_DETAILS_FAILED,
  FETCH_PATIENT_DETAILS_SUCCESS,
} from "./types";

import { apiCallGet } from "../../../common/axios";
import { apiCallPost } from "../../../common/axios";

async function callFetchBookAppointmentData(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callFetchPatientDetails(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callFetchCities(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callFetchStates(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callCreatePayment(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callCouponCode(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callVerifyCoupon(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callCapturePayment(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callCheckIfUserExisting(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callSendOtpBeforeBooking(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}
async function callVerifyOtp(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callConfirmBooking(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

function* createPayment(action) {
  const response = yield call(callCreatePayment, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: CREATE_PAYMENT_SUCCESS,
        createPaymentObject: response.data,
      });
    } else {
      yield put({ type: CREATE_PAYMENT_FAILED });
    }
  }
}

function* couponCode(action) {
  const response = yield call(callCouponCode, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: COUPON_CODE_SUCCESS,
        couponCode: response.data.message,
      });
    } else {
      yield put({ type: COUPON_CODE_FAILED });
    }
  }
}

function* verifyCoupon(action) {
  const response = yield call(callVerifyCoupon, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: VERIFY_COUPON_SUCCESS,
        verifyCoupon: response.data,
      });
    } else {
      yield put({ type: VERIFY_COUPON_FAILED });
    }
  }
}

function* capturePayment(action) {
  const response = yield call(callCapturePayment, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: CAPTURE_PAYMENT_SUCCESS,
        capturePaymentObject: response.data,
      });
    } else {
      yield put({ type: CAPTURE_PAYMENT_FAILED });
    }
  }
}

function* confirmBooking(action) {
  const response = yield call(callConfirmBooking, action);

  if (response) {
    action.callback(response);
    if (response.status === 200) {
      yield put({
        type: CONFIRM_BOOKING_SUCCESS,
        confirmBooking: response.data,
      });
    } else {
      yield put({ type: CONFIRM_BOOKING_FAILED });
    }
  }
}

function* verifyOtp(action) {
  const response = yield call(callVerifyOtp, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: VERIFY_OTP_SUCCESS,
        otpCorrect: response.data,
      });
    } else {
      yield put({ type: VERIFY_OTP_FAILED });
    }
  }
}
function* sendOtpBeforeBooking(action) {
  const response = yield call(callSendOtpBeforeBooking, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: SEND_OTP_BEFORE_BOOKING_SUCCESS,
        patiendId: response.data.id,
      });
    } else {
      yield put({ type: SEND_OTP_BEFORE_BOOKING_FAILED });
    }
  }
}
function* checkIfUserExisting(action) {
  const response = yield call(callCheckIfUserExisting, action);

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: CHECK_IF_USER_EXISTING_SUCCESS,
        checkIfUserExistingArray: response.data,
      });
    } else {
      yield put({ type: CHECK_IF_USER_EXISTING_FAILED });
    }
  }
}
function* fetchBookAppointmentData(action) {
  const response = yield call(callFetchBookAppointmentData, action);

  const { data } = response;

  if (data) {
    if (data) {
      action.callback(data);
      yield put({
        type: BOOK_APPOINTMENT_SUCCESS,
        bookedSlots: data,
      });
    }
  } else {
    yield put({ type: BOOK_APPOINTMENT_FAILED });
  }
}

function* fetchPatientDetails(action) {
  const response = yield call(callFetchPatientDetails, action);

  const { data } = response;

  if (data) {
    if (data) {
      action.callback(data);
      yield put({
        type: FETCH_PATIENT_DETAILS_SUCCESS,
        patientDetails: data,
      });
    }
  } else {
    yield put({ type: FETCH_PATIENT_DETAILS_FAILED });
  }
}

function* fetchCities(action) {
  const response = yield call(callFetchCities, action);

  const { data } = response;

  if (data) {
    if (data) {
      action.callback(data);
      yield put({
        type: CITY_SUCCESS,
        city: data.data,
      });
    }
  } else {
    yield put({ type: CITY_FAILED });
  }
}

function* fetchStates(action) {
  const response = yield call(callFetchStates, action);

  const { data } = response;

  if (data) {
    if (data) {
      action.callback(data);
      yield put({
        type: STATE_SUCCESS,
        state: data.data,
      });
    }
  } else {
    yield put({ type: STATE_FAILED });
  }
}

export default function* BookAppointmentWatcher() {
  yield takeLatest(BOOK_APPOINTMENT_REQUEST, fetchBookAppointmentData);
  yield takeLatest(CITY_REQUEST, fetchCities);
  yield takeLatest(STATE_REQUEST, fetchStates);
  yield takeLatest(CHECK_IF_USER_EXISTING_REQUEST, checkIfUserExisting);
  yield takeLatest(SEND_OTP_BEFORE_BOOKING_REQUEST, sendOtpBeforeBooking);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtp);
  yield takeLatest(CONFIRM_BOOKING_REQUEST, confirmBooking);
  yield takeLatest(CREATE_PAYMENT_REQUEST, createPayment);
  yield takeLatest(CAPTURE_PAYMENT_REQUEST, capturePayment);
  yield takeLatest(VERIFY_COUPON_REQUEST, verifyCoupon);
  yield takeLatest(COUPON_CODE_REQUEST, couponCode);
  yield takeLatest(FETCH_PATIENT_DETAILS_REQUEST, fetchPatientDetails);
}
