import {
  FETCH_ALL_APPOINTMENTS_DATA_REQUEST,
  FETCH_ALL_APPOINTMENTS_DATA_SUCCESS,
  FETCH_ALL_APPOINTMENTS_DATA_FAILED,
  DELETE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILED,
  EDIT_APPOINTMENT_REQUEST,
  EDIT_APPOINTMENT_FAILED,
  EDIT_APPOINTMENT_SUCCESS,
  MY_APPOINTMENT_REQUEST,
  MY_APPOINTMENT_FAILED,
  MY_APPOINTMENT_SUCCESS,
  UPDATE_PATIENT_DETAILS_REQUEST,
  UPDATE_PATIENT_DETAILS_FAILED,
  UPDATE_PATIENT_DETAILS_SUCCESS,
  LAST_FEW_BOOKED_DOCTORS_REQUEST,
  LAST_FEW_BOOKED_DOCTORS_FAILED,
  LAST_FEW_BOOKED_DOCTORS_SUCCESS,
} from "./types";
import { CLEAR_REDUCER } from "../../../components/layout/store/types";
const initialState = {
  allAppointments: {},
  deleteAppointment: {},
  editAppointment: {},
  myAppointment: {},
  updatePatientDetails: {},
  lastFewBookedDoctors: {},
};

const AllAppointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_APPOINTMENTS_DATA_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_ALL_APPOINTMENTS_DATA_SUCCESS: {
      return {
        ...state,
        allAppointments: action.allAppointments || {},
      };
    }
    case FETCH_ALL_APPOINTMENTS_DATA_FAILED: {
      return {
        ...state,
      };
    }

    case DELETE_APPOINTMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case DELETE_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        deleteAppointment: action.deleteAppointment || {},
      };
    }
    case DELETE_APPOINTMENT_FAILED: {
      return {
        ...state,
      };
    }

    case EDIT_APPOINTMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case EDIT_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        editAppointment: action.editAppointment || {},
      };
    }
    case EDIT_APPOINTMENT_FAILED: {
      return {
        ...state,
      };
    }

    case MY_APPOINTMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case MY_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        myAppointment: action.myAppointment || {},
      };
    }
    case MY_APPOINTMENT_FAILED: {
      return {
        ...state,
      };
    }

    case UPDATE_PATIENT_DETAILS_REQUEST: {
      return {
        ...state,
      };
    }
    case UPDATE_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        updatePatientDetails: action.updatePatientDetails || {},
      };
    }
    case UPDATE_PATIENT_DETAILS_FAILED: {
      return {
        ...state,
      };
    }
    case LAST_FEW_BOOKED_DOCTORS_REQUEST: {
      return {
        ...state,
      };
    }
    case LAST_FEW_BOOKED_DOCTORS_SUCCESS: {
      return {
        ...state,
        lastFewBookedDoctors: action.lastFewBookedDoctors || {},
      };
    }
    case LAST_FEW_BOOKED_DOCTORS_FAILED: {
      return {
        ...state,
      };
    }
    case CLEAR_REDUCER: {
      return {
        state: initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default AllAppointmentsReducer;
