import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import {
	fetchBlogsData,
	fetchMedicalShotsData,
	fetchHealthTipsData,
} from './store/action';
import { setVideoPlayOption } from '../HealthIssues/store/action';
import cx from 'classnames';
import { Media, Player, utils, withMediaProps } from 'react-media-player';
import { config } from '../../../config';
const { keyboardControls } = utils;

class BlogsSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogs: [],

			medicalShots: [],
			healthTips: [],
			activeVideoIndex: null,
			medicalHealth: [],
		};
		this.videoElRef = [];
	}

	componentDidMount() {
		const { fetchBlogsData, fetchHealthTipsData, fetchMedicalShotsData } =
			this.props;

		fetchBlogsData('data/featuredBlogs/172', (value) => {
			this.setState({ blogs: value.blogs });
		});

		fetchHealthTipsData('data/featuredBlogs/173', (value) => {
			this.setState({ healthTips: value.arr });
		});

		fetchMedicalShotsData('data/medicalShots', (value) => {
			let medicalShotsArray = Object.values(value);

			this.setState({ medicalShots: medicalShotsArray });
		});
	}
	playVideo = (index, media) => {
		const { activeVideoIndex } = this.state;
		const { setVideoPlayOption, HealthIssuesData } = this.props;

		var size = Object.keys(HealthIssuesData.currentVideoData).length;
		if (
			HealthIssuesData &&
			size > 0 &&
			(activeVideoIndex !== index ||
				HealthIssuesData.videoType !== 'medicalShots')
		) {
			HealthIssuesData.currentVideoData.pause();
		}
		setVideoPlayOption('medicalShots', media);
		this.setState({
			activeVideoIndex: index,
		});
	};
	pauseVideo = () => {
		const { activeVideoIndex } = this.state;
		const { HealthIssuesData } = this.props;
		this.setState({
			[`video${activeVideoIndex}`]: false,
		});
		var size = Object.keys(HealthIssuesData.currentVideoData).length;
		if (
			HealthIssuesData &&
			size > 0 &&
			HealthIssuesData.videoType === 'medicalShots'
		) {
			HealthIssuesData.currentVideoData.pause();
		}
	};
	handleVideoSlide = (media) => {
		this.setState({
			activeMedia: media,
		});

		media && media.playPause();
	};
	validateYouTubeUrl = (url) => {
		if (url != undefined || url != '') {
			var regExp =
				/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			var match = url.match(regExp);
			if (match && match[2].length == 11) {
				// Do anything for being valid
				// if need to change the url to embed url then use below line
				return true;
			} else {
				return false;
				// Do anything for not being valid
			}
		}
	};
	render() {
		const { blogs, medicalShots, healthTips } = this.state;

		let medicalShotData = medicalShots.filter((item) => {
			return item.title !== '' && item.video !== '';
		});

		let medicalHealth = [...medicalShotData, ...healthTips];

		let finalArr;

		let health_medical_arr = ['Health Tips / Medical Shots'];
		let blogs_arr = ['Blogs'];
		let arrCount = 0;
		if (blogs.length > 0) {
			arrCount += 1;
		}
		if (medicalShots.length > 0) {
			arrCount += 1;
		}
		if (healthTips.length > 0) {
			arrCount += 1;
		}

		return (
			<section id="blog" className="health-issues-wrapper">
				<Container className="blog-container">
					<Row className="justify-content-center health-advise-row">
						{blogs_arr &&
							blogs_arr.map((item, index) => {
								finalArr = [...(item === 'Blogs' ? blogs : medicalHealth)];

								const settings = {
									arrows: true,
									dots: false,
									infinite: true,
									speed: 500,
									slidesToShow:
										finalArr && finalArr.length < 3 ? finalArr.length : 3,
									slidesToScroll:
										finalArr && finalArr.length < 3 ? finalArr.length : 3,
									centerMode: true,
									centerPadding: '60px',
									responsive: [
										{
											breakpoint: 1300,
											settings: {
												slidesToShow:
													finalArr && finalArr.length < 3 ? finalArr.length : 3,
												slidesToScroll:
													finalArr && finalArr.length < 3 ? finalArr.length : 3,
												centerMode: true,
												centerPadding: '40px',
											},
										},
										{
											breakpoint: 1200,
											settings: {
												className: 'center',
												slidesToShow:
													finalArr && finalArr.length < 2 ? finalArr.length : 2,
												slidesToScroll:
													finalArr && finalArr.length < 2 ? finalArr.length : 2,
												centerMode: true,
												centerPadding: '40px',
											},
										},
										{
											breakpoint: 767,
											settings: {
												slidesToShow: 1,
												slidesToScroll: 1,
												className: 'center',
												centerMode: true,
												infinite: true,
												centerPadding: '60px',
											},
										},
										{
											breakpoint: 460,
											settings: {
												slidesToShow: 1,
												slidesToScroll: 1,
												className: 'center',
												centerMode: true,
												infinite: true,
												centerPadding: '45px',
											},
										},
									],
								};
								if (finalArr && finalArr.length > 0) {
									const colProps = {
										xs: 12,
									};
									return (
										<Col key={index} {...colProps}>
											<div className="health-advise-wrap">
												<div
													className={cx('health-advise', {
														'px-0 mb-3': arrCount > 1,
													})}
												>
													<h2 className="title text-center font-weight-black theme-title-blog">
														Blogs
													</h2>
													<div
														className={cx(
															'health-advise-slider outer-opacity sp',
															{
																arrowBottom: arrCount > 1,
															}
														)}
													>
														<Slider {...settings} afterChange={this.pauseVideo}>
															{finalArr.map((item, index) => {
																if (
																	item &&
																	item._embedded &&
																	item._embedded['wp:featuredmedia'] &&
																	item._embedded['wp:featuredmedia'][0] &&
																	item._embedded['wp:featuredmedia'][0]
																		.source_url
																) {
																	return (
																		<div
																			className="px-1 px-sm-2 px-md-3"
																			key={index}
																		>
																			<div className="health-img">
																				<img
																					src={
																						item &&
																						item._embedded &&
																						item._embedded[
																							'wp:featuredmedia'
																						] &&
																						item._embedded[
																							'wp:featuredmedia'
																						][0] &&
																						item._embedded[
																							'wp:featuredmedia'
																						][0].source_url
																					}
																					style={{ cursor: 'pointer' }}
																					onClick={() => {
																						window.location.href = item.link;
																					}}
																					alt="doctor"
																				/>
																				<p
																					className="mb-0"
																					title={item.title.rendered}
																				>
																					{item.title.rendered}
																				</p>
																			</div>
																		</div>
																	);
																}
															})}
														</Slider>
													</div>
												</div>
											</div>
										</Col>
									);
								}
								return null;
							})}
					</Row>
				</Container>
				<Container>
					<Row className="justify-content-center">
						{health_medical_arr &&
							health_medical_arr.map((item, index) => {
								finalArr = [...(item === 'Blogs' ? blogs : medicalHealth)];
								const finalArrLen = finalArr.length || 0;
								const isShowSlider =
									finalArrLen && finalArr.filter((i) => i.video).length > 3
										? true
										: false;
								const settings = {
									arrows: isShowSlider,
									dots: false,
									infinite: isShowSlider,
									speed: 500,
									// slidesToShow:
									//   finalArr && finalArr.length < 3 ? finalArr.length : 3,
									// slidesToScroll:
									//   finalArr && finalArr.length < 3 ? finalArr.length : 3,
									slidesToShow: isShowSlider ? finalArrLen : 0,
									slidesToScroll: isShowSlider ? finalArrLen : 0,
									centerMode: true,
									centerPadding: '60px',
									responsive: [
										{
											breakpoint: 1300,
											settings: {
												// slidesToShow:
												// 	finalArr && finalArr.length < 3 ? finalArr.length : 3,
												// slidesToScroll:
												// 	finalArr && finalArr.length < 3 ? finalArr.length : 3,
												slidesToShow: isShowSlider ? finalArrLen : 0,
												slidesToScroll: isShowSlider ? finalArrLen : 0,
												centerMode: true,
												centerPadding: '40px',
											},
										},
										{
											breakpoint: 1200,
											settings: {
												className: 'center',
												// slidesToShow:
												// 	finalArr && finalArr.length < 2 ? finalArr.length : 2,
												// slidesToScroll:
												// 	finalArr && finalArr.length < 2 ? finalArr.length : 2,
												slidesToShow: isShowSlider ? finalArrLen : 0,
												slidesToScroll: isShowSlider ? finalArrLen : 0,
												centerMode: true,
												centerPadding: '40px',
											},
										},
										{
											breakpoint: 767,
											settings: {
												slidesToShow: 1,
												slidesToScroll: 1,
												className: 'center',
												centerMode: true,
												infinite: true,
												centerPadding: '60px',
											},
										},
										{
											breakpoint: 460,
											settings: {
												slidesToShow: 1,
												slidesToScroll: 1,
												// slidesToShow: isShowSlider ? finalArrLen : 0,
												// slidesToScroll: isShowSlider ? finalArrLen : 0,
												className: 'center',
												centerMode: true,
												infinite: true,
												centerPadding: '45px',
											},
										},
									],
								};
								if (finalArr && finalArr.length > 0) {
									const colProps = {
										xs: 12,
									};
									return (
										<Col key={index} {...colProps}>
											<div className="health-advise-wrap medical-shots-wrapper">
												<div
													className={cx('health-advise', {
														'p-0': arrCount > 1,
													})}
												>
													<h3 className="title text-center theme-title">
														<span>Health Tips</span> /{' '}
														<span>Medical Shots</span>
													</h3>
													<div
														className={cx(
															`health-advise-slider ${
																isShowSlider ? 'outer-opacity' : ''
															} sp pb-0`,
															{
																arrowBottom: arrCount > 1,
															}
														)}
													>
														<Row>
															<Col lg={3}>
																<div className="health-articles-content">
																	<h3>
																		Read
																		<span> top articles</span> from
																		<span> health experts</span>
																	</h3>
																	<p>
																		Health articles that keep you informed about
																		good health practices & achieve your goals.
																	</p>
																	<button
																		onClick={() => {
																			window.location.href =
																				'https://blog.wishhealth.in/';
																		}}
																	>
																		See all articles
																	</button>
																</div>
															</Col>
															<Col lg={9}>
																<Slider
																	{...settings}
																	afterChange={this.pauseVideo}
																	className="px-0  medical-shots-slider"
																>
																	{finalArr.map((data, index) => {
																		const {
																			post_title,
																			guid,
																			image,

																			title,
																			id,
																			ID,
																			video,
																		} = data;

																		const Id = ID || id;
																		const placeholderimage =
																			'https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg';
																		let isValidURL;
																		if (video !== undefined) {
																			isValidURL =
																				this.validateYouTubeUrl(video);
																		}

																		return (
																			<div
																				className="px-1 px-sm-2 px-md-3"
																				key={index}
																			>
																				{
																					// data && image ? (
																					//   <div className="medical-health-img">
																					//     <img
																					//       src={
																					//         (image && image.guid) ||
																					//         `${config.oldWebsiteUrl}${image}` ||
																					//         placeholderimage
																					//       }
																					//       style={{ cursor: "pointer" }}
																					//       onClick={() => {
																					//         window.location.href = guid;
																					//       }}
																					//       alt="doctor"
																					//     />
																					//     {/* <p className="sub-text">
																					//       Coronavirus
																					//     </p> */}
																					//     <h3
																					//       className="mb-0 name"
																					//       title={post_title || title}
																					//     >
																					//       {post_title || title}
																					//     </h3>
																					//     {/* <p className="docName">
																					//       Dr. Diana Borgio
																					//     </p> */}
																					//   </div>
																					// ) :
																					data && video && (
																						<div className="medical-health-img">
																							{isValidURL ? (
																								<Media>
																									{(mediaProps) => (
																										<div
																											className="media w-100"
																											onKeyDown={keyboardControls.bind(
																												null,
																												mediaProps
																											)}
																										>
																											<Player
																												src={video}
																												className="media-player w-100"
																												isPlaying={
																													this.state[
																														`video${Id}`
																													] !== undefined
																														? this.state[
																																`video${Id}`
																														  ]
																														: false
																												}
																												onPlay={() =>
																													this.playVideo(
																														Id,
																														mediaProps
																													)
																												}
																											/>

																											<div
																												className="media-controls"
																												onClick={() =>
																													this.handleVideoSlide(
																														mediaProps
																													)
																												}
																											></div>
																										</div>
																									)}
																								</Media>
																							) : (
																								<div className="went-wrong-txt medical-shots-wrong-text">
																									{' '}
																									<p>
																										The requested URL was not
																										found on this server
																									</p>{' '}
																								</div>
																							)}
																							{/* <p className="sub-text">
                                              Coronavirus
                                            </p> */}

																							<h3 className="mb-0 name">
																								{title}
																							</h3>
																							{/* <p className="docName">
                                              Dr. Diana Borgio
                                            </p> */}
																						</div>
																					)
																					// : (
																					//   <div className="medical-shots-no-data">
																					//     {" "}
																					//     <p>No Data Found</p>{" "}
																					//   </div>
																					// )
																				}
																			</div>
																		);
																	})}
																</Slider>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										</Col>
									);
								}
								return null;
							})}
					</Row>
				</Container>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		HealthIssuesData: state.HealthIssues,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchBlogsData,
			fetchHealthTipsData,
			fetchMedicalShotsData,
			setVideoPlayOption,
		},
		dispatch
	);
};

export default withMediaProps(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogsSection))
);
