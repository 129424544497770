import React from "react";
import { Container, Row, Col } from "reactstrap";
import Logo from "../../../assets/images/logo.png";
import wishhealthBanner from "../../../assets/images/wishhealth-screen.png";
import AppStoreLinks from "../../../components/appStoreLinks";

import GetAppLink from "../../../components/form/GetAppLink";
import { fetchFeaturesData } from "./store/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: "",
    };
  }
  componentDidMount() {
    const { fetchFeaturesData } = this.props;

    fetchFeaturesData("page/110", (value) => {
      this.setState({ features: value });
    });
  }
  render() {
    const { features } = this.state;
    const content = features.replace(/(<([^>]+)>)/gi, "");

    return (
      <section className="wishhealth-features">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="mobile-image mb-4 mb-lg-0">
                <img
                  src={wishhealthBanner}
                  className="img-fluid"
                  alt="mobile"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="pt-xl-4 pl-xl-5 ml-xl-5 text-center text-lg-left">
                <div className="mb-4">
                  <img src={Logo} alt="logo" />
                </div>
                <div className="text">
                  <p>{content}</p>
                </div>
                <AppStoreLinks className="mt-4" />
                <GetAppLink />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchFeaturesData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
