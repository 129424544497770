import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";
import qs from 'qs'

class PanchkulaIndex extends React.Component {

  componentDidMount(){
    const { speciality } = qs.parse(this.props.location.search.substr(1))
    if (document.getElementById(speciality)!=null){
      document.getElementById(speciality).scrollIntoView()
   }
  
  }
  
  
  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <h2  id = "Dentist" className="text-center title">Dentist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-1, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-2, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-3, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-4, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-5-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-5, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-6, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-7, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-8, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-9-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-9, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-10, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                                to={"/search/Sector-11-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-11, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-12, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-13-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-13, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-14, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-15, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-16, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-17-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-17, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-18-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-18, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-19-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-19, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-20-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-20, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-21-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-21, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-22-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-22, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-23-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-23, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-24-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-24, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-25-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-25, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-26-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-26, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-27-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-27, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-28-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-28, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-29-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-29, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-30-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-30, Panchkula
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-31-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-31, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-32-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-32, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-33-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-33, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-34-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-34, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-35-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-35, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-36-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-36, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-37-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-37, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-38-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-38, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-39-Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-39, Panchkula
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 id = "Orthodontist" className="text-center title">Orthodontist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-1, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-2, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-3, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-4, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-5-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-5, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-6, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-7, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-8, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-9-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-9, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-10, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                                to={"/search/Sector-11-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-11, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-12, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-13-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-13, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-14, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-15, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-16, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-17-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-17, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-18-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-18, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-19-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-19, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-20-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-20, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-21-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-21, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-22-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-22, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-23-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-23, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-24-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-24, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-25-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-25, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-26-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-26, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-27-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-27, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-28-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-28, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-29-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-29, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-30-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-30, Panchkula
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-31-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-31, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-32-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-32, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-33-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-33, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-34-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-34, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-35-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-35, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-36-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-36, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-37-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-37, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-38-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-38, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-39-Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-39, Panchkula
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
               
                <h2 id = "Pediatrician"className="text-center title">Pediatrician</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-1, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-2, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-3, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-4, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-5-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-5, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-6, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-7, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-8, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-9-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-9, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-10, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                                to={"/search/Sector-11-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-11, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-12, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-13-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-13, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-14, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-15, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-16, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-17-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-17, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-18-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-18, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-19-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-19, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-20-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-20, Panchkula
                            </Link>
                          </li>
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-21-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-21, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-22-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-22, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-23-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-23, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-24-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-24, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-25-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-25, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-26-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-26, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-27-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-27, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-28-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-28, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-29-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-29, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-30-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-30, Panchkula
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-31-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-31, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-32-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-32, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-33-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-33, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-34-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-34, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-35-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-35, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-36-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-36, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-37-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-37, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-38-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-38, Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-39-Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-39, Panchkula
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
               
<h2 id = "Neurologist" className="text-center title">Neu`rologist</h2>
<div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/NeurologistNeurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Ophthamologist" className="text-center title">Ophthamologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "ENT" className="text-center title">ENT</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Homoeopath" className="text-center title">Homoeopath</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Cardiologist" className="text-center title">Cardiologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Ayurveda" className="text-center title">Ayurveda</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-28, Panchkula
          </Link>
        </li>
        <li>
          
          <Link
              to={"/search/Sector-29-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
              >
            Ayurveda in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Dermatologist" className="text-center title">DERMATOLOGIST</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Gynecologist" className="text-center title">Gynecologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Orthopedist" className="text-center title">Orthopedist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Psychiatrist" className="text-center title">Psychiatrist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2  id = "Internal Medicine" className="text-center title">Internal-Medicine</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Sexologist" className="text-center title">Sexologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Physiotherapist" className="text-center title">Physiotherapist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id  = "Dietitian/Nutritionist" className="text-center title">Dietitian/Nutritionist</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-1, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-2, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-3, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-4, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-5-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-5, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-6, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-7, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-8, Chandigarh
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-9-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-9, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-10, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
              to={"/search/Sector-11-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-11, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-12, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-13-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-13, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-14, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-15, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-16, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-17-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-17, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-18-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-18, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-19-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-19, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-20-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-20, Panchkula
          </Link>
        </li>
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-21-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-21, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-22-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-22, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-23-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-23, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-24-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-24, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-25-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-25, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-26-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-26, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-27-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-27, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-28-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-28, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-29-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-29, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-30-Panchkula/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-30, Panchkula
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-31-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-31, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-32-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-32, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-33-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-33, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-34-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-34, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-35-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-35, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-36-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-36, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-37-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-37, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-38-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-38, Panchkula
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-39-Panchkula/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-39, Panchkula
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
  </div>
               
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default PanchkulaIndex;

