import React from "react";
import { Container, FormGroup, Label, Input } from "reactstrap";

const SearchFilter = (props) => {
  const { clinicConsultation, videoConsultation } = props;
  return (
    <>
      <div className={`banner`}>
        <Container>
          <div className="search-filter-wrapper ">
            <div className="search-filter d-flex justify-content-between">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={clinicConsultation}
                    onClick={() =>
                      props.setclinicConsultation(!clinicConsultation)
                    }
                  />
                  <span className="checkmark"></span>
                  Book now
                </Label>
                <Label check style={{display:"none"}}>
                  <Input
                    type="checkbox"
                    checked={videoConsultation}
                    onClick={() =>
                      props.setvideoConsultation(!videoConsultation)
                    }
                  />
                  <span className="checkmark"></span>
                  Video Consultation
                </Label>
              </FormGroup>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SearchFilter;
