import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import { config } from "../../../config";
import { socialLinks } from "../../../constants";

const Contact = () => (
  <>
    <h4>Contact Us</h4>
    {/* <p className="mb-2">+91 172 4730099</p> */}
    <p className="mb-2">{process.env.REACT_APP_CONTACT_US}</p>
    <p className="mb-2">info@wishhealth.in</p>

    <div className="pt-2">
      {
        socialLinks.map(socialLink => {
          return <a className="text-decoration-none" href={socialLink.url} rel="noopener noreferrer" target="_blank"><img width={36} height={36} src={socialLink.image} alt={socialLink.altText}></img></a>
        })
      }
    </div>
  </>
);
export default () => (
  <footer id="footer">
    <div className="footer-top">
      <Col>
        <Row>


          <Col lg={12}>
            <Row className="linkRow">
              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <h4>CHANDIGARH</h4>
                  <ul className="pl-0">
                    <li>
                      <Link
                        to={"/search/Chandigarh/Dentist"}
                        onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dentist in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/Ophthalmologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ophthamologist in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/Ayurveda"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ayurveda in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/Homoeopath"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Homoeopath in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/ENT"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        ENT in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/DERMATOLOGIST"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dermatologist in Chandigarh
                      </Link>
                    </li>


                    <li>
                      <Link
                          to={"/search/Chandigarh/Pediatrician"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Pediatrician in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/Orthodontist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Orthodontist in Chandigarh
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Chandigarh/Gynecologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Gynecologist in Chandigarh
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Chandigarh/Neurotologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Neurotologist in Chandigarh
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Chandigarh/Cardiologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Cardiologist in Chandigarh
                      </Link>
                    </li>



                  </ul>
                </div>
              </Col>

              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <h4>MOHALI</h4>
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/search/Mohali/Dentist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dentist in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Ophthalmologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ophthalmologist in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Ayurveda"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ayurveda in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Homoeopath"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Homoeopath in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/ENT"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        ENT in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/DERMATOLOGIST"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dermatologist in Mohali
                      </Link>
                    </li>


                    <li>
                      <Link
                          to={"/search/Mohali/Pediatrician"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Pediatrician in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Orthodontist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Orthodontist in Mohali
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Mohali/Gynecologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Gynecologist in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Neurotologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Neurotologist in Mohali
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Mohali/Cardiologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Cardiologist in mohali
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <h4>PANCHKULA</h4>
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/search/Panchkula/Dentist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dentist in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/Ophthalmologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ophthalmologist in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/Ayurveda"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ayurveda in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Mohali/Homoeopath"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Homoeopath in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/ENT"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        ENT in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/DERMATOLOGIST"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dermatologist in Panchkula
                      </Link>
                    </li>


                    <li>
                      <Link
                          to={"/search/Panchkula/Pediatrician"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Pediatrician in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/Orthodontist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Orthodontist in Panchkula
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Panchkula/Gynecologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Gynecologist in Panchkula
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Panchkula/Neurotologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Neurotologist in Panchkula
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Panchkula/Cardiologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Cardiologist in Panchkula
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <h4>ZIRAKPUR</h4>
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/search/Zirakpur/Dentist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dentist in Mohali
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/Ophthalmologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ophthalmologist in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/Ayurveda"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Ayurveda in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/Homoeopath"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Homoeopath in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/ENT"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        ENT in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/DERMATOLOGIST"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Dermatologist in Zirakpur
                      </Link>
                    </li>


                    <li>
                      <Link
                          to={"/search/Zirakpur/Pediatrician"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Pediatrician in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/Orthodontist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Orthodontist in Zirakpur
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Zirakpur/Gynecologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Gynecologist in Zirakpur
                      </Link>
                    </li>
                    <li>
                      <Link
                          to={"/search/Zirakpur/Neurotologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Neurotologist in Zirakpur
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/search/Zirakpur/Cardiologist"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Cardiologist in Zirakpur
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>


              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">

                <div className="pl-lg-5 ">
                  <h4>WISHHEALTH.IN</h4>
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/privacy-policy"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Privacy Policy
                      </Link>
                    </li>

                    <li>
                      <Link
                          to={"/refund-policy"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Refund Policy
                      </Link>
                    </li>





                    <li>
                      <Link
                          to={"/terms-policy"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                        Terms and conditions
                      </Link>
                    </li>

                    <li>
                      <a href="/#blog">Blog</a>
                    </li>




                  </ul>
                </div>
              </Col>


              <Col xs={6} md={4} lg={3} xl={2} className="contactCol">
                <Contact />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>


          <Col lg={12}>
            <Row className="linkRow">

              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/locality-city-index"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                   City Index
                      </Link>
                    </li>

                  </ul>

                </div>
              </Col>
              <Col xs={6} md={4} lg={3} xl={2} className="linkCol">
                <div className="pl-lg-5 ">
                  <ul className="pl-0">
                    <li>
                      <Link
                          to={"/locality-index"}
                          onClick={() => window.scrollTo({ top: 0 })}
                      >
                       Locality City Index
                      </Link>
                    </li>

                  </ul>

                </div>
              </Col>

            </Row>
          </Col>
        </Row>
      </Col>
    </div>
    <div className="footer-bottom text-center">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="copyright-text">
              <p className="mb-0">
                Copyright 2020{" "}
                <a href={`${config.oldWebsiteUrl}`}>Wish Health</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);
