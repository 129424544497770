import React, { PureComponent } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ModalDialog extends PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			modal: false
		}
	}

	open = () => {
		this.setState(prevState => ({ modal: true }));
	}

	close = () => {
		this.setState(prevState => ({ modal: false }));
		if(this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {

		const { modal } = this.state;
		const { 
			className = '', 
			title = '', 
			children, 
			hideFooter = false, 
			cancelText = 'Cancel', 
			confirmText = 'Save',
			showHeaderClose=true,
			cancelButtonClass='',
			confirmButtonClass='',
			onSave
		} = this.props;

		return (
			<Modal isOpen={modal} toggle={this.close} className={className}>
				<ModalHeader toggle={showHeaderClose ? this.close : null}>{title}</ModalHeader>
				<ModalBody>
					{children}
				</ModalBody>
				{!hideFooter ? <ModalFooter>
					<Button className={cancelButtonClass} outline color="primary" onClick={this.close}>{cancelText}</Button>{' '}
					<Button className={confirmButtonClass} color="primary" onClick={onSave}>{confirmText}</Button>
				</ModalFooter> : null}
			</Modal>
		)
	}
}