import React,  {useEffect} from 'react';
import CallNow from "../../../../assets/images/search/callNow.png";


const CallContact = (props) => {
	const { toggle, extension } = props;



	useEffect(() => {
		/*	const anchor = document.createElement('a');
            anchor.href = `tel:${+919580011011},${extension}`;
            anchor.innerText = `+919580011011,${extension}`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
    */


		const anchor = document.createElement('a');
		anchor.href = `tel:${7733991188}`;
		anchor.innerText = `+919580011011`;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);

		//menuApiCall() // background call / fire and forget
	}, []);

	return (
		<div>
			{/*	<button className="close" onClick={toggle}>
				&times;
			</button>
			<div>

				<p className="d-text">
					Call <a href={`tel:${+919580011011},${extension}`}>+919580011011,{extension}</a>
				</p>

				<p className="d-text">Phone Number</p>
				<p className="v-text">{process.env.REACT_APP_CONTACT_US}</p>
				<p className="d-text">
					Dial the extension given below after the call connects
				</p>
				<p className="v-text">Ext. {extension}</p>
				<p className="d-text">
					If you could not connect with the center, please write to{' '}
					<a href="mailto:info@wishhealth.in">info@wishhealth.in</a>
				</p>
			</div>*/}
		</div>
	);
};

export default CallContact;
