import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Make sure it hasn't already been initialized
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'dc45437d45db7cb4c358ae2c4a6e036a9ea1f4ad',
        authDomain: 'https://accounts.google.com/o/oauth2/auth',
        projectId: 'wishhealth-79fce',
        storageBucket: "wishhealth-79fce.appspot.com",
    });
}

export default firebase;