import React, { useState } from "react";
import { Button } from "reactstrap";

import { withRouter } from "react-router-dom";
import { setSelectedTime } from "../store/action";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { titleCase } from "../../../common/stringFunction";
const TimeSelect = (props) => {
  const {
    shift,
    icon,
    timeSchedule = [],
    clinic_details,

    selectedTimeSlot,

    doctorsDetails,
    selectedDate,
    videoConsultationBooking,
    isOpen,
    wh_doctor_detail,
    doctorInfoDetails,
    doctorDataById,
    video_timings,
    video_bookings,
    typeEdit,
    booking_id,
    paymentDetails,
  } = props;

  let nameReplace =
    doctorInfoDetails && doctorInfoDetails.name.split(" ").join("-");

  let specialities =
    doctorInfoDetails &&
    doctorInfoDetails.wh_doctor_specialities &&
    doctorInfoDetails.wh_doctor_specialities.filter((item) => {
      return item.wh_speciality !== null;
    });
  let city =
    wh_doctor_detail &&
    wh_doctor_detail.wh_city &&
    wh_doctor_detail.wh_city.name;
  // let doctorsSpecialitiesArray =
  //   specialities &&
  //   specialities.length > 0 &&
  //   specialities.map((item) => {
  //     return item && item.wh_speciality && titleCase(item.wh_speciality.title);
  //   });

  let doctorsSpecialitiesReplaced =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      const data =
        item &&
        item.wh_speciality &&
        titleCase(item.wh_speciality.title).split(" ").join("-");
      return data.split("/").join("-");
    });

  let specialitiesReplace =
    doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");
  const [selectedArray, setselectedArray] = useState(selectedTimeSlot || {});
  const filterArray = [];
  timeSchedule &&
    timeSchedule.length > 0 &&
    timeSchedule.map((item) => {
      let timeFormatted = moment(item.time, ["hh:mm A"]).format("hh:mm A");
      let obj = { ...item, time: timeFormatted };
      filterArray.push(obj);
    });

  const handleButton = (data) => {
    props.toggle();

    if (data.isBooked) {
      return null;
    }

    const timeSlot = filterArray.find((item) => {
      if (JSON.stringify(item) === JSON.stringify(data)) {
        let obj = data;
        obj.selected = true;
        return obj;
      }
    });

    setselectedArray(timeSlot);

    props.setSelectedTime(timeSlot);

    // if (selectedArray.time === timeSlot.time) {
    //   return null;
    // }

    setTimeout(() => {
      props.history.push({
        pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${wh_doctor_detail.user_id}/bookingForm`,
        search: `?specialization=${specialitiesReplace}`,
        // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${wh_doctor_detail.user_id}/bookingForm`,
        state: {
          doctorDetails: doctorsDetails,
          wh_doctor_detail: wh_doctor_detail,
          clinic_details: clinic_details ? clinic_details : {},
          timeSlot: data && data.time,
          selectedDate: selectedDate,
          videoConsultationBooking: videoConsultationBooking,
          isOpen: isOpen,
          selectedTimeSlot: timeSlot,
          data: data,
          doctorInfoDetails: doctorInfoDetails,
          doctorDataById: doctorDataById,
          video_timings: video_timings,
          video_bookings: video_bookings,
          type: typeEdit === "edit" ? "edit" : "create",
          booking_id: booking_id,
          paymentDetails: paymentDetails,
        },
      });
    }, 1000);
  };

  return (
    <>
      {filterArray.length > 0 && (
        <div className="time-select">
          <div className="shift">
            <div className="shift-content">
              <div className="shift-name">
                <img src={icon} alt={shift} />
                <span>{shift}</span>
              </div>
            </div>
          </div>
          <div className="timeSchedule">
            {filterArray.map((data, index) => {
              const {
                time,
                isBooked = false,
                selected,
                date,
                clinic_id,
                type,
              } = data;

              let selectedFinal = selected;
              if (
                selectedArray.time === time &&
                selectedArray.date === date &&
                selectedArray.clinic_id === clinic_id &&
                selectedArray.type === type
              ) {
                selectedFinal = selectedArray.selected;
              }

              let hours = moment(time, ["h:mm A"]).format("HH");
              let minutes = moment(time, ["h:mm A"]).format("mm");

              return (
                <>
                  {time && (
                    <Button
                      color={
                        isBooked
                          ? "red-200"
                          : selectedFinal
                          ? "success"
                          : "c-white"
                      }
                      key={index}
                      onClick={() => handleButton(data)}
                      disabled={
                        new Date(date).setHours(hours, minutes, 0) < new Date()
                      }
                    >
                      {time}
                    </Button>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTimeSlot: state.SelectedTimeReducer.selectedArray,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedTime,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeSelect)
);
