import React from "react";

import {
  Home,
  Search,
  DoctorProfile,
  BookingForm,
  Dashboard,
  PatientSignIn,
  MyAppointments,
  UpdateProfile,
    LocalityIndex,
    DentistIndexC,
  PatientLogIn,
  PrivacyPolicy,
  PrivacyPolicyWeb,
   RefundPolicy,
  TermsConditions,
  TermsConditionsWeb,
  ZirakpurIndex,
  MohaliIndex,
  Aboutus,
  Panchkula,
    LocalityCity,
  GetInTouch,
  Faq,
  PanchkulaIndex,
} from "../pages";
import { PrivateRoutes, PublicRoutes } from "./RouteType";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import CallScreen from "../../src/components/card/DocResultCard/CallScreen";

export default () => {
  return (
    <>
      <Router>
        <>
          <Switch>
            <PublicRoutes exact path="/" component={Home} />
            <PublicRoutes
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
             <PublicRoutes
              exact
              path="/privacy-policy-web"
              component={PrivacyPolicyWeb}
            />
            <PublicRoutes
                exact
                path="/locality-city-index"
                component={LocalityCity}
            />

            <PublicRoutes
                exact
                path="/Chandigarh-index"
                component={DentistIndexC}
            />

<PublicRoutes
                exact
                path="/Panchkula-index"
                component={PanchkulaIndex}
            />

<PublicRoutes
                exact
                path="/Mohali-index"
                component={MohaliIndex}
            />
            <PublicRoutes
                exact
                path="/Zirakpur-index"
                component={ZirakpurIndex}
            />


            <PublicRoutes
                exact
                path="/locality-index"
                component={LocalityIndex}
            />
            <PublicRoutes
              exact
              path="/about-us"
              component={Aboutus}
            />

            
            <PublicRoutes exact path="/get-in-touch" component={GetInTouch} />
            <PublicRoutes exact path="/search" component={Search} />
            <PublicRoutes exact path="/search/:location/#" component={Search} />
            <PublicRoutes
              exact
              path="/search/#/:speciality"
              component={Search}
            />
            <PublicRoutes
              exact
              path="/search/:location/:speciality"
              component={Search}
            />
            <PublicRoutes
              exact
              path="/refund-policy"
              component={RefundPolicy}
            />
            <PublicRoutes
              exact
              path="/terms-policy"
              component={TermsConditions}
            />
            <PublicRoutes
              exact
              path="/terms-policy-web"
              component={TermsConditionsWeb}
            />

            <PublicRoutes
              path="/:location/doctor/:name-:speciality/:id"
              exact
              component={DoctorProfile}
            />

            <PublicRoutes
              exact
              path="/patient_signin"
              component={PatientSignIn}
            />
            <PublicRoutes
              exact
              path="/patient_login"
              component={PatientLogIn}
            />
            <PublicRoutes
              path="/:location/doctor/:name-:speciality/:id/bookingForm"
              exact
              component={BookingForm}
            />

            <PrivateRoutes
              exact
              path="/patient/appointment/:name/:id"
              component={MyAppointments}
            />

            <PrivateRoutes path="/patient/dashboard" component={Dashboard} />
            <PrivateRoutes
              exact
              path="/patient/updateProfile"
              component={UpdateProfile}
            />
            <PrivateRoutes exact path="/call" component={CallScreen} />

            <PublicRoutes exact path="/**" component={Home} />
          </Switch>
        </>
      </Router>
    </>
  );
};
