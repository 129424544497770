import React, { useState } from "react";
import { Button } from "reactstrap";
import Mute from "../../../assets/images/mute.svg";
import Unmute from "../../../assets/images/unmute.svg";
import EndCall from "../../../assets/images/end-call.svg";
import VideoIcon from "../../../assets/images/video.svg";
import VideoIconOff from "../../../assets/images/videoOff.svg";
import QBConfig from "../../../QBConfig";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setVideoSessionData } from "./store/action ";
import moment from "moment";
import VideoCallModal from "../../VideoCallModal";
import { connect } from "react-redux";
// import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { notification } from "../../notification";

let callStatusGlobal = "not_started";

const VidoeActionButtons = (props) => {
  const {
    audio,
    videoType,
    onEndCall,
    audioType,
    // onAcceptCall,
    // onRejectCall,
    // onCall,
    video,
    videoStatus,
    toggleVideo,
    // VideoSessionData,
  } = props;

  return (
    <div className="video-buttons">
      <button
        onClick={() => {
          videoType(video);
          toggleVideo();
        }}
      >
        <img alt="" src={videoStatus === "on" ? VideoIcon : VideoIconOff} />
      </button>
      <button
        className="endCall"
        onClick={() => {
          onEndCall();
        }}
      >
        <img alt="" src={EndCall} />
      </button>
      <button onClick={() => audioType(audio)}>
        <img alt="" src={audio === "mute" ? Mute : Unmute} />
      </button>
      {/* <button
				onClick={() => {
					onAcceptCall();
				}}
			>
				Accept
			</button> */}
      {/* <button
				onClick={() => {
					onRejectCall();
				}}
			>
				Reject
			</button> */}
      {/* <button
				onClick={() => {
					onCall();
				}}
			>
				Call
			</button> */}
    </div>
  );
};

const CallScreen = (props) => {
  const { QB } = window;
  const { VideoSessionData } = props;
  const [incomimgCallModal, setIncomimgCallModal] = useState(false);
  const [callStatus, setCallStatus] = useState("not_started");
  const [callAudio, setCallAudio] = useState("unmute");
  const [callVideo, setCallVideo] = useState("on");
  console.log("quickblox-callStatus", callStatus);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const session = state.VideoSessionData.session;
  const size = session ? Object.keys(session).length : 0;

  if (QB) {
    const { patientDetails } = props.location.state;
    QB.init(
      QBConfig.appId,
      QBConfig.authKey,
      QBConfig.authSecret,
      QBConfig.CONFIG
    );
    console.log("quickblox-QB", QB);
    const userCredentials = {
      userId: patientDetails.quickblox_id,
      password: "quickblox",
    };
    QB.chat.connect(userCredentials, function (error, data) {
      console.log("quickblox-Connected Successfully");
    });
    QB.webrtc.onCallListener = function onCallListener(session, extension) {
      console.group("quickblox-onCallListener.");
      console.log("Session: ", session);

      console.log("Extension: ", extension);
      console.groupEnd();
      dispatch(setVideoSessionData({ session }));
      setVideoModal();
    };
    QB.chat.onDisconnectedListener = function () {
      console.log("quickblox-onDisconnectedListener.");
      notification({ message: "Network connection lost", status: "warning" });
    };

    QB.webrtc.onCallStatsReport = function onCallStatsReport(
      session,
      userId,
      stats,
      error
    ) {
      console.group("quickblox-onCallStatsReport");
      console.log("userId: ", userId);
      console.log("session: ", session);
      console.log("stats: ", stats);
      console.groupEnd();
    };

    QB.webrtc.onSessionCloseListener = function onSessionCloseListener(
      session
    ) {
      if (callStatusGlobal === "started") {
        console.log("quickblox-Network connection lost");
        notification({ message: "Network connection lost", status: "warning" });
      }
      props.history.push("/patient/dashboard/upcoming-appointments");
      dispatch(setVideoSessionData({}));
      console.log("quickblox-onSessionCloseListener", session);
    };

    QB.webrtc.onUserNotAnswerListener = function onUserNotAnswerListener(
      session,
      userId
    ) {
      console.group("quickblox-onUserNotAnswerListener.");
      console.log("UserId: ", userId);
      console.log("Session: ", session);
      console.groupEnd();
      notification({ message: "Call not answered", status: "warning" });
    };

    // QB.webrtc.onRejectCallListener = function onRejectCallListener(
    // 	session,
    // 	userId,
    // 	extension
    // ) {
    // 	console.group('onRejectCallListener.');
    // 	console.log('UserId: ' + userId);
    // 	console.log('Session: ' + session);
    // 	console.log('Extension: ' + JSON.stringify(extension));
    // 	console.groupEnd();
    // };

    QB.webrtc.onStopCallListener = function onStopCallListener(
      session,
      userId,
      extension
    ) {
      console.group("quickblox-onStopCallListener.");
      console.log("UserId: ", userId);
      console.log("Session: ", session);
      console.log("Extension: ", extension);
      console.groupEnd();
      callStatusGlobal = "call_ended";
    };

    QB.webrtc.onAcceptCallListener = function onAcceptCallListener(
      session,
      userId,
      extension
    ) {
      console.group("quickblox-onAcceptCallListener.2r2323");
      console.log("UserId: ", userId);
      console.log("Session: ", session);
      console.log("Extension: ", extension);
      console.groupEnd();
      setCallStatus("started");
      callStatusGlobal = "started";
    };
    QB.webrtc.onUpdateCallListener = function onUpdateCallListener(
      session,
      userId,
      extension
    ) {
      console.group("quickblox-onUpdateCallListener.");
      console.log("UserId: " + userId);
      console.log("Session: " + session);
      console.log("Extension: " + JSON.stringify(extension));
      console.groupEnd();
    };

    QB.webrtc.onSessionConnectionStateChangedListener = function onSessionConnectionStateChangedListener(
      session,
      userId,
      connectionState
    ) {
      console.group("quickblox-onSessionConnectionStateChangedListener.");
      console.log("UserID:", userId);
      console.log("Session:", session);
      console.log("Сonnection state:", connectionState);
      console.groupEnd();
      setCallStatus("started");
      callStatusGlobal = "started";
    };
  }

  const toggleVideo = () => {
    if (callVideo === "on") {
      setCallVideo("off");
    } else {
      setCallVideo("on");
    }
  };

  const setVideoModal = () => {
    setIncomimgCallModal(!incomimgCallModal);
  };

  function onCall() {
    try {
      const { patientDetails, doctorDetails } = props.location.state;
      console.log("props.doctordetails", props.location.state.patientDetails);
      var calleesIds = [doctorDetails.wh_doctor_detail.quickblox_id]; // User's ids 122088935
      var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
      var callerID = patientDetails.quickblox_id; // Your user ID (optional, will be defined from chat connection)
      var additionalOptions = { bandwith: 512 }; // The video bandwith (number, optional, 0 = unlimited)
      var session = QB.webrtc.createNewSession(
        calleesIds,
        sessionType,
        callerID,
        additionalOptions
      );

      dispatch(setVideoSessionData({ session }));
      var mediaParams = {
        audio: true,
        video: true,
        elemId: "testVideo",
        options: {
          muted: false,
          mirror: true,
        },
      };

      session.getUserMedia(mediaParams, function (err, stream) {
        if (err) {
        } else {
        }
        console.log("stream-getUserMedia-onCall", stream);
        if (!stream) {
          if (callStatus === "not_started") {
            notification({
              message: "Your camera is not attached.",
              status: "warning",
            });
            session.stop(extension);
            props.history.push("/patient/dashboard/upcoming-appointments");
          }
        }
        session.attachMediaStream("testVideo", stream);

        session.onRemoteStreamListener = function onRemoteStreamListener(
          session,
          userId,
          stream
        ) {
          console.group(
            "onRemoteStreamListener: ",
            stream.getAudioTracks().length
          );
          console.log("userId: ", userId);
          console.log("Session: ", session);
          console.log("Stream-onRemoteStreamListener", stream);
          console.groupEnd();

          var state = session.connectionStateForUser(userId),
            peerConnList = QB.webrtc.PeerConnectionState;
          console.log("calling.....");
          if (
            state === peerConnList.DISCONNECTED ||
            state === peerConnList.FAILED ||
            state === peerConnList.CLOSED
          ) {
            return false;
          }

          session.peerConnections[userId].stream = stream;

          session.attachMediaStream("remote_video", stream);
        };

        dispatch(setVideoSessionData({ stream }));
        var extension = {};
        session.call(extension, function (error) {
          if (error) {
            console.log(error);
          } else {
            console.log(
              "Call SentCall SentCall SentCall SentCall SentCall Sent"
            );
          }
        });
      });
    } catch (err) {
      console.log("quickblox-err", err);
    }
  }
  function videoType(type) {
    if (size) {
      if (type === "mute") {
        session.unmute("video");
        dispatch(setVideoSessionData({ videoType: "unmute" }));
      } else {
        session.mute("video");
        dispatch(setVideoSessionData({ videoType: "mute" }));
      }
    }
  }

  function audioType(type) {
    if (size) {
      if (type === "mute") {
        session.unmute("audio");
        dispatch(setVideoSessionData({ audioType: "unmute" }));
      } else {
        session.mute("audio");
        dispatch(setVideoSessionData({ audioType: "mute" }));
      }
      if (callAudio === "unmute") {
        setCallAudio("mute");
      } else {
        setCallAudio("unmute");
      }
    }
  }
  function onEndCall() {
    callStatusGlobal = "call_ended";
    var extension = {};
    if (size) {
      session.stop(extension);
      props.history.push("/patient/dashboard/upcoming-appointments");
      dispatch(setVideoSessionData({}));
      console.log("End Call");
    }
  }
  function onAcceptCall() {
    var extension = {};
    const { QB } = window;
    if (size) {
      var mediaParams = {
        audio: true,
        video: true,
        elemId: "testVideo",
        options: {
          muted: false,
          mirror: true,
        },
      };
      session.getUserMedia(mediaParams, function (err, stream) {
        if (err) {
        } else {
          session.accept({});
        }
        console.log("stream-getUserMedia-onAcceptCall", stream);
        if (!stream) {
          if (callStatus === "not_started") {
            notification({
              message: "Your camera is not attached.",
              status: "warning",
            });
            onRejectCall();
          }
        }
        session.attachMediaStream("testVideo", stream);
        session.onRemoteStreamListener = function onRemoteStreamListener(
          session,
          userId,
          stream
        ) {
          console.group("onRemoteStreamListener.");
          console.log("userId: ", userId);
          console.log("Session: ", session);
          console.log("Stream: ", stream);
          console.groupEnd();
          var state = session.connectionStateForUser(userId),
            peerConnList = QB.webrtc.PeerConnectionState;
          console.log("calling.....");
          if (
            state === peerConnList.DISCONNECTED ||
            state === peerConnList.FAILED ||
            state === peerConnList.CLOSED
          ) {
            return false;
          }
          session.peerConnections[userId].stream = stream;
          session.attachMediaStream("remote_video", stream);
        };
        dispatch(setVideoSessionData({ stream }));
      });
      console.log("End Call");
    }
  }
  function onRejectCall() {
    var extension = {};
    if (size) {
      session.reject(extension);
      props.history.push("/patient/dashboard/upcoming-appointments");
      console.log("End Call");
    }
  }
  function currentPerson() {
    const doctorName =
      (props &&
        props.location &&
        props.location.state &&
        props.location.state.doctorDetails &&
        props.location.state.doctorDetails.name) ||
      "";
    return (
      <div className="person">
        {doctorName && <p className="name">Dr. {doctorName}</p>}
        <p className="time">{moment().format("h:mm a")}</p>
      </div>
    );
  }
  // const audio = state.VideoSessionData.audioType;

  if (!QB) {
    return <Redirect to="/patient/dashboard/upcoming-appointments" />;
  } else {
    let doctorName = "";
    let doctorPic = "";
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.doctorDetails
    ) {
      doctorName = props.location.state.doctorDetails.name || "";
      doctorPic =
        (props.location.state.doctorDetails.wh_doctor_detail &&
          props.location.state.doctorDetails.wh_doctor_detail.profile_pic) ||
        "";
    }
    const isValid =
      (props.history &&
        props.history.location &&
        props.history.location.state &&
        props.history.location.state.doctorDetails &&
        props.history.location.state.doctorDetails.wh_doctor_detail &&
        VideoSessionData.session &&
        VideoSessionData.session.initiatorID &&
        props.history.location.state.doctorDetails.wh_doctor_detail
          .quickblox_id === VideoSessionData.session.initiatorID) ||
      false;
    return (
      <div className="py-md-5">
        {incomimgCallModal && isValid && (
          <VideoCallModal
            setVideoModal={setVideoModal}
            onAcceptCall={onAcceptCall}
            onRejectCall={onRejectCall}
            senderName={doctorName}
            senderPic={doctorPic}
          />
        )}
        <div className="video-call-view">
          <div className="video-view">
            <div className="video-view-sender">
              <video
                className="video"
                autoplay
                playsinline
                id="remote_video"
              ></video>
              {currentPerson()}
            </div>
            <div
              className="video-view-receiver"
              style={{ opacity: callStatus === "not_started" ? 0 : 1 }}
            >
              <video
                className="video"
                autoplay
                playsinline
                id="testVideo"
                muted
              ></video>
              <div className="person">
                {props &&
                  props.location &&
                  props.location.state &&
                  props.location.state.patientDetails &&
                  props.location.state.patientDetails.name && (
                    <p className="name">
                      {props.location.state.patientDetails.name}
                    </p>
                  )}
              </div>
            </div>
            <div className="video-action-controller">
              <div className="wrapper">
                {currentPerson()}
                {callStatus === "not_started" || callStatus === "starting" ? (
                  <>
                    <div className="call-action-btn">
                      <Button
                        color="success"
                        className="start-call"
                        onClick={() => {
                          setCallStatus("starting");
                          callStatusGlobal = "starting";
                          onCall();
                        }}
                      >
                        {callStatus === "starting"
                          ? "Calling..."
                          : "Start Call"}
                      </Button>
                    </div>
                    {callStatus === "starting" && (
                      <div className="video-buttons">
                        <button
                          className="endCall m-0"
                          onClick={() => {
                            onEndCall();
                          }}
                        >
                          <img alt="" src={EndCall} />
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <VidoeActionButtons
                    videoType={videoType}
                    onEndCall={onEndCall}
                    audioType={audioType}
                    onAcceptCall={onAcceptCall}
                    onRejectCall={onRejectCall}
                    onCall={onCall}
                    video={state.VideoSessionData.videoType}
                    audio={callAudio}
                    videoStatus={callVideo}
                    toggleVideo={toggleVideo}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    VideoSessionData: state.VideoSessionData,
  };
};

export default connect(mapStateToProps, null)(CallScreen);
