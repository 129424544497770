import React from "react";
import { Row, Col } from "reactstrap";
import MetaTags from "react-meta-tags";
import moment from "moment";
import Loader from "../../loader/index";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import DashboardSlider from "./dashboardSlider";
import FewBookedDoctors from "./fewBookedDoctors";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAllAppointmentsData } from "../dashboard/store/action";
import PastAppointment from "./pastAppointments";
import cx from "classnames";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientBookings: [],
      patientDetails: {},
      loading: false,
    };
  }
  componentDidMount() {
    this.handleAppointments();
  }
  handleAppointments = () => {
    this.setState({
      loading: true,
    });

    let patient_id = this.props && this.props.savePatientId;

    this.props.fetchAllAppointmentsData(
      `patient/getPatientBookings/${patient_id}`,
      (response) => {
        this.setState({
          patientBookings: response.data.wh_patient_details[0].patient_bookings,
          patientDetails: response.data.wh_patient_details[0],
          loading: false,
        });
      }
    );
  };

  render() {
    window.scrollTo(0, 0);

    const { patientBookings, patientDetails, loading } = this.state;

    const matchPath = this.props.match.path;
    const { pathname } = this.props.location;
    let pastBookings = [],
      upcomingBookings = [];

    for (let i = 0; i < patientBookings.length; i++) {
      let item = patientBookings[i];

      let itemDate = new Date(item.date);
      let todayDate = new Date();
      if (moment(todayDate).isAfter(itemDate, "day")) {
        pastBookings.push(item);
      } else {
        upcomingBookings.push(item);
      }
    }

    return (
      <div className="appointment-wrapper">
        <MetaTags>
          <title>My Appointments - Book Appointment Online, View Fees</title>
        </MetaTags>
        <Row className="mb-sm-4">
          <Col className="appointments-buttons-wrapper">
            <Link
              to={matchPath + "/upcoming-appointments"}
              className={cx("appointment-buttons btn", {
                active: pathname.includes("/upcoming-appointments"),
              })}
            >
              Upcoming Appointments
            </Link>
            <Link
              to={matchPath + "/past-appointments"}
              className={cx("appointment-buttons btn", {
                active: pathname.includes("/past-appointments"),
              })}
            >
              Past Appointments
            </Link>
          </Col>
        </Row>

        <Row className="appointments-content">
          <Col sm={12}>
            {loading ? (
              <Loader />
            ) : (
              <div className="h-100">
                <Route
                  path={matchPath + "/upcoming-appointments"}
                  component={() => (
                    <DashboardSlider
                      upcomingBookings={upcomingBookings}
                      allAppointments={this.handleAppointments}
                      patientDetails={patientDetails}
                    />
                  )}
                />
                <Route
                  path={matchPath + "/past-appointments"}
                  component={() => (
                    <PastAppointment
                      pastBookings={pastBookings}
                      callDisable={true}
                    />
                  )}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FewBookedDoctors />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  savePatientId: state.BookAppointmentReducer.savePatientId,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAllAppointmentsData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
