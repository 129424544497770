import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";

class TermsConditionsWeb extends React.Component {
  render() {
    return (
      <div className="privacy-policy-wrapper">
        <div className="privacy-banner">
          <Container>
            <Row className="align-items-center text-md-left text-center">
              <Col md={6}>
                <h1>
            Terms and Conditions 
                </h1>
              </Col>
              <Col md={6}>
                <img src={PrivacyImage} className="img-fluid privacy" alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="mb-5">

            <ul>
              <li>
                In the event that, the Practitioner with whom User has
                booked a paid appointment via the Website, has not been
                able to meet the User, User will need to write to us at
                info@wishhealth.in within five (5) days from the occurrence
                of such event; in which case, the entire consultation amount
                as mentioned on the Website will be refunded to the User within
                the next five (5) to six (6) business days in the original mode
                of payment done by the User while booking. In case where the User,
                does not show up for the appointment booked with a Practitioner,
                without cancelling the appointment beforehand, the amount will not
                be refunded, and treated. However, where cancellation charges have
                been levied (as charged by the Practitioner/Practice), you would not
                be entitled to complete refund even if you have cancelled beforehand.{" "}
              </li>
              <li>
                Users will not be entitled for any refunds in cases where,
                the Practitioner is unable to meet the User at the exact
                time of the scheduled appointment time and the User is
                required to wait, irrespective of the fact whether the
                User is required to wait or choose to not obtain the medical
                services from the said Practitioner..
              </li>

            </ul>






          </div>
        </Container>
      </div>
    );
  }
}

export default TermsConditionsWeb;
