import {
  BOOK_APPOINTMENT_REQUEST,
  CHECK_IF_USER_EXISTING_REQUEST,
  SEND_OTP_BEFORE_BOOKING_REQUEST,
  VERIFY_OTP_REQUEST,
  CONFIRM_BOOKING_REQUEST,
  CREATE_PAYMENT_REQUEST,
  CAPTURE_PAYMENT_REQUEST,
  SET_PATIENT_ID,
  SET_NAME,
  SET_PHONE_NUMBER,
  SET_BOOKING_ID,
  SET_ID,
  COUPON_CODE_REQUEST,
  SET_IMAGE,
  CITY_REQUEST,
  STATE_REQUEST,
  VERIFY_COUPON_REQUEST,
  FETCH_PATIENT_DETAILS_REQUEST,
} from "./types";

export const fetchBookAppointmentData = (data, callback) => {
  return {
    type: BOOK_APPOINTMENT_REQUEST,
    payload: data,
    callback,
  };
};

export const fetchPatientDetails = (data, callback) => {
  return {
    type: FETCH_PATIENT_DETAILS_REQUEST,
    payload: data,
    callback,
  };
};

export const fetchCities = (data, callback) => {
  return {
    type: CITY_REQUEST,

    payload: data,
    callback,
  };
};

export const fetchStates = (data, callback) => {
  return {
    type: STATE_REQUEST,
    payload: data,
    callback,
  };
};

export const checkIfUserExisting = (url, body, callback) => {
  return {
    type: CHECK_IF_USER_EXISTING_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const sendOtpBeforeBooking = (url, body, callback) => {
  return {
    type: SEND_OTP_BEFORE_BOOKING_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const verifyOtp = (url, body, callback) => {
  return {
    type: VERIFY_OTP_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const confirmBooking = (url, body, callback) => {
  return {
    type: CONFIRM_BOOKING_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const createPayment = (url, body, callback) => {
  return {
    type: CREATE_PAYMENT_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const capturePayment = (url, body, callback) => {
  return {
    type: CAPTURE_PAYMENT_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const couponCode = (url, body, callback) => {
  return {
    type: COUPON_CODE_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const verifyCoupon = (url, body, callback) => {
  return {
    type: VERIFY_COUPON_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const setPatientId = (data) => {
  return {
    type: SET_PATIENT_ID,

    payload: data,
  };
};
export const setId = (data) => {
  return {
    type: SET_ID,

    payload: data,
  };
};

export const setName = (data) => {
  return {
    type: SET_NAME,
    payload: data,
  };
};

export const setImage = (data) => {
  return {
    type: SET_IMAGE,
    payload: data,
  };
};
export const setBookingId = (data) => {
  return {
    type: SET_BOOKING_ID,
    payload: data,
  };
};
export const setPhoneNumber = (data) => {
  return {
    type: SET_PHONE_NUMBER,

    payload: data,
  };
};
