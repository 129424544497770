import React from "react";
import Routes from "./routes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
// import {
//   BreadcrumbsProvider,
//   Breadcrumbs,
//   BreadcrumbsItem
// } from 'react-breadcrumbs-dynamic'
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {/* <Breadcrumbs/>  */}
        <Routes />
        <NotificationContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
