import React, { Component } from 'react';
import Avatar from '../../assets/images/avatar.svg';
import CallAccept from '../../assets/images/callAccept.svg';
import CallReject from '../../assets/images/callReject.svg';

export default class VideoCallModal extends Component {
	render() {
		const {
			setVideoModal,
			onAcceptCall,
			onRejectCall,
			senderName,
			senderPic
		} = this.props;
		return (
			<div className="incoming-call-wrapper">
				<h2>Incoming Call </h2>
				<div className="call-user-icon">
					<img src={senderPic || Avatar} alt="profile" className="profile" />
				</div>
				<h4 className="username">{senderName}</h4>
				<button
					onClick={() => {
						setVideoModal();
						onAcceptCall();
					}}
					className="call-btns"
				>
					<img src={CallAccept} alt="accept" />
				</button>
				<button
					onClick={() => {
						setVideoModal();
						onRejectCall();
					}}
					className="end-call"
				>
					<img src={CallReject} alt="reject" />
				</button>
			</div>
		);
	}
}
