import { put, takeLatest, call } from "redux-saga/effects";
import {
  FETCH_DOCTOR_DATA_BY_ID_REQUEST,
  FETCH_DOCTOR_DATA_BY_ID_SUCCESS,
  FETCH_DOCTOR_DATA_BY_ID_FAILED,
  FETCH_DOCTOR_DETAILS_REQUEST,
  FETCH_DOCTOR_DETAILS_FAILED,
  FETCH_DOCTOR_DETAILS_SUCCESS,
  FETCH_DOCTOR_REVIEWS_SUCCESS,
  FETCH_DOCTOR_REVIEWS_REQUEST,
  FETCH_DOCTOR_REVIEWS_FAILED,
  SAVE_DOCTOR_REVIEWS_REQUEST,
  SAVE_DOCTOR_REVIEW_SUCCESS
} from "./types";

import { apiCallGet, apiCallPost } from "../../../common/axios";

async function callDoctorsDatabyId(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callDoctorDetails(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callDoctorReviews(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callSaveReview(data) {
  const res = await apiCallPost(data.payload.url, data.payload.data, false, true);
  return res;
}

function* fetchDoctorsDataById(action) {
  const response = yield call(callDoctorsDatabyId, action);

  const { data } = response;

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: FETCH_DOCTOR_DATA_BY_ID_SUCCESS,
        doctorsDataById: data,
      });
    } else {
      yield put({ type: FETCH_DOCTOR_DATA_BY_ID_FAILED });
    }
  }
}

function* fetchDoctorDetails(action) {
  const response = yield call(callDoctorDetails, action);

  const { data } = response;

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: FETCH_DOCTOR_DETAILS_SUCCESS,
        doctorDetails: data,
      });
    } else {
      yield put({ type: FETCH_DOCTOR_DETAILS_FAILED });
    }
  }
}

function* fetchDoctorReviews(action) {
  const response = yield call(callDoctorReviews, action);

  const { data } = response;
  if (response && response.data) {
    action.callback && action.callback(response.data);
    if (response.status === 200) {
      yield put({
        type: FETCH_DOCTOR_REVIEWS_SUCCESS,
        doctorReviews: data.data,
      });
    } else {
      yield put({ type: FETCH_DOCTOR_REVIEWS_FAILED });
    }
  }
}

function* saveDoctorReview(action) {
  const response = yield call(callSaveReview, action);

  const { data } = response;

  if (response && response.data) {
    action.callback(response.data);
    if (response.status === 201) {
      /* yield put({
        type: SAVE_DOCTOR_REVIEW_SUCCESS,
        doctorReviews: data.data,
      }); */
      yield call(fetchDoctorReviews, { payload: `review/get?doctor_id=${action.payload.data.doctor_id}`});
    } else {
      //yield put({ type: FETCH_DOCTOR_REVIEWS_FAILED });
    }
  }
}

export default function* DoctorProfileWatcher() {
  yield takeLatest(FETCH_DOCTOR_DATA_BY_ID_REQUEST, fetchDoctorsDataById);
  yield takeLatest(FETCH_DOCTOR_DETAILS_REQUEST, fetchDoctorDetails);
  yield takeLatest(FETCH_DOCTOR_REVIEWS_REQUEST, fetchDoctorReviews);
  yield takeLatest(SAVE_DOCTOR_REVIEWS_REQUEST, saveDoctorReview);  
}
