export const BOOK_APPOINTMENT_REQUEST = "BOOK_APPOINTMENT_REQUEST";
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";
export const BOOK_APPOINTMENT_FAILED = "BOOK_APPOINTMENT_FAILED";

export const CHECK_IF_USER_EXISTING_REQUEST = "CHECK_IF_USER_EXISTING_REQUEST";
export const CHECK_IF_USER_EXISTING_SUCCESS = "CHECK_IF_USER_EXISTING_SUCCESS";
export const CHECK_IF_USER_EXISTING_FAILED = "CHECK_IF_USER_EXISTING_FAILED";

export const SEND_OTP_BEFORE_BOOKING_REQUEST =
  "SEND_OTP_BEFORE_BOOKING_REQUEST";
export const SEND_OTP_BEFORE_BOOKING_SUCCESS =
  "SEND_OTP_BEFORE_BOOKING_SUCCESS";
export const SEND_OTP_BEFORE_BOOKING_FAILED = "SEND_OTP_BEFORE_BOOKING_FAILED";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

export const CONFIRM_BOOKING_REQUEST = "CONFIRM_BOOKING_REQUEST";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";

export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED";

export const CAPTURE_PAYMENT_REQUEST = "CAPTURE_PAYMENT_REQUEST";
export const CAPTURE_PAYMENT_SUCCESS = "CAPTURE_PAYMENT_SUCCESS";
export const CAPTURE_PAYMENT_FAILED = "CAPTURE_PAYMENT_FAILED";

export const SET_PATIENT_ID = "SET_PATIENT_ID";
export const SET_ID = "SET_ID";
export const SET_NAME = "SET_NAME";
export const SET_BOOKING_ID = "SET_BOOKING_ID";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_IMAGE = "SET_IMAGE";
export const CLEAR_PHONE_NUMBER = "CLEAR_PHONE_NUMBER";
export const CLEAR_NAME = "CLEAR_NAME";

export const COUPON_CODE_REQUEST = "COUPON_CODE_REQUEST";
export const COUPON_CODE_SUCCESS = "COUPON_CODE_SUCCESS";
export const COUPON_CODE_FAILED = "COUPON_CODE_FAILED";

export const CITY_REQUEST = "CITY_REQUEST";
export const CITY_SUCCESS = "CITY_SUCCESS";
export const CITY_FAILED = "CITY_FAILED";

export const STATE_REQUEST = "STATE_REQUEST";
export const STATE_SUCCESS = "STATE_SUCCESS";
export const STATE_FAILED = "STATE_FAILED";
export const VERIFY_COUPON_REQUEST = "VERIFY_COUPON_REQUEST";
export const VERIFY_COUPON_SUCCESS = "VERIFY_COUPON_SUCCESS";
export const VERIFY_COUPON_FAILED = "VERIFY_COUPON_FAILED";

export const FETCH_PATIENT_DETAILS_REQUEST = "FETCH_PATIENT_DETAILS_REQUEST";
export const FETCH_PATIENT_DETAILS_SUCCESS = "FETCH_PATIENT_DETAILS_SUCCESS";
export const FETCH_PATIENT_DETAILS_FAILED = "FETCH_PATIENT_DETAILS_FAILED";
