import React from "react";
import { Button, FormGroup, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { sendAppLink } from "../store/action";
import Loader from "../../../loader/index";

class GetAppLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_number: "",
      errorType: "",
      errorText: "",
      loading: false,
    };
  }

  handleInput = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errorType: "",
      errorText: "",
    });
  };
  sendAppLink = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const { contact_number } = this.state;
    const { sendAppLink } = this.props;

    if (contact_number === "") {
      this.setState({
        errorType: "contact_number",
        errorText: <span className="text-danger">Phone number is empty</span>,
        loading: false,
      });
      return;
    }
    if (contact_number.length < 10 || contact_number.length > 10) {
      this.setState({
        errorType: "contact_number",
        errorText: (
          <span className="text-danger">
            <b>Phone number should be of length 10</b>
          </span>
        ),
        loading: false,
      });
      return;
    }
    if (contact_number !== "") {
      let filter = /^\d{10}$/;
      if (!filter.test(contact_number)) {
        this.setState({
          errorType: "contact_number",
          errorText: (
            <span className="text-danger">
              <b>Please enter valid phone number</b>
            </span>
          ),
          loading: false,
        });
        return;
      }
    }

    sendAppLink(`auth/sendAppLink/${contact_number}`, "", (value) => {
      if (value === 200) {
        NotificationManager.success(
          "Link has been sent on your phone successfully"
        );
        this.setState({
          loading: false,
          contact_number: "",
        });
      }
    });
  };
  render() {
    const { contact_number, errorText, errorType, loading } = this.state;
    return (
      <div className="get-app-link-wrapper mt-4">
        <p className="mb-2">Get the link to download the app</p>
        <div className="d-table d-lg-block mx-auto">
          <div className="d-sm-flex text-center">
            <FormGroup className="get-app-input mb-sm-0">
              <div className="country-code">+91</div>
              <Input
                type="text"
                placeholder="Enter phone number"
                name="contact_number"
                onChange={this.handleInput}
                value={contact_number}
              />
            </FormGroup>
            {/* {loading ? ( */}
            <div className="position-relative">
              {loading && (
                <div className="search-loader">
                  <Loader />
                </div>
              )}

              <Button
                color="primary"
                className="send-app-link"
                onClick={this.sendAppLink}
              >
                <span style={loading ? { opacity: 0 } : {}}>Send app link</span>
              </Button>
            </div>
          </div>
        </div>
        {errorType && errorType === "contact_number" && errorText}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendAppLink,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetAppLink)
);
