import React, { useState, useEffect, useRef } from "react";
import { countLines } from "../../../util";
import { Button } from "reactstrap";
import classes from "./styles.module.scss";
import cx from "classnames";

const DescriptionComponent = (props) => {
  const { description = "" } = props;
  const [length, setLenght] = useState(0);

  const [isReadMoreOption, setIsReadMoreOption] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const decRef = useRef(null);

  const fetchLength = () => {
    const count = countLines(decRef.current);
    setLenght(count);
    length === 0 && count > 5 && setIsReadMore(true);
    length === 0 && count > 5 && setIsReadMoreOption(true);
  };
  useEffect(fetchLength);
  return (
    <>
      <p
        ref={decRef}
        className={cx("mb-1", { [classes.isReadMore]: isReadMore })}
      >
        {description}
      </p>
      <div className="mb-3">
        {isReadMoreOption && (
          <Button
            color="link"
            size="sm"
            onClick={() => setIsReadMore((previous) => !previous)}
          >
            {isReadMore ? "Read More..." : "Show Less..."}
          </Button>
        )}
      </div>
    </>
  );
};
export default DescriptionComponent;
