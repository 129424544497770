import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { extractChar } from '../../../util/string';
import { fetchTestimonialsData } from "./store/action";
import { Media, Player, utils, withMediaProps } from "react-media-player";
import { setVideoPlayOption } from "../HealthIssues/store/action";
import { config } from "../../../config";
import HealthIssues from "../HealthIssues";
const { keyboardControls } = utils;
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: { adaptiveHeight: true },
    },
  ],
};

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [],
      play: false,
      activeVideoIndex: null,
    };
  }
  componentDidMount() {
    const { fetchTestimonialsData } = this.props;
    fetchTestimonialsData("data/testimonials", (value) =>
      this.setState({ testimonials: value })
    );
  }

  playVideo = (index, media) => {
    const { activeVideoIndex } = this.state;
    const { setVideoPlayOption, HealthIssuesData } = this.props;
    var size = Object.keys(HealthIssuesData.currentVideoData).length;
    if (
      HealthIssuesData &&
      size > 0 &&
      (activeVideoIndex !== index ||
        HealthIssuesData.videoType !== "testimonials")
    ) {
      HealthIssuesData.currentVideoData.pause();
    }
    setVideoPlayOption("testimonials", media);
    this.setState({
      activeVideoIndex: index,
    });
  };

  pauseVideo = (currentVideo) => {
    const { activeVideoIndex } = this.state;
    const { HealthIssuesData } = this.props;
    this.setState({
      [`video${activeVideoIndex}`]: false,
    });
    var size = Object.keys(HealthIssuesData.currentVideoData).length;
    if (
      HealthIssuesData &&
      size > 0 &&
      HealthIssuesData.videoType === "testimonials"
    ) {
      HealthIssuesData.currentVideoData.pause();
    }
  };
  handleVideoSlide = (media) => {
    this.setState({
      activeMedia: media,
    });

    media && media.playPause();
  };
  validateYouTubeUrl = (url) => {
    if (url != undefined || url != "") {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        return true;
      } else {
        return false;
        // Do anything for not being valid
      }
    }
  };
  render() {
    const { testimonials } = this.state;

    return (
      <div>
        <section className="wishhealth-doctors">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="text-center title theme-title">
                  <span>What our</span> doctor’s say
                </h2>
              </Col>
            </Row>
            <Row className="align-items-center my-5">
              <Col xl={6} className="pr-0">
                <div className="testimonial-slider">
                  <Slider
                    className="px-0 mb-0"
                    {...settings}
                    afterChange={this.pauseVideo}
                  >
                    {testimonials &&
                      testimonials.length > 0 &&
                      testimonials.map((item, index) => {
                      
                        let name=item.client_name.split("Dr. ")
                     
                        const content = item.content.replace(
                          /(<([^>]+)>)/gi,
                          ""
                        );

                        const content_split = content.split("|");

                        const splitarr =
                          item.client_name.match(/[^\s"]+|"([^"]*)"/gi);
                        let designation = "";
                        splitarr &&
                          splitarr.length > 0 &&
                          splitarr.map((item, index) => {
                            if (item.match(/^"(.+(?="$))"$/)) {
                              designation = item;
                              splitarr.splice(index, 1);
                            }
                            return null;
                          });
                        let client_name =
                          splitarr && splitarr.length > 0 && splitarr.join(" ");
                        let isValidURL;
                        if (item.video_link !== undefined) {
                          isValidURL = this.validateYouTubeUrl(item.video_link);
                        }

                        return (
                          <div key={index}>
                            <div className="mx-0 d-flex flex-column flex-sm-row">
                              <div>
                                {item.video_link.includes("http") ? (
                                  <div className="py-xl-4 mx-2 video-shots-wrap">
                                    {isValidURL ? (
                                      <Media>
                                        {(mediaProps) => (
                                          <div
                                            className="media"
                                            onKeyDown={keyboardControls.bind(
                                              null,
                                              mediaProps
                                            )}
                                          >
                                            <Player
                                              src={item.video_link}
                                              className="media-player"
                                              isPlaying={
                                                this.state[`video${index}`] !==
                                                undefined
                                                  ? this.state[`video${index}`]
                                                  : false
                                              }
                                              onPlay={() =>
                                                this.playVideo(
                                                  index,
                                                  mediaProps
                                                )
                                              }
                                            />
                                            <div
                                              className="media-controls"
                                              onClick={() =>
                                                this.handleVideoSlide(
                                                  mediaProps
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}
                                      </Media>
                                    ) : (
                                      <div className="went-wrong-txt">
                                        {" "}
                                        <p>
                                          The requested URL was not found on
                                          this server
                                        </p>{" "}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="textimonial-image">
                                  {/* { item.image? <img
                                      src={`${config.apiUrl}${item.image}`}
                                      className="img-fluid"
                                      alt="testimonial"
                                    />:(<div className="dummy-text">
                                    <span>{extractChar(name[1])}</span>
                                  </div>)} */}
                                  <div className="dummy-text">
                                    <span>{extractChar(name[1])}</span>
                                  </div>
                                  </div>
                                )}
                              </div>
                              <div className="p-0 flex-grow-1">
                                <div className="client-content">
                                  <p className="client-about">
                                    {content_split[0]}
                                  </p>
                                  <div className="client-info">
                                    <h4>{client_name}</h4>
                                    <p>{designation}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </Col>
              <Col xl={6} className="pl-0 pr-xl-2 pr-0">
                <HealthIssues />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HealthIssuesData: state.HealthIssues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchTestimonialsData,
      setVideoPlayOption,
    },
    dispatch
  );
};
export default withMediaProps(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Testimonials))
);
