import React from "react";

import Logo from "../../../assets/images/logo.png";
import Dashboard from "../../../assets/images/dashboard/dashboard.png";
import UpdateProfile from "../../../assets/images/dashboard/update-profile.png";

import BookedDoctor from "../../../assets/images/dashboard/doctor.svg";
import { scrollToTop } from "../../../util";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import Avatar from "../../../assets/images/avatar.png";
import { extractChar } from '../../../util/string';
const menuItems = [
  {
    name: "Dashboard",
    icon: Dashboard,
    link: "/patient/dashboard/upcoming-appointments",
  },
  {
    name: "Update Profile",
    icon: UpdateProfile,
    link: "/patient/updateProfile",
  },
  {
    name: "Book a Doctor",
    icon: BookedDoctor,
    link: "/search",
  },
];

const Sidebar = (props) => {
  const { sidebarOpen, toggle } = props;
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className={`dashboard-sidebar ${sidebarOpen ? "isVisible" : ""}`}>
      <div className="backdrop" onClick={toggle} />
      <div className="dashboard-sidebar-content">
        <div className="logo">
          <Link to="/" onClick={scrollToTop}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="profile-wrapper">
          <div className="profile-icon">
           { props && props.saveImage?<img src={(props && props.saveImage) || Avatar} alt="profile" />
           :(	<div className="dummy-text">
           <span>{extractChar(props && props.saveName)}</span>
         </div>)}
          </div>
          <div className="ml-3 profile-text">
            <h2>{props && props.saveName}</h2>
            <p>{props && props.savePhoneNumber}</p>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul>
            {menuItems.map(({ name, link = "/", icon }, index) => (
              <li key={index}>
                {link === "/search" ? (
                  <Link
                    to={{
                      pathname: "/search",
                      state: {
                        clinic: true,
                        video: true,
                      },
                    }}
                    className={cx({ active: link === pathname })}
                  >
                    {" "}
                    <img src={icon} alt={name} className="mr-3" />
                    {name}
                  </Link>
                ) : (
                  <Link to={link} className={cx({ active: link === pathname })}>
                    <img src={icon} alt={name} className="mr-3" />
                    {name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    saveName: state.BookAppointmentReducer.saveName,
    saveImage: state.BookAppointmentReducer.saveImage,
    savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
