import React, { useState, useEffect, Fragment } from "react";
import SearchFilter from "./searchFilter";
import SearchResults from "./searchResults";
import { Container } from "reactstrap";
import { SearchDoctorForm } from "../../components/form";
import { useParams, withRouter } from "react-router-dom";

import { saveLocationName } from "./store/action";

import { useDispatch } from "react-redux";
const SearchWrapper = ({ children }) => (
  <div className="banner search-banner">
    <Container>{children}</Container>
  </div>
);

const Search = (props) => {
  const { history } = props;
  const { pathname } = history.location;
  const splitPath = pathname.split("/");
  const hashOfPath =
    history.location && history.location.hash.replace("#/", "");
  const params = useParams();
  const [searchLocation, setSearchLocation] = useState("");
  const [isLocationDropDown, setIsLocationDropDown] = useState(false);
  const [isSearchDropDown, setIsSearchDropDown] = useState(false);
  const [search, setSearch] = useState("");
  const [clinicConsultation, setclinicConsultation] = useState(false);
  const [searchClicked, setsearchClicked] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const [videoConsultation, setvideoConsultation] = useState(false);
  const [consultLiveNow, setconsultLiveNow] = useState(false);
  const [latLong, setLatLong] = useState({
    latitude:
      props &&
      props.history.location.state &&
      props.history.location.state.latLong &&
      props.history.location.state.latLong.latitude
        ? props.history.location.state.latLong.latitude
        : 0,
    longitude:
      props &&
      props.history.location.state &&
      props.history.location.state.latLong &&
      props.history.location.state.latLong.longitude
        ? props.history.location.state.latLong.longitude
        : 0,
  });
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setsearchClicked(true);

    setTimeout(() => {
      setsearchClicked(false);
    }, 1000);
    let location =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.searchLocation
        ? props.location.state.searchLocation
        : params.location
        ? params.location
        : splitPath[2] && splitPath[2] != "#"
        ? splitPath[2]
        : "";
    let search =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.search
        ? props.location.state.search
        : params.speciality
        ? params.speciality
        : hashOfPath
        ? hashOfPath
        : splitPath[3] && splitPath[3] != "#"
        ? splitPath[3]
        : "";
    let videoConsultation =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.video
        ? props.location.state.video
        : false;
    let clinicConsultation =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.clinic
        ? props.location.state.clinic
        : false;

    let consultLiveNow =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.consult === true
        ? true
        : false;
    if (location) {
      location = location.split("-").join(" ");
    }
    if (search) {
      search = search.split("-").join(" ");
    }

    setSearchLocation(location);
    setclinicConsultation(clinicConsultation);
    setvideoConsultation(videoConsultation);
    setconsultLiveNow(consultLiveNow);
    setSearch(search);
  }, [props]);
  const handleSearch = (e) => {
    e && e.preventDefault();
    setIsLocationDropDown(false);
    setIsSearchDropDown(false);

    let searchReplaceAll = search.trim(" ").split(" ").join("-");
    let searchLocationReplaceAll = searchLocation
      .trim(" ")
      .replace(/,/g, "")
      .split(" ")
      .join("-");

    if (searchLocationReplaceAll !== "" && searchReplaceAll === "") {
      props.history.push({
        pathname: `/search/${searchLocationReplaceAll}/#`,
        state: {
          searchLocation,
          search,
          video: videoConsultation,
          clinic: clinicConsultation,
          latLong: latLong,
        },
      });
    }

    if (searchReplaceAll !== "" && searchLocationReplaceAll === "") {
      props.history.push({
        pathname: `/search/#/${searchReplaceAll}`,
        state: {
          searchLocation,
          search,
          video: videoConsultation,
          clinic: clinicConsultation,
          latLong: latLong,
        },
      });
    }
    if (searchReplaceAll !== "" && searchLocationReplaceAll !== "") {
      props.history.push({
        pathname: `/search/${searchLocationReplaceAll}/${searchReplaceAll}`,
        state: {
          searchLocation,
          search,
          video: videoConsultation,
          clinic: clinicConsultation,
          latLong: latLong,
        },
      });
    }
    if (searchReplaceAll === "" && searchLocationReplaceAll === "") {
      props.history.push({
        pathname: "/search",

        state: {
          searchLocation,
          search,
          video: videoConsultation,
          clinic: clinicConsultation,
          latLong: latLong,
        },
      });
    }

    setsearchClicked(true);
    setIsSearchApplied(true);

    dispatch(saveLocationName({ search, searchLocation }));
    setTimeout(() => {
      setIsLoading(false);
      setsearchClicked(false);
    }, 1000);
  };

  const SearchWrapperComponent = pathname.includes("search")
    ? SearchWrapper
    : Fragment;
  return (
    <>
      <div>
        <SearchWrapperComponent>
          <SearchDoctorForm
            searchLocation={searchLocation}
            search={search}
            setSearchLocation={setSearchLocation}
            setSearch={setSearch}
            handleSearch={handleSearch}
            setLatLong={setLatLong}
            setIsSearchDropDown={setIsSearchDropDown}
            setIsLocationDropDown={setIsLocationDropDown}
            isLocationDropDown={isLocationDropDown}
            isSearchDropDown={isSearchDropDown}
            consultLiveNow={consultLiveNow}
          />
        </SearchWrapperComponent>
        {window.location.pathname.includes("search") && (
          <SearchFilter
            searchPage={
              window.location.pathname.includes("search") ? true : false
            }
            clinicConsultation={clinicConsultation}
            videoConsultation={videoConsultation}
            setclinicConsultation={setclinicConsultation}
            setvideoConsultation={setvideoConsultation}
            consultLiveNow={consultLiveNow}
            setconsultLiveNow={setconsultLiveNow}
          />
        )}
      </div>
      {window.location.pathname.includes("search") && (
        <SearchResults
          searchLocation={searchLocation}
          search={search}
          videoConsultation={videoConsultation}
          clinicConsultation={clinicConsultation}
          consultLiveNow={consultLiveNow}
          searchClicked={searchClicked}
          latLong={latLong}
          loading={loading}
          setIsLoading={setIsLoading}
          isSearchApplied={isSearchApplied}
          setIsSearchApplied={setIsSearchApplied}
          setconsultLiveNow={setconsultLiveNow}
        />
      )}
    </>
  );
};

export default withRouter(Search);
