import 'firebase/storage';
import firebase from "./firebase";

const storage = firebase.storage();

const filePath = "patient-profiles/";

export const uploadFile = async (file) => {
    const fileName = `${new Date().getTime()}-image.jpeg`;
    return new Promise(async (resolve, reject) => {
        const uploadFile = storage
            .ref(filePath + fileName)
            .put(file);
        await uploadFile.on(
            "state_changed",
            null,
            (err) => {
                reject(err);
            },
            () => {
                // To get the download url then sets the image from firebase as the value for the imgage url :
                storage
                    .ref(filePath)
                    .child(fileName)
                    .getDownloadURL()
                    .then((url) => {
                        resolve(url);
                    })
                    .catch(() => {
                        reject(null);
                    });
            }
        );
    })
}

export const fetchFile = () => {
    return new Promise(resolve => {
        storage
            .ref(
                filePath
            ).listAll().then((res) => {
                if (res) {
                    resolve(res)
                }
            })
    })
}

export const downloadUrl = (path) => {
    return storage.ref(path).getDownloadURL()
        .then((url) => {
            // Do something with the URL ...
            return url;
        })
}
