import {
  FETCH_FAQS_REQUEST,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQS_FAILED,
} from "./types";
import { CLEAR_REDUCER } from "../../../../components/layout/store/types";

const initialState = {
  FAQSData: [],
};

const FAQSReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAQS_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_FAQ_SUCCESS: {
      return {
        ...state,
        FAQSData: action.faqsData || [],
      };
    }
    case FETCH_FAQS_FAILED: {
      return {
        ...state,
      };
    }

    case CLEAR_REDUCER: {
      return {
        state: initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default FAQSReducer;
