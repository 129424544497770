import React, { useState, Fragment, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Collapse,
} from "reactstrap";
import { titleCase } from "../../../../common/stringFunction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { isProduction } from "../../../../config";
import Degree from "../../../../assets/images/profile/degree.png";
import Timings from "../../../../assets/images/profile/timings.png";
import ClinicName from "../../../../assets/images/profile/clinicName.png";
import ClinicConsultation from "../../../../assets/images/profile/clinicConsultation.png";
import VideoConsultation from "../../../../assets/images/profile/videoConsultation.png";
import User from "../../../../assets/images/profile/user.svg";

import Phone from "../../../../assets/images/profile/phone.png";

import Avatar from "../../../../assets/images/avatar.png";
import { BookAppoitmentModal } from "../../../../components/modal";
import Edit from "../../../../assets/images/profile/edit.svg";
import { extractChar } from '../../../../util/string';
import {
  setPatientId,
  setName,
  setPhoneNumber,
  setBookingId,
  setId,
} from "../../store/action";
import Loader from "../../../../loader";
import cx from "classnames";
import {
  checkIfUserExisting,
  sendOtpBeforeBooking,
  verifyOtp,
  confirmBooking,
  createPayment,
  couponCode,
  verifyCoupon,
  capturePayment,
} from "../../store/action";
import { editAppointment } from "../../../../pages/dashboard/store/action";
const ConfirmBooking = (props) => {
  let obj = props;

  if (
    props.location.state &&
    (props.location.state.type === "edit" ||
      props.location.state.type === "create")
  ) {
    obj = props.location.state;
  }

  const {
    clinic_details = null,
    timeSlot = null,
    selectedDate = "",
    type = null,
    videoConsultationBooking = null,
    wh_doctor_detail = null,
    data = null,
    doctorInfoDetails = null,
    doctorDataById = null,
    video_bookings = null,
    video_timings = null,
    paymentDetails = null,
  } = obj;

  let remainingAmount =
    wh_doctor_detail &&
    wh_doctor_detail.doc_fees - wh_doctor_detail.doc_advance_fees;

  let nameReplace =
    doctorInfoDetails && doctorInfoDetails.name.split(" ").join("-");
  let wh_doctor_qualifications = [];
  if (doctorInfoDetails) {
    wh_doctor_qualifications = doctorInfoDetails.wh_doctor_qualifications;
  }

  let city =
    wh_doctor_detail &&
    wh_doctor_detail.wh_city &&
    wh_doctor_detail.wh_city.name;

  let specialities =
    doctorInfoDetails &&
    doctorInfoDetails.wh_doctor_specialities &&
    doctorInfoDetails.wh_doctor_specialities.filter((item) => {
      return item.wh_speciality !== null;
    });

  let doctorsSpecialitiesReplaced =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      const data =
        item &&
        item.wh_speciality &&
        titleCase(item.wh_speciality.title).split(" ").join("-");
      return data.split("/").join("-");
    });

  let specialitiesReplace =
    doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");
  const [clinicAppointment, setclinicAppointment] = useState(false);
  const [isNameField, setIsNameField] = useState(false);
  const [name, set_Name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [errorType, setErrortype] = useState("");
  const [errorText, setErrortext] = useState("");
  const [registered, setRegistered] = useState("");
  const [sendOtp, setsendOtp] = useState(false);
  const [booking, setBooking] = useState(false);

  const [payLater, setPayLater] = useState(false);
  const [otpInputFilled, setOtpInputFilled] = useState(false);
  const [patient_id, setPatient_Id] = useState(
    props && props.savePatientId ? props && props.savePatientId : ""
  );
  const [otpInput, setOtpInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");

  const history = useHistory();
  useEffect(() => {
    let pathname = window.location.pathname.split("/");

    let user_id = pathname[pathname.length - 2];

    if (!obj.clinic_details && !obj.modalOpenForEditing) {
      history.push({
        pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
        search: `?specialization=${specialitiesReplace}`,
      });
      // history.push(`/doctor/${nameReplace}/specialization=${specialitiesReplace}/${user_id}`);
    }
    if (obj.modalOpenForEditing) {
      clinicAppointmentToggle();
    }
  }, []);
  useEffect(() => {
    if (otpInput.length === 5) {
      verifyOtp();
      document.getElementById("otp1").focus();
    }
  }, [otpInput]);

  const paymentHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    if (booking) {
      history.push({
        pathname: "/patient/dashboard/upcoming-appointments",
      });
    } else {
      let dataObj;
      if (props.selectedTimeSlot && props.selectedTimeSlot.type === "video") {
        dataObj = {
          amount:
            videoConsultationBooking && videoConsultationBooking.length > 0
              ? videoConsultationBooking[0].advance_fees
              : videoConsultationBooking.advance_fees,
        };
      } else {
        dataObj = {
          amount:
            wh_doctor_detail && wh_doctor_detail.doc_advance_fees !== 0
              ? wh_doctor_detail.doc_advance_fees
              : 0,
        };
      }

      if (dataObj.amount === 0 || dataObj.amount === "0") {
        handleConfirmBooking(e, "pay_later");
      } else {
        props.createPayment("order/", dataObj, (response) => {
          const data = response;
          setLoading(false);
          const options = {
            key: !isProduction
              ? "rzp_test_5G5VyL9K8BPPNJ"
              : "rzp_live_OlRwttnj1mQtYv",
            name: "Wish Health",
            description: "Search and consult doctors anywhere anytime",
            order_id: data.id,
            handler: async (response) => {
              setLoading(true);
              try {
                setLoading(false);
                handleConfirmBooking({}, "pay", (result) => {
                  let objCreatePayment;
                  if (
                    props.selectedTimeSlot &&
                    props.selectedTimeSlot.type === "video"
                  ) {
                    objCreatePayment = {
                      advance_fees:
                        videoConsultationBooking &&
                        videoConsultationBooking.length > 0
                          ? videoConsultationBooking[0].advance_fees
                          : videoConsultationBooking.advance_fees,
                      fees_type: "advance",
                      booking_id: result.idBooking.data.id,
                      advance_fees_payment_id: response.razorpay_payment_id,
                      paymentDecision: "paid",
                    };
                  } else {
                    objCreatePayment = {
                      advance_fees:
                        wh_doctor_detail &&
                        wh_doctor_detail.doc_advance_fees !== 0
                          ? wh_doctor_detail.doc_advance_fees
                          : 0,
                      fees_type: "advance",
                      booking_id: result.idBooking.data.id,
                      advance_fees_payment_id: response.razorpay_payment_id,
                      paymentDecision: "advancePaid",
                    };
                  }

                  props.capturePayment(
                    "order/capturePayment",
                    objCreatePayment,
                    (captureResponse) => {
                      if (captureResponse.message === "success") {
                        setBooking(true);
                        setLoading(false);
                        if (
                          result.idBooking.message ===
                          "Appointment Booked Successfully."
                        ) {
                          NotificationManager.success(
                            "Appointment Booked Successfully.",
                            "",
                            1000
                          );
                        } else {
                          NotificationManager.info(
                            result.idBooking.message,
                            "",
                            1000
                          );
                        }
                      } else {
                        setLoading(false);
                        NotificationManager.warning("Payment Failed", "", 1000);
                      }
                    }
                  );
                });
              } catch (err) {
                console.log(err);
              }
            },
            theme: {
              color: "#389EEC",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        });
      }
    }
  };

  const paymentHandleWhileEditing = (e) => {
    setLoading(true);
    e.preventDefault();
    if (booking) {
      history.push({
        pathname: "/patient/dashboard/upcoming-appointments",
      });
    } else {
      let dataObj;
      if (props.selectedTimeSlot && props.selectedTimeSlot.type === "video") {
        dataObj = {
          amount:
            videoConsultationBooking && videoConsultationBooking.length > 0
              ? videoConsultationBooking[0].advance_fees
              : videoConsultationBooking.advance_fees,
        };
      } else {
        dataObj = {
          amount:
            wh_doctor_detail && wh_doctor_detail.doc_advance_fees !== 0
              ? wh_doctor_detail.doc_advance_fees
              : 0,
        };
      }

      props.createPayment("order/", dataObj, (response) => {
        const data = response;

        const options = {
          key: !isProduction
            ? "rzp_test_5G5VyL9K8BPPNJ"
            : "rzp_live_OlRwttnj1mQtYv",
          name: "Wish Health",
          description: "Search and consult doctors anywhere anytime",
          order_id: data.id,
          handler: async (response) => {
            setLoading(true);
            try {
              let objCreatePayment;
              if (
                props.selectedTimeSlot &&
                props.selectedTimeSlot.type === "video"
              ) {
                objCreatePayment = {
                  advance_fees:
                    videoConsultationBooking &&
                    videoConsultationBooking.length > 0
                      ? videoConsultationBooking[0].advance_fees
                      : videoConsultationBooking.advance_fees,
                  fees_type: "advance",
                  booking_id: props && props.booking_id,
                  advance_fees_payment_id: response.razorpay_payment_id,
                };
              } else {
                objCreatePayment = {
                  advance_fees:
                    wh_doctor_detail && wh_doctor_detail.doc_advance_fees !== 0
                      ? wh_doctor_detail.doc_advance_fees
                      : 0,
                  fees_type: "advance",
                  booking_id: props && props.booking_id,
                  advance_fees_payment_id: response.razorpay_payment_id,
                };
              }

              props.capturePayment(
                "order/capturePayment",
                objCreatePayment,
                (captureResponse) => {
                  if (captureResponse.message === "success") {
                    setBooking(true);
                    setPayLater(true);
                    setLoading(false);
                    if (
                      props &&
                      props.booking_id !== "This slot is already booked!"
                    ) {
                      NotificationManager.success(
                        "Booking Successful",
                        "",
                        1000
                      );
                    } else {
                      NotificationManager.info(
                        props && props.booking_id,
                        "",
                        1000
                      );
                    }
                  } else {
                    setLoading(false);
                    setPayLater(true);
                    NotificationManager.warning("Payment Failed", "", 1000);
                  }
                }
              );
            } catch (err) {
              console.log(err);
            }
          },
          theme: {
            color: "#389EEC",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      });
    }
  };

  const toggleNameField = () => {
    setIsNameField((previous) => !previous);
    set_Name("");
  };

  let degrees =
    wh_doctor_qualifications &&
    wh_doctor_qualifications.length > 0 &&
    wh_doctor_qualifications.filter((item) => {
      return item.wh_qualification && item.wh_qualification.degree !== null;
    });

  let doctorDegree =
    degrees &&
    degrees.length > 0 &&
    degrees.map((item, key) => {
      const length = degrees && degrees.length;

      return (
        <Fragment key={key}>
          {key === length - 1
            ? item.wh_qualification.degree
            : `${item.wh_qualification.degree} , `}
        </Fragment>
      );
    });

  let profileImage =
    wh_doctor_detail && wh_doctor_detail.profile_pic
      ? wh_doctor_detail && wh_doctor_detail.profile_pic
      : Avatar;

  const clinicAppointmentToggle = () => {
    setclinicAppointment(!clinicAppointment);
  };
  const sendOtpApi = (name) => {
    setLoading(true);
    let formData = {
      phone: phone_number,
    };
    if (registered === "not registered") {
      formData = {
        name: name,
        phone: phone_number,
      };
    }

    props.sendOtpBeforeBooking("patient/patientSave", formData, (value) => {
      if (value.message === "Otp Sent Successfully") {
        setLoading(false);
        NotificationManager.success("Otp has been sent successfully", "", 1000);
      } else {
        setLoading(false);
        NotificationManager.warning("Invalid Otp", "", 1000);
      }
      setPatient_Id(value.data.user_id);
      props.setPatientId(value.data.user_id);
      props.setId(value.data.wh_patient_details[0].id);

      setsendOtp(true);

      setOtpInput("");
    });
  };

  const handleContinueButton = () => {
    setLoading(true);

    if (isNameField) {
      if (name === "") {
        setErrortype("name");
        setErrortext(<span className="text-danger">Name is empty</span>);
        setLoading(false);
        return;
      }
    }

    if (phone_number === "") {
      setErrortype("phone_number");
      setErrortext(<span className="text-danger">Mobile number is empty</span>);
      setLoading(false);
      return;
    } else if (phone_number.length < 10 || phone_number.length > 10) {
      setErrortype("phone_number");
      setErrortext(
        <span className="text-danger">
          {" "}
          <b>Phone number should be of length 10</b>
        </span>
      );
      setLoading(false);
      return;
    } else if (phone_number !== "") {
      let filter = /^\d{10}$/;
      if (!filter.test(phone_number)) {
        setErrortype("phone_number");
        setErrortext(
          <span className="text-danger">
            <b>Please enter valid phone number</b>
          </span>
        );
        setLoading(false);
        return;
      }
    }

    if (registered === "") {
      let obj = {
        phone: phone_number,
      };

      props.checkIfUserExisting("patient/getPatientExistence", obj, (value) => {
        setRegistered(value.message);

        if (value.message === "already registered") {
          setLoading(false);
          set_Name(value.data.name);
          sendOtpApi();
        } else if (value.message === "not registered" && name === "") {
          setLoading(false);
          toggleNameField();
        } else {
          setLoading(false);
          sendOtpApi(name);
        }
      });
    } else {
      setLoading(false);
      sendOtpApi(name);
    }
  };

  const handleName = (e) => {
    e.preventDefault();

    set_Name(e.target.value);

    setErrortype("");
    setErrortext("");
  };
  const handleOtp = (e, index, nextId = e.target.id) => {
    e.preventDefault();

    let value = otpInput;
    let splitVal = value.split("");
    if (e.target.value === "" && splitVal[index + 1]) {
      splitVal[index] = ",";
    } else {
      splitVal[index] = e.target.value;
    }
    value = splitVal.join("");
    setOtpInput(value);
    document.getElementById(nextId).focus();
    setErrortype("");
    setErrortext("");
  };

  const handlePhonenumber = (e) => {
    e.preventDefault();

    setPhone_number(e.target.value);

    setErrortype("");
    setErrortext("");
  };

  const verifyOtp = () => {
    setLoading(true);
    if (otpInput === "") {
      setErrortype("otpInput");
      setErrortext(<span className="text-danger">Otp is empty</span>);
      setLoading(false);
      return;
    }

    if (otpInput.length < 5) {
      setErrortype("otpInput");
      setErrortext(<span className="text-danger">Please enter Otp</span>);
      setLoading(false);
      return;
    }
    let formDataOtp = {
      otp: otpInput,
      id: patient_id,
    };

    props.verifyOtp("patient/verifyOtp", formDataOtp, (value) => {
      setLoading(false);
      setOtpInputFilled(true);

      if (value.message === "otp valid") {
        NotificationManager.success(value.message, "", 1000);
        setsendOtp(false);
        props.setName(name);
        props.setPhoneNumber(phone_number);
        localStorage.setItem("isLoggedIn", true);
      } else {
        NotificationManager.error(value.message, "", 1000);
        setOtpInput("");
      }
    });
  };

  const handleConfirmBooking = async (e, type, callback) => {
    let formDataOtp;

    formDataOtp = {
      clinic_id:
        data.type === "clinic" ? clinic_details && clinic_details.clinic_id : 1,
      user_id: props && props.saveId,
      doctor_id: wh_doctor_detail && wh_doctor_detail.user_id,
      date: selectedDate,
      time: timeSlot,
      status: wh_doctor_detail && wh_doctor_detail.status,
      user_type: "U",
      booked_by: "patient",
      modify_by: "patient",
      type: data.type === "clinic" ? "clinic" : "video",
      paymentDecision:
        data.type === "clinic"
          ? type === "pay"
            ? "advancePaid"
            : "payLater"
          : "paid",
    };

    setLoading(true);

    callConfirmBooking(formDataOtp, type, (result) => {
      callback && callback(result);
    });
  };

  const callConfirmBooking = (formDataOtp, type, callback) => {
    props.confirmBooking("create/bookAppointment", formDataOtp, (value) => {
      if (type === "pay_later") {
        setBooking(true);
        if (value.data.message !== "Appointment Booked Successfully.") {
          setLoading(false);
          NotificationManager.info(value.data.message, "", 1000);
        }
        if (value.data.message === "Appointment Booked Successfully.") {
          setLoading(false);

          NotificationManager.success(value.data.message, "", 1000);
        }
      } else {
        if (value.data.status === 400) {
          setLoading(false);
          NotificationManager.info(value.data.message, "", 1000);
        }
      }

      setPayLater(true);
      callback({ idBooking: value.data });
    });
  };

  const handleEditButton = (e, type) => {
    e.preventDefault();
    if (booking) {
      history.push({
        pathname: "/patient/dashboard/upcoming-appointments",
      });
    } else {
      setLoading(true);
      let obj = {
        booking_id: props && props.booking_id,

        clinic_id:
          clinic_details && clinic_details.clinic_id
            ? clinic_details.clinic_id
            : 1,
        date: selectedTimeSlot && selectedTimeSlot.date,
        time: selectedTimeSlot && selectedTimeSlot.time,
        modify_by: "patient",
      };

      props.editAppointment("booking/update", obj, (response) => {
        if (response.message === "Updated Successfully") {
          setLoading(false);
          NotificationManager.success(response.message, "", 1000);
          if (type === "confirmBooking") {
            setBooking(true);
          }
          if (type === "bookingDone") {
            setBooking(true);
          }
        }
        if (response.status === 400) {
          setLoading(false);
          NotificationManager.info(response.message, "", 1000);
        }
      });
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleContinueButton();
    }
  };
  const handleInput = (e) => {
    e.preventDefault();
    setCoupon(e.target.value);
    setErrortext("");
    setErrortype("");
  };
  const handleCoupon = () => {
    setLoading(true);
    if (coupon === "") {
      setErrortype("coupon");
      setErrortext(
        <p>
          <span className="text-danger">Coupon Code is empty</span>
        </p>
      );
      setLoading(false);
      return;
    }
    let obj = {
      name: coupon,
      patient_id: props && props.saveId,
    };
    props.verifyCoupon("booking/verify-coupon", obj, (response) => {
      if (response.status === 400) {
        NotificationManager.info(response.message, "", 3000);

        setPayLater(false);
        setLoading(false);
        setCoupon("");
      } else {
        try {
          handleConfirmBooking({}, "pay", (result) => {
            if (result.idBooking.status === 400) {
              NotificationManager.info(result.idBooking.message, "", 1000);
              setPayLater(false);
              setLoading(false);
              setCoupon("");
            } else {
              let dataObj;
              if (
                props.selectedTimeSlot &&
                props.selectedTimeSlot.type === "video"
              ) {
                dataObj = {
                  name: coupon,
                  booking_id: result.idBooking.data.id,
                  patient_id: patient_id,

                  advance_fees:
                    videoConsultationBooking &&
                    videoConsultationBooking.length > 0
                      ? videoConsultationBooking[0].advance_fees
                      : videoConsultationBooking.advance_fees,

                  fees: 0,
                };
              } else {
                dataObj = {
                  name: coupon,
                  booking_id: result.idBooking.data.id,
                  patient_id: props && props.saveId,
                  advance_fees:
                    wh_doctor_detail && wh_doctor_detail.doc_advance_fees !== 0
                      ? wh_doctor_detail.doc_advance_fees
                      : 0,
                  fees:
                    wh_doctor_detail && wh_doctor_detail.doc_fees !== null
                      ? wh_doctor_detail.doc_fees
                      : 0,
                };
              }

              props.couponCode("booking/applyCoupon", dataObj, (response) => {
                if (response.status === 400) {
                  NotificationManager.info(response.message, "", 3000);

                  setPayLater(false);
                  setLoading(false);
                  setCoupon("");
                } else {
                  NotificationManager.success(
                    "Coupon applied and appointment booked successfully",
                    "",
                    3000
                  );
                  history.push({
                    pathname: "/patient/dashboard/upcoming-appointments",
                  });
                  setBooking(true);
                  setPayLater(true);
                  setLoading(false);
                  setCoupon("");
                }
              });
            }
          });
        } catch (err) {}
      }
    });
  };

  let nameReducer = props && props.saveName;
  let phoneNumberReducer = props && props.savePhoneNumber;
  const selectedTimeSlot =
    type === "edit"
      ? props.location.state.selectedTimeSlot
      : props.selectedTimeSlot;

  return (
    <div className="confirm-booking-wrapper">
      <Row>
        <Col lg={6} className="mt-lg-0 mt-3">
          <div className="confirm-booking">
            <h2 className="title-name">Doctor Details</h2>
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className="p-4">
                <div className="booking-details-wrap">
                  <div className="doctor-profile-image">
                  {wh_doctor_detail && wh_doctor_detail.profile_pic?  <img
                      src={wh_doctor_detail && wh_doctor_detail.profile_pic}
                      className="profile-image"
                      alt="profile"
                    />:(
                      <div className="dummy-text">
                      <span>{extractChar(doctorInfoDetails && doctorInfoDetails.name)}</span>
                    </div>
                    )}
                    <div className="mt-sm-4 pt-2">
                      <h3>
                        {wh_doctor_detail && wh_doctor_detail.prefix}
                        {doctorInfoDetails && doctorInfoDetails.name}
                      </h3>
                      {doctorDegree && (
                        <p>
                          <img src={Degree} className="mr-2" alt="degree" />
                          {doctorDegree}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="appointment-details">
                    {selectedTimeSlot && selectedTimeSlot.type === "clinic" ? (
                      <p className="visit-text mb-3 ">
                        <img
                          src={ClinicConsultation}
                          className="mr-2"
                          alt="clinicConsultaion"
                        />
                        Clinic visit appointment
                      </p>
                    ) : (
                      <p className="visit-text mb-3">
                        <img
                          src={VideoConsultation}
                          className="mr-2"
                          alt="clinicConsultaion"
                        />
                        Video appointment
                      </p>
                    )}

                    <div className="appointments mb-4">
                      <img src={Timings} alt="timings" />
                      <div className="text">
                        <h4>Appointment Date & Time:</h4>
                        <p className="mb-1">
                          {selectedTimeSlot && selectedTimeSlot.date} at{" "}
                          {selectedTimeSlot && selectedTimeSlot.time}
                        </p>
                        <Button
                          className="appointmentBtn"
                          onClick={clinicAppointmentToggle}
                          disabled={booking}
                        >
                          Change Appointment Date & Time
                        </Button>
                      </div>
                    </div>
                    {selectedTimeSlot && selectedTimeSlot.type === "clinic" && (
                      <div className="appointments mb-0">
                        <img src={ClinicName} alt="clinicName" />
                        <div className="text">
                          <h4>Hospital Name & Address:</h4>
                          <p className="mb-1">{`${
                            (clinic_details && clinic_details.name) || ""
                          } & ${
                            (clinic_details && clinic_details.address) || ""
                          }`}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {selectedTimeSlot && selectedTimeSlot.type === "clinic" && (
                <div className="arrival-time">
                  <p className="mb-0">
                    Please Arrive 15 min before the appointment
                  </p>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col lg={6} className="mt-lg-0 mt-3">
          {sendOtp && patient_id ? (
            <div className="confirm-booking">
              <h2 className={cx("title-name ", { "bg-gray": loading })}>
                We have sent you an OTP on
              </h2>

              <div className="p-4 otp-wrapper">
                {loading && <Loader />}
                <div
                  className="content"
                  style={loading ? { opacity: 0.6 } : {}}
                >
                  <h3 className="phone-no">
                    {phone_number}
                    <button className="edit-btn">
                      <img src={Edit} alt="edit" />
                    </button>
                  </h3>
                  <h5>Please enter the 5-digit OTP here to verify </h5>
                  <div className="d-flex justify-content-between my-3">
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp1"
                      id="otp1"
                      value={
                        otpInput && otpInput[0] && otpInput[0] !== ","
                          ? otpInput[0]
                          : ""
                      }
                      autoFocus
                      onChange={(e) => handleOtp(e, 0, "otp2")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp2"
                      id="otp2"
                      value={
                        otpInput && otpInput[1] && otpInput[1] !== ","
                          ? otpInput[1]
                          : ""
                      }
                      onChange={(e) => handleOtp(e, 1, "otp3")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp3"
                      id="otp3"
                      value={
                        otpInput && otpInput[2] && otpInput[2] !== ","
                          ? otpInput[2]
                          : ""
                      }
                      onChange={(e) => handleOtp(e, 2, "otp4")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp4"
                      id="otp4"
                      value={
                        otpInput && otpInput[3] && otpInput[3] !== ","
                          ? otpInput[3]
                          : ""
                      }
                      onChange={(e) => handleOtp(e, 3, "otp5")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp5"
                      id="otp5"
                      value={
                        otpInput && otpInput[4] && otpInput[4] !== ","
                          ? otpInput[4]
                          : ""
                      }
                      onChange={(e) => handleOtp(e, 4)}
                    />
                  </div>
                  {errorType && errorType === "otpInput" && errorText}
                  <div className="otp-text">
                    <Button
                      color="link"
                      className="border-0"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={sendOtpApi}
                      disabled={loading}
                    >
                      Resend OTP
                    </Button>
                  </div>
                </div>

                <div className="text-center mb-lg-5 mb-sm-4">
                  <Button
                    color="primary"
                    className="confirm-btn"
                    onClick={verifyOtp}
                    disabled={loading}
                  >
                    Continue to Booking
                  </Button>
                </div>
              </div>
            </div>
          ) : props.saveName && props.savePhoneNumber ? (
            <div className="confirm-booking">
              <h2 className={cx("title-name ", { "bg-gray": loading })}>
                Patient Details
              </h2>
              <div className="p-4">
                {loading && <Loader />}
                <div
                  className="content"
                  style={loading ? { opacity: 0.6 } : {}}
                >
                  <div className="booking-details-content">
                    <div className="text-center mb-3">
                      <img
                        src={User}
                        className="user-img"
                        alt="user"
                        style={loading ? { filter: "contrast(0)" } : {}}
                      />
                      <h2 className="mb-1">{nameReducer || name}</h2>
                      <div className="phone">
                        <a>
                          <img
                            src={Phone}
                            className="mr-2"
                            alt="phone"
                            style={loading ? { filter: "contrast(0)" } : {}}
                          />
                          <span style={loading ? { opacity: 0.6 } : {}}>
                            {phoneNumberReducer || phone_number}
                          </span>
                        </a>
                      </div>
                    </div>

                    {selectedTimeSlot && selectedTimeSlot.type === "clinic" ? (
                      <div className="consultation-fees">
                        <span>
                          Advance Fees: ₹
                          {wh_doctor_detail &&
                          wh_doctor_detail.doc_advance_fees !== 0
                            ? wh_doctor_detail.doc_advance_fees
                            : 0}
                        </span>
                      </div>
                    ) : (
                      <div className="consultation-fees">
                        <span>
                          Total Fees: ₹
                          {videoConsultationBooking &&
                          videoConsultationBooking.length > 0
                            ? videoConsultationBooking[0].advance_fees
                            : videoConsultationBooking.advance_fees}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {!booking && type !== "edit" && (
                  <div className="coupon-code-wrapper">
                    <div className="d-flex">
                      <Input
                        type="text"
                        name="coupon"
                        id="coupon"
                        placeholder="Enter coupon code"
                        onChange={handleInput}
                        value={coupon}
                        disabled={loading}
                        style={loading ? { opacity: 0.5 } : {}}
                      />

                      <Button
                        disabled={loading}
                        onClick={handleCoupon}
                        color="primary"
                      >
                        Apply
                      </Button>
                    </div>
                    {errorType && errorType === "coupon" && errorText}
                  </div>
                )}

                {type !== "edit" &&
                  wh_doctor_detail &&
                  wh_doctor_detail.doc_advance_fees !== 0 && (
                    <div className="text-center mb-lg-5 mb-sm-4">
                      <Button
                        color="primary"
                        className="confirm-btn border-0"
                        onClick={paymentHandler}
                        disabled={loading}
                      >
                        {booking ? "Go to Dashboard" : "Pay & Confirm Booking"}
                      </Button>
                      {selectedTimeSlot.type === "clinic" && !payLater && (
                        <div className="mt-2">
                          <Button
                            color="primary"
                            className="paylater-btn border-0"
                            onClick={(e) =>
                              handleConfirmBooking(e, "pay_later")
                            }
                            disabled={loading}
                          >
                            Pay at clinic
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                {type !== "edit" &&
                  wh_doctor_detail &&
                  wh_doctor_detail.doc_advance_fees === 0 && (
                    <div className="text-center mb-lg-5 mb-sm-4">
                      <Button
                        color="primary"
                        className="confirm-btn border-0"
                        onClick={paymentHandler}
                        disabled={loading}
                      >
                        {booking ? "Go to Dashboard" : "Confirm Booking"}
                      </Button>
                    </div>
                  )}

                {type === "edit" && !paymentDetails && (
                  <div className="text-center mb-lg-5 mb-sm-4">
                    <Button
                      color="primary"
                      className="confirm-btn border-0"
                      onClick={(e) => {
                        handleEditButton(e);

                        paymentHandleWhileEditing(e);
                      }}
                      disabled={loading}
                    >
                      {booking ? "Go to Dashboard" : "Pay & Confirm Booking"}
                    </Button>
                    {selectedTimeSlot.type === "clinic" && !payLater && (
                      <div className="mt-2">
                        <Button
                          color="primary"
                          className="paylater-btn border-0"
                          onClick={(e) => {
                            if (type === "edit") {
                              handleEditButton(e, "bookingDone");
                            } else {
                              handleConfirmBooking(e, "pay_later");
                            }
                          }}
                          disabled={loading}
                        >
                          {!booking && "Pay at clinic"}
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {type === "edit" && paymentDetails && (
                  <div className="text-center mb-lg-5 mb-sm-4">
                    <Button
                      color="primary"
                      className="confirm-btn border-0"
                      onClick={(e) => handleEditButton(e, "confirmBooking")}
                      disabled={loading}
                    >
                      {booking ? "Go to Dashboard" : "Confirm Booking"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="confirm-booking">
              <h2 className={cx("title-name ", { "bg-gray": loading })}>
                Enter your mobile number
              </h2>
              <div className="py-4 px-4 px-sm-5 mobile-number-wrapper">
                {loading && <Loader />}
                <div
                  className="content"
                  style={loading ? { opacity: 0.6 } : {}}
                >
                  <Collapse isOpen={isNameField}>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input
                        type="text"
                        name="mobile"
                        placeholder="Enter your name"
                        onChange={handleName}
                        value={name}
                      />
                      {errorType && errorType === "name" && errorText}
                    </FormGroup>
                  </Collapse>
                  <FormGroup className="position-relative  mb-4">
                    <Label>Mobile</Label>
                    <div className="number-input">
                      <Input
                        type="text"
                        name="mobile"
                        placeholder="Mobile Number"
                        onChange={handlePhonenumber}
                        value={phone_number}
                        disabled={loading}
                        onKeyDown={(e) => onKeyDown(e)}
                      />
                    </div>
                    {errorType && errorType === "phone_number" && errorText}
                    <Button
                      color="link"
                      onClick={toggleNameField}
                      className="position-absolute border-0"
                      style={{ top: "100%", right: 0 }}
                      disabled={loading}
                    >
                      {isNameField
                        ? "Already registered?"
                        : "Haven’t register yet?"}
                    </Button>
                  </FormGroup>

                  <p>You will receive an OTP shortly.</p>
                  <p>
                    We will send appointment-related communications on this
                    number.
                  </p>
                </div>

                <div className="text-center  mb-lg-5 mb-sm-4">
                  <Button
                    color={loading ? "secondary" : "primary"}
                    className="confirm-btn"
                    onClick={() => handleContinueButton()}
                    disabled={loading}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>

      <BookAppoitmentModal
        isOpen={clinicAppointment}
        toggle={clinicAppointmentToggle}
        clinic_details={clinic_details}
        wh_doctor_detail={wh_doctor_detail}
        doctorInfoDetails={doctorInfoDetails}
        doctorDataById={doctorDataById}
        video_bookings={video_bookings}
        video_timings={video_timings}
        typeEdit={type}
        booking_id={props && props.booking_id}
        paymentDetails={paymentDetails}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTimeSlot: state.SelectedTimeReducer.selectedArray,
    savePatientId: state.BookAppointmentReducer.savePatientId,
    saveId: state.BookAppointmentReducer.saveId,
    saveName: state.BookAppointmentReducer.saveName,
    saveBookingId: state.BookAppointmentReducer.saveBookingId,
    savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkIfUserExisting,
      sendOtpBeforeBooking,
      verifyOtp,
      confirmBooking,
      createPayment,
      capturePayment,
      setPatientId,
      setName,
      setPhoneNumber,
      setBookingId,
      setId,
      editAppointment,
      couponCode,
      verifyCoupon,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmBooking)
);
