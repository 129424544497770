import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../util/isLogin";
import Layout from "../components/layout";
import DashboardLayout from "../components/dashboardLayout";

export const PrivateRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() === "true" ? (
          !rest.path.includes("call") ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        ) : rest.path.includes("patient/appointment") ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() === "true" &&
        (rest.path.includes("patient_login") ||
          rest.path.includes("patient_signin")) ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : rest.path.includes("patient_login") ||
          rest.path.includes("patient_signin") || 
          rest.path.includes("privacy-policy-web") || 
          rest.path.includes("about-us") || 
          rest.path.includes("terms-policy-web") ? (
          <Component {...props} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};
