import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";
import qs from 'qs'

class MohaliIndex extends React.Component {

  componentDidMount(){
    const { speciality } = qs.parse(this.props.location.search.substr(1))
    if (document.getElementById(speciality)!=null){
      document.getElementById(speciality).scrollIntoView()
   }
  
  }
  
  
  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <h2 id = "Dentist" className="text-center title">Dentist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Phase-1-Mohali/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-1, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-2-Mohali/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-2, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-3, Mohali
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                               to={"/search/Phase-4-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-4, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-5-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-5, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-6-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-6, Mohali
                            </Link>
                          </li>
                          
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-7, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-8, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-9, Mohali
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-10-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-10, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-11-Mohali/Dentist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in phase-11, Mohali
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 id = "Orthodontist" className="text-center title">Orthodontist</h2>

                <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Orthodontist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Orthodontist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Orthodontist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthodontist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
               
               
                <h2 id = "Pediatrician" className="text-center title">Pediatrician</h2>

                <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Pediatrician"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Pediatrician"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Pediatrician"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Pediatrician in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
               
<h2 id = "Neurologist" className="text-center title">Neurologist</h2>
<div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Neurologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Ophthamologist" className="text-center title">Ophthamologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Ophthamologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Ophthamologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Ophthamologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthamologist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "ENT" className="text-center title">ENT</h2>
  <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Phase-1-Mohali/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-1, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-2-Mohali/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-2, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-3, Mohali
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                               to={"/search/Phase-4-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-4, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-5-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-5, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-6-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-6, Mohali
                            </Link>
                          </li>
                          
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-7-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-7, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-8-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-8, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-9-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-9, Mohali
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-10-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-10, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-11-Mohali/ENT"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in phase-11, Mohali
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
  <h2 id = "Homoeopath" className="text-center title">Homoeopath</h2>


  <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Phase-1-Mohali/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-1, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-2-Mohali/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-2, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-3, Mohali
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                               to={"/search/Phase-4-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-4, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-5-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-5, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-6-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-6, Mohali
                            </Link>
                          </li>
                          
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-7-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-7, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-8-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-8, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-9-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-9, Mohali
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-10-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-10, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-11-Mohali/Homoeopath"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in phase-11, Mohali
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>

  <h2 id = "Cardiologist" className="text-center title">Cardiologist</h2>
  <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Phase-1-Mohali/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-1, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-2-Mohali/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-2, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-3, Mohali
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                               to={"/search/Phase-4-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-4, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-5-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-5, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-6-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-6, Mohali
                            </Link>
                          </li>
                          
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-7-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-7, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-8-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-8, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-9-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-9, Mohali
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-10-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-10, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-11-Mohali/Cardiologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in phase-11, Mohali
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
  <h2 id = "Ayurveda" className="text-center title">Ayurveda</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Ayurveda"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  
  <h2 id  = "Dermatologist" className="text-center title">DERMATOLOGIST</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/DERMATOLOGIST"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Gynecologist" className="text-center title">Gynecologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Gynecologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Orthopedist" className="text-center title">Orthopedist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Orthopedist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>

  <h2 id = "Psychiatrist" className="text-center title">Psychiatrist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Psychiatrist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Internal Medicine" className="text-center title">Internal-Medicine</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Internal-Medicine"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Sexologist" className="text-center title">Sexologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Sexologist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>

  <h2 id = "Physiotherapist" className="text-center title">Physiotherapist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Phase-1-Mohali/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-1, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-2-Mohali/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-2, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-3-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-3, Mohali
          </Link>
        </li>
        
      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
       
        <li>
          <Link
             to={"/search/Phase-4-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-4, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-5-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-5, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-6-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-6, Mohali
          </Link>
        </li>
        
       


      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-7-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-7, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-8-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-8, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-9-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-9, Mohali
          </Link>
        </li>
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
             to={"/search/Phase-10-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-10, Mohali
          </Link>
        </li>
        <li>
          <Link
             to={"/search/Phase-11-Mohali/Physiotherapist"}
             onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in phase-11, Mohali
          </Link>
        </li>
       



      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Dietitian/Nutritionist" className="text-center title">Dietitian/Nutritionist</h2>

  <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Phase-1-Mohali/Dietitian/Nutritionist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-1, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-2-Mohali/Dietitian/Nutritionist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-2, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-3-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-3, Mohali
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                               to={"/search/Phase-4-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-4, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-5-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-5, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-6-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-6, Mohali
                            </Link>
                          </li>
                          
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-7-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-7, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-8-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-8, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-9-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-9, Mohali
                            </Link>
                          </li>
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/search/Phase-10-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-10, Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/search/Phase-11-Mohali/Dietitian/Nutritionist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist in phase-11, Mohali
                            </Link>
                          </li>
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
               
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default MohaliIndex;

