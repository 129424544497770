import { SET_SELECTED_TIME } from "./types";
const initialState = {
  selectedArray: {},
};

const SelectedTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_TIME: {
      return {
        selectedArray: action.payload || {},
      };
    }

    default: {
      return state;
    }
  }
};

export default SelectedTimeReducer;
