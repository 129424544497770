import { put, takeLatest, call } from "redux-saga/effects";
import {
  FETCH_ALL_APPOINTMENTS_DATA_FAILED,
  FETCH_ALL_APPOINTMENTS_DATA_SUCCESS,
  FETCH_ALL_APPOINTMENTS_DATA_REQUEST,
  DELETE_APPOINTMENT_FAILED,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_REQUEST,
  EDIT_APPOINTMENT_REQUEST,
  EDIT_APPOINTMENT_SUCCESS,
  EDIT_APPOINTMENT_FAILED,
  MY_APPOINTMENT_FAILED,
  MY_APPOINTMENT_SUCCESS,
  MY_APPOINTMENT_REQUEST,
  UPDATE_PATIENT_DETAILS_FAILED,
  UPDATE_PATIENT_DETAILS_SUCCESS,
  UPDATE_PATIENT_DETAILS_REQUEST,
  LAST_FEW_BOOKED_DOCTORS_FAILED,
  LAST_FEW_BOOKED_DOCTORS_SUCCESS,
  LAST_FEW_BOOKED_DOCTORS_REQUEST,
} from "./types";
import { apiCallGet, apiCallPost } from "../../../common/axios";

async function callFetchAllAppointmentsData(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callFetchLastFewBookedDoctors(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callFetchMyAppointments(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

async function callDeleteAppointment(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callUpdatePatientDetails(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

async function callEditAppointment(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);
  return res;
}

function* fetchAllAppointmentsData(action) {
  const response = yield call(callFetchAllAppointmentsData, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: FETCH_ALL_APPOINTMENTS_DATA_SUCCESS,
        allAppointments: response.data,
      });
    }
  } else {
    yield put({ type: FETCH_ALL_APPOINTMENTS_DATA_FAILED });
  }
}

function* fetchLastFewBookedDoctors(action) {
  const response = yield call(callFetchLastFewBookedDoctors, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: LAST_FEW_BOOKED_DOCTORS_SUCCESS,
        lastFewBookedDoctors: response.data,
      });
    }
  } else {
    yield put({ type: LAST_FEW_BOOKED_DOCTORS_FAILED });
  }
}

function* fetchMyAppointments(action) {
  const response = yield call(callFetchMyAppointments, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: MY_APPOINTMENT_SUCCESS,
        allAppointments: response.data,
      });
    }
  } else {
    yield put({ type: MY_APPOINTMENT_FAILED });
  }
}

function* deleteAppointment(action) {
  const response = yield call(callDeleteAppointment, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: DELETE_APPOINTMENT_SUCCESS,
        deleteAppointment: response.data,
      });
    }
  } else {
    yield put({ type: DELETE_APPOINTMENT_FAILED });
  }
}

function* updatePatientDetails(action) {
  const response = yield call(callUpdatePatientDetails, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: UPDATE_PATIENT_DETAILS_SUCCESS,
        updatePatientDetails: response.data,
      });
    }
  } else {
    yield put({ type: UPDATE_PATIENT_DETAILS_FAILED });
  }
}

function* editAppointment(action) {
  const response = yield call(callEditAppointment, action);

  if (response && response.status === 200) {
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: EDIT_APPOINTMENT_SUCCESS,
        editAppointment: response.data,
      });
    }
  } else {
    yield put({ type: EDIT_APPOINTMENT_FAILED });
  }
}

export default function* AllAppointmentsWatcher() {
  yield takeLatest(
    FETCH_ALL_APPOINTMENTS_DATA_REQUEST,
    fetchAllAppointmentsData
  );
  yield takeLatest(DELETE_APPOINTMENT_REQUEST, deleteAppointment);
  yield takeLatest(EDIT_APPOINTMENT_REQUEST, editAppointment);
  yield takeLatest(MY_APPOINTMENT_REQUEST, fetchMyAppointments);
  yield takeLatest(UPDATE_PATIENT_DETAILS_REQUEST, updatePatientDetails);
  yield takeLatest(LAST_FEW_BOOKED_DOCTORS_REQUEST, fetchLastFewBookedDoctors);
}
