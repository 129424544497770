export const FETCH_BLOGS_DATA_REQUEST = "FETCH_BLOGS_DATA_REQUEST";
export const FETCH_BLOGS_DATA_SUCCESS = "FETCH_BLOGS_DATA_SUCCESS";
export const FETCH_BLOGS_DATA_FAILED = "FETCH_BLOGS_DATA_FAILED";

export const FETCH_HEALTH_TIPS_DATA_REQUEST = "FETCH_HEALTH_TIPS_DATA_REQUEST";
export const FETCH_HEALTH_TIPS_DATA_SUCCESS = "FETCH_HEALTH_TIPS_DATA_SUCCESS";
export const FETCH_HEALTH_TIPS_DATA_FAILED = "FETCH_HEALTH_TIPS_DATA_FAILED";

export const FETCH_MEDICAL_SHOTS_DATA_REQUEST =
  "FETCH_MEDICAL_SHOTS_DATA_REQUEST";
export const FETCH_MEDICAL_SHOTS_DATA_SUCCESS =
  "FETCH_MEDICAL_SHOTS_DATA_SUCCESS";
export const FETCH_MEDICAL_SHOTS_DATA_FAILED =
  "FETCH_MEDICAL_SHOTS_DATA_FAILED";
