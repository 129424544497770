import React, { Component } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { fetchVideosData, setVideoPlayOption } from "./store/action";

import { Media, Player, utils, withMediaProps } from "react-media-player";
import ReactPlayer from "react-player";

const { keyboardControls } = utils;

const videoSettings = {
  className: "center",
  dots: false,
  centerMode: true,
  centerPadding: "150px",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "70px",
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "200px",
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "70px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
      },
    },
  ],
  // responsive: [
  //   {
  //     breakpoint: 1340,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       className: "center",
  //       centerMode: true,
  //       infinite: true,
  //       centerPadding: "110px",
  //     },
  //   },
  //   {
  //     breakpoint: 767,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       className: "center",
  //       centerMode: true,
  //       infinite: true,
  //       centerPadding: "60px",
  //     },
  //   },
  //   {
  //     breakpoint: 460,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       className: "center",
  //       centerMode: true,
  //       infinite: true,
  //       centerPadding: "45px",
  //     },
  //   },
  // ],
};
class HealthIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],

      activeVideoIndex: null,
      activeMedia: null,
    };
  }

  componentDidMount() {
    const { fetchVideosData } = this.props;

    fetchVideosData("data/videos", (value) => {
      this.setState({ videos: value });
    });
  }
  playVideo = (index, media) => {
    const { activeVideoIndex } = this.state;
    const { setVideoPlayOption, HealthIssuesData } = this.props;
    var size = Object.keys(HealthIssuesData.currentVideoData).length;

    if (
      HealthIssuesData &&
      size > 0 &&
      (activeVideoIndex !== index || HealthIssuesData.videoType !== "health")
    ) {
      HealthIssuesData.currentVideoData.pause();
    }
    setVideoPlayOption("health", media);
    this.setState({
      activeVideoIndex: index,
      activeMedia: media,
    });
  };
  pauseVideo = () => {
    const { HealthIssuesData } = this.props;

    var size = Object.keys(HealthIssuesData.currentVideoData).length;
    if (
      HealthIssuesData &&
      size > 0 &&
      HealthIssuesData.videoType === "health"
    ) {
      HealthIssuesData.currentVideoData.pause();
    }
  };
  handleVideoSlide = (media) => {
    this.setState({
      activeMedia: media,
    });

    media && media.playPause();
  };

  validateYouTubeUrl = (url) => {
    if (url != undefined || url != "") {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        return true;
      } else {
        return false;
        // Do anything for not being valid
      }
    }
  };
  render() {
    const { videos } = this.state;

    return (
      <section className="health-issues-wrapper health-issues-wrap">
        {/* <Container>
          <h3 className="title text-center">Wishhealth for everyone</h3> */}
        <div className="video-shots-wrapper outer-opacity">
          <Slider {...videoSettings} afterChange={this.pauseVideo}>
            {videos &&
              videos.length > 0 &&
              videos.map((video, index) => {
                const isValidURL = this.validateYouTubeUrl(video.link);

                return (
                  <div
                    key={index}
                    className="px-1 px-sm-2 px-md-3 video-shots-wrap"
                  >
                    <div className="position-relative">
                      {isValidURL ? (
                        <Media>
                          {(mediaProps) => (
                            <div
                              className="media w-100"
                              onKeyDown={keyboardControls.bind(
                                null,
                                mediaProps
                              )}
                            >
                              <Player
                                src={video.link}
                                className="media-player w-100"
                                isPlaying={
                                  this.state[`video${index}`] !== undefined
                                    ? this.state[`video${index}`]
                                    : false
                                }
                                onPlay={() => this.playVideo(index, mediaProps)}
                              />

                              <div
                                className="media-controls"
                                onClick={() =>
                                  this.handleVideoSlide(mediaProps)
                                }
                              ></div>
                            </div>
                          )}
                        </Media>
                      ) : (
                        <div className="went-wrong-txt videos-wrong-text">
                          {" "}
                          <p>
                            The requested URL was not found on this server
                          </p>{" "}
                        </div>
                      )}
                    </div>
                    <p>{video.title}</p>
                  </div>
                );
              })}
          </Slider>
        </div>
        {/* </Container> */}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HealthIssuesData: state.HealthIssues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchVideosData,
      setVideoPlayOption,
    },
    dispatch
  );
};

export default compose(
  withMediaProps(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthIssues))
  )
);
