import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";

class LocalityIndex extends React.Component {
  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <h2 className="text-center title">Top specialities in Chandigarh</h2>

                <div className="mb-4">




                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>



                       <li>
                            <Link
                                to={"/Chandigarh-index?speciality=Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                           Dentist
                            </Link>
                          </li>
                          <li>
                          </li>


                          <li>
                            
                            <Link
                                to={"/Chandigarh-index?speciality=Ophthamologist"}
                            
                            >
                              Ophthamologist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Ayurveda"}
                            >
                              Ayurveda
                            </Link>
                          </li>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Psychiatrist"}
                            >
                              Psychiatrist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Dietitian/Nutritionist"}
                            >
                              Dietitian/Nutritionist
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Homoeopath"}
                            >
                              Homoeopath
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=ENT"}
                            >
                              ENT
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Dermatologist"}
                            >
                              Dermatologist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Internal Medicine"}
                            >
                             Internal Medicine
                            </Link>
                          </li>

                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Pediatrician"}
                            >
                              Pediatrician
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Orthodontist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist
                            </Link>
                          </li>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Gynecologist"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Sexologist"}
                            >
                              Sexologist
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Neurologist"}
                            >
                              Neurologist
                            </Link>
                          </li>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Cardiologist"}
                            >
                              Cardiologist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Orthopedist"}
                            >
                              Orthopedist
                            </Link>
                          </li>

                          <li>
                            <Link
                               to={"/Chandigarh-index?speciality=Physiotherapist"}
                            >
                              Physiotherapist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>




                  </Row>
                </div>
                <h2 className="text-center title">Top specialities in Mohali</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Ophthamologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist
                            </Link>
                          </li>
                          <li>
                            <Link
                               to={"/Mohali-index?speciality=Ayurveda"}
                               onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Psychiatrist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Psychiatrist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Dietitian/Nutritionist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Internal Medicine"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Internal Medicine
                            </Link>
                          </li>

                        

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Mohali-index/?speciality=Sexologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Sexologist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Neurologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Orthopedist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthopedist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Mohali-index?speciality=Physiotherapist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Physiotherapist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>


                <h2 className="text-center title">Top specialities in Kharar</h2>

                <div className="mb-4">

                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/kharar/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda
                            </Link>
                          </li>


                          <li>
                            <Link
                                to={"/search/kharar/Psychiatrist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Psychiatrist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Dietitian/Nutritionist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist
                            </Link>
                          </li>


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/kharar/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist
                            </Link>
                          </li>



                          <li>
                            <Link
                                to={"/search/kharar/Internal Medicine"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Internal Medicine
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/kharar/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/kharar/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Sexologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Sexologist
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/kharar/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/kharar/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/kharar/Orthopedist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthopedist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/kharar/Physiotherapist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Physiotherapist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 className="text-center title">Top specialities in Panchkula</h2>

                <div className="mb-4">

                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Ophthamologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Ayurveda"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda
                            </Link>
                          </li>


                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Psychiatrist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Psychiatrist
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Dietitian/Nutritionist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist
                            </Link>
                          </li>


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Homoeopath"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=ENT"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Dermatologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist
                            </Link>
                          </li>



                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Internal Medicine"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Internal Medicine
                            </Link>
                          </li>

                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Pediatrician"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Orthodontist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist
                            </Link>
                          </li>

                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Gynecologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Sexologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Sexologist
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Neurologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurologist
                            </Link>
                          </li>

                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Cardiologist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Orthopedist"}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthopedist
                            </Link>
                          </li>

                          <li>
                            <Link
                            to={"/Panchkula-index?speciality=Physiotherapist  "}
                            onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Physiotherapist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 className="text-center title">Top specialities in Zirakpur</h2>

                <div className="mb-4">

                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                             to={"/Zirakpur-index?speciality=Dentist"}
                           
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Ophthamologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Psychiatrist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Psychiatrist
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Dietitian/Nutritionist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dietitian/Nutritionist
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist
                            </Link>
                          </li>


                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Internal-Medicine"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Internal Medicine
                            </Link>
                          </li>

                        

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Sexologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Sexologist
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Neurologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurologist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist
                            </Link>
                          </li>


                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Orthopedist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthopedist
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/Zirakpur-index?speciality=Physiotherapist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Physiotherapist
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>





            
            
            
            
            
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default LocalityIndex;

