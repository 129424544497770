import React from "react";
import { Modal, ModalBody } from "reactstrap";
import AppStoreLinks from "../../appStoreLinks";
import docImage from "../../../assets/images/doc.png";
import icon1 from "../../../assets/images/icons/m-line-1.png";
import icon2 from "../../../assets/images/icons/m-line-2.png";
import icon3 from "../../../assets/images/icons/m-line-3.png";

const DocModal = (props) => {
  const { toggle = () => {}, isOpen = false } = props;
  return (
    <Modal isOpen={isOpen} className="doc-access-modal" toggle={toggle}>
      <ModalBody>
        <button className="close" onClick={toggle}>
          &times;
        </button>
        <img src={icon1} className="icon icon-1" alt="line-icon-1" />
        <img src={icon2} className="icon icon-2" alt="line-icon-2" />
        <img src={icon3} className="icon icon-3" alt="line-icon-3" />
        <div className="text-center">
          <img src={docImage} alt="doc" />
          <p className="text-center text">
            To access the doctor's features and to register as doctor please
            download the app from Apple or Android Play Store
          </p>
          <AppStoreLinks />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocModal;
