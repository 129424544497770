import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import MetaTags from "react-meta-tags";
import { ClickAwayListener } from "../../../util";
import CameraIcon from "../../../assets/images/dashboard/photo-camera.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { updatePatientDetails } from "../../../pages/dashboard/store/action";
import { NotificationManager } from "react-notifications";
import Loader from "../../../loader/index";
import DatePicker from "react-datepicker";
import { uploadFile } from '../../../common/uploadImage';
import {
  verifyOtp,
  setName,
  setImage,
  setPhoneNumber,
  fetchStates,
  fetchCities,
  fetchPatientDetails,
} from "../../../components/modal/store/action";

// import { setImage } from "../../../components/form/updateProfileForm/store/action";
import Avatar from "../../../assets/images/avatar.png";
const InputComponent = (props) => {
  const {
    type = "text",
    label = "",
    name,
    placeholder,
    onChange,
    value,
    readonly = false,
  } = props;

  return (
    <FormGroup>
      <Label>{label}</Label>

      {readonly ? (
        <div className="form-control">{value}</div>
      ) : (
        <Input
          autoComplete="none"
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          readonly={readonly}
        />
      )}
    </FormGroup>
  );
};

const DropDownInput = (props) => {
  const {
    type = "text",
    label = "",
    name,
    values,
    onChange = () => { },
    onSelect = () => { },
    value = "",
    error,
    placeholder,
    cityEnable,
    onBlur = () => { },
  } = props;

  const [isDropDown, setIsDropDown] = useState(false);

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <div className="position-relative">
        <ClickAwayListener onClickAway={() => setIsDropDown(false)}>
          <Input
            autoComplete="none"
            type={type}
            name={name}
            onChange={onChange}
            onClick={() => {
              setIsDropDown(true);
              error();
            }}
            className="dropdown-input"
            value={value}
            placeholder={placeholder}
            disabled={cityEnable === false}
            onBlur={onBlur}
          />
          {isDropDown && values && values.length > 0 && (
            <ul className="dropdown-input-list">
              {values.map((item) => {
                return (
                  <li>
                    <button
                      onClick={() => {
                        onSelect(item);
                        setIsDropDown(false);
                      }}
                    >
                      {name === "state" || name === "city" ? item.name : item}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </ClickAwayListener>
      </div>
    </FormGroup>
  );
};

const UpdateProfileForm = (props) => {
  const { savePhoneNumber, saveName } = props;
  const [image, set_Image] = useState(null);
  const imageRef = useRef();

  const [inputs, setInputs] = useState({
    name: saveName,
    phone_number: savePhoneNumber,
    email: "",
    bloodGroups: ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"],

    state: [],
    city: [],
    address: "",
    zipCode: "",
  });
  const [cityEnable, setCityEnable] = useState(false);
  const [otpForm, setOtpForm] = useState(false);
  const [otp, setOtp] = useState("");

  const [errorType, setErrortype] = useState("");
  const [errorText, setErrortext] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [bloodGroupName, setBloodGroupName] = useState("");
  const [updatedState, setUpdatedState] = useState([]);
  const [updatedCity, setUpdatedCity] = useState([]);
  const [updatedBloodGroup, setUpdatedBloodGroup] = useState([]);
  const fetchDetails = () => {
    let id = props.savePatientId;

    props.fetchPatientDetails(`patient/detail/${id}`, (value) => {
      setLoading(false);

      setInputs((prevValue) => ({
        ...prevValue,
        email: value.data.email,
        address: value.data.address,
        zipCode: value.data.pin_code !== 0 ? value.data.pin_code : "",
      }));
      setDateOfBirth(new Date(value.data.dob));
      setSelectedBloodGroup(value.data.bloodGroup || "");
      setBloodGroupName(value.data.bloodGroup || "");
      setSelectedState(value.data.wh_state || {});
      setStateName((value.data.wh_state && value.data.wh_state.name) || "");
      setSelectedCity(value.data.wh_city || {});
      setCityName((value.data.wh_city && value.data.wh_city.name) || "");
      set_Image(value.data.patientProfile);
      props.setImage(value.data.patientProfile);
    });
  };

  const fetchStates = () => {
    props.fetchStates("data/states", (value) => {
      setInputs((prevValue) => ({
        ...prevValue,
        state: value.data,
      }));
    });
  };

  const fetchFormDetails = () => {
    fetchDetails();
    fetchStates();
  };

  useEffect(() => {
    fetchFormDetails();
  }, []);

  const clearError = () => {
    setErrortype("");
    setErrortext("");
  };

  const handleInputs = (e) => {
    e.preventDefault();
    const key = e.target.name;
    const value = e.target.value;

    setInputs((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });

    setErrortype("");
    setErrortext("");
  };

  const handleOtp = (e, index, nextId = e.target.id) => {
    e.preventDefault();

    let value = otp;
    let splitVal = value.split("");
    if (e.target.value === "" && splitVal[index + 1]) {
      splitVal[index] = ",";
    } else {
      splitVal[index] = e.target.value;
    }
    value = splitVal.join("");

    setOtp(value);
    document.getElementById(nextId).focus();

    setErrortext("");
    setErrortype("");
    if (value.length === 5) {
      handleVerifyOtp(value);
      document.getElementById("otp1").focus();
    }
  };

  const validateEmail = (email) => {
    let re = /^(([^<>()\]\\.,;:\s@“]+(\.[^<>()\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  };

  const handleContinueButton = (e) => {
    e.preventDefault();
    setLoading(true);
    if (inputs.name === "") {
      setErrortype("name");
      setErrortext(<span className="text-danger">Name is empty</span>);
      setLoading(false);
      return;
    }

    if (inputs.phone_number === "") {
      setErrortype("phone_number");
      setErrortext(<span className="text-danger">Mobile number is empty</span>);
      setLoading(false);
      return;
    } else if (
      inputs.phone_number.length < 10 ||
      inputs.phone_number.length > 10
    ) {
      setErrortype("phone_number");
      setErrortext(
        <span className="text-danger">
          <b>Phone number should be of length 10</b>
        </span>
      );
      setLoading(false);
      return;
    } else if (inputs.phone_number !== "") {
      let filter = /^\d{10}$/;
      if (!filter.test(inputs.phone_number)) {
        setErrortype("phone_number");
        setErrortext(
          <span className="text-danger">
            {" "}
            <b>Please enter valid phone number</b>
          </span>
        );
        setLoading(false);
        return;
      }
    }

    if (inputs.name !== props && props.saveName) {
      setOtpForm(false);
    } else if (inputs.phone_number !== props && props.savePhoneNumber) {
      setOtpForm(true);
    }
    if (
      inputs.phone_number !== (props && props.savePhoneNumber) &&
      inputs.name !== (props && props.saveName)
    ) {
      setOtpForm(true);
    }

    // if (inputs.email === "") {
    //   setErrortype("email");
    //   setErrortext(<span className="text-danger">Email is empty</span>);
    //   setLoading(false);
    //   return;
    // }
    if (inputs.email !== "") {
      if (!validateEmail(inputs.email)) {
        setErrortype("email");
        setErrortext(<span className="text-danger">Invalid Email</span>);
        setLoading(false);
        return;
      }
    }

    // if (selectedBloodGroup === "") {
    //   setErrortype("bloodGroup");
    //   setErrortext(
    //     <span className="text-danger">Please select your blood group</span>
    //   );
    //   setLoading(false);
    //   return;
    // }

    // if (inputs.address === "") {
    //   setErrortype("address");
    //   setErrortext(<span className="text-danger">Address is empty</span>);
    //   setLoading(false);
    //   return;
    // }

    // if (inputs.zipCode === "") {
    //   setErrortype("zipCode");
    //   setErrortext(<span className="text-danger">Zip code is empty</span>);
    //   setLoading(false);
    //   return;
    // }

    if (inputs.zipCode && inputs.zipCode !== "") {
      if (inputs.zipCode.length < 6 || inputs.zipCode.length > 6) {
        setErrortype("zipCode");
        setErrortext(
          <span className="text-danger">
            <b>Zip code should be of length 6</b>
          </span>
        );
        setLoading(false);
        return;
      }
    }

    callUpdateApi();
  };
  const callUpdateApi = () => {
    setLoading(true);

    let obj;

    obj = {
      id: props && props.savePatientId,
      name: inputs.name,
      phone: inputs.phone_number,
      email: inputs.email ? inputs.email : "",
      address: inputs.address ? inputs.address : "",
      city_id: selectedCity.id ? selectedCity.id : "",
      state_id: selectedState.id ? selectedState.id : "",

      bloodGroup: selectedBloodGroup ? selectedBloodGroup : "",
      dob: dateOfBirth ? dateOfBirth : "",

      pin_code: inputs.zipCode ? inputs.zipCode : "",
    };
    const link = props && props.saveImage ? props.saveImage : image;
    if (link && !link.includes("https://wishhealth-images.s3.amazonaws.com")) {
      obj.patientProfile = link;
    }

    props.updatePatientDetails("patient/update", obj, (response) => {
      if (response.message === "Update Successfully") {
        setLoading(false);
        if (inputs.phone_number !== response.data.phone) {
          setOtpForm(true);
          NotificationManager.success(
            "Otp has been sent successfully",
            "",
            1000
          );
        } else {
          props.setName(response.data.name);

          localStorage.setItem("isLoggedIn", true);
          props.history.push("/patient/dashboard/upcoming-appointments");
          window.scrollTo(0, 0);
        }
      }
    });
  };
  const handleVerifyOtp = (otpSend) => {
    setLoading(true);
    if (otpSend === "") {
      setErrortype("otp");
      setErrortext(<span className="text-danger">Otp is empty</span>);
      setLoading(false);
      return;
    }

    if (otpSend.length < 5) {
      setErrortype("otp");
      setErrortext(
        <span className="text-danger">Otp should be of length 5</span>
      );
      setLoading(false);
      return;
    }

    let formDataOtp = {
      otp: otpSend,
      id: props && props.savePatientId,
      phone: inputs.phone_number,
    };

    props.verifyOtp("patient/verifyOtp", formDataOtp, (value) => {
      setLoading(false);

      if (value.message === "otp valid") {
        NotificationManager.success("Updated Successfully", "", 1000);

        setOtpForm(false);
        props.setName(inputs.name);
        props.setPhoneNumber(inputs.phone_number);
        localStorage.setItem("isLoggedIn", true);
        props.history.push("/patient/dashboard/upcoming-appointments");
        window.scrollTo(0, 0);
      } else {
        NotificationManager.error(value.message, "", 1000);
        setOtp("");
      }
    });
  };

  const uploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      uploadFile(event.target.files[0]).then(result => {
        props.setImage(result);
        set_Image(result);
      })
      // var reader = new FileReader();
      // reader.onloadend = function () {
      //   props.setImage(reader.result);
      //   set_Image(reader.result);
      // };
      // reader.readAsDataURL(event.target.files[0]);
    }
  };
  const handleBloodGroupChange = (item) => {
    setSelectedBloodGroup(item);
    setBloodGroupName(item);
  };
  const handleStateChange = (item) => {
    setSelectedState(item);
    setStateName(item.name);

    setCityEnable(true);

    props.fetchCities(`data/cities/${item.id}`, (value) => {
      setInputs({ ...inputs, city: value.data });
    });
  };

  const handleCityChange = (item) => {
    setSelectedCity(item);
    setCityName(item.name);
  };

  const handleDateOfBirth = (date) => {
    clearError();
    setDateOfBirth(date);

    clearError();
  };
  const handleChange = (e) => {
    let stateArray;
    let cityArray;
    let bloodGroupArray;
    if (e.target.name === "bloodGroup") {
      setBloodGroupName(e.target.value);
      bloodGroupArray =
        inputs.bloodGroups &&
        inputs.bloodGroups.filter((data) => {
          return data
            .toLowerCase()
            .trim()

            .startsWith(e.target.value.toLowerCase().trim());
        });
      setUpdatedBloodGroup(bloodGroupArray);
    }
    if (e.target.name === "state") {
      setStateName(e.target.value);
      stateArray =
        inputs.state &&
        inputs.state.filter((data) => {
          return data.name
            .toLowerCase()
            .trim()

            .startsWith(e.target.value.toLowerCase().trim());
        });
      setUpdatedState(stateArray);
    }
    if (e.target.name === "city") {
      setCityName(e.target.value);
      cityArray =
        inputs.city &&
        inputs.city.filter((data) => {
          return data.name
            .toLowerCase()
            .trim()

            .startsWith(e.target.value.toLowerCase().trim());
        });
      setUpdatedCity(cityArray);
    }
  };

  const handleBlur = (e) => {
    if (e.target.name === "bloodGroup") {
      setBloodGroupName(selectedBloodGroup || "");
    }
    if (e.target.name === "state") {
      setStateName(selectedState.name || "");
    }
    if (e.target.name === "city") {
      setCityName(selectedCity.name || "");
    }
  };
  return (
    <div className="update-profile-wrapper">
      <MetaTags>
        <title>Update Profile</title>
      </MetaTags>
      <h2>Update Profile</h2>
      <div className="update-profile position-relative">
        {loading && <Loader />}
        <div style={loading ? { filter: "blur(3px)" } : {}}>
          <div className="profile-information">
            <div className="update-profile-image">
              <img
                ref={imageRef}
                id="output"
                src={
                  props && props.saveImage
                    ? props.saveImage
                    : image
                      ? image
                      : Avatar
                }
                alt="profile"
                className="profile negative-margin"
              />
              <div>
                <Input
                  type="file"
                  accept="image/*"
                  id="f-upload"
                  name="myImage"
                  onChange={uploadImage}
                  className="d-none"
                />
                <label htmlFor="f-upload" class="custom-file-upload">
                  <div className="camera-btn" onClick={uploadImage}>
                    <img src={CameraIcon} alt="camera" />
                  </div>
                </label>
              </div>
            </div>
            <div className="text-center">
              <h2 className="mb-0">{props && props.saveName}</h2>
              {selectedCity.name && (
                <p>
                  <i className="fas fa-map-marker-alt mr-1" />
                  {selectedCity.name}
                </p>
              )}
            </div>
          </div>
          <form autoComplete={false}>
            {!otpForm ? (
              <div className="update-profile-form">
                <div className="info-title">
                  <p>Personal Information</p>
                </div>
                <Row>
                  <Col md={6}>
                    <InputComponent
                      label={
                        <>
                          Name<span className="text-danger">*</span>
                        </>
                      }
                      name="name"
                      placeholder="Enter your name"
                      onChange={handleInputs}
                      value={inputs.name}
                    />
                    {errorType && errorType === "name" && errorText}
                  </Col>
                  <Col md={6}>
                    <InputComponent
                      label={
                        <>
                          Mobile<span className="text-danger">*</span>
                        </>
                      }
                      name="phone_number"
                      placeholder="Mobile Number"
                      onChange={handleInputs}
                      value={inputs.phone_number}
                    />
                    {errorType && errorType === "phone_number" && errorText}
                  </Col>
                  <Col md={6}>
                    <InputComponent
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Email"
                      onChange={handleInputs}
                      value={inputs.email}
                    />
                    {errorType && errorType === "email" && errorText}
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Label>Date of birth</Label>
                        <div>
                          <DatePicker
                            selected={dateOfBirth}
                            onChange={handleDateOfBirth}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select Date"
                            maxDate={new Date()}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <DropDownInput
                            label="Blood Group"
                            name="bloodGroup"
                            values={updatedBloodGroup}
                            onSelect={handleBloodGroupChange}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={bloodGroupName}
                            error={clearError}
                            placeholder="Select"
                          />
                          {errorType && errorType === "bloodGroup" && errorText}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="info-title">
                  <p>Contact Information</p>
                </div>
                <Row>
                  <Col md={6}>
                    <InputComponent
                      type="textarea"
                      label="Address"
                      name="address"
                      placeholder="Address"
                      onChange={handleInputs}
                      value={inputs.address}
                    />
                    {errorType && errorType === "address" && errorText}
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col sm={6}>
                        <DropDownInput
                          label="State"
                          name="state"
                          values={updatedState}
                          onSelect={handleStateChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={stateName}
                          error={clearError}
                          placeholder="Select"
                        />
                      </Col>
                      <Col sm={6}>
                        <DropDownInput
                          label="City"
                          name="city"
                          values={updatedCity}
                          onSelect={handleCityChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={cityName}
                          error={clearError}
                          placeholder="Select"
                          cityEnable={cityEnable}
                        />
                      </Col>
                      <Col sm={6}>
                        <InputComponent
                          label="Country"
                          name="country"
                          type="text"
                          value="India"
                          readonly
                        />
                      </Col>
                      <Col sm={6}>
                        <InputComponent
                          label="Zip"
                          name="zipCode"
                          type="number"
                          placeholder="Zip Code"
                          onChange={handleInputs}
                          value={inputs.zipCode}
                        />
                        {errorType && errorType === "zipCode" && errorText}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : (
              <div style={{ maxWidth: "330px", margin: "13px auto 0" }}>
                <FormGroup className="otp-field">
                  <Label for="otp">
                    Enter 5 digit OTP sent on your mobile number
                  </Label>
                  <div className="d-flex justify-content-between">
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp1"
                      id="otp1"
                      value={otp && otp[0] && otp[0] !== "," ? otp[0] : ""}
                      onChange={(e) => handleOtp(e, 0, "otp2")}
                      autoFocus
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp2"
                      id="otp2"
                      value={otp && otp[1] && otp[1] !== "," ? otp[1] : ""}
                      onChange={(e) => handleOtp(e, 1, "otp3")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp3"
                      id="otp3"
                      value={otp && otp[2] && otp[2] !== "," ? otp[2] : ""}
                      onChange={(e) => handleOtp(e, 2, "otp4")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp4"
                      id="otp4"
                      value={otp && otp[3] && otp[3] !== "," ? otp[3] : ""}
                      onChange={(e) => handleOtp(e, 3, "otp5")}
                    />
                    <Input
                      type="number"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      name="otp5"
                      id="otp5"
                      value={otp && otp[4] && otp[4] !== "," ? otp[4] : ""}
                      onChange={(e) => handleOtp(e, 4)}
                    />
                  </div>
                </FormGroup>
                {errorType && errorType === "otp" && errorText}
              </div>
            )}
            {!otpForm && (
              <div className="text-center saveBtn my-4">
                <Button
                  color="primary"
                  onClick={(e) => handleContinueButton(e)}
                >
                  Save
                </Button>
              </div>
            )}
            {otpForm && (
              <div className="text-center saveBtn my-4">
                <Button color="primary" onClick={() => handleVerifyOtp(otp)}>
                  Submit
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    saveName: state.BookAppointmentReducer.saveName,
    savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
    savePatientId: state.BookAppointmentReducer.savePatientId,
    saveId: state.BookAppointmentReducer.saveId,
    saveImage: state.BookAppointmentReducer.saveImage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updatePatientDetails,
      setName,
      setPhoneNumber,
      verifyOtp,
      setImage,
      fetchStates,
      fetchCities,
      fetchPatientDetails,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateProfileForm)
);
