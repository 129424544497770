import {
  FETCH_BLOGS_DATA_REQUEST,
  FETCH_HEALTH_TIPS_DATA_REQUEST,
  FETCH_MEDICAL_SHOTS_DATA_REQUEST,
} from "./types";

export const fetchBlogsData = (url, callback) => ({
  type: FETCH_BLOGS_DATA_REQUEST,
  url,

  callback,
});

export const fetchHealthTipsData = (url, callback) => ({
  type: FETCH_HEALTH_TIPS_DATA_REQUEST,
  url,

  callback,
});

export const fetchMedicalShotsData = (url, callback) => ({
  type: FETCH_MEDICAL_SHOTS_DATA_REQUEST,
  url,
  callback,
});
