import React, { Component } from 'react';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import Loader from '../../../loader/index';
import Hospital from '../../../assets/images/home/hospital.jpeg';
import StarFilled from '../../../assets/images/home/star-filled.svg';
class DoctorOffers extends Component {
	render() {
		const { doctorDeals, loading } = this.props;

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow:
				doctorDeals.length >= 4
					? 4
					: doctorDeals.length >= 3
					? 3
					: doctorDeals.length >= 2
					? 2
					: 1,
			slidesToScroll:
				doctorDeals.length >= 4
					? 4
					: doctorDeals.length >= 3
					? 3
					: doctorDeals.length >= 2
					? 2
					: 1,

			responsive: [
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: doctorDeals
							? doctorDeals.length >= 3
								? 3
								: doctorDeals.length
							: 1,
						slidesToScroll: doctorDeals
							? doctorDeals.length >= 3
								? 3
								: doctorDeals.length
							: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: doctorDeals
							? doctorDeals.length >= 2
								? 2
								: doctorDeals.length
							: 1,
						slidesToScroll: doctorDeals
							? doctorDeals.length >= 2
								? 2
								: doctorDeals.length
							: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 767,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '60px',
						slidesToShow: 1,
						speed: 500,
					},
				},
				{
					breakpoint: 575,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '50px',
						slidesToShow: 1,
						speed: 500,
					},
				},
			],
		};

		return (
			<section className="hospitals-deals-wrapper pb-0 doctors-offers">
				<Container>
					<h3 className="title text-center theme-title">
						<span>Doctors</span> Offers
					</h3>
					<div className="doc-slider-wrapper">
						{loading ? (
							<Loader />
						) : (
							<Slider {...settings}>
								{doctorDeals &&
									doctorDeals.length > 0 &&
									doctorDeals.map((item) => {
										const content = item.excerpt.rendered.replace(
											/(<([^>]+)>)/gi,
											''
										);

										return (
											<div className="hospital-deals-content">
												<div className="hospital-image">
													<img
														style={{ cursor: 'pointer' }}
														src={
															item._embedded['wp:featuredmedia'][0].source_url
														}
														alt="hospital"
														onClick={() => {
															window.location.href = item.link;
														}}
													/>
												</div>
												<div className="hospital-content">
													<h3>{item.title.rendered}</h3>
													<p
														className="mb-0"
														dangerouslySetInnerHTML={{ __html: content }}
													/>
												</div>
											</div>
										);
									})}
							</Slider>
						)}
					</div>
				</Container>
			</section>
		);
	}
}
export default DoctorOffers;
