import {
  FETCH_BOOK_DOCTORS_DATA_REQUEST,
  SET_FILTERED_DOCTORS_DATA,
} from "./types";

export const fetchBookDoctorsData = (url, body, callback) => {
  return {
    type: FETCH_BOOK_DOCTORS_DATA_REQUEST,
    url,
    payload: body,
    callback,
  };
};

export const fetchFilteredDoctorsData = (data) => {
  return {
    type: SET_FILTERED_DOCTORS_DATA,
    payload: data,
  };
};
