import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import DeleteIcon from "../assets/images/dashboard/delete-modal-icon.png";
import CloseIcon from "../assets/images/dashboard/closeIcon.png";

class DeleteModal extends React.Component {
  render() {
    const { isOpen, toggle, loading, deleteAppointment } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="deleteModal mt-5 modal-dialog-centered"
      >
        <ModalBody className="text-center">
          <Button
            color="secondary"
            onClick={toggle}
            className="closeBtn"
            disabled={loading}
          >
            <img src={CloseIcon} alt="close" />
          </Button>
          <div className="text-center mb-3">
            <img src={DeleteIcon} alt="delete" />
          </div>

          <h2>Want to Delete</h2>
          <p>
            Do you really want to delete this appointment ? You will not be able
            to recover it.
          </p>
          <div className="text-center deleteBtn mt-4">
            {/* <Button
              color="secondary"
              onClick={toggle}
              className="cancelBtn"
              disabled={loading}
            >
              Cancel
            </Button>{" "} */}
            <Button
              color="primary"
              onClick={() => deleteAppointment(this.props.bookingId)}
              disabled={loading}
            >
              Yes, Confirmed
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default DeleteModal;
