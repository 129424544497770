import React, { Component, Fragment } from 'react';
import { Container, Button } from 'reactstrap';
import Slider from 'react-slick';
import { titleCase } from '../../../common/stringFunction';
import StarRating from '../../../components/reviews/StarRating';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../loader/index';

import startIcon from '../../../assets/images/icons/starRatin.png';
import Avatar from '../../../assets/images/avatar.png';
import { fetchBookDoctorsData } from './store/action';
import Language from '../../../assets/images/search/language.png';
import { extractChar } from '../../../util/string';

const Card = (props) => {
	const {
		wh_doctor_detail,
		user_id,
		wh_doctor_specialities,
		name,
		wh_doctor_languages,
		wh_doctor_qualifications,
		feedback,
	} = props;

	let city =
		wh_doctor_detail &&
		wh_doctor_detail.wh_city &&
		wh_doctor_detail.wh_city.name;

	let state =
		wh_doctor_detail &&
		wh_doctor_detail.wh_state &&
		wh_doctor_detail.wh_state.name;

	let specialities =
		wh_doctor_specialities &&
		wh_doctor_specialities.filter((item) => {
			return item.wh_speciality !== null;
		});

	let doctorsSpecialitiesArray =
		specialities &&
		specialities.length > 0 &&
		specialities.map((item) => {
			return item && item.wh_speciality && titleCase(item.wh_speciality.title);
		});

	let doctorsSpecialitiesReplaced =
		specialities &&
		specialities.length > 0 &&
		specialities.map((item) => {
			const data =
				item &&
				item.wh_speciality &&
				titleCase(item.wh_speciality.title).split(' ').join('-');
			return data.split('/').join('-');
		});
	let languages =
		wh_doctor_languages &&
		wh_doctor_languages.length > 0 &&
		wh_doctor_languages.map((item, key) => {
			const length = wh_doctor_languages && wh_doctor_languages.length;

			return (
				<Fragment key={key}>
					{key === length - 1
						? item.wh_language && item.wh_language.name
						: `${item.wh_language.name}, `}
				</Fragment>
			);
		});

	let profileImage =
		wh_doctor_detail && wh_doctor_detail.profile_pic
			? wh_doctor_detail.profile_pic
			: Avatar;

	const Verified = () => (
		<>
			<span className="verified">
				<i className="fas fa-certificate"></i>
				<i className="fas fa-check"></i>
				<div>
					<div className="fade show">
						<div
							className="tooltip show bs-tooltip-auto"
							x-placement="right"
							x-out-of-boundaries="true"
						>
							<div
								className="tooltip-inner"
								role="tooltip"
								style={{ whiteSpace: 'nowrap' }}
							>
								Verified
							</div>
							<span className="arrow" style={{ top: 8 }}></span>
						</div>
					</div>
				</div>
			</span>
		</>
	);

	const viewProfile = () => {
		let nameReplace = name.split(' ').join('-');
		let specialitiesReplace = doctorsSpecialitiesReplaced.join('-');

		props.history.push({
			pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
			search: `?specialization=${specialitiesReplace}`,
			// pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${user_id}`,
			state: {
				wh_doctor_detail: wh_doctor_detail,
				user_id: user_id,
				wh_doctor_specialities: wh_doctor_specialities,
				name: name,
				wh_doctor_languages: wh_doctor_languages,
				wh_doctor_qualifications: wh_doctor_qualifications,
			},
		});
	};

	const bookAppointment = () => {
		let nameReplace = name.split(' ').join('-');
		let specialitiesReplace =
			doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join('-');
		props.history.push({
			pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
			search: `?specialization=${specialitiesReplace}`,
			// pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${user_id}`,
			state: {
				modalOpen: true,
				wh_doctor_detail: wh_doctor_detail,
				user_id: user_id,
				wh_doctor_specialities: wh_doctor_specialities,
				name: name,
				wh_doctor_languages: wh_doctor_languages,
				wh_doctor_qualifications: wh_doctor_qualifications,
			},
		});
	};
	let currentYear = new Date().getFullYear();

	let practice_start_year =
		wh_doctor_detail && wh_doctor_detail.practice_start_year;
	let experience = currentYear - practice_start_year;

	if (!practice_start_year) {
		experience = 0;
	}

	let rating;
	if (feedback.length === 0) {
		rating = 0;
	} else {
		let a = 0;
		rating =
			feedback &&
			feedback.reduce((acc, currentValue) => {
				a += acc.recommended;
				return a;
			});
	}

	let average_rating =
		(wh_doctor_detail && wh_doctor_detail.average_rating) || 0;

	return (
		<div
			className="doc-card-wrapper"
			onClick={viewProfile}
			style={{ cursor: 'pointer' }}
		>
			<div className="doc-card">
				<div className="imgWrapper">
					<div className="holder">
						{wh_doctor_detail && wh_doctor_detail.profile_pic ? (
							<img src={wh_doctor_detail && wh_doctor_detail.profile_pic} alt={name} />
						) : (
							<div className="dummy-text">
								<span>{extractChar(name)}</span>
							</div>
						)}
						{wh_doctor_detail && wh_doctor_detail.is_verified === 1 && (
							<Verified />
						)}
					</div>
				</div>
				<div className="content">
					<div className="text-content">
						{feedback && feedback.length !== 0 && (
							<p
								className="rating d-flex mb-0 justify-content-end align-items-center"
								style={{ marginTop: '-10px' }}
							>
								<img src={startIcon} alt="reting" />
								{rating / 5}&nbsp;
								<span>{`(${feedback && feedback.length})`}</span>
							</p>
						)}
						<div className="c-title">
							<h5>
								{wh_doctor_detail && wh_doctor_detail.prefix} {name}
							</h5>
						</div>

						{wh_doctor_specialities && (
							<p className="text">
								{doctorsSpecialitiesArray &&
									doctorsSpecialitiesArray.length > 0 &&
									doctorsSpecialitiesArray[0]}
								<>
									<span className="spec-count">
										{doctorsSpecialitiesArray.length > 1 && (
											<span className="count">
												{`+(${doctorsSpecialitiesArray.length - 1})`}
											</span>
										)}

										<div className="fade show">
											<div
												className="tooltip show bs-tooltip-auto"
												x-placement="bottom"
												x-out-of-boundaries="true"
											>
												<div className="tooltip-light" role="tooltip">
													<p>
														{doctorsSpecialitiesArray &&
															doctorsSpecialitiesArray.length > 1 &&
															doctorsSpecialitiesArray.map((item, key) => {
																const length = doctorsSpecialitiesArray.length;
																if (key === 0) {
																	return null;
																}
																return (
																	<Fragment key={key}>
																		{key === length - 1 ? item : `${item} , `}
																	</Fragment>
																);
															})}
													</p>
												</div>
												<span className="arrow"></span>
											</div>
										</div>
									</span>
								</>
							</p>
						)}
						{wh_doctor_detail && wh_doctor_detail.city && (
							<p className="text">
								<div className="text-icon mr-2">
									<i className="fas fa-map-marker-alt" />
								</div>
								<span>{wh_doctor_detail.city}</span>
							</p>
						)}
						<div className="d-flex align-items-center">
							<span className="rating-text">Rating</span>
							<StarRating
								className="ml-2"
								stop={5}
								rating={average_rating}
								readonly
							/>
						</div>
						{experience != 0 && (
							<p className="text">
								<div className="text-icon mr-2">
									<i className="fas fa-trophy" />
								</div>
								<span>{`Experience: ${experience} years`}</span>
							</p>
						)}
						{wh_doctor_detail && wh_doctor_detail.doc_fees !== null && (
							<p className="text">
								<div className="text-icon mr-2">
									<i className="fas fa-rupee-sign" />
								</div>
								<span>{wh_doctor_detail.doc_fees}</span>
							</p>
						)}
						{wh_doctor_languages && wh_doctor_languages.length > 0 && (
							<p className="text">
								<div className="text-icon mr-2">
									<img src={Language} alt="language" />
								</div>
								<span>Language Known: {languages}</span>
							</p>
						)}
						{(city || state) && city !== state ? (
							<p className="text">
								<div className="text-icon mr-2">
									{(city || state) && (
										<i className="fas fa-map-marker-alt mr-2" />
									)}
								</div>
								<span>{`${city}, ${state}`}</span>
							</p>
						) : (
							<p className="text">
								<div className="text-icon mr-2">
									{(city || state) && (
										<i className="fas fa-map-marker-alt mr-2" />
									)}
								</div>
								<span>{city}</span>
							</p>
						)}
					</div>

					<div className="text-center">
						<Button
							color="link"
							className="view-btn mb-2"
							onClick={viewProfile}
						>
							View Profile
						</Button>

						<Button color="primary" className="w-100" onClick={bookAppointment}>
							Book now
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

class BookOurDoctors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doctors: [],
			loading: false,
			count: '',
		};
	}
	componentDidMount() {
		this.setState({
			loading: true,
		});

		let formDataDoctors = {
			doctorParams: '',
			type: '',
			location: localStorage.getItem('liveCity') || '',
			offset: 0,
			limit: 30,
			featured: 1,
		};
		const { fetchBookDoctorsData } = this.props;
		fetchBookDoctorsData('search/searchDoctors', formDataDoctors, (doctors) => {
			this.setState({
				doctors: doctors.data.arr,
				loading: false,
				count: doctors.count,
			});
		});
	}

	render() {
		const { doctors, loading, count } = this.state;

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: doctors
							? doctors.length >= 3
								? 3
								: doctors.length
							: 1,
						slidesToScroll: doctors
							? doctors.length >= 3
								? 3
								: doctors.length
							: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: doctors
							? doctors.length >= 2
								? 2
								: doctors.length
							: 1,
						slidesToScroll: doctors
							? doctors.length >= 2
								? 2
								: doctors.length
							: 1,
						infinite: true,
					},
				},

				{
					breakpoint: 416,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '60px',
						slidesToShow: 1,
						speed: 500,
					},
				},
				{
					breakpoint: 374,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '50px',
						slidesToShow: 1,
						speed: 500,
					},
				},
			],
		};
		return (
			<section className="book-our-doctor">
				<Container>
					<h2 className="text-center title theme-title">
						<span>Book</span> Our Doctors
					</h2>
					<div className="doc-slider-wrapper">
						{loading ? (
							<Loader />
						) : (
							<Slider className="doc-slider pt-sm-4" {...settings}>
								{doctors &&
									doctors.map((data, index) => {
										return (
											<Card
												key={index}
												{...data}
												history={this.props.history}
												count={count}
											/>
										);
									})}
							</Slider>
						)}
					</div>
				</Container>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchBookDoctorsData,
		},
		dispatch
	);
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BookOurDoctors)
);
