import React, { useState, Fragment } from "react";
import { Button } from "reactstrap";
import startIcon from "../../../assets/images/icons/starRatin.png";
import VideoConsultation from "../../../assets/images/search/videoConsulation.png";
import DisableVideo from "../../../assets/images/profile/disableVideo.png";
import ClinicConsultation from "../../../assets/images/search/clinicConsultation.png";
import ClinicConsultationDisabled from "../../../assets/images/search/clinicConsultation-disabled.png";
import CallNow from "../../../assets/images/search/callNow.png";
import Location from "../../../assets/images/search/drLoaction.png";
import Experience from "../../../assets/images/search/experience.png";
import Language from "../../../assets/images/search/language.png";
import ClinicFee from "../../../assets/images/search/clinicFee.png";
import Verified from "../../../assets/images/search/verified.png";
import Avatar from "../../../assets/images/avatar.png";
import { titleCase } from "../../../common/stringFunction";
import { withRouter } from "react-router-dom";
// import { config } from "../../../config";
import { extractChar } from "../../../util/string";
import CallContact from "./callContact";

const DocResultCard = (props) => {
  const [isOpecCallDetail, setIssOpenCallDetail] = useState(false);
  const toggleCallDetail = () => setIssOpenCallDetail((previous) => !previous);
  const {
    wh_doctor_detail,
    user_id,
    wh_doctor_specialities,
    name,
    wh_doctor_languages,
    wh_doctor_qualifications,
    clinic_details,
    feedback,
    clinic_timings,
  } = props;
  let nameReplace = name.split(" ").join("-");

  let currentYear = new Date().getFullYear();

  let practice_start_year =
    wh_doctor_detail && wh_doctor_detail.practice_start_year;
  let experience = currentYear - practice_start_year;
  if (!practice_start_year) {
    experience = 0;
  }

  let languages =
    wh_doctor_languages &&
    wh_doctor_languages.length > 0 &&
    wh_doctor_languages.map((item, key) => {
      const length = wh_doctor_languages && wh_doctor_languages.length;

      return (
        <Fragment key={key}>
          {key === length - 1
            ? item.wh_language && item.wh_language.name
            : `${item.wh_language.name}, `}
        </Fragment>
      );
    });
  let city =
    wh_doctor_detail &&
    wh_doctor_detail.wh_city &&
    wh_doctor_detail.wh_city.name;

  let state =
    wh_doctor_detail &&
    wh_doctor_detail.wh_state &&
    wh_doctor_detail.wh_state.name;
  const Informations = () => (
    <div className="informations">
      {wh_doctor_detail && wh_doctor_detail.city && (
        <p>
          <img src={Location} className="mr-2" alt="location" />
          {wh_doctor_detail && wh_doctor_detail.city}
        </p>
      )}
      {experience !== 0 && (
        <p>
          <img src={Experience} className="mr-2" alt="experience" />
          Experience: {`${experience} years`}
        </p>
      )}

      {wh_doctor_detail && wh_doctor_detail.doc_fees !== null && (
        <p>
          <img src={ClinicFee} className="mr-2" alt="clinicFee" />

          <span>{wh_doctor_detail.doc_fees}</span>
        </p>
      )}

      {wh_doctor_languages && wh_doctor_languages.length > 0 && (
        <p>
          <img src={Language} className="mr-2" alt="language" />
          Language Known: {languages}
        </p>
      )}

      {city && state && city !== state ? (
        <p>
          <i className="fas fa-map-marker-alt mr-2" />
          {`${city}, ${state}`}
        </p>
      ) : (
        <p>
          <i className="fas fa-map-marker-alt mr-2" />
          {city || state}
        </p>
      )}

      {wh_doctor_detail && wh_doctor_detail.is_verified === 1 && (
        <p className="d-md-block d-none">
          <img src={Verified} className="mr-2" alt="verified" />
          Verified Doctor
        </p>
      )}
    </div>
  );

  let profileImage =
    wh_doctor_detail && wh_doctor_detail.profile_pic
      ? wh_doctor_detail.profile_pic
      : Avatar;

  let specialities =
    wh_doctor_specialities &&
    wh_doctor_specialities.filter((item) => {
      return item.wh_speciality !== null;
    });

  let doctorsSpecialitiesArray =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      return item && item.wh_speciality && titleCase(item.wh_speciality.title);
    });

  let doctorsSpecialitiesReplaced =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      const data =
        item &&
        item.wh_speciality &&
        titleCase(item.wh_speciality.title).split(" ").join("-");
      return data.split("/").join("-");
    });
  let specialitiesReplace =
    doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");
  const handleClinicConsultation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.history.push({
      pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
      search: `?specialization=${specialitiesReplace}`,
      state: {
        modalOpen: true,
        wh_doctor_detail: wh_doctor_detail,
        user_id: user_id,
        wh_doctor_specialities: wh_doctor_specialities,
        name: name,
        wh_doctor_languages: wh_doctor_languages,
        wh_doctor_qualifications: wh_doctor_qualifications,
        clinic_details: clinic_details,
      },
    });
  };
  const viewProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.history.push({
      pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
      search: `?specialization=${specialitiesReplace}`,
      state: {
        wh_doctor_detail: wh_doctor_detail,
        user_id: user_id,
        wh_doctor_specialities: wh_doctor_specialities,
        name: name,
        wh_doctor_languages: wh_doctor_languages,
        wh_doctor_qualifications: wh_doctor_qualifications,
      },
    });
  };
  // const callNow = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   window.location.href = `${config.oldWebsiteUrl}doctor/doctorProfile/${user_id}`;
  // };
  const videoConsultation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.history.push({
      pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
      search: `?specialization=${specialitiesReplace}`,
      state: {
        modalOpen: true,
        wh_doctor_detail: wh_doctor_detail,
        user_id: user_id,
        wh_doctor_specialities: wh_doctor_specialities,
        name: name,
        wh_doctor_languages: wh_doctor_languages,
        wh_doctor_qualifications: wh_doctor_qualifications,
        clinic_details: clinic_details,
      },
    });
  };

  let rating;

  if (feedback && feedback.length === 0) {
    rating = 0;
  } else {
    let a = 0;
    rating = rating =
      feedback &&
      feedback.reduce((acc, currentValue) => {
        a += acc.recommended;
        return a;
      });
  }
  return (
    <div className="search-results">
      {isOpecCallDetail && (
        <CallContact
          toggle={toggleCallDetail}
          extension={wh_doctor_detail.extension}
        />
      )}
      <div
        className="content"
        onClick={viewProfile}
        style={{ cursor: "pointer" }}
      >
        <div className="flex-sm-row top-info">
          <div className="doctorImage">
            {wh_doctor_detail && wh_doctor_detail.profile_pic ? (
              <img
                src={wh_doctor_detail && wh_doctor_detail.profile_pic}
                className="doctor-image d-block mx-sm-0 mb-3"
                alt="profile"
              />
            ) : (
              <div className="dummy-text">
                <span>{extractChar(name)}</span>
              </div>
            )}
          </div>

          <div className="doctor-info">
            <div className="doctor-name">
              <h3 className="mb-0">
                {" "}
                {wh_doctor_detail && wh_doctor_detail.prefix} {name}
              </h3>
              {feedback && feedback.length !== 0 && (
                <div className="rating mb-0">
                  <img src={startIcon} alt="reting" />
                  <span className="count">
                    {rating / 5}
                    <span className="total ml-1">{`(${
                      feedback && feedback.length
                    })`}</span>
                  </span>
                </div>
              )}
            </div>
            <p className="info">
              {doctorsSpecialitiesArray &&
                doctorsSpecialitiesArray.length > 0 &&
                doctorsSpecialitiesArray[0]}
              <>
                <span className="spec-count">
                  {doctorsSpecialitiesArray &&
                    doctorsSpecialitiesArray.length > 1 && (
                      <span className="count">
                        {`+(${doctorsSpecialitiesArray.length - 1})`}
                      </span>
                    )}

                  <div className="fade show">
                    <div
                      className="tooltip show bs-tooltip-auto"
                      x-placement="bottom"
                      x-out-of-boundaries="true"
                    >
                      <div
                        className="tooltip-light"
                        role="tooltip"
                        style={{ width: 240 }}
                      >
                        <p>
                          {doctorsSpecialitiesArray &&
                            doctorsSpecialitiesArray.length > 1 &&
                            doctorsSpecialitiesArray.map((item, key) => {
                              const length = doctorsSpecialitiesArray.length;
                              if (key === 0) {
                                return null;
                              }
                              return (
                                <>{key === length - 1 ? item : `${item}, `}</>
                              );
                            })}
                        </p>
                      </div>
                      <span className="arrow"></span>
                    </div>
                  </div>
                </span>
              </>
            </p>
            <div className="informations d-md-none">
              {wh_doctor_detail && wh_doctor_detail.is_verified === 1 && (
                <p>
                  <img src={Verified} alt="Verified" className="mr-2" />
                  Verified Doctor
                </p>
              )}
            </div>

            <div className="d-md-block d-none ">
              <Informations />
            </div>
          </div>
        </div>
        <div className="d-md-none pl-4 pl-md-0">
          <Informations />
        </div>
      </div>
      <div className="contacts-buttons">
       {/* <Button
          color="themeBordered"
          disabled={
            (wh_doctor_detail && wh_doctor_detail.video_consultation === 0) ||
            (wh_doctor_detail && wh_doctor_detail.video_consultation === null)
          }
          onClick={videoConsultation}
        >
          <img
            src={
              wh_doctor_detail &&
              (wh_doctor_detail.video_consultation === 0 ||
                wh_doctor_detail.video_consultation === null)
                ? DisableVideo
                : VideoConsultation
            }
            className="mr-2"
            alt="VideoConsultation"
          />
          Video Consultation
        </Button>*/}
        <Button
          color="bordered"
          className="call-now"
          // onClick={callNow}
          // disabled
          onClick={toggleCallDetail}
        >
          <img src={CallNow} className="mr-2" alt="callNow" />
          Call Now
        </Button>

        <Button
          color="themeBordered"
          onClick={handleClinicConsultation}
          disabled={clinic_timings && clinic_timings.length === 0}
        >
          <img
            src={
              clinic_timings && clinic_timings.length === 0
                ? ClinicConsultationDisabled
                : ClinicConsultation
            }
            className="mr-2"
            alt="clinicConsulation"
          />
          Book now
        </Button>
      </div>
    </div>
  );
};
export default withRouter(DocResultCard);
