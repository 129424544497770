import React from "react";
import { Container } from "reactstrap";
import HealthPackage from "../../../assets/images/home/covid.png";
import Phone from "../../../assets/images/home/phone-icon.svg";

export default () => (
  <section className="health-package-wrapper">
    <Container>
      <h3 className="title text-center theme-title mb-0">
        <span>Covid</span> Help
      </h3>
      <div className="package-banner">
        <img
          style={{ cursor: "pointer" }}
          src={HealthPackage}
          alt="HealthPackage"
          className="img-fluid"
          onClick={() => {
            window.location.href = "https://blog.wishhealth.in/covid-help/";
          }}
        />
        <span>
          <img src={Phone} className="mr-2 icon" alt="phone" />
          7733991188 / 9580011011
        </span>
      </div>
    </Container>
  </section>
);
