import { CLEAR_NAME, CLEAR_PHONE_NUMBER, CLEAR_REDUCER } from "./types";

export const clearName = (data) => {
  return {
    type: CLEAR_NAME,
    payload: data,
  };
};

export const clearPhoneNumber = (data) => {
  return {
    type: CLEAR_PHONE_NUMBER,

    payload: data,
  };
};

export const clearReducer = (data) => {
  return {
    type: CLEAR_REDUCER,

    payload: data,
  };
};
