import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PrivacyImage from '../../assets/images/privacy-policy/privacy-image.png';

class PrivacyPolicy extends React.Component {
	render() {
		return (
			<div className="privacy-policy-wrapper">
				<div className="privacy-banner">
					<Container>
						<Row className="align-items-center text-md-left text-center">
							<Col md={6}>
								<h1>
									Welcome to our <span> Privacy Policy</span>
								</h1>
							</Col>
							<Col md={6}>
								<img src={PrivacyImage} className="img-fluid privacy" alt="" />
							</Col>
						</Row>
					</Container>
				</div>
				<Container>
					<div className="mb-5">
						<h2>Your Privacy Is Critically Important To Us.</h2>
						<p className="mb-3 address">
							<b>
								Wishhealth Private Limited,
								<br />
								Plot No 205, Phase -8, Industrial Area, Mohali, India.
							</b>
						</p>

						<div className="contact-info">
							<h4>Contact Us:</h4>
							<p className="mb-0">{process.env.REACT_APP_CONTACT_US}</p>
							<p className="mb-0">info@wishhealth.in</p>
						</div>

						<ul>
							<li>
								It is Wishhealth Private Limited's policy to respect your
								privacy regarding any information we may collect while operating
								our website. This Privacy Policy applies to
								https://wishhealth.in (hereinafter, "us", "we", or
								"https://wishhealth.in"). We respect your privacy and are
								committed to protecting personally identifiable information you
								may provide us through the Website.{' '}
							</li>
							<li>
								We have adopted this privacy policy ("Privacy Policy") to
								explain what information may be collected on our Website, how we
								use this information, and under what circumstances we may
								disclose the information to third parties.
							</li>
							<li>
								This Privacy Policy applies only to information we collect
								through the Website and does not apply to our collection of
								information from other sources.
							</li>
							<li>
								This Privacy Policy, together with the Terms and conditions
								posted on our Website, set forth the general rules and policies
								governing your use of our Website. Depending on your activities
								when visiting our Website, you may be required to agree to
								additional terms and conditions.
							</li>
						</ul>
						<h2>Website Visitors.</h2>
						<ul>
							<li>
								This Privacy Policy, together with the Terms and conditions
								posted on our Website, set forth the general rules and policies
								governing your use of our Website. Depending on your activities
								when visiting our Website, you may be required to agree to
								additional terms and conditions.
							</li>
							<li>
								Wishhealth Private Limited's purpose in collecting
								non-personally identifying information is to better understand
								how Wishhealth Private Limited's visitors use its website.
							</li>
							<li>
								From time to time, Wishhealth Private Limited may release
								non-personally-identifying information in the aggregate, e.g.,
								by publishing a report on trends in the usage of its website.
							</li>
						</ul>

						<h2>Gathering of personally-idenfying information.</h2>
						<ul>
							<li>
								Certain visitors to Wishhealth Private Limited's websites choose
								to interact with Wishhealth Private Limited in ways that require
								Wishhealth Private Limited to gather personally-identifying
								information.
							</li>
							<li>
								The amount and type of information that Wishhealth Private
								Limited gathers depends on the nature of the interaction. For
								example, we ask visitors who sign up for a blog at
								https://wishhealth.in to provide a username and email address.
							</li>
						</ul>

						<h2>Security.</h2>
						<ul>
							<li>
								The amount and type of information that Wishhealth Private
								Limited gathers depends on the nature of the interaction. For
								example, we ask visitors who sign up for a blog at
								https://wishhealth.in to provide a username and email address.
							</li>
						</ul>
						<h2>Advertisements</h2>
						<ul>
							<li>
								Ads appearing on our website may be delivered to users by
								advertising partners, who may set cookies. These cookies allow
								the ad server to recognize your computer each time they send you
								an online advertisement to compile information about you or
								others who use your computer.
							</li>
							<li>
								This information allows ad networks to, among other things,
								deliver targeted advertisements that they believe will be of
								most interest to you. This Privacy Policy covers the use of
								cookies by Wishhealth Private Limited and does not cover the use
								of cookies by any advertisers.
							</li>
						</ul>
						<h2>Links to external sites</h2>
						<ul>
							<li>
								Our Service may contain links to external sites that are not
								operated by us. If you click on a third party link, you will be
								directed to that third party's site. We strongly advise you to
								review the Privacy Policy and terms and conditions of every site
								you visit.
							</li>
							<li>
								We have no control over, and assume no responsibility for the
								content, privacy policies or practices of any third party sites,
								products or services.
							</li>
						</ul>
						<h2>HTTPS://WISHHEALTH.IN USES GOOGLE ADWORDS FOR REMARKETING.</h2>
						<ul>
							<li>
								Https://wishhealth.in uses the remarketing services to advertise
								on third party websites (including Google) to previous visitors
								to our site. It could mean that we advertise to previous
								visitors who haven't completed a task on our site, for example
								using the contact form to make an enquiry.
							</li>
							<li>
								This could be in the form of an advertisement on the Google
								search results page, or a site in the Google Display Network.
								Third-party vendors, including Google, use cookies to serve ads
								based on someone's past visits. Of course, any data collected
								will be used in accordance with our own privacy policy and
								Google's privacy policy.
							</li>
							<li>
								You can set preferences for how Google advertises to you using
								the Google Ad Preferences page, and if you want to you can opt
								out of interest-based advertising entirely by cookie settings or
								permanently using a browser plugin.
							</li>
						</ul>
						<h2>PROTECTION OF CERTAIN PERSONALLY-IDENTIFYING INFORMATION.</h2>
						<ul>
							<li>
								Wishhealth Private Limited discloses potentially
								personally-identifying and personally-identifying information
								only to those of its employees, contractors and affiliated
								organizations that (i) need to know that information in order to
								process it on Wishhealth Private Limited's behalf or to provide
								services available at Wishhealth Private Limited's website, and
								(ii) that have agreed not to disclose it to others. Some of
								those employees, contractors and affiliated organizations may be
								located outside of your home country; by using Wishhealth
								Private Limited's website, you consent to the transfer of such
								information to them.
							</li>
							<li>
								Wishhealth Private Limited will not rent or sell potentially
								personally-identifying and personally-identifying information to
								anyone. Other than to its employees, contractors and affiliated
								organizations, as described above, Wishhealth Private Limited
								discloses potentially personally-identifying and
								personally-identifying information only in response to a
								subpoena, court order or other governmental request, or when
								Wishhealth Private Limited believes in good faith that
								disclosure is reasonably necessary to protect the property or
								rights of Wishhealth Private Limited, third parties or the
								public at large.
							</li>
							<li>
								If you are a registered user of https://wishhealth.in and have
								supplied your email address, Wishhealth Private Limited may
								occasionally send you an email to tell you about new features,
								solicit your feedback, or just keep you up to date with what's
								going on with Wishhealth Private Limited and our products. We
								primarily use our blog to communicate this type of information,
								so we expect to keep this type of email to a minimum. If you
								send us a request (for example via a support email or via one of
								our feedback mechanisms), we reserve the right to publish it in
								order to help us clarify or respond to your request or to help
								us support other users. Wishhealth Private Limited takes all
								measures reasonably necessary to protect against the
								unauthorized access, use, alteration or destruction of
								potentially personally-identifying and personally-identifying
								information.
							</li>
						</ul>
						<h2>AGGREGATED STATISTICS.</h2>
						<ul>
							<li>
								Wishhealth Private Limited may collect statistics about the
								behavior of visitors to its website. Wishhealth Private Limited
								may display this information publicly or provide it to others.
								However, Wishhealth Private Limited does not disclose your
								personally-identifying information.
							</li>
						</ul>
						<h2>COOKIES.</h2>
						<ul>
							<li>
								To enrich and perfect your online experience, Wishhealth Private
								Limited uses "Cookies", similar technologies and services
								provided by others to display personalized content, appropriate
								advertising and store your preferences on your computer.
							</li>
							<li>
								A cookie is a string of information that a website stores on a
								visitor's computer, and that the visitor's browser provides to
								the website each time the visitor returns. Wishhealth Private
								Limited uses cookies to help Wishhealth Private Limited identify
								and track visitors, their usage of https://wishhealth.in, and
								their website access preferences.
							</li>
							<li>
								Wishhealth Private Limited visitors who do not wish to have
								cookies placed on their computers should set their browsers to
								refuse cookies before using Wishhealth Private Limited's
								websites, with the drawback that certain features of Wishhealth
								Private Limited's websites may not function properly without the
								aid of cookies.
							</li>
							<li>
								By continuing to navigate our website without changing your
								cookie settings, you hereby acknowledge and agree to Wishhealth
								Private Limited's use of cookies.
							</li>
						</ul>
						<h2>BUSINESS TRANSFERS.</h2>
						<ul>
							<li>
								If Wishhealth Private Limited, or substantially all of its
								assets, were acquired, or in the unlikely event that Wishhealth
								Private Limited goes out of business or enters bankruptcy, user
								information would be one of the assets that is transferred or
								acquired by a third party. You acknowledge that such transfers
								may occur, and that any acquirer of Wishhealth Private Limited
								may continue to use your personal information as set forth in
								this policy.
							</li>
						</ul>
						<h2>PRIVACY POLICY CHANGES.</h2>
						<ul>
							<li>
								Although most changes are likely to be minor, Wishhealth Private
								Limited may change its Privacy Policy from time to time, and in
								Wishhealth Private Limited's sole discretion. Wishhealth Private
								Limited encourages visitors to frequently check this page for
								any changes to its Privacy Policy. Your continued use of this
								site after any change in this Privacy Policy will constitute
								your acceptance of such change.
							</li>
						</ul>
					</div>
				</Container>
			</div>
		);
	}
}

export default PrivacyPolicy;
