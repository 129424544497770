import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/patient_signin/logo.png";
import PatientSigninImage from "../../assets/images/patient_signin/banner.png";
import { scrollToTop } from "../../util";
import MetaTags from "react-meta-tags";
import {
  sendOtpBeforeBooking,
  verifyOtp,
  setPatientId,
  setId,
  setName,
  setPhoneNumber,
  checkIfUserExisting,
} from "../../components/modal/store/action";
import Loader from "../../loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
class PatientSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone_number:
        this.props &&
        this.props.history.location.state &&
        this.props.history.location.state.phone
          ? this.props.history.location.state.phone
          : "",
      errorType: "",
      errorText: "",
      sendOtp: false,
      otpInput: "",
      loading: false,
      patient_id: "",
      otpInputFilled: false,
      registered: "",
    };
    this.renderSendOtp = this.renderSendOtp.bind(this);
    this.renderSignUp = this.renderSignUp.bind(this);
  }
  handleName = (e) => {
    e.preventDefault();
    this.setState({
      name: e.target.value,
      errorType: "",
      errorText: "",
    });
  };

  handlePhonenumber = (e) => {
    e.preventDefault();
    this.setState({
      phone_number: e.target.value,
      errorType: "",
      errorText: "",
    });
  };
  sendOtpApi = () => {
    const { name, phone_number, registered } = this.state;

    this.setState({
      loading: true,
    });
    let formData = {
      phone: phone_number,
    };
    if (registered === "not registered") {
      formData = {
        name: name,
        phone: phone_number,
      };
    }

    this.props.sendOtpBeforeBooking(
      "patient/patientSave",
      formData,
      (value) => {
        if (value.message === "Otp Sent Successfully") {
          this.setState({ loading: false });
          NotificationManager.success(
            "Otp has been sent successfully",
            "",
            1000
          );
        } else {
          this.setState({ loading: false });
          NotificationManager.warning("Invalid Otp", "", 1000);
        }

        this.setState({
          loading: false,
          patient_id: value.data.user_id,
          sendOtp: true,
          otpInput: "",
        });
        this.props.setPatientId(value.data.user_id);

        this.props.setId(value.data.wh_patient_details[0].id);
      }
    );
  };

  handleSignUp = () => {
    const { name, phone_number } = this.state;
    this.setState({
      loading: true,
    });
    if (name === "") {
      this.setState({
        errorType: "name",
        errorText: <span className="text-danger">Name is empty</span>,
        loading: false,
      });

      return;
    }

    if (phone_number === "") {
      this.setState({
        errorType: "phone_number",
        errorText: <span className="text-danger">Mobile number is empty</span>,
        loading: false,
      });

      return;
    } else if (phone_number.length < 10 || phone_number.length > 10) {
      this.setState({
        errorType: "phone_number",
        errorText: (
          <span className="text-danger">
            {" "}
            <b>Phone number should be of length 10</b>
          </span>
        ),
        loading: false,
      });

      return;
    } else if (phone_number !== "") {
      let filter = /^\d{10}$/;
      if (!filter.test(phone_number)) {
        this.setState({
          errorType: "phone_number",
          errorText: (
            <span className="text-danger">
              {" "}
              <b>Please enter valid phone number</b>
            </span>
          ),
          loading: false,
        });

        return;
      }
    }
    this.checkIfUserExists();
  };
  checkIfUserExists = () => {
    this.setState({
      loading: true,
    });
    const { phone_number } = this.state;
    let obj = {
      phone: phone_number,
    };
    this.props.checkIfUserExisting(
      "patient/getPatientExistence",
      obj,
      (value) => {
        this.setState({
          registered: value.message,
        });
        if (value.message === "already registered") {
          this.setState({
            loading: false,
            name: value.data.name,
          });
          NotificationManager.success("User is already registered", "", 1000);
          this.props.history.push("/patient_login");
        } else if (value.message === "not registered" && value.data === null) {
          this.setState({
            loading: false,
          });

          this.sendOtpApi();
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    );
  };

  handleOtp = (e, index, nextId = e.target.id) => {
    e.preventDefault();
    const { otpInput } = this.state;

    let value = otpInput;
    let splitVal = value.split("");
    if (e.target.value === "" && splitVal[index + 1]) {
      splitVal[index] = ",";
    } else {
      splitVal[index] = e.target.value;
    }
    value = splitVal.join("");

    this.setState({
      otpInput: value,
    });
    document.getElementById(nextId).focus();
    this.setState({
      errorText: "",
      errorType: "",
    });
    if (value.length === 5) {
      this.verifyOtp(value);
      document.getElementById("otp1").focus();
    }
  };

  verifyOtp = (otp) => {
    const { patient_id, name, phone_number } = this.state;
    this.setState({ loading: true });
    if (otp === "") {
      this.setState({
        errorType: "otpInput",
        errorText: <span className="text-danger">Otp is empty</span>,
        loading: false,
      });

      return;
    }

    if (otp.length < 5) {
      this.setState({
        errorType: "otpInput",
        errorText: <span className="text-danger">Please enter otp</span>,
        loading: false,
      });
      return;
    }
    let formDataOtp = {
      otp: otp,
      id: patient_id,
    };

    this.props.verifyOtp("patient/verifyOtp", formDataOtp, (value) => {
      this.setState({
        loading: false,
        otpInputFilled: true,
      });

      if (value.message === "otp valid") {
        NotificationManager.success(value.message, "", 1000);
        this.setState({
          sendOtp: false,
        });

        this.props.setName(name);
        this.props.setPhoneNumber(phone_number);
        localStorage.setItem("isLoggedIn", true);
        window.scrollTo(0, 0);
        this.props.history.push("patient/dashboard/upcoming-appointments");
      } else {
        NotificationManager.error(value.message, "", 1000);

        this.setState({ otpInput: "" });
      }
    });
  };

  renderSignUp() {
    const { name, phone_number, errorText, errorType } = this.state;
    return (
      <div className="banner-content">
        <h1>Make an appointment within Seconds.</h1>
        <FormGroup>
          <Label for="name">Enter your Name</Label>
          <label className="icon name">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              onChange={this.handleName}
              value={name}
            />
          </label>
          {errorType && errorType === "name" && errorText}
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Enter your phone number</Label>
          <label className="icon phone-no">
            <Input
              type="text"
              name="mobile"
              id="phone_no"
              placeholder="Phone number"
              onChange={this.handlePhonenumber}
              value={phone_number}
            />
          </label>
          {errorType && errorType === "phone_number" && errorText}
        </FormGroup>
        <p className="text-center otp-text">
          Enter your name and mobile number to receive 5 digit OTP.
        </p>

        <div className="text-center">
          <Button
            color="primary"
            className="signup-btn "
            onClick={() => this.handleSignUp()}
          >
            Sign Up
          </Button>
          <p className="login-text">
            Already have an account? <Link to="/patient_login">Login</Link>
          </p>
        </div>
      </div>
    );
  }
  renderSendOtp() {
    const { otpInput, errorText, errorType, loading } = this.state;
    return (
      <div className="banner-content">
        <FormGroup className="otp-field">
          <Label>Please enter the 5-digit OTP here to verify</Label>
          <div className="d-flex justify-content-between">
            <Input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              name="otp1"
              id="otp1"
              value={
                otpInput && otpInput[0] && otpInput[0] !== ","
                  ? otpInput[0]
                  : ""
              }
              autoFocus
              onChange={(e) => this.handleOtp(e, 0, "otp2")}
            />
            <Input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              name="otp2"
              id="otp2"
              value={
                otpInput && otpInput[1] && otpInput[1] !== ","
                  ? otpInput[1]
                  : ""
              }
              onChange={(e) => this.handleOtp(e, 1, "otp3")}
            />
            <Input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              name="otp3"
              id="otp3"
              value={
                otpInput && otpInput[2] && otpInput[2] !== ","
                  ? otpInput[2]
                  : ""
              }
              onChange={(e) => this.handleOtp(e, 2, "otp4")}
            />
            <Input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              name="otp4"
              id="otp4"
              value={
                otpInput && otpInput[3] && otpInput[3] !== ","
                  ? otpInput[3]
                  : ""
              }
              onChange={(e) => this.handleOtp(e, 3, "otp5")}
            />
            <Input
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              name="otp5"
              id="otp5"
              value={
                otpInput && otpInput[4] && otpInput[4] !== ","
                  ? otpInput[4]
                  : ""
              }
              onChange={(e) => this.handleOtp(e, 4)}
            />
          </div>
        </FormGroup>

        {errorType && errorType === "otpInput" && errorText}
        <div className="text-right mb-3">
          <Button
            color="link"
            className="border-0"
            style={{ whiteSpace: "nowrap" }}
            onClick={this.sendOtpApi}
            disabled={loading}
          >
            Resend OTP
          </Button>
        </div>
        <div className="text-center">
          <Button
            color="primary"
            className="signup-btn "
            onClick={() => this.verifyOtp(otpInput)}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { sendOtp, loading } = this.state;
    return (
      <div className="patientSigninContentWrapper">
        <MetaTags>
          <title>Wishhealth Accounts</title>
        </MetaTags>
        <div className="patient-logo">
          <Link to="/" className="logo" onClick={scrollToTop}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <Container fluid="lg">
          <Row>
            <Col md={12} lg={6} xl={5} className="patient-col">
              <div
                className="patient-signin-content"
                style={loading ? { filter: "blur(3px)" } : {}}
              >
                {sendOtp ? this.renderSendOtp() : this.renderSignUp()}
              </div>
              {loading && <Loader />}
            </Col>
            <Col md={12} lg={6} xl={7} className="patient-col">
              <div className="patient-signin-image">
                <img
                  src={PatientSigninImage}
                  alt="patient-signin"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTimeSlot: state.SelectedTimeReducer.selectedArray,
    savePatientId: state.BookAppointmentReducer.savePatientId,
    saveName: state.BookAppointmentReducer.saveName,
    savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkIfUserExisting,
      sendOtpBeforeBooking,
      verifyOtp,
      setPatientId,
      setName,
      setPhoneNumber,
      setId,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientSignIn)
);
