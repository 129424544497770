import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Geolocation from '../../assets/images/autoDetectLocation.png';

let filteredSuggestions = [];

const areaTerms = ['sublocality_level_1'];
const cityTerms = ['locality', 'sublocality'];

export default function GoogleLocationSearch(props) {
	const [address, setAddress] = useState('');
	const [googleSuggestions, setGoogleSuggestions] = useState([]);

	const setLocation = (address) => {
		const location = googleSuggestions.find(
			(i) => i.location.description === address
		);
		const searchText = (location && `${location.address}`) || address;
		setAddress(searchText);
		localStorage.setItem('liveCity', '');
		props.locationOnChange(searchText);
	};

	useEffect(() => {
		setTimeout(() => {
			setGoogleSuggestions(filteredSuggestions);
		}, 1000);
	}, [props.location, address]);

	const suggestionsFilterByCityArea = (suggestions) => {
		filteredSuggestions = [];
		for (let suggestion of suggestions) {
			let area = '';
			let city = '';

			if (suggestion && suggestion.types && suggestion.types.length) {
				if (cityTerms.includes(suggestion.types[0])) {
					city = suggestion.terms[0].value;
				} else if (suggestion.types[0] === 'sublocality_level_2') {
					area = suggestion.terms[0].value;
					city = suggestion.terms[2].value;
				} else {
					if (areaTerms.includes(suggestion.types[0])) {
						area = suggestion.terms[0].value;
					}
					if (cityTerms.includes(suggestion.types[1])) {
						city = suggestion.terms[1].value;
					}

					// if (suggestion.types[0] === "locality") {
					//   city = suggestion.formattedSuggestion.mainText;
					// } else {
					//   if (suggestion.types[0] === "sublocality_level_1") {
					//     area = suggestion.terms[0].value;
					//   }
					//   if (
					//     suggestion.types[0] === "sublocality_level_2" &&
					//     suggestion.types[2] === "political" &&
					//     suggestion.terms[2].value === "Chandigarh"
					//   ) {
					//     area = suggestion.terms[0].value;
					//   }
					//   if (suggestion.types[1] === "sublocality") {
					//     city = suggestion.terms[1].value;
					//     if (
					//       suggestion.types[2] === "political" &&
					//       suggestion.terms[2].value === "Chandigarh"
					//     ) {
					//       city = suggestion.terms[2].value;
					//     }
					//   }
					// }
				}
			}
			const index = filteredSuggestions.findIndex(
				(i) => i.city === city && i.area === area
			);
			if (index === -1 && (city || area)) {
				const searchText = `${area}${area ? ', ' : ''}${city}`;
				filteredSuggestions.push({
					city,
					area,
					location: suggestion,
					address: searchText,
				});
			}
		}
	};
	const style = {
		backgroundColor: '#ffffff',
		cursor: 'pointer',
		display: 'block',
		padding: '10px 20px',
		borderTop: '1px solid #ccc',
		position: 'relative',
	};
	return (
		<div>
			<PlacesAutocomplete
				value={props.location || address}
				onChange={setLocation}
				onSelect={setLocation}
				// searchOptions={{
				//  location: new window.google.maps.LatLng(30.705854, 76.770861),
				//  componentRestrictions: {
				//    country: 'in',
				//  },
				//  radius: 1000 * 80,
				// }}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					suggestionsFilterByCityArea(suggestions);
					return (
						<div>
							<input
								{...getInputProps({
									placeholder: 'Location',
									className: 'location-search-input',
								})}
							/>
						</div>
					);
				}}
			</PlacesAutocomplete>
			{googleSuggestions && googleSuggestions.length ? (
				<div className="autocomplete-dropdown-container">
					<div style={style}>
						<button
							color="primary"
							onClick={() => props.handleCurrentLocation(setAddress)}
							className="gps-btn mb-2 ml-0 pl-0"
						>
							<img src={Geolocation} alt="geoLocation" />{' '}
							<span className="pl-3">Use my location</span>
						</button>
					</div>
					{googleSuggestions.map((suggestion) => {
						const { city, area, address } = suggestion;
						return (
							(city || area) && (
								<div style={style} onClick={() => setLocation(address)}>
									{area && (
										<div
											style={{
												width: '100%',
												color: '#414146',
												display: 'block',
											}}
										>
											{area}
										</div>
									)}
									{city && (
										<div
											style={{
												width: '100%',
												color: area ? '#787887' : '#414146',
												display: 'block',
												fontSize: area ? '14px' : '16px',
											}}
										>
											{city}
										</div>
									)}
								</div>
							)
						);
					})}
				</div>
			) : (
				''
			)}
		</div>
	);
}
