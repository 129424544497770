import React, { Component } from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import Hospital from "../../../assets/images/home/hospital.jpeg";
import StarFilled from "../../../assets/images/home/star-filled.svg";

import Loader from "../../../loader/index";

class LabsTestDeals extends Component {
  render() {
    const { labTestDeals, loading } = this.props;
   
    // const { videos } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
      labTestDeals.length >= 4
        ? 4
        : labTestDeals.length >= 3
        ? 3
        : labTestDeals.length >= 2
        ? 2
        : 1,
      slidesToScroll:  labTestDeals.length >= 4
      ? 4
      : labTestDeals.length >= 3
      ? 3
      : labTestDeals.length >= 2
      ? 2
      : 1,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: labTestDeals
            ? labTestDeals.length >= 3
              ? 3
              : labTestDeals.length
            : 1,
          slidesToScroll: labTestDeals
            ? labTestDeals.length >= 3
              ? 3
              : labTestDeals.length
            : 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: labTestDeals
            ? labTestDeals.length >= 2
              ? 2
              : labTestDeals.length
            : 1,
          slidesToScroll: labTestDeals
            ? labTestDeals.length >= 2
              ? 2
              : labTestDeals.length
            : 1,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 1,
            speed: 500,
          },
        },
        {
          breakpoint: 575,
          settings: {
            centerMode: true,
            infinite: true,
            centerPadding: "50px",
            slidesToShow: 1,
            speed: 500,
          },
        },
      ],
    };
    return (
      <section className="labTest-deals-wrapper">
        <Container>
          <h3 className="title text-center theme-title">
            <span>Lab Tests</span> Deals
          </h3>
          <div className="doc-slider-wrapper">
            {loading ? (
              <Loader />
            ) : (
              <Slider {...settings}>
                {labTestDeals &&
                  labTestDeals.length > 0 &&
                  labTestDeals.map((item) => {
                    const content = item.excerpt.rendered.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    );
                    // let splitContent = content.split(":")[1];
                    // let splitByDiscount = splitContent.split("DISCOUNTED PRICE");
                    // let discountedPrice=content.split(":")[2];
                 
                    return (
                      <div className="labTest-deals-content">
                        <div className="labTest-image">
                          {/* <h2>Full Profile</h2> */}
                          <img
                           style={{ cursor: "pointer" }}
                          src={item._embedded["wp:featuredmedia"][0].source_url}
                            alt="hospital"
                            onClick={() => {
                              window.location.href = item.link;
                            }}
                          />
                          {/* <h3 className="text-white">31 Tests</h3>
                          <div className="discount-wrap">
                            <p className="discount mb-0">10%</p>
                            <p className="text mb-0">Discount</p>
                          </div> */}
                        </div>
                        <div className="labTest-content">
                          <h3>{item.title.rendered}</h3>
                          <p className="mb-0">
                            {/* <span className="base-price">{`Rs. ${splitByDiscount}`}</span>
                            <span className="discount-price">{`Rs. ${discountedPrice}`}</span> */}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </Container>
      </section>
    );
  }
}

export default LabsTestDeals;
