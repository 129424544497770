import React from "react";

import AppointmentsDetails from "../dashboard/appointmentsDetails";
import { fetchMyAppointments } from "../dashboard/store/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class MyAppointments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myAppointment: {},
    };
  }
  componentDidMount() {
    this.callApiMyAppointments();
  }

  callApiMyAppointments = () => {
    let pathname = window.location.pathname.split("/");

    let id = pathname[pathname.length - 1];

    this.setState({
      loading: true,
    });

    this.props.fetchMyAppointments(`booking/${id}`, (response) => {
      this.setState({
        myAppointment: response.data,
        loading: false,
      });
    });
  };
  render() {
    const { myAppointment } = this.state;

    return (
      <div>
        <AppointmentsDetails
          myAppointment={myAppointment}
          loading={this.state.loading}
          callApiAppointmentDetails={this.callApiMyAppointments}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchMyAppointments,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyAppointments)
);
