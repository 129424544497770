import FBIcon from './assets/images/get-in-touch/FBIcon.png';
import InstaIcon from './assets/images/get-in-touch/InstaIcon.png';
import LinkedinIcon from './assets/images/get-in-touch/LinkedinIcon.png';
import TwitterIcon from './assets/images/get-in-touch/TwitterIcon.png';
import YoutubeIcon from './assets/images/get-in-touch/YoutubeIcon.png';

export const socialLinks = [
	{
		image: FBIcon,
		url: 'https://www.facebook.com/WishHealthIndia/',
		altText: 'facebook'
	},
	{
		image: TwitterIcon,
		url: 'https://twitter.com/wishhealthindia/',
		altText: 'twitter'
	},
	{
		image: LinkedinIcon,
		url: 'https://in.linkedin.com/company/wishhealth',
		altText: 'linkedin'
	},
	{
		image: YoutubeIcon,
		url: 'https://www.youtube.com/channel/UCC9M8DgKKARVQ8P0hZKgJZg',
		altText: 'youtube'
	},
	{
		image: InstaIcon,
		url: 'https://www.instagram.com/wishhealth.in/',
		altText: 'instagram'
	}
];

export const contactUsOptions = [
	'Getting Listed as a Doctor in wishealth',
	'Advertising on Wishhealth',
	'Looking for Doctor',
	'Payment Issues',
	'Career Opportunities',
	'Grievances',
	'Other'
];

export const couponName = 'Wishhealth';