const { SET_VIDEO_SESSION_DATA } = require("./types");
const { CLEAR_REDUCER } = require("../../../layout/store/types");
const initialState = {
  session: null,
  stream: null,
  audioType: "unmute",
  videoType: "unmute",
};

const VideoSessionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_SESSION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_REDUCER: {
      return {
        state: initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default VideoSessionDataReducer;
