import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";

class LocalityCity extends React.Component {
  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <div className="mb-4">


                  <Link
                to={"/Chandigarh-index"}          
                onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h4>Chandigarh</h4>
                  </Link>

                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist in Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda in Chandigarh
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Chandigarh/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist in Chandigarh
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Chandigarh/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in Chandigarh
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Chandigarh/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist in Chandigarh
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Chandigarh/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist in Chandigarh
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Chandigarh/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in Chandigarh
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-4">

                  <Link
                      to={"/Mohali-index"}
                      onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h4>Mohali</h4>
                  </Link>
                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Mohali/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Mohali/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist in Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Mohali/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda in Mohali
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Mohali/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Mohali/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Mohali/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist in Mohali
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Mohali/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in Mohali
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Mohali/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in Mohali
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Mohali/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist in Mohali
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Mohali/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist in Mohali
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Mohali/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in Mohali
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-4">
                  <Link
                      to={"/Panchkula-index"}
                      onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h4>panchkula</h4>
                  </Link>
                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Panchkula/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Panchkula/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist in Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Panchkula/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda in Panchkula
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Panchkula/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Panchkula/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Panchkula/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist in Panchkula
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Panchkula/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in Panchkula
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Panchkula/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in Panchkula
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Panchkula/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist in Panchkula
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Panchkula/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist in Panchkula
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Panchkula/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in Panchkula
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-4">
                  <Link
                      to={"/Zirakpur-index"}
                      onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h4>Zirakpur</h4>
                  </Link>
                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist in Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda in Zirakpur
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Zirakpur/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist in Zirakpur
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in Zirakpur
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Zirakpur/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist in Zirakpur
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Zirakpur/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist in Zirakpur
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Zirakpur/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in Zirakpur
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>


                <div className="mb-4">
                  <Link
                      to={"/search/Kharar/#"}
                      onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h4>Kharar</h4>
                  </Link>
                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Kharar/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in Kharar
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Kharar/Ophthalmologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ophthamologist in Kharar
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Kharar/Ayurveda"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Ayurveda in Kharar
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Kharar/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Homoeopath in Kharar
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Kharar/ENT"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              ENT in Kharar
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Kharar/Dermatologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dermatologist in Kharar
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Kharar/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in Kharar
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Kharar/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in Kharar
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Kharar/Gynecologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Gynecologist in Kharar
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                          <li>
                            <Link
                                to={"/search/Kharar/Neurotologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Neurotologist in Kharar
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Kharar/Cardiologist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Cardiologist in Kharar
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
             
             
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default LocalityCity;

