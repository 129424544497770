import {
  SET_LOCATION_NAME_DATA,
  SET_ACTIVE_PAGE,
  SET_SEARCH_TYPE,
} from "./types";
const initialState = {
  search: "",
  searchLocation: "",
  activePage: 1,
  searchType: "",
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_NAME_DATA: {
      return {
        ...state,
        search: action.payload.search,
        searchLocation: action.payload.searchLocation,
      };
    }
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.payload,
      };
    }
    case SET_SEARCH_TYPE: {
      return {
        ...state,
        searchType: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default SearchReducer;
