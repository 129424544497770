import React, { PureComponent } from 'react';
import ModalDialog from '../common/ModalDialog';
import StarRating from './StarRating';
import { NotificationManager } from "react-notifications";
import {
	Container,
	Input
} from 'reactstrap';
import Loader from '../../loader';

export default class ReviewModal extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			rating: 0,
			review: '',
			isLoading: false
		}
	}

	open = () => {
		this.modalDialog.open();
	}

	close = () => {
		this.modalDialog.close();
		this.resetReview();
	}

	handleStarRating = (rating) => {
		this.setState({ rating });
	}

	handleReviewTextChange = (event) => {
		this.setState({ review: event.target.value });
	}

	onSave = () => {
		const { rating, review } = this.state
		if (!rating) {
			return NotificationManager.error('Please select a rating');
		}
		// if (!review) {
		// 	return NotificationManager.error('Please enter review for the doctor');
		// }
		localStorage.removeItem('postReview');
		this.setState({ isLoading: true })
		this.props.postReview({
			rating: rating || 0,
			review: review || '',
		}, () => {
			this.close();
			this.setState({ isLoading: false });
		});
	}

	resetReview = () => {
		this.setState({
			review: '',
			rating: 0
		})
	}

	render() {

		const { rating = 0, review = '', isLoading } = this.state;
		const { doctorName = '' } = this.props;

		return (
			<ModalDialog
				title="Rating & Reviews"
				confirmText="Post"
				className="review-container"
				showHeaderClose={false}
				cancelButtonClass="cancel-review"
				onSave={this.onSave}
				ref={(c) => this.modalDialog = c}
				onClose={this.resetReview}
			>
				{isLoading && <Loader />}
				<Container>
					<h5>{doctorName}</h5>
					<StarRating
						stop={5}
						rating={rating}
						onChange={this.handleStarRating}
					/>
					<Input
						type="textarea"
						name="review"
						className="review-text"
						placeholder="Share your own experience with the Doctor"
						onChange={this.handleReviewTextChange}
						value={review}
					/>
				</Container>
			</ModalDialog>
		)
	}
}

