import React, { useState } from "react";
import { Container } from "reactstrap";
import cx from "classnames";
import Search from "../../../pages/search";

import doctorImage from "../../../assets/images/doctor-image.png";
import videoConsulIcon from "../../../assets/images/icons/videoConsultation.png";
import clinicConsulIcon from "../../../assets/images/icons/clinicConsultation.png";
import liveConsulIcon from "../../../assets/images/icons/consultLiveDoctors.png";
import BlueArrow from "../../../assets/images/consultArrow.svg";
import MikeIcon from "../../../assets/images/mike-icon.png";
import Loader from "../../../loader/index";

import { withRouter } from "react-router-dom";
import { couponName } from "../../../constants";
const consultArray = [

  {
    id: 2,
    icon: clinicConsulIcon,
    iconClassName: "clinic",
    textClassName: "",
    name: "Book now",
  },
  {
    id: 3,
    icon: liveConsulIcon,
    iconClassName: "consultLive",
    textClassName: "mr-0",
    name: "Consult live Doctor now",
    isLive: true,
  },
];
const TitleHeader = ({ className = "", title="Search and consult doctors anywhere anytime" }) => (
  <div className={cx("titleHeader", className)}>
    <h1 className="title">{title}</h1>
  </div>
);

const HomeBanner = (props) => {
  const [isLoading, setIsLoading] = useState(0);

  const icons = (id) => {
    setIsLoading(id);
    if (id === 1) {
      props.history.push({
        pathname: "/search",
        state: { video: true },
      });
    } else if (id === 2) {
      props.history.push({
        pathname: "/search",
        state: { clinic: true },
      });
    } else {
      props.history.push({
        pathname: "/search",
        state: { consult: true },
      });
    }
  };
  return (
    <section
      className={window.location.href.includes("search") ? "" : "homeBanner"}
    >
      <Container
        className={
          window.location.href.includes("search")
            ? ""
            : "containerWrapper position-relative"
        }
      >
        <Search />
        {!window.location.href.includes("search") && (
          <>
            {/* <TitleHeader className="d-md-none text-center" /> */}
            {/* <TitleHeader className="d-md-none text-center" title={<><span>Free Consultation</span> with Best Doctors</>} /> */}
            {/* <div className="d-md-none text-center promo-title text-uppercase rating-text">
              <img src={MikeIcon} width={40}></img> Apply Coupon <span className="coupon-title">'{couponName}'</span> on checkout
            </div> */}
            <div className="homeBannerContent">
              {/* <TitleHeader className="d-none d-md-block" /> */}
              {/* <TitleHeader className="d-none d-md-block" title={<>
                  <span className="rating-text">Free Consultation</span> with Best Doctors
                </>}  */}
              {/* /> */}
              {/* <div className="d-none d-md-block promo-title text-uppercase rating-text">
                <img src={MikeIcon} width={40}></img> Apply Coupon <span className="coupon-title">'{couponName}'</span> on checkout
              </div> */}
              <div className="consults">
                {consultArray.map(
                  ({
                    id,
                    name,
                    icon,
                    iconClassName,
                    textClassName,
                    isLive = false,
                  }) => {
                    return (
                      <div
                        key={id}
                        className="consult position-relative"
                        onClick={() => icons(id)}
                      >
                        {id === isLoading && (
                          <div className="location-loader">
                            <Loader />
                          </div>
                        )}
                        <div className="content">
                          <div className="icon bg-primary d-flex align-items-center justify-content-center mb-md-5 mb-2 mb-sm-4">
                            <img
                              src={icon}
                              alt={name}
                              className={iconClassName}
                            />
                          </div>
                          <div className="consult-name">
                            <p className={`name ${textClassName}`}>{name}</p>
                            <button className="arrow">
                              <img src={BlueArrow} alt="arrow" />
                            </button>
                          </div>

                          {isLive && (
                            <p className="isLive bg-danger text-white">Live</p>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <img
              className="d-img d-none d-sm-block"
              src={doctorImage}
              alt="profile"
            />
          </>
        )}
      </Container>
    </section>
  );
};

export default withRouter(HomeBanner);
