import React from "react";
import { Container, Row, Col } from "reactstrap";
import Mask from "../../../assets/images/mask.png";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { fetchServicesData } from "./store/action";
import Slider from "react-slick";
import { titleCase } from "../../../common/stringFunction";
import { config } from "../../../config";
import Doctor from "../../../assets/images/home/hospital.jpeg";
class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      finalArr: [],
      seeAll: false,
    };
  }
  componentDidMount() {
    const { fetchServicesData } = this.props;
    fetchServicesData("data/services", (value) => {
      let arr = value && value.data.splice(0, 12);
      this.setState({ services: value, finalArr: arr });
    });
  }
  handleSeeAll = () => {
    this.setState({
      seeAll: true,
      finalArr: this.state.services,
    });
  };
  render() {
    const { finalArr } = this.state;
    const { latitude, longitude } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        finalArr.length >= 4
          ? 4
          : finalArr.length >= 3
            ? 3
            : finalArr.length >= 2
              ? 2
              : 1,
      slidesToScroll:
        finalArr.length >= 4
          ? 4
          : finalArr.length >= 3
            ? 3
            : finalArr.length >= 2
              ? 2
              : 1,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: finalArr
              ? finalArr.length >= 3
                ? 3
                : finalArr.length
              : 1,
            slidesToScroll: finalArr
              ? finalArr.length >= 3
                ? 3
                : finalArr.length
              : 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: finalArr
              ? finalArr.length >= 2
                ? 2
                : finalArr.length
              : 1,
            slidesToScroll: finalArr
              ? finalArr.length >= 2
                ? 2
                : finalArr.length
              : 1,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 1,
            speed: 500,
          },
        },
        {
          breakpoint: 575,
          settings: {
            centerMode: true,
            infinite: true,
            centerPadding: "50px",
            slidesToShow: 1,
            speed: 500,
          },
        },
      ],
    };
    return (
      <section className="services-we-provide">
        <Container>
          <h2 className="title text-center font-weight-black theme-title">
            {/* Services we provide */}
            <span>Our</span> Specialities
          </h2>
        </Container>
        {/* <Container fluid="lg"> */}
        <Container>
          {/* <Row className="text-center d-none d-lg-flex align-items-center services-list justify-content-between">
            {finalArr &&
              finalArr.length > 0 &&
              finalArr.map((service, index) => {
                let match = /[&/\\#, +()$~%.'":*?<>{}]/g.exec(service.name);
                let finalName = service.name;
                if (match) {
                  finalName = service.name.substr(0, match.index).trim();
                  if (!finalName) {
                    finalName = service.name
                      .substr(match.index + 1, service.name.length)
                      .trim();
                    match = /[&/\\#, +()$~%.'":*?<>{}]/g.exec(finalName);
                    if (match) {
                      finalName = finalName.substr(0, match.index);
                    }
                  }
                }
                const serviceImage = service.image.split("/");
                let service_image = serviceImage.includes("services")
                  ? service.image
                  : Mask;
                let finalNameReplaceAll = finalName.split(" ").join("-");
                return (
                  <Col key={index}>
                    <div
                      key={index}
                      className="text-center"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/search/#/${finalNameReplaceAll}`,
                          state: {
                            search: service.name,
                            searchLocation: "",

                            latitude: latitude,
                            longitude: longitude,
                            video: true,
                            clinic: true,
                          },
                        });
                      }}
                    >
                      <div className="services-img">
                        <img
                          style={{ cursor: "pointer" }}
                          width="113px"
                          height="113px"
                          src={`${config.apiUrl}${service_image}`}
                          alt="services"
                        />
                      </div>
                      <p title={titleCase(service.name)}>
                        {titleCase(service.name)}
                      </p>
                    </div>
                  </Col>
                );
              })}
          </Row> */}
          <div className="serviceSlider">
            <Slider {...settings} className="mb-0">
              {finalArr &&
                finalArr.length > 0 &&
                finalArr.map((service, index) => {

                  let match = /[&/\\#, +()$~%.'":*?<>{}]/g.exec(service.name);
                  let finalName = service.name;
                  if (match) {
                    finalName = service.name.substr(0, match.index).trim();
                    if (!finalName) {
                      finalName = service.name
                        .substr(match.index + 1, service.name.length)
                        .trim();
                      match = /[&/\\#, +()$~%.'":*?<>{}]/g.exec(finalName);
                      if (match) {
                        finalName = finalName.substr(0, match.index);
                      }
                    }
                  }
                  const serviceImage = service.image.split("/");
                  let service_image = serviceImage.includes("services")
                    ? service.image
                    : Mask;
                  let finalNameReplaceAll = finalName.split(" ").join("-");
                  const imageTitle = service.name.includes('/') ? service.name.split('/')[0] : service.name;
                  const serviceImageUrl = `${process.env.REACT_APP_API_URL_TEST_INNOW8}/images/${imageTitle.split(' ').join('-')}.png`;
                  return (
                    <div key={index}>
                      <div
                        key={index}
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/search/#/${finalNameReplaceAll}`,
                            state: {
                              search: service.name,
                              searchLocation: "",
                              latitude: latitude,
                              longitude: longitude,
                              video: true,
                              clinic: true,
                            },
                          });
                        }}
                      >
                        <div className="services-img">
                          <img
                            style={{ cursor: "pointer" }}
                            width="113px"
                            height="113px"
                            src={serviceImageUrl}
                            alt="services"
                          />
                          {/* <img src={Doctor} alt="doctor" /> */}
                        </div>
                        <h3 title={titleCase(service.name)}>
                          {titleCase(service.name)}
                        </h3>
                        {/* <p>Lorem ipsum</p> */}
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchServicesData,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Services)
);
