import React from "react";

import { titleCase } from "../../../common/stringFunction";
import Calendar from "../../../assets/images/dashboard/calendar.png";

import { Button, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Avatar from "../../../assets/images/avatar.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { extractChar } from '../../../util/string';
const PastAppointment = (props) => {
  const { pastBookings, callDisable } = props;

  const handleAppointment = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    let nameReplace = props.saveName.split(" ").join("-");
    let lastFourDigitsPhone = props.savePhoneNumber.substr(
      props.savePhoneNumber.length - 4
    );

    let namePhone = nameReplace + lastFourDigitsPhone;
    props.history.push({
      pathname: `/patient/appointment/${namePhone}/${data && data.id}`,
      state: {
        appointmentDetails: data,
        callDisable: callDisable,
        type: "past",
      },
    });
  };

  const Card = (data) => {
    const { wh_user } = data;

    let timeFormat = moment(data.time, "hh:mm a");
    let timeFormatInSmall = timeFormat.format("hh:mm A");
    let degrees =
      wh_user &&
      wh_user.wh_doctor_qualifications &&
      wh_user.wh_doctor_qualifications.length > 0 &&
      wh_user.wh_doctor_qualifications.filter((item) => {
        return item.wh_qualification && item.wh_qualification.degree !== null;
      });

    let doctorDegree =
      degrees &&
      degrees.length > 0 &&
      degrees.map((item) => {
        return item.wh_qualification && item.wh_qualification.degree;
      });
    let qualification = doctorDegree && doctorDegree.join(", ");

    let specialities =
      wh_user &&
      wh_user.wh_doctor_specialities &&
      wh_user.wh_doctor_specialities.filter((item) => {
        return item.wh_speciality !== null;
      });

    // let doctorsSpecialitiesArray =
    //   specialities &&
    //   specialities.length > 0 &&
    //   specialities.map((item) => {
    //     return (
    //       item && item.wh_speciality && titleCase(item.wh_speciality.title)
    //     );
    //   });

    let doctorsSpecialitiesReplaced =
      specialities &&
      specialities.length > 0 &&
      specialities.map((item) => {
        const data =
          item &&
          item.wh_speciality &&
          titleCase(item.wh_speciality.title).split(" ").join("-");
        return data.split("/").join("-");
      });

    let profileImage =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic
        ? wh_user &&
          wh_user.wh_doctor_detail &&
          wh_user.wh_doctor_detail.profile_pic
        : Avatar;
    const handleRebook = (e) => {
      let nameReplace = wh_user && wh_user.name.split(" ").join("-");

      let city =
        wh_user &&
        wh_user.wh_doctor_detail &&
        wh_user.wh_doctor_detail.wh_city &&
        wh_user.wh_doctor_detail.wh_city.name;
      let specialitiesReplace =
        doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");
      e.preventDefault();
      e.stopPropagation();
      props.history.push({
        pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${
          wh_user && wh_user.user_id
        }`,
        search: `?specialization=${specialitiesReplace}`,
        // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${
        //   wh_user && wh_user.user_id
        // }`,
        state: { modalOpen: true },
      });
    };

    return (
      <>
        {wh_user && wh_user.name && (
          <div
            className="past-appointment-list"
            onClick={(e) => handleAppointment(e, data)}
            style={{ cursor: "pointer" }}
          >
            <div className="past-doctor-profile">
             { wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic?<img src={wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.profile_pic} className="profile" alt="" />:(
        <div className="dummy-text">
								<span>{extractChar(wh_user && wh_user.name)}</span>
							</div>
      )}
              <h2>
                {wh_user && wh_user.name}
                {qualification && <span>{`(${qualification})`}</span>}
              </h2>
            </div>
            <div className="past-appointment-time">
              <img src={Calendar} alt="calendar" className="mr-2" />
              {data && data.clinic_id === 1 ? (
                <p className="mb-0">
                  Video Appointment Time:{" "}
                  <span className="date">{`${
                    data && data.date
                  }, ${timeFormatInSmall}`}</span>
                </p>
              ) : (
                <p className="mb-0">
                  Clinic Appointment Time:{" "}
                  <span className="date">{`${
                    data && data.date
                  }, ${timeFormatInSmall}`}</span>
                </p>
              )}
            </div>

            <div className="appointments-edit-options">
              <Button
                className="reBook-btn"
                color="primary"
                onClick={handleRebook}
              >
                Re-book
              </Button>
              {/* <button className="delete-btn">
            <i className="fas fa-trash" />
          </button> */}
            </div>
          </div>
        )}
      </>
    );
  };

  pastBookings &&
    pastBookings.length > 0 &&
    pastBookings.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
  return (
    <div className="past-appointments">
      {pastBookings && pastBookings.length > 0 && (
        <Row>
          {pastBookings.map((data) => {
            return (
              <Col sm={6} md={12}>
                <Card {...data} />
              </Col>
            );
          })}
        </Row>
      )}{" "}
      {(pastBookings.length === 0 || pastBookings === false) && (
        <p className="text-center no-records">No Past Appointments</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  saveName: state.BookAppointmentReducer.saveName,
  savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PastAppointment)
);
