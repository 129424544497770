import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";
import qs from 'qs'
class DentistIndexC extends React.Component {

componentDidMount(){
  const { speciality } = qs.parse(this.props.location.search.substr(1))
  if (document.getElementById(speciality)!=null){
    document.getElementById(speciality).scrollIntoView()
 }

}


  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <h2 id = "Dentist" className="text-center title">Dentist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-1, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-2, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-3, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-4, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-5-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-5, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-6, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-7, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-8, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-9-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-9, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-10, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-11-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-11, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-12, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-13-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-13, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-14, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                                to={"/search/Sector-15-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-15, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-16, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-17-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-17, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-18-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-18, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-19-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-19, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-20-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-20, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-21-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-21, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-22-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-22, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-23-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-23, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-24-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-24, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-25-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-25, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-26-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-26, Chandigarh
                            </Link>``
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-27-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-27, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-28-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-28, Chandigarh
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-29-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-29, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-30-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-30, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-31-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-31, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-32-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-32, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-33-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-33, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-34-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-34, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-35-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-35, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-36-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-36, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-37-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-37, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-38-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-38 Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-39-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-39, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-40-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-40, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-41-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-41, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-42-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-42, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-43-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-43, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-44-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-44, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-45-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-45, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-46-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-46, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-47-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-47, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-48-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-48, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-49-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-49, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-50-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-50, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-51-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-51, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-52-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-52, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-53-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-53, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-54-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-54, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-55-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-55, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-56-Chandigarh/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-56, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 id = "Orthodontist" className="text-center title">Orthodontist</h2>

                <div className="mb-4">

                 
                <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                          
                              Orthodontist in sector-1, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-2, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Chandigarh/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Homoeopath in sector-3, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist2 in sector-4, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-5-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-5, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-6, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-7, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-8, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-9-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-9, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-10, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-11-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-11, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-12, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-13-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-13, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Chandigarh/Homoeopath"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-14, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         
                          <li>
                            <Link
                                to={"/search/Sector-15-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-15, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-16, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-17-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-17, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-18-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-18, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-19-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-19, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-20-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-20, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-21-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-21, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-22-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-22, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-23-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-23, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-24-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-24, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-25-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-25, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-26-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-26, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-27-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-27, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-28-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-28, Chandigarh
                            </Link>
                          </li>



                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-29-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-29, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-30-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-30, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-31-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-31, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-32-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-32, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-33-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-33, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-34-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-34, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-35-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-35, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-36-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-36, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-37-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-37, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-38-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-38 Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-39-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-39, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-40-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-40, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-41-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-41, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-42-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-42, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-43-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-43, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-44-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-44, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-45-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-45, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-46-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-46, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-47-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-47, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-48-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-48, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-49-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-49, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-50-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-50, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-51-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-51, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-52-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-52, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-53-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-53, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-54-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-54, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-55-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-55, Chandigarh
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-56-Chandigarh/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-56, Chandigarh
                            </Link>
                          </li>
                      



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 id = "Pediatrician" className="text-center title">Pediatrician</h2>

                <div className="mb-4">

                 
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Pediatrician in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Pediatrician in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Pediatrician"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Pediatrician in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>






  
</div>
<h2 id = "Neurologist" className="text-center title">Neurologist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Neurologist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Neurologist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Neurologist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Ophthamologist" className="text-center title">Ophthalmologist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Neurologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ophthalmologist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ophthalmologist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Ophthalmologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ophthalmologist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>


  <h2 id = "ENT" className="text-center title">ENT</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             ENT in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            ENT in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Homoeopath"  className="text-center title">Homoeopath</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/ENT"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              ENT in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Homoeopath in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Homoeopath in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Homoeopath"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Homoeopath in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>

  <h2 id = "Cardiologist" className="text-center title">Cardiologist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Cardiologist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Cardiologist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Cardiologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Cardiologist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Ayurveda" className="text-center title">Ayurveda</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Ayurveda in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Ayurveda in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Dermatologist" className="text-center title">DERMATOLOGIST</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Ayurveda"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Ayurveda in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             DERMATOLOGIST in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            DERMATOLOGIST in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              DERMATOLOGIST in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Gynecologist" className="text-center title">Gynecologist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/DERMATOLOGIST"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Gynecologist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Gynecologist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Gynecologist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Orthopedist" className="text-center title">Orthopedist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Gynecologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Orthopedist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>

  <h2 id = "Psychiatrist" className="text-center title">Psychiatrist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Orthopedist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Orthopedist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Orthopedist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Psychiatrist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Psychiatrist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Psychiatrist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Psychiatrist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2  id = "Internal Medicine" className="text-center title">Internal-Medicine</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Internal Medicine in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Internal Medicine in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Internal Medicine in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Sexologist" className="text-center title">Sexologist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
          Sexologist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Sexologist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Sexologist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Sexologist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Sexologist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Internal-Medicine"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Internal Medicine in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Sexologist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Sexologist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Sexologist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Sexologist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>

  <h2 id = "Physiotherapist" className="text-center title">Physiotherapist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Sexologist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
          Physiotherapist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Physiotherapist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Physiotherapist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Physiotherapist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Physiotherapist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Physiotherapist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Physiotherapist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Physiotherapist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>
  <h2 id = "Dietitian/Nutritionist" className="text-center title">Dietitian/Nutritionist</h2>

<div className="mb-4">
<Row className="linkRow">
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-1-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-1, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-2-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-2, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-3-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-3, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-4-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-4, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-5-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-5, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-6-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-6, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-7-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-7, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-8-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
          Dietitian/Nutritionist in sector-8, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-9-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-9, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-10-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-10, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-11-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-11, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-12-Chandigarh/Physiotherapist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-12, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-13-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Physiotherapist in sector-13, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-14-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-14, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
         
          <li>
            <Link
                to={"/search/Sector-15-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-15, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-16-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-16, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-17-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-17, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-18-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-18, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-19-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-19, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-20-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-20, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-21-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Dietitian/Nutritionist in sector-21, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-22-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-22, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-23-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-23, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-24-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-24, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-25-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
           Dietitian/Nutritionist in sector-25, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-26-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-26, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-27-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-27, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-28-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-28, Chandigarh
            </Link>
          </li>



        </ul>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-29-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-29, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-30-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-30, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-31-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-31, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-32-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-32, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-33-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-33, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-34-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
               Dietitian/Nutritionist in sector-34, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-35-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-35, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-36-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Dietitian/Nutritionist in sector-36, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-37-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-37, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-38-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-38 Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-39-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-39, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-40-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-40, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-41-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-41, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-42-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-42, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>

    <Col xs={6} md={3} lg={3} className="linkCol">
      <div>
        <ul>
          <li>
            <Link
                to={"/search/Sector-43-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-43, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-44-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-44, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-45-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
         Dietitian/Nutritionist in sector-45, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-46-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-46, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-47-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-47, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-48-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-48, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-49-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-49, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-50-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-50, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-51-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-51, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-52-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-52, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-53-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-53, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-54-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
             Dietitian/Nutritionist in sector-54, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-55-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
            Dietitian/Nutritionist in sector-55, Chandigarh
            </Link>
          </li>
          <li>
            <Link
                to={"/search/Sector-56-Chandigarh/Dietitian/Nutritionist"}
                onClick={() => window.scrollTo({ top: 0 })}
            >
              Dietitian/Nutritionist in sector-56, Chandigarh
            </Link>
          </li>
      



        </ul>
      </div>
    </Col>
  </Row>
  </div>


              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default DentistIndexC;

