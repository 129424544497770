import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";
import {Link} from "react-router-dom";
import qs from 'qs'

class ZirakpurIndex extends React.Component {

  componentDidMount(){
    const { speciality } = qs.parse(this.props.location.search.substr(1))
    if (document.getElementById(speciality)!=null){
      document.getElementById(speciality).scrollIntoView()
   }
  
  }
  
  
  render() {
    return (
        <div className="footer-top locality-index">
          <Col>
            <Row>
              <Col lg={12} className="pl-lg-5">
                <h2 id  = "Dentist" className="text-center title">Dentist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-1, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-2, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-3, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-4, Zirakpur
                            </Link>
                          </li>
                          
                        
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                        <li>
                            <Link
                                to={"/search/Sector-5-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-5, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-6, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-7, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-8, Zirakpur
                            </Link>
                          </li>
                         
                         
            
                         


                        </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>




                        <li>
                            <Link
                                to={"/search/Sector-9-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-9, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-10, Zirakpur
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Sector-11-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-11, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Dentist in sector-12, Zirakpur
                            </Link>
                          </li>
                        

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                        <li>
                            <Link
                                to={"/search/Sector-13-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-13, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-14, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Dentist in sector-15, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Zirakpur/Dentist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Dentist in sector-16, Zirakpur
                            </Link>
                          </li>
                         
                         



                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h2 id = "Orthodontist" className="text-center title">Orthodontist</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-1, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-2, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-3, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-4, Zirakpur
                            </Link>
                          </li>
                          
                        
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                         

                        <li>
                            <Link
                                to={"/search/Sector-5-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-5, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-6, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-7, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-8, Zirakpur
                            </Link>
                          </li>
                           </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>


                        <li>
                            <Link
                                to={"/search/Sector-9-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-9, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-10, Zirakpur
                            </Link>
                          </li>



                          <li>
                            <Link
                                to={"/search/Sector-11-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-11, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Orthodontist in sector-12, Zirakpur
                            </Link>
                          </li>
                         </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>



                        <li>
                            <Link
                                to={"/search/Sector-13-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-13, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-14, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Orthodontist in sector-15, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Zirakpur/Orthodontist"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Orthodontist in sector-16, Zirakpur
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
               
                <h2 id ="Pediatrician" className="text-center title">Pediatrician</h2>

                <div className="mb-4">


                  <Row className="linkRow">
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>
                          <li>
                            <Link
                                to={"/search/Sector-1-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-1, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-2-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-2, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-3-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-3, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-4-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-4, Zirakpur
                            </Link>
                          </li>
                         
                         


                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>





                        <li>
                            <Link
                                to={"/search/Sector-5-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-5, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-6-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-6, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-7-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-7, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-8-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-8, Zirakpur
                            </Link>
                          </li>
                          </ul>
                      </div>
                    </Col>
                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>




                        <li>
                            <Link
                                to={"/search/Sector-9-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-9, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-10-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-10, Zirakpur
                            </Link>
                          </li>

                          <li>
                            <Link
                                to={"/search/Sector-11-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-11, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-12-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                            Pediatrician in sector-12, Zirakpur
                            </Link>
                          </li>+
                         
                         
                         

                        </ul>
                      </div>
                    </Col>

                    <Col xs={6} md={3} lg={3} className="linkCol">
                      <div>
                        <ul>

                        <li>
                            <Link
                                to={"/search/Sector-13-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-13, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-14-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-14, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-15-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                              Pediatrician in sector-15, Zirakpur
                            </Link>
                          </li>
                          <li>
                            <Link
                                to={"/search/Sector-16-Zirakpur/Pediatrician"}
                                onClick={() => window.scrollTo({ top: 0 })}
                            >
                             Pediatrician in sector-16, Zirakpur
                            </Link>
                          </li>
                         </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
               
<h2 id = "Neurologist" className="text-center title">Neurologist</h2>
<div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-4, Zirakpur
          </Link>
        </li>
       
       
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Neurologist in sector-12, Zirakpur
          </Link>
        </li>
     </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/NeurologistNeurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Neurologist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Neurologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Neurologist in sector-16, Zirakpur
          </Link>
        </li>
      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "Ophthamologist" className="text-center title">Ophthamologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-4, Zirakpur
          </Link>
        </li>
       
      
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-8, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-10, Zirakpur
          </Link>
        </li>

        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ophthalmologist in sector-12, Zirakpur
          </Link>
        </li>
  </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>


      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ophthalmologist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Ophthalmologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ophthalmologist in sector-16, Zirakpur
          </Link>
        </li>
      </ul>
    </div>
  </Col>
</Row>
</div>
  <h2 id = "ENT" className="text-center title">ENT</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-4, Zirakpur
          </Link>
        </li>
       
      
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-8, Zirakpur
          </Link>
        </li>
      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>


      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-10, Zirakpur
          </Link>
        </li>

        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          ENT in sector-12, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            ENT in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/ENT"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           ENT in sector-16, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Homoeopath" className="text-center title">Homoeopath</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-4, Zirakpur
          </Link>
        </li>
      
   


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Homoeopath in sector-12, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Homoeopath in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Homoeopath"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Homoeopath in sector-16, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Cardiologist" className="text-center title">Cardiologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-4, Zirakpur
          </Link>
        </li>
       
      
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-10, Zirakpur
          </Link>
        </li>

        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Cardiologist in sector-12, Zirakpur
          </Link>
        </li>

       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Cardiologist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Cardiologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Cardiologist in sector-16, Zirakpur
          </Link>
        </li>
     </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Ayurveda" className="text-center title">Ayurveda</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-4, Zirakpur
          </Link>
        </li>
      

       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-8, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-10, Zirakpur
          </Link>
        </li>

        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Ayurveda in sector-12, Zirakpur
          </Link>
        </li>
   </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>


      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Ayurveda in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Ayurveda"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Ayurveda in sector-16, Zirakpur
          </Link>
        </li>
   
        </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Dermatologist" className="text-center title">DERMATOLOGIST</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-4, Zirakpur
          </Link>
        </li>
    


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-8, Zirakpur
          </Link>
        </li>
  </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
            
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          DERMATOLOGIST in sector-12, Zirakpur
          </Link>
        </li>
      


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            DERMATOLOGIST in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/DERMATOLOGIST"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           DERMATOLOGIST in sector-16, Zirakpur
          </Link>
        </li>
      </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Gynecologist" className="text-center title">Gynecologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-4, Zirakpur
          </Link>
        </li>
     


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

           
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Gynecologist in sector-12, Zirakpur
          </Link>
        </li>


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Gynecologist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Gynecologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Gynecologist in sector-16, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id ="Orthopedist" className="text-center title">Orthopedist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-4, Zirakpur
          </Link>
        </li>
    
      
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-8, Zirakpur
          </Link>
        </li>
     </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-10, Zirakpur
          </Link>
        </li>

        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Orthopedist in sector-12, Zirakpur
          </Link>
        </li>
      

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Orthopedist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Orthopedist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Orthopedist in sector-16, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Psychiatrist" className="text-center title">Psychiatrist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-4, Zirakpur
          </Link>
        </li>
      
     


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Psychiatrist in sector-12, Zirakpur
          </Link>
        </li>

       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>

        
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Psychiatrist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Psychiatrist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Psychiatrist in sector-16, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Internal-Medicine" className="text-center title">Internal-Medicine</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-4, Zirakpur
          </Link>
        </li>
      
      

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-8, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Internal-Medicine in sector-12, Zirakpur
          </Link>
        </li>

       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Internal-Medicine in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Internal-Medicine"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Internal-Medicine in sector-16, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Sexologist" className="text-center title">Sexologist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-4, Zirakpur
          </Link>
        </li>
       
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-8, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-10, Zirakpur
          </Link>
        </li>
       
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Sexologist in sector-12, Zirakpur
          </Link>
        </li>
     
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Sexologist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Sexologist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Sexologist in sector-16, Zirakpur
          </Link>
        </li>
    </ul>
    </div>
  </Col>
</Row>
  </div>

  <h2 id = "Physiotherapist" className="text-center title">Physiotherapist</h2>

  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-4, Zirakpur
          </Link>
        </li>
      
      


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-8, Zirakpur
          </Link>
        </li>
      
      
 </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Physiotherapist in sector-12, Zirakpur
          </Link>
        </li>
        </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Physiotherapist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Physiotherapist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Physiotherapist in sector-16, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
</Row>
  </div>
  <h2 id = "Dietitian/Nutritionist" className="text-center title">Dietitian/Nutritionist</h2>


  <div className="mb-4">


<Row className="linkRow">
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
        <li>
          <Link
              to={"/search/Sector-1-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-1, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-2-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-2, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-3-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-3, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-4-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-4, Zirakpur
          </Link>
        </li>
      
       
       


      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-5-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-5, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-6-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-6, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-7-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-7, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-8-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-8, Zirakpur
          </Link>
        </li>
       
      
 

      </ul>
    </div>
  </Col>
  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-9-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-9, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-10-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-10, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-11-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-11, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-12-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
          Dietitian/Nutritionist in sector-12, Zirakpur
          </Link>
        </li>
       
       

      </ul>
    </div>
  </Col>

  <Col xs={6} md={3} lg={3} className="linkCol">
    <div>
      <ul>
      <li>
          <Link
              to={"/search/Sector-13-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-13, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-14-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-14, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-15-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
            Dietitian/Nutritionist in sector-15, Zirakpur
          </Link>
        </li>
        <li>
          <Link
              to={"/search/Sector-16-Zirakpur/Dietitian/Nutritionist"}
              onClick={() => window.scrollTo({ top: 0 })}
          >
           Dietitian/Nutritionist in sector-16, Zirakpur
          </Link>
        </li>
       </ul>
    </div>
  </Col>
</Row>
  </div>
               
              </Col>
            </Row>
            <Row>
            </Row>
          </Col>
        </div>

    );
  }
}

export default ZirakpurIndex;

