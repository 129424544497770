import React, { useState, useEffect } from "react";
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import DocModal from "./docModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../../assets/images/logo.png";
import ToggleMenu from "../../../assets/images/toggle-menu.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../../util";
import cx from "classnames";
import { withRouter, useHistory } from "react-router-dom";
import {
  clearName,
  clearPhoneNumber,
  clearReducer,
} from "../../../components/layout/store/action";

const Header = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setSidebarOpen((prevState) => !prevState);
  const [sticky, setSticky] = useState(false);

  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const onScroll = (e) => {
    let scrollPosition = window.scrollY;
    if (scrollPosition >= 70) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  const handleLogout = () => {
    props.clearName("");
    props.clearPhoneNumber("");
    props.clearReducer("");
    localStorage.setItem("isLoggedIn", false);
    history.push("/");
  };

  const history = useHistory();
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleDoctorButton = () => {
    setModal(!modal);
  };
  return (
    <>
      <header
        id="header"
        className={cx("d-flex align-items-center", { sticky })}
      >
        <Container fluid="xl">
          <div className="header-wrap">
            <Link to="/" className="logo" onClick={scrollToTop}>
              <img src={logo} alt="wishhealth" />
            </Link>
            <div className="header-menus d-lg-block d-none">
              <ul>
                <li>
                  <a href="https://blog.wishhealth.in/covid-help/">
                    Covid Help
                  </a>
                </li>
                <li className="active">
                  <a href="https://healthdeals.wishhealth.in/index.php/doctors-deal/">
                    Doctor Offer's
                  </a>
                </li>
                <li>
                  <a href="https://healthdeals.wishhealth.in/index.php/hospital-deals/">
                    Hospital Deal's
                  </a>
                </li>

                <li>
                  <a href="https://healthdeals.wishhealth.in/index.php/lab-test/">
                    Lab Test's
                  </a>
                </li>
              </ul>
            </div>
            <div className="account-wrapper">
              {props.saveName && props.savePhoneNumber && (
                <Button
                  color="primary"
                  className="confirm-btn border-0 d-sm-block d-none"
                  onClick={() => {
                    history.push("/patient/dashboard/upcoming-appointments");
                  }}
                >
                  Go to Dashboard
                </Button>
              )}
              {/* <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle>Login/Sign Up</DropdownToggle>
              <DropdownMenu>
                {!props.saveName && !props.savePhoneNumber ? (
                  <DropdownItem
                    onClick={() => {
                      history.push("/patient_signin");
                    }}
                  >
                    Patient
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                )}
                <DropdownItem
                  className="text-black-50 mb-0"
                  disabled
                  onClick={() => {
                    window.location.href = `${config.oldWebsiteUrl}index/index/signin`;
                  }}
                >
                  Doctor
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
              {!props.saveName && !props.savePhoneNumber ? (
                <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                  <DropdownToggle>Login/Sign Up</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        history.push("/patient_login");
                      }}
                    >
                      Patient
                    </DropdownItem>

                    <DropdownItem
                      onClick={
                        // window.location.href = `${config.oldWebsiteUrl}index/index/signin`;
                        handleDoctorButton
                      }
                    >
                      Doctor
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Button
                  color="bordered"
                  onClick={handleLogout}
                  className="logout-btn ml-2"
                >
                  Logout
                </Button>
              )}

              <div className="menu-wrap">
                <p className="mb-0 pr-2 pr-sm-3 d-none d-lg-block">Menu</p>
                <button onClick={toggle}>
                  <img src={ToggleMenu} alt="menu" />
                </button>
              </div>
            </div>
          </div>
          <div className="header-menus d-lg-none mt-2">
            <ul>
              <li>
                <a href="https://blog.wishhealth.in/covid-help/">Covid Help</a>
              </li>
              <li className="active">
                <a href="https://healthdeals.wishhealth.in/index.php/doctors-deal/">
                  Doctor Offer's
                </a>
              </li>
              <li>
                <a href="https://healthdeals.wishhealth.in/index.php/hospital-deals/">
                  Hospital Deal's
                </a>
              </li>

              <li>
                <a href="https://healthdeals.wishhealth.in/index.php/lab-test/">
                  Lab Test's
                </a>
              </li>
            </ul>
          </div>

          <div className={`sidebar-wrapper ${sidebarOpen ? "active" : ""}`}>
            <div className="backdrop" onClick={toggle}></div>
            <div className="sidebar-content">
              <button className="cross-btn" onClick={toggle}>
                <i className="fas fa-times" />
              </button>
              <div className="menu-wrapper">
                <div className="text-center pt-4">
                  <img src={logo} alt="logo" />
                </div>
                <ul className="mt-4 list-unstyled pl-3">
                  {/* <li> */}
                  {/* <a href={`${config.oldWebsiteUrl}index/receptionistLogin`}>
                    Office Assistant Sign In
                  </a> 
                </li> */}
                  {/* <li>
                  <a href={`${config.oldWebsiteUrl}#our-features`}>Features</a>
                </li>
                <li>
                  <a href={`${config.oldWebsiteUrl}#whoweare`}>Who We Are</a>
                </li>
                <li>
                  <a href={`${config.oldWebsiteUrl}#clientsay`}>
                    What Our Clients Say
                  </a>
                </li>
                <li>
                  <a href={`${config.oldWebsiteUrl}#getintouch`}>
                    Get in Touch
                  </a>
                </li>

                <li>
                  <a href={`${config.oldWebsiteUrl}index/doctor`}>
                    For Doctors
                  </a>
                </li> */}
                  {props.saveName && props.savePhoneNumber && (
                    <Button
                      color="primary"
                      className="confirm-btn border-0 mb-2 d-sm-none"
                      onClick={() => {
                        history.push(
                          "/patient/dashboard/upcoming-appointments"
                        );
                      }}
                    >
                      Go to dashboard{" "}
                    </Button>
                  )}
                  <li onClick={toggle}>
                    <a href="https://healthdeals.wishhealth.in/features/">
                    <p className="text-black-50 mb-0">Features</p>
                    </a>
                  </li>
                  <li onClick={toggle}>
                    <a href="https://healthdeals.wishhealth.in/demo/">
                    <p className="text-black-50 mb-0">About us</p>
                    </a>
                  </li>
                  <li>
                    <a href="https://healthdeals.wishhealth.in/packages/">
                    <p className="text-black-50 mb-0">Packages</p>
                    </a>
                  </li>
                  <li onClick={toggle}>
                    <Link
                      to={"/get-in-touch"}
                      onClick={() => window.scrollTo({ top: 0 })}
                    >
                      <p className="text-black-50 mb-0">Get in Touch</p>
                    </Link>
                  </li>

                  <li>
                    <p className="text-black-50 mb-0">For Doctors</p>
                  </li>
                  {/* <li>
                  <a href={`${config.oldWebsiteUrl}`}>Search Doctors</a>
                </li> */}
                  {/* <li> */}
                  {/* <a href={`${config.oldWebsiteUrl}index/patient_signin`}>
                    Patient Sign In
                  </a> */}
                  {/* </li> */}
                </ul>
              </div>
            </div>
            <DocModal toggle={handleDoctorButton} isOpen={modal} />
          </div>
        </Container>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    saveName: state.BookAppointmentReducer.saveName,
    savePhoneNumber: state.BookAppointmentReducer.savePhoneNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearName,
      clearReducer,
      clearPhoneNumber,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
