import React from "react";
import { Row, Col } from "reactstrap";
import ClinicName from "../../../assets/images/profile/clinicName.png";
import WrokingDays from "../../../assets/images/profile/wrokingDays.png";
import ClinicFees from "../../../assets/images/profile/clinicFees.png";
import Address from "../../../assets/images/profile/address.png";
import Timings from "../../../assets/images/profile/timings.png";
import Point from "../../../assets/images/profile/points.png";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";
import cx from "classnames";

const DetailCard = (props) => {
  const {
    noMargin = false,
    iconImage,
    title,
    dec = "",
    multiDec = [],
    bTime = "",
  } = props;
  return (
    <div className={cx("details-info", { "mb-0": noMargin })}>
      <img src={iconImage} alt={title} />
      <div className={cx("px-3", { "mb-sm-4 mb-3": !noMargin })}>
        <h4>{title}</h4>
        {dec && <p>{dec}</p>}
        {multiDec.map((data, index) => {
          return <p key={index}>{data}</p>;
        })}
        {bTime && <i>{bTime}</i>}
      </div>
    </div>
  );
};

const DayTiming = (props) => {
  const { timingsArray = [] } = props;

  if (timingsArray.length === 0) {
    return null;
  }
  return (
    <Row className="mb-3">
      <Col md={6}>
        <DetailCard noMargin iconImage={WrokingDays} title="Days of Working:" />
      </Col>
      <Col md={6} className="d-none d-md-block">
        <DetailCard noMargin iconImage={Timings} title="Timings:" />
      </Col>
      {timingsArray.map(
        ({ day, start_time, end_time, break_start_time, break_end_time }) => {
          return (
            <>
              <Col md={6}>
                <div className="details-info">
                  <img
                    src={WrokingDays}
                    style={{ opacity: 0 }}
                    alt="WrokingDays"
                  />
                  <div className="px-3">
                    <p>
                      {day.length === 6
                        ? "Monday - Saturday"
                        : day.length === 7
                        ? "Monday - Sunday"
                        : day.sort().map((day, dayIndex) => {
                            let dayName = "Monday";
                            if (day === 2) {
                              dayName = "Tuesday";
                            }
                            if (day === 3) {
                              dayName = "Wednesday";
                            }
                            if (day === 4) {
                              dayName = "Thursday";
                            }
                            if (day === 5) {
                              dayName = "Friday";
                            }
                            if (day === 6) {
                              dayName = "Saturday";
                            }
                            if (day === 7 || day === 0) {
                              dayName = "Sunday";
                            }

                            return (
                              <>{dayIndex !== 0 ? `, ${dayName}` : dayName}</>
                            );
                          })}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="details-info">
                  <img src={Timings} style={{ opacity: 0 }} alt="Timings" />
                  <div className="px-3">
                    <p>
                      {start_time} - {end_time}
                    </p>
                    <i>
                      Break Time: {break_start_time} - {break_end_time}
                    </i>
                  </div>
                </div>
              </Col>
            </>
          );
        }
      )}
    </Row>
  );
};

class ClinicDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: "",
      lng: "",
      showingInfoWindow: false,
      activeMarker: null,
      clinicAppointment: false,
      clinic_id: "",
    };
  }
  componentDidMount() {
    const { clinic_details } = this.props;

    Geocode.setApiKey("AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU");

    Geocode.enableDebug();
    Geocode.fromAddress(
      `${clinic_details.wh_clinic && clinic_details.wh_clinic.address}`
    ).then(
      (response) => {
        this.setState({
          lat: response.results[0].geometry.location.lat,
          lng: response.results[0].geometry.location.lng,
        });
      },
      (error) => {}
    );
  }

  handleConsultation = (clinic_id) => {
    this.props.clinicAppointmentToggle();

    this.props.setClinicDetails(this.props.clinic_details);
  };
  clinicAppointmentToggle = () => {
    this.props.clinicAppointmentToggle();
  };
  render() {
    const { clinic_details, wh_doctor_detail } = this.props;

    const { lat, lng } = this.state;
    let newArray = [];
    clinic_details &&
      clinic_details.wh_clinic.wh_clinic_timings.length > 0 &&
      clinic_details.wh_clinic.wh_clinic_timings.forEach((data, index) => {
        if (newArray && newArray.length <= 0) {
          let dataObj = { ...data };
          dataObj.day = [];
          dataObj.day.push(data.day);
          newArray.push(dataObj);
        } else {
          const checkTime = newArray.findIndex(
            (ele) =>
              ele.start_time === data.start_time &&
              ele.end_time === data.end_time &&
              ele.break_start_time === data.break_start_time &&
              ele.break_end_time === data.break_end_time
          );
          if (checkTime !== -1) {
            newArray[checkTime]["day"].push(data.day);
          } else {
            let dataObj = { ...data };
            dataObj.day = [];
            dataObj.day.push(data.day);
            newArray.push(dataObj);
          }
        }
      });

    let servicesOfClinics =
      clinic_details &&
      clinic_details.wh_clinic &&
      clinic_details.wh_clinic.wh_clinic_services;

    const uniqueServicesOfClinics = [
      ...new Set(
        servicesOfClinics.map((item) => item.wh_service && item.wh_service.name)
      ),
    ];

    return (
      <>
        <div className="clinic-details-wrapper">
          <div className="clinic-details ">
            <Row>
              <Col lg={8}>
                <Row>
                  <Col md={6}>
                    <DetailCard
                      iconImage={ClinicName}
                      title="Clinic Name:"
                      dec={
                        clinic_details.wh_clinic &&
                        clinic_details.wh_clinic.name
                      }
                    />
                  </Col>
                  <Col md={6}>
                    {clinic_details.wh_clinic &&
                    clinic_details.wh_clinic.wh_city !== "" ? (
                      <DetailCard
                        iconImage={Address}
                        title="Address:"
                        dec={`${
                          clinic_details.wh_clinic &&
                          clinic_details.wh_clinic.address
                        }, ${
                          clinic_details.wh_clinic &&
                          clinic_details.wh_clinic.wh_city &&
                          clinic_details.wh_clinic.wh_city.name
                        }`}
                      />
                    ) : (
                      <DetailCard
                        iconImage={Address}
                        title="Address:"
                        dec={
                          clinic_details.wh_clinic &&
                          clinic_details.wh_clinic.address
                        }
                      />
                    )}
                  </Col>

                  <Col lg={12}>
                    <DayTiming timingsArray={newArray} />
                  </Col>

                  {wh_doctor_detail && wh_doctor_detail.doc_fees !== null && (
                    <Col lg={6}>
                      <DetailCard
                        iconImage={ClinicFees}
                        title="Clinic Fee:"
                        dec={wh_doctor_detail.doc_fees}
                      />
                    </Col>
                  )}
                </Row>
              </Col>

              <Col lg={4}>
                <div
                  className="position-relative map-wrapper w-100"
                  style={{ height: 300 }}
                >
                  <Map
                    style={{ height: "100%", width: "100%" }}
                    google={this.props.google}
                    center={{
                      lat: lat,
                      lng: lng,
                    }}
                    fullscreenControl={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                    zoom={15}
                    onClick={this.onMapClicked}
                  >
                    <Marker
                      onClick={this.onMarkerClick}
                      name={"Current location"}
                      position={{ lat: lat, lng: lng }}
                    />

                    <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
                  </Map>
                </div>
              </Col>
            </Row>
          </div>

          <div className="services-wrapper">
            <h2>Services</h2>
            <div className="list py-4">
              {uniqueServicesOfClinics &&
                uniqueServicesOfClinics.length > 0 &&
                uniqueServicesOfClinics.map((item, index) => {
                  return (
                    <p key={index}>
                      <img src={Point} alt="arrow" />
                      {item}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: "AIzaSyCK9gCAuECvrKI2dQ2OcIv_h847F-dItdU&libraries=places",

  apiKey: "AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU&libraries=places",
})(ClinicDetails);
