import React from "react";
import { Container } from "reactstrap";

export default (props) => {
  return (
    <div className="specialities-wrapper">
      <Container>
        <div className="specialities-wrap"></div>
      </Container>
    </div>
  );
};
