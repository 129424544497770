import {
   FETCH_DEALS_REQUEST
  } from "./types";
  
  export const fetchDeals = (data, callback) => {
   
    return {
      type: FETCH_DEALS_REQUEST,
      // url,
      payload: data,
      callback,
    };
  };
  
  