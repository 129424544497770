import React, { useEffect, useState } from 'react';
import {
	Container,
	Button,
	Collapse,
	ListGroup,
	ListGroupItem,
} from 'reactstrap';
import CallContact from '../../../components/card/DocResultCard/callContact';

import ClinicConsultation from '../../../assets/images/profile/icon/clinic.png';
import VideoConsultation from '../../../assets/images/profile/icon/video.png';
import CallNow from '../../../assets/images/profile/icon/call.png';
import ClinicConsultationDisable from '../../../assets/images/profile/clinicConsultation-disable.png';

import Experience from '../../../assets/images/profile/experience.png';
import Verified from '../../../assets/images/search/verified.png';
import Avatar from '../../../assets/images/avatar.png';
import { titleCase } from '../../../common/stringFunction';
import DescriptionComponent from './descriptionComponent';
import DisableVideo from '../../../assets/images/profile/disableVideo.png';
import DoctorTick from '../../../assets/images/profile/doctor-tick.png';
import Points from '../../../assets/images/profile/points.png';
import cx from 'classnames';
import { Media, Player, utils } from 'react-media-player';
import StarRating from '../../../components/reviews/StarRating';
import ReviewModal from '../../../components/reviews/ReviewModal';
import { isLogin } from '../../../util/isLogin';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import {
	BreadcrumbsProvider,
	Breadcrumbs,
	BreadcrumbsItem,
} from 'react-breadcrumbs-dynamic';
import { NavLink, Link } from 'react-router-dom';
import Home from '../../../../src/pages/home/index';
import { extractChar } from '../../../util/string';
const { keyboardControls } = utils;

const Image = (props) => {
	return (
		<div className="images">
			<img src={props.profileImage} alt="profile" />
		</div>
	);
};

const DoctorInfoFeatures = (props) => {
	const {
		doctorDegree: degrees,
		doctorsSpecialitiesArray: specializations,
		languages: languagesOfDoctor,
		wh_medical_council_registration: registrationDetails,
		collegeDoctor: collegeDoctorDetails,
		doctorReviews = {},
		openReviewModal,
		patientId,
	} = props;

	const [tab, setTab] = useState(0);
	const [list, setList] = useState([]);
	const [item, setItem] = useState({});

	useEffect(() => {
		let arr = [];

		degrees &&
			degrees.length > 0 &&
			arr.push({ degrees, title: 'Qualification' });

		specializations &&
			specializations.length > 0 &&
			arr.push({ specializations, title: 'Specialization' });

		registrationDetails &&
			registrationDetails.reg_number !== 0 &&
			arr.push({
				registrationDetails,
				title: 'Medical Council Registration',
			});

		languagesOfDoctor &&
			languagesOfDoctor.length > 0 &&
			arr.push({ languagesOfDoctor, title: 'Language Known' });

		arr.push({ title: 'Ratings & Reviews' });

		setList(arr);
		if (localStorage.getItem('postReview')) {
			setTab(arr.length - 1);
			!!arr.length && setItem(arr[arr.length - 1]);
			openReviewModal();
			localStorage.removeItem('postReview');
		} else {
			!!arr.length && setItem(arr[0]);
		}
	}, [props]);

	const tab1 = () => {
		return (
			<div>
				{collegeDoctorDetails &&
					collegeDoctorDetails.length > 0 &&
					collegeDoctorDetails.map((item, index) => {
						return (
							<p key={index}>
								<img alt="" src={Points} className="mr-2" />
								{`${item.wh_qualification && item.wh_qualification.degree} - ${
									item.wh_college && item.wh_college.college
								}, ${item.year}`}
							</p>
						);
					})}
			</div>
		);
	};
	const tab2 = () => {
		return (
			<div>
				{' '}
				{specializations &&
					specializations.length > 0 &&
					specializations.map((item, index) => {
						return (
							<p key={index}>
								<img alt="" src={Points} className="mr-2" />
								{item}
							</p>
						);
					})}
			</div>
		);
	};
	const tab3 = () => {
		return (
			<div>
				{`${registrationDetails && registrationDetails.reg_number} ${
					registrationDetails &&
					registrationDetails.wh_medical_council &&
					registrationDetails.wh_medical_council.name
				},  ${registrationDetails && registrationDetails.year}`}
			</div>
		);
	};
	const tab4 = () => {
		return (
			<div>
				{' '}
				{languagesOfDoctor &&
					languagesOfDoctor.length > 0 &&
					languagesOfDoctor.map((item, index) => {
						return (
							<p key={index}>
								<img alt="" src={Points} className="mr-2" />
								{item}
							</p>
						);
					})}
			</div>
		);
	};

	const tab5 = () => {
		const { reviews = [] } = doctorReviews;
		const isReviewGiven = reviews.find((review) => {
			return review.patient_id === patientId;
		});

		return (
			<div className="patient-review">
				{!isReviewGiven ? (
					<Button color="primary" onClick={openReviewModal}>
						<i className="fas fa-edit mr-2"></i>Write a Review
					</Button>
				) : null}{' '}
				{reviews && reviews.length > 0 && (
					<ListGroup>
						{reviews.map((review, index) => {
							return (
								<ListGroupItem key={index}>
									<p className="m-0 patient-name">
										{review.patient && review.patient.name}
									</p>
									<div className="d-flex align-items-center">
										<span className="rating-text">Rating</span>
										<StarRating
											className="ml-2"
											stop={5}
											rating={review.rating}
											readonly
										/>
									</div>
									<p className="m-0">{review.review}</p>
								</ListGroupItem>
							);
						})}
					</ListGroup>
				)}
			</div>
		);
	};

	const setTabActive = (e, index, item) => {
		setTab(index);
		setItem(item);
	};

	return (
		<div className="doctor-info-features">
			<div className="d-lg-block d-none">
				{list && list.length > 0 && (
					<ul className="pl-0 border-tab-bottom">
						{list.map((value, index) => {
							return (
								<li key={index}>
									<button
										className={tab === index ? 'active' : ''}
										onClick={(e) => {
											setTabActive(e, index, value);
										}}
									>
										{value.title}
									</button>
								</li>
							);
						})}
					</ul>
				)}
				<div className="doctor-info-features-list">
					{item.title === 'Qualification'
						? tab1()
						: item.title === 'Specialization'
						? tab2()
						: item.title === 'Medical Council Registration'
						? tab3()
						: item.title === 'Language Known'
						? tab4()
						: item.title === 'Ratings & Reviews'
						? tab5()
						: null}
				</div>
			</div>

			<div className="d-lg-none mobile-info-features-list">
				{list && list.length > 0 && (
					<ul className="pl-0 border-tab-bottom">
						{list.map((value, index) => {
							return (
								<li key={index}>
									<Button
										className={tab === index ? 'active' : ''}
										onClick={(e) => {
											setTabActive(e, index, value);
										}}
									>
										{value.title}
									</Button>
									<Collapse isOpen={tab === index} className="features-content">
										{item.title === 'Qualification'
											? tab1()
											: item.title === 'Specialization'
											? tab2()
											: item.title === 'Medical Council Registration'
											? tab3()
											: item.title === 'Language Known'
											? tab4()
											: item.title === 'Ratings & Reviews'
											? tab5()
											: null}
									</Collapse>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

class DoctorInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpecCallDetail: false,
		};
	}
	toggleCallDetail = () => {
		this.setState({
			isOpecCallDetail: !this.state.isOpecCallDetail,
		});
	};

	actionButtons = (className = '') => {
		const { allClinics, loading } = this.props;
		const { isOpecCallDetail } = this.state;
		const { wh_doctor_detail } = this.props && this.props.doctorInfoDetails;

		return (
			!loading && (
				<div className={cx('doctor-buttons', className)}>
					{allClinics.length > 0 && !loading ? (
						<button
							disabled={allClinics.length === 0}
							className="btn btn-primary d-inline-flex align-items-center justify-content-center"
							onClick={() => {
								this.props.clinicAppointmentToggle();

								this.props.setClinicDetails(
									this.props.doctorDataById &&
										this.props.doctorDataById[0] &&
										this.props.doctorDataById[0].wh_clinic
								);
							}}
						>
							<img
								src={ClinicConsultation}
								className="mr-2"
								alt="ClinicConsultation"
							/>
							Book now
						</button>
					) : (
						<p
							className="btn btn-primary d-inline-flex align-items-center justify-content-center disabled"
							style={{ cursor: 'no-drop' }}
						>
							<img
								src={ClinicConsultationDisable}
								className="mr-2"
								alt="ClinicConsultation"
							/>
							Book now
						</p>
					)}

					<div className="d-inline-block position-relative">
						<Button
							color="primary"
							className="call-now"
							onClick={this.toggleCallDetail}
						>
							<img src={CallNow} className="mr-2" alt="CallNow" />
							Call Now
						</Button>
						{isOpecCallDetail && (
							<CallContact
								toggle={this.toggleCallDetail}
								extension={wh_doctor_detail.extension}
							/>
						)}
					</div>

					{/*{!loading &&
					wh_doctor_detail &&
					(wh_doctor_detail.video_consultation === 0 ||
						wh_doctor_detail.video_consultation === null) ? (
						<p
							className={`btn btn-primary d-inline-flex align-items-center justify-content-center ${
								wh_doctor_detail &&
								(wh_doctor_detail.video_consultation === 0 ||
									wh_doctor_detail.video_consultation === null)
									? 'disabled'
									: ''
							}`}
						>
							<img
								src={
									wh_doctor_detail &&
									(wh_doctor_detail.video_consultation === 0 ||
										wh_doctor_detail.video_consultation === null)
										? DisableVideo
										: VideoConsultation
								}
								className="mr-2"
								alt="VideoConsultation"
							/>
							Video <span className="ml-1">Consultation</span>
						</p>
					) : (
						<a
							href="#"
							className="btn btn-primary d-inline-flex align-items-center justify-content-center"
							onClick={() => {
								this.props.clinicAppointmentToggle();

								this.props.setClinicDetails(
									this.props.doctorDataById &&
										this.props.doctorDataById[0] &&
										this.props.doctorDataById[0].wh_clinic
								);
							}}
						>
							<img
								src={
									wh_doctor_detail &&
									(wh_doctor_detail.video_consultation === 0 ||
										wh_doctor_detail.video_consultation === null)
										? DisableVideo
										: VideoConsultation
								}
								className="mr-2"
								alt="VideoConsultation"
							/>
							Video <span className="ml-1">Consultation</span>
						</a>
					)}*/}
				</div>
			)
		);
	};
	validateYouTubeUrl = (url) => {
		if (url != undefined || url != '') {
			var regExp =
				/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			var match = url.match(regExp);
			if (match && match[2].length == 11) {
				// Do anything for being valid
				// if need to change the url to embed url then use below line
				return true;
			} else {
				return false;
				// Do anything for not being valid
			}
		}
	};

	openReviewModal = () => {
		const { patientId, doctorInfoDetails, doctorReviews = {}, navigateToLogin } = this.props;
		if (isLogin() === 'true') {
		  const isReviewGiven =
			doctorReviews.reviews &&
			doctorReviews.reviews.find((review) => {
				return review.patient_id === patientId;
			});
			if (!isReviewGiven) {
				this.reviewModal.open();
			}
		} else {
			localStorage.setItem(
				'postReview',
				JSON.stringify(doctorInfoDetails)
			);
			navigateToLogin();
		}
	};

	postReview = (review, callback) => {
		this.props.saveReview(review, callback);
	};

	render() {
		const {
			wh_doctor_detail,

			wh_doctor_specialities,
			name,
			wh_doctor_languages,
			wh_doctor_qualifications,

			wh_medical_council_registration,
		} = this.props && this.props.doctorInfoDetails;
		const { loading } = this.props;
		let currentYear = new Date().getFullYear();

		let practice_start_year =
			wh_doctor_detail && wh_doctor_detail.practice_start_year;
		let experience = currentYear - practice_start_year;
		if (!practice_start_year) {
			experience = 0;
		}

		let degrees =
			wh_doctor_qualifications &&
			wh_doctor_qualifications.filter((item) => {
				return item.wh_qualification && item.wh_qualification.degree !== null;
			});

		let doctorDegree =
			degrees &&
			degrees.length > 0 &&
			degrees.map((item) => {
				return item.wh_qualification && item.wh_qualification.degree;
			});

		let collegeDoctor =
			wh_doctor_qualifications &&
			wh_doctor_qualifications.filter((item) => {
				return item.wh_college && item.wh_college.college !== null;
			});

		let college =
			collegeDoctor &&
			collegeDoctor.length > 0 &&
			collegeDoctor.map((item) => {
				return item.wh_college && item.wh_college.college;
			});

		let registration_number =
			wh_medical_council_registration &&
			wh_medical_council_registration.reg_number;

		let profileImage =
			wh_doctor_detail && wh_doctor_detail.profile_pic
				? wh_doctor_detail && wh_doctor_detail.profile_pic
				: Avatar;

		let languages =
			wh_doctor_languages &&
			wh_doctor_languages.length > 0 &&
			wh_doctor_languages.map((item) => {
				// const length = wh_doctor_languages && wh_doctor_languages.length;
				return item.wh_language.name;
				// return (
				//   <Fragment key={key}>
				//     {key === length - 1
				//       ? item.wh_language.name
				//       : `${item.wh_language.name}, `}
				//   </Fragment>
				// );
			});

		let specialities =
			wh_doctor_specialities &&
			wh_doctor_specialities.filter((item) => {
				return item.wh_speciality !== null;
			});

		let doctorsSpecialitiesArray =
			specialities &&
			specialities.map((item) => {
				return titleCase(item.wh_speciality && item.wh_speciality.title);
			});
		let specialitiesOfDoctor =
			doctorsSpecialitiesArray && doctorsSpecialitiesArray.join(', ');

		let isValidURL;

		if (
			wh_doctor_detail &&
			wh_doctor_detail.video_url &&
			wh_doctor_detail.video_url !== undefined
		) {
			isValidURL = this.validateYouTubeUrl(
				wh_doctor_detail && wh_doctor_detail.video_url
			);
		}
		const NavLink = (
			<>
				<NavLink to="/">Main Page</NavLink>
				<b> / </b>
			</>
		);
		const { searchLocation, doctorReviews = {} } = this.props;
		const isReviewGiven =
			doctorReviews.reviews &&
			doctorReviews.reviews.find((review) => {
				return review.patient_id === this.props.patientId;
			});

		return (
			<>
				<div className="doctor-profile-wrapper mb-4 mb-md-5">
					<MetaTags>
						<title>{`${
							wh_doctor_detail && wh_doctor_detail.prefix
						} ${name} - ${specialitiesOfDoctor} - Book Appointment with Doctor Nearby, Online, View Fees, Feedbacks | WishHealth
            `}</title>
						<description>{`Dr. ${name} is a ${specialitiesOfDoctor} in <Area>. Book appointments Online, View Fees, User Feedbacks for ${name}

            `}</description>
						<meta
							name="description"
							content={`${
								wh_doctor_detail && wh_doctor_detail.prefix
							}-jhghfdhjkfdhdifudohk`}
						/>
						{/* <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" /> */}
					</MetaTags>

					<div className="banner p-0">
						<div className="banner-doc">
							<Container>
								<div className="py-3">
									<a href="/" className="nav-item">
										{' '}
										Home{' '}
									</a>
									<i class="fas fa-chevron-right icons"></i>
									<a
										className="nav-item"
										href={`/search/${
											wh_doctor_detail &&
											wh_doctor_detail.wh_city &&
											wh_doctor_detail.wh_city.name.split(' ').join('')
										}/#`}
									>
										{wh_doctor_detail &&
											wh_doctor_detail.wh_city &&
											wh_doctor_detail.wh_city.name}{' '}
									</a>
									<i class="fas fa-chevron-right icons"></i>
									{doctorsSpecialitiesArray &&
										doctorsSpecialitiesArray.length > 0 &&
										doctorsSpecialitiesArray.reverse() &&
										doctorsSpecialitiesArray.map((element, index) => {
											return (
												<>
													<a
														className="nav-item"
														href={`/search/${
															wh_doctor_detail &&
															wh_doctor_detail.wh_city &&
															wh_doctor_detail.wh_city.name.split(' ').join('')
														}/${element}`}
													>
														{element}
													</a>
													<i class="fas fa-chevron-right icons"></i>
												</>
											);
										})}
									{searchLocation &&
										wh_doctor_detail &&
										wh_doctor_detail.wh_city &&
										searchLocation.includes(wh_doctor_detail.wh_city.name) &&
										searchLocation !== wh_doctor_detail.wh_city.name && (
											<>
												<a
													className="nav-item"
													href={`/search/${searchLocation}/#`}
												>
													{searchLocation
														.replace(
															wh_doctor_detail &&
																wh_doctor_detail.wh_city &&
																wh_doctor_detail.wh_city.name,
															''
														)
														.replace(',', '')}
												</a>
												<i class="fas fa-chevron-right icons"></i>
											</>
										)}
									<div className="nav-item-text d-inline">
										{' '}
										{wh_doctor_detail && wh_doctor_detail.prefix} {name}
									</div>
								</div>
								<div className="doc-profile doc-info-profile">
									<div className="doc-view ">
										{wh_doctor_detail && wh_doctor_detail.profile_pic ? (
											<Image
												profileImage={
													wh_doctor_detail && wh_doctor_detail.profile_pic
												}
											/>
										) : (
											<div className="dummy-text">
												<span>{extractChar(name)}</span>
											</div>
										)}
									</div>
									<div className="content-view">
										<div className="d-flex align-items-center mb-2 flex-lg-row flex-column">
											<h1 className="doc-name">
												{wh_doctor_detail && wh_doctor_detail.prefix} {name}
											</h1>
											{wh_doctor_detail &&
												wh_doctor_detail.wh_city &&
												wh_doctor_detail.wh_city.name && (
													<p className="doc-location">
														<i className="fas fa-map-marker-alt mr-1" />
														{wh_doctor_detail &&
															wh_doctor_detail.wh_city &&
															wh_doctor_detail.wh_city.name}
													</p>
												)}
										</div>

										<p className="mb-0">{specialitiesOfDoctor}</p>
										<div className="mt-2 mb-4">
											{wh_doctor_detail && wh_doctor_detail.is_verified === 1 && (
												<p className="mb-0">
													<img alt="" src={Verified} className="mr-1" />{' '}
													Verified Doctor
												</p>
											)}
											{experience != 0 && (
												<p className="mb-0">
													<img alt="" src={Experience} className="mr-2" />

													{`${experience} Years Experience Overall`}
												</p>
											)}

											{!wh_medical_council_registration ||
												(registration_number !== 0 && (
													<p className="mb-0">
														<img alt="" src={DoctorTick} className="mr-2" />{' '}
														Medical Registration Verified
													</p>
												))}
										</div>
										{this.actionButtons('d-block d-lg-none')}

										{wh_doctor_detail && wh_doctor_detail.description && (
											<div className="content">
												<h2>
													<span>About</span>
												</h2>

												<DescriptionComponent
													description={
														wh_doctor_detail && wh_doctor_detail.description
													}
												/>
											</div>
										)}
										{wh_doctor_detail &&
											typeof wh_doctor_detail.average_rating !==
												'undefined' && (
												<div className="d-flex align-items-center">
													<span className="rating-text">Rating</span>
													<StarRating
														className="ml-2"
														stop={5}
														rating={
															(wh_doctor_detail &&
																wh_doctor_detail.average_rating) ||
															0
														}
														handleWriteRating={this.openReviewModal}
														readonly
													/>
													{!isReviewGiven ? (
														<Button
															className="ml-2"
															color="primary"
															onClick={this.openReviewModal}
														>
															<i className="fas fa-edit"></i>
														</Button>
													) : null}
												</div>
											)}

										{!wh_medical_council_registration ||
											(registration_number !== 0 && (
												<p className="mb-0">
													<img alt="" src={DoctorTick} className="mr-2" />{' '}
													Medical Registration Verified
												</p>
											))}
									</div>
								</div>
								{!loading && (
									<div className="doc-profile detail-col mt-lg-3">
										<div className="content-view details-view">
											{(doctorDegree ||
												doctorsSpecialitiesArray ||
												wh_medical_council_registration ||
												languages) && (
												<h2 className="doc-name">
													<span>Details</span>
												</h2>
											)}
											<div className="mt-3">
												<DoctorInfoFeatures
													doctorDegree={doctorDegree}
													doctorsSpecialitiesArray={doctorsSpecialitiesArray}
													wh_medical_council_registration={
														wh_medical_council_registration
													}
													languages={languages}
													college={college}
													collegeDoctor={collegeDoctor}
													openReviewModal={this.openReviewModal}
													doctorReviews={this.props.doctorReviews}
													patientId={this.props.patientId}
												/>
											</div>
											{this.actionButtons('d-lg-block d-none')}
										</div>
										{isValidURL && (
											<div className="doc-view details-doc-view">
												{isValidURL ? (
													<Media>
														{(mediaProps) => (
															<div
																className="media w-100"
																onKeyDown={keyboardControls.bind(
																	null,
																	mediaProps
																)}
															>
																<Player
																	src={
																		wh_doctor_detail &&
																		wh_doctor_detail.video_url
																	}
																	className="media-player w-100"
																/>

																<div className="media-controls"></div>
															</div>
														)}
													</Media>
												) : (
													<div className="went-wrong-txt">
														{' '}
														<p>
															The requested URL was not found on this server
														</p>{' '}
													</div>
												)}
											</div>
										)}
									</div>
								)}
							</Container>
						</div>
					</div>
				</div>
				<ReviewModal
					ref={(c) => (this.reviewModal = c)}
					postReview={this.postReview}
					doctorName={`Dr. ${name}`}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	selectedGeoLocation: state.geoLocationReducer,
	searchLocation: state.SearchReducer.searchLocation,
});

export default connect(mapStateToProps, null)(DoctorInfo);
