export const FETCH_ALL_APPOINTMENTS_DATA_REQUEST =
  "FETCH_ALL_APPOINTMENTS_DATA_REQUEST";
export const FETCH_ALL_APPOINTMENTS_DATA_SUCCESS =
  "FETCH_ALL_APPOINTMENTS_DATA_SUCCESS";
export const FETCH_ALL_APPOINTMENTS_DATA_FAILED =
  "FETCH_ALL_APPOINTMENTS_DATA_FAILED";

export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_FAILED = "DELETE_APPOINTMENT_FAILED";

export const EDIT_APPOINTMENT_REQUEST = "EDIT_APPOINTMENT_REQUEST";
export const EDIT_APPOINTMENT_SUCCESS = "EDIT_APPOINTMENT_SUCCESS";
export const EDIT_APPOINTMENT_FAILED = "EDIT_APPOINTMENT_FAILED";

export const MY_APPOINTMENT_REQUEST = "MY_APPOINTMENT_REQUEST";
export const MY_APPOINTMENT_SUCCESS = "MY_APPOINTMENT_SUCCESS";
export const MY_APPOINTMENT_FAILED = "MY_APPOINTMENT_FAILED";

export const UPDATE_PATIENT_DETAILS_REQUEST = "UPDATE_PATIENT_DETAILS_REQUEST";
export const UPDATE_PATIENT_DETAILS_SUCCESS = "UPDATE_PATIENT_DETAILS_SUCCESS";
export const UPDATE_PATIENT_DETAILS_FAILED = "UPDATE_PATIENT_DETAILS_FAILED";

export const LAST_FEW_BOOKED_DOCTORS_REQUEST =
  "LAST_FEW_BOOKED_DOCTORS_REQUEST";
export const LAST_FEW_BOOKED_DOCTORS_SUCCESS =
  "LAST_FEW_BOOKED_DOCTORS_SUCCESS";
export const LAST_FEW_BOOKED_DOCTORS_FAILED = "LAST_FEW_BOOKED_DOCTORS_FAILED";
