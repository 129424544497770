const {
  BOOK_APPOINTMENT_REQUEST,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAILED,
  CHECK_IF_USER_EXISTING_REQUEST,
  CHECK_IF_USER_EXISTING_FAILED,
  CHECK_IF_USER_EXISTING_SUCCESS,
  SEND_OTP_BEFORE_BOOKING_REQUEST,
  SEND_OTP_BEFORE_BOOKING_SUCCESS,
  SEND_OTP_BEFORE_BOOKING_FAILED,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_SUCCESS,
  CONFIRM_BOOKING_REQUEST,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILED,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  CAPTURE_PAYMENT_REQUEST,
  CAPTURE_PAYMENT_FAILED,
  CAPTURE_PAYMENT_SUCCESS,
  SET_PATIENT_ID,
  SET_NAME,
  SET_PHONE_NUMBER,
  CLEAR_NAME,
  CLEAR_PHONE_NUMBER,
  SET_BOOKING_ID,
  SET_ID,
  COUPON_CODE_REQUEST,
  COUPON_CODE_SUCCESS,
  COUPON_CODE_FAILED,
  VERIFY_COUPON_REQUEST,
  VERIFY_COUPON_SUCCESS,
  VERIFY_COUPON_FAILED,
  SET_IMAGE,
  FETCH_PATIENT_DETAILS_REQUEST,
  FETCH_PATIENT_DETAILS_FAILED,
  FETCH_PATIENT_DETAILS_SUCCESS,
} = require("./types");
const { CLEAR_REDUCER } = require("../../layout/store/types");

const initialState = {
  bookedSlots: [],
  checkIfUserExistingArray: [],
  otpCorrect: {},
  createPaymentObject: {},
  capturePaymentObject: {},
  savePatientId: "",
  saveName: "",
  savePhoneNumber: "",
  saveImage: "",
  confirmBooking: {},
  saveId: "",
  couponCode: "",
  verifyCoupon: {},
  patientDetails: {},
};

const BookAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_APPOINTMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case BOOK_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        bookedSlots: action.bookedSlots || [],
      };
    }
    case BOOK_APPOINTMENT_FAILED: {
      return {
        ...state,
      };
    }

    case FETCH_PATIENT_DETAILS_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        patientDetails: action.patientDetails || {},
      };
    }
    case FETCH_PATIENT_DETAILS_FAILED: {
      return {
        ...state,
      };
    }

    case CHECK_IF_USER_EXISTING_REQUEST: {
      return {
        ...state,
      };
    }
    case CHECK_IF_USER_EXISTING_SUCCESS: {
      return {
        ...state,
        checkIfUserExistingArray: action.checkIfUserExistingArray || [],
      };
    }
    case CHECK_IF_USER_EXISTING_FAILED: {
      return {
        ...state,
      };
    }
    case SEND_OTP_BEFORE_BOOKING_REQUEST: {
      return {
        ...state,
      };
    }
    case SEND_OTP_BEFORE_BOOKING_SUCCESS: {
      return {
        ...state,
        patiendId: action.patiendId,
      };
    }
    case SEND_OTP_BEFORE_BOOKING_FAILED: {
      return {
        ...state,
      };
    }
    case VERIFY_OTP_REQUEST: {
      return {
        ...state,
      };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        otpCorrect: action.otpCorrect,
      };
    }
    case VERIFY_OTP_FAILED: {
      return {
        ...state,
      };
    }
    case CONFIRM_BOOKING_REQUEST: {
      return {
        ...state,
      };
    }
    case CONFIRM_BOOKING_SUCCESS: {
      return {
        ...state,
        confirmBooking: action.confirmBooking || {},
      };
    }
    case CONFIRM_BOOKING_FAILED: {
      return {
        ...state,
      };
    }
    case CREATE_PAYMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        createPaymentObject: action.createPaymentObject || {},
      };
    }
    case CREATE_PAYMENT_FAILED: {
      return {
        ...state,
      };
    }

    case CAPTURE_PAYMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case CAPTURE_PAYMENT_SUCCESS: {
      return {
        ...state,
        capturePaymentObject: action.capturePaymentObject || {},
      };
    }
    case CAPTURE_PAYMENT_FAILED: {
      return {
        ...state,
      };
    }

    case COUPON_CODE_REQUEST: {
      return {
        ...state,
      };
    }
    case COUPON_CODE_SUCCESS: {
      return {
        ...state,
        couponCode: action.couponCode || "",
      };
    }
    case COUPON_CODE_FAILED: {
      return {
        ...state,
      };
    }

    case VERIFY_COUPON_REQUEST: {
      return {
        ...state,
      };
    }
    case VERIFY_COUPON_SUCCESS: {
      return {
        ...state,
        verifyCoupon: action.verifyCoupon || {},
      };
    }
    case VERIFY_COUPON_FAILED: {
      return {
        ...state,
      };
    }

    case SET_PATIENT_ID: {
      return {
        ...state,
        savePatientId: action.payload || "",
      };
    }
    case SET_ID: {
      return {
        ...state,
        saveId: action.payload || "",
      };
    }
    case SET_NAME: {
      return {
        ...state,
        saveName: action.payload || "",
      };
    }

    case SET_IMAGE: {
      return {
        ...state,
        saveImage: action.payload || "",
      };
    }
    case SET_PHONE_NUMBER: {
      return {
        ...state,
        savePhoneNumber: action.payload || "",
      };
    }

    case SET_BOOKING_ID: {
      return {
        ...state,
        saveBookingId: action.payload || "",
      };
    }
    case CLEAR_NAME: {
      return {
        ...state,
        saveName: "",
      };
    }
    case CLEAR_PHONE_NUMBER: {
      return {
        ...state,
        savePhoneNumber: "",
      };
    }
    case CLEAR_REDUCER: {
      return {
        state: initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default BookAppointmentReducer;
