import React, { useState } from "react";
import ClinicName from "../../../assets/images/profile/clinicName.png";
import Address from "../../../assets/images/profile/address.png";

import ClinicConsultation from "../../../assets/images/search/clinicConsultation.png";

import { Button, Container, Collapse } from "reactstrap";
import DownArrow from "../../../assets/images/profile/down-arrow.svg";
const Accordion = ({
  children,

  clinic_details,

  isOpen,
  setIsOpen,
  clinicAppointmentToggle,
  index,
  setClinicDetails,
}) => {
  const [clinic_id, setClinic_id] = useState("");
  const handleClinicConsultation = (clinic_id) => {
    clinicAppointmentToggle();
    setClinic_id(clinic_id);
    setClinicDetails(clinic_details);
  };

  return (
    <>
      <div className="mb-1">
        <Container>
          <div className="accordion-button-wrapper">
            <button
              onClick={(e) => setIsOpen(e, index)}
              className={`accordion-button ${isOpen ? "visible" : ""}`}
            >
              <ul className="info">
                <li className="name pl-0">
                  <p title={clinic_details && clinic_details.name}>
                    <img src={ClinicName} alt="Clinic Name" />
                    {clinic_details && clinic_details.name}
                  </p>
                </li>
                <li className="address">
                  <p title={clinic_details && clinic_details.address}>
                    <img src={Address} alt="address" />
                    {clinic_details && clinic_details.address}
                  </p>
                </li>
              </ul>

              <img className="arrow" src={DownArrow} alt="downArrow" />
            </button>
            <Button
              color="themeBordered "
              className="bg-white consultation-btn"
              onClick={() =>
                handleClinicConsultation(
                  clinic_details && clinic_details.clinic_id
                )
              }
            >
              <img
                src={ClinicConsultation}
                alt="ClinicConsultation"
                className="mr-2"
              />
              Book now
            </Button>
          </div>
          <Collapse isOpen={isOpen} className="accordion-collapse">
            <div className="clinicDetailsWrapper">
              {children}
              <div className="d-sm-none text-center pb-4">
                <Button
                  color="themeBordered "
                  className="bg-white consultation-btn"
                  onClick={() =>
                    handleClinicConsultation(
                      clinic_details && clinic_details.clinic_id
                    )
                  }
                >
                  <img
                    src={ClinicConsultation}
                    alt="ClinicConsultation"
                    className="mr-2"
                  />
                  Book now
                </Button>
              </div>
            </div>
          </Collapse>
        </Container>
      </div>
    </>
  );
};

export default Accordion;
