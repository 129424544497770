import React from "react";
import DoctorInfo from "./doctorInfo";
import ClinicDetails from "./clinicDetails";
import ClinicImage from "./clinicImages";
import Specialities from "./specialities";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDoctorsDataById,
  fetchDoctorDetails,
  fetchDoctorReviews,
  saveDoctorReview,
} from "./store/action";
import Accordion from "./Accordion";
import Loader from "../../loader/index";
import { BookAppoitmentModal } from "../../components/modal";

class DoctorProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorDataById: [],
      openIndex: [0],
      loading: false,
      clinicAppointment:
        this.props &&
        this.props.history.location.state &&
        this.props.history.location.state.modalOpen === true
          ? true
          : false,
      clinic_details: {},
      specificClinic: {},
      doctorDetails: {},
      video_bookings: [],
      video_timings: [],
    };
  }

  componentDidMount() {
    let pathname = this.props.location.search.split("/");
    let user_id = this.props && this.props.match.params.id;
    // const expert_id = props && props.match.params.id;
    this.setState({
      loading: true,
    });

    window.scrollTo(0, 0);
    const {
      fetchDoctorsDataById,
      fetchDoctorDetails,
      fetchDoctorReviews,
    } = this.props;

    fetchDoctorDetails(`search/getDoctorDetails/${user_id}`, (value) => {
      if (value.data === null) {
        this.props.history.push("/");
      }
      this.setState({
        loading: false,
        doctorDetails: value.data,
      });
    });

    fetchDoctorReviews(`review/get?doctor_id=${user_id}`, (response) => {});

    fetchDoctorsDataById(`search/getDoctorClinics/${user_id}`, (value) => {
      this.setState({
        loading: false,
      });

      const clinic_details =
        value.data && value.data.clinics && value.data.clinics.length > 0
          ? value.data.clinics[0].wh_clinic
          : {};
      const video_bookings = value.data.videobookings;
      const video_timings = value.data.video_timings;
      const doctorDataById =
        value.data && value.data.clinics && value.data.clinics.length > 0
          ? value.data.clinics
          : [];
      let specificClinic =
        doctorDataById &&
        doctorDataById.length > 0 &&
        doctorDataById.find((item) => {
          return (
            item.wh_clinic.clinic_id ===
            this.props?.history?.location?.state?.clinic_details?.clinic_id
          );
        });

      this.setState({
        doctorDataById,
        video_timings,
        video_bookings,
        clinic_details,
        specificClinic,
      });
    });
  }

  clinicAppointmentToggle = () => {
    this.setState({
      clinicAppointment: !this.state.clinicAppointment,
      specificClinic: null,
    });
  };

  setClinicDetails = (clinic_details) => {
    this.setState({
      clinic_details,
    });
  };
  renderAccordionConponents = (clinic_details) => {
    const { doctorDataById, doctorDetails } = this.state;

    const { wh_doctor_detail } = doctorDetails;
    return (
      <>
        <ClinicDetails
          clinic_details={clinic_details}
          doctorOwnClinic={doctorDataById}
          wh_doctor_detail={wh_doctor_detail}
          clinicAppointmentToggle={this.clinicAppointmentToggle}
          setClinicDetails={this.setClinicDetails}
          doctorDataById={doctorDataById}
          doctorInfoDetails={doctorDetails}
        />
        <ClinicImage
          doctorOwnClinic={doctorDataById}
          clinic_details={clinic_details}
        />
        <Specialities clinic_details={clinic_details} />
      </>
    );
  };

  toggle = (e, index) => {
    const { openIndex } = this.state;
    let arr = openIndex;
    if (openIndex.includes(index)) {
      let finalIndex = arr.findIndex((b) => b === index);
      arr.splice(finalIndex, 1);
    } else {
      arr.push(index);
    }
    this.setState({
      openIndex: arr,
      loading: false,
    });
  };

  saveReview = (data, callback) => {
    const {
      saveDoctorReview,
      match,
      patientId,
      fetchDoctorDetails,
    } = this.props;
    const { id = "" } = match.params;
    saveDoctorReview(
      "review/save",
      { ...data, doctor_id: id, patient_id: patientId },
      () => {
        callback();
        fetchDoctorDetails(`search/getDoctorDetails/${id}`, (value) => {
          this.setState({
            doctorDetails: value.data,
          });
        });
      }
    );
  };

  render() {
    const {
      doctorDataById,
      loading,
      clinicAppointment,
      clinic_details,
      openIndex,
      doctorDetails,
      video_bookings,
      video_timings,
      specificClinic,
    } = this.state;

    const { doctorReviews } = this.props;

    const { wh_doctor_detail } = doctorDetails;
    let allClinics = [...doctorDataById];

    return (
      <>
        <DoctorInfo
          doctorDataById={[specificClinic] ? doctorDataById : [specificClinic]}
          clinicAppointmentToggle={this.clinicAppointmentToggle}
          clinicAppointment={clinicAppointment}
          setClinicDetails={this.setClinicDetails}
          doctorInfoDetails={doctorDetails}
          allClinics={allClinics}
          loading={loading}
          doctorReviews={doctorReviews}
          saveReview={this.saveReview}
          navigateToLogin={() => this.props.history.push("/patient_login")}
          patientId={this.props.patientId}
        />

        <div className="pb-5 position-relative" id="clinicConsultation">
          {loading ? (
            <Loader />
          ) : (
            <>
              {allClinics && allClinics.length > 0 ? (
                allClinics.map((clinic_details, index) => {
                  return (
                    <Accordion
                      key={index}
                      clinic_details={clinic_details.wh_clinic}
                      wh_doctor_detail={wh_doctor_detail}
                      isOpen={openIndex.includes(index)}
                      setIsOpen={this.toggle}
                      clinicAppointmentToggle={this.clinicAppointmentToggle}
                      index={index}
                      setClinicDetails={this.setClinicDetails}
                      doctorInfoDetails={doctorDetails}
                    >
                      {this.renderAccordionConponents(clinic_details)}
                    </Accordion>
                  );
                })
              ) : (
                <h3 className="text-center">No Clinics Found!</h3>
              )}
            </>
          )}
        </div>

        {clinicAppointment && (
          <BookAppoitmentModal
            isOpen={clinicAppointment}
            toggle={this.clinicAppointmentToggle}
            clinic_details={
              specificClinic && specificClinic.wh_clinic
                ? specificClinic.wh_clinic
                : clinic_details
            }
            wh_doctor_detail={wh_doctor_detail}
            video_bookings={video_bookings}
            video_timings={video_timings}
            doctorDataById={
              [specificClinic] ? doctorDataById : [specificClinic]
            }
            doctorInfoDetails={doctorDetails}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doctorReviews: state.DoctorProfile.doctorReviews,
    patientId: state.BookAppointmentReducer.savePatientId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDoctorsDataById,
      fetchDoctorDetails,
      fetchDoctorReviews,
      saveDoctorReview,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DoctorProfile)
);
