import { put, takeLatest, call } from "redux-saga/effects";
import {
  FETCH_VIDEOS_DATA_REQUEST,
  FETCH_VIDEOS_DATA_SUCCESS,
  FETCH_VIDEOS_DATA_FAILED,
} from "./types";
import { apiCallGet } from "../../../../common/axios";

async function callFetchVideosData(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

function* fetchVideosData(action) {
  const response = yield call(callFetchVideosData, action);
  const { data } = response;

  if (response.status === 200) {
    if (data.data) {
      action.callback(data.data);
      yield put({ type: FETCH_VIDEOS_DATA_SUCCESS, videos: data.data });
    }
  } else {
    yield put({ type: FETCH_VIDEOS_DATA_FAILED });
  }
}

export default function* HealthIssueWatcher() {
  yield takeLatest(FETCH_VIDEOS_DATA_REQUEST, fetchVideosData);
}
