import { put, takeLatest, call } from "redux-saga/effects";
import {
  SEND_APP_LINK_REQUEST,
  SEND_APP_LINK_SUCCESS,
  SEND_APP_LINK_FAILED,
  DOCTOR_LOCATION_REQUEST,
  DOCTOR_LOCATION_SUCCESS,
  DOCTOR_LOCATION_FAILED,
  SEARCH_SUGGESTIONS_FAILED,
  SEARCH_SUGGESTIONS_REQUEST,
  SEARCH_SUGGESTIONS_SUCCESS,
} from "./types";
import { apiCallPost } from "../../../common/axios";
import { apiCallGet } from "../../../common/axios";

async function callSendAppLink(data) {
  const res = await apiCallGet(data.url, true);
  return res;
}

async function callSearchSuggestions(data) {
  const res = await apiCallPost(data.url, data.payload, false, true);

  return res;
}

async function callDoctorLocation(data) {
  const res = await apiCallGet(data.payload, true);
  return res;
}

function* sendAppLink(action) {
  const response = yield call(callSendAppLink, action);

  if (response && response.status) {
    action.callback(response.status);
    if (response.status === 200) {
      yield put({ type: SEND_APP_LINK_SUCCESS, appLink: response.data });
    } else {
      yield put({ type: SEND_APP_LINK_FAILED });
    }
  }
}

function* searchSuggestions(action) {
  const response = yield call(callSearchSuggestions, action);

  const { data } = response;
  if (data.data) {
    if (data.data) {
      action.callback(data.data);
      yield put({
        type: SEARCH_SUGGESTIONS_SUCCESS,
        search_suggestions: data.data,
      });
    } else {
      yield put({ type: SEARCH_SUGGESTIONS_FAILED });
    }
  }
}

function* doctorLocation(action) {
  const response = yield call(callDoctorLocation, action);

  const { data } = response;
  if (data) {
    if (data.data) {
      action.callback(data.data);
      yield put({ type: DOCTOR_LOCATION_SUCCESS, cities: data.data });
    } else {
      yield put({ type: DOCTOR_LOCATION_FAILED });
    }
  }
}

export default function* SearchDoctorWatcher() {
  yield takeLatest(SEND_APP_LINK_REQUEST, sendAppLink);
  yield takeLatest(DOCTOR_LOCATION_REQUEST, doctorLocation);
  yield takeLatest(SEARCH_SUGGESTIONS_REQUEST, searchSuggestions);
}
