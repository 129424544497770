import React, { PureComponent } from 'react';
import { Container, Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';
import ArrowIcon from '../../assets/images/get-in-touch/ArrowIcon.png';
import OfficeVenueIcon from '../../assets/images/get-in-touch/OfficeVenueIcon.png';
import ElementImage from '../../assets/images/get-in-touch/ElementImage.jpg';
import { contactUsOptions } from '../../constants';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { apiCallPost } from "../../common/axios";

export default class GetInTouch extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			interestedIn: contactUsOptions[0],
			name: '',
			email: '',
			query: ''
		}
	}

	onChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value
		});
	}

	onSubmit = async () => {
		const { name, interestedIn, email, query } = this.state;
		if(!name) {
			return NotificationManager.error('Please enter your name');  
		}
		if(!email) {
			return NotificationManager.error('Please enter your email'); 
		}
		if(!query) {
			return NotificationManager.error('Please enter your query'); 
		}
		
		await apiCallPost('contact', this.state, false, true);

		NotificationManager.success('Query submitted succesfully.');
		this.setState({
			name: '',
			email: '',
			query: '',
			interestedIn: contactUsOptions[0]
		});
	}

	render() {

		const { interestedIn, name, email, query } = this.state;

		return (
			<div className="get-in-touch-container">
				<Container fluid>
					<Row className="map-container">
						<Col sm={{size: 12}}>
							<div className="text-center pt-5">
								<h4>GET IN TOUCH</h4>
								<p className="my-4"><img src={ArrowIcon} alt="" height={60}></img></p>
							</div>
						</Col>
						<Col sm={{size: 6}}>
							<FormGroup>
								<Label>Interested In <span className="text-danger">*</span></Label>
								<Input type="select" value={interestedIn} name="interestedIn" onChange={this.onChange}>
									{
										contactUsOptions.map(option => {
											return <option value={option}>{option}</option>
										})
									}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label>Name <span className="text-danger">*</span></Label>
								<Input type="text" value={name} placeholder="Your Name" name="name" onChange={this.onChange} />
							</FormGroup>
							<FormGroup>
								<Label>Email <span className="text-danger">*</span></Label>
								<Input type="email" value={email} placeholder="Your email" name="email" onChange={this.onChange} />
							</FormGroup>
							<FormGroup>
								<Label>Query <span className="text-danger">*</span></Label>
								<Input type="textarea" value={query} placeholder="Add a message" name="query" onChange={this.onChange} />
							</FormGroup>
							<Button color="primary" onClick={this.onSubmit}>Submit</Button>
						</Col>
						<Col sm={{size: 6}}>
							<iframe
								title="Office Location"
								frameborder="0" 
								style={{border:0}}
								src="https://maps.google.com/maps?q=wishhealth&t=&z=13&ie=UTF8&iwloc=&output=embed" 
								allowfullscreen
								height={350}
								class="mt-3"
							>
							</iframe>
						</Col>
					</Row>
					<Row>
						<Col sm={{size: 12}} lg={{size: 10, offset: 1}} xl={{size: 8, offset: 2}} className="meet-us mb-5 text-center">
							<div className="d-inline-block">
								<img className="element-image" src={ElementImage} alt="" />
								<img 
									className="location-image"
									role="button"
									height={60} 
									width={60} 
									src={OfficeVenueIcon}
									alt=""
									onClick={() => window.open('https://maps.google.com/maps?q=wishhealth&t=&z=13&ie=UTF8&iwloc=')}
								>
								</img>
							</div>
							<div className="text-left d-inline-block location-address">
								<h4>MEET US</h4>
								<div className="office-location">
									<p>
										<span>C-205, SM Heights, Ground Floor, Industrial Area, Sector 74,</span>
										<span>Sahibzada Ajit Singh Nagar, Punjab - 140308</span>
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}