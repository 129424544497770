import { put, takeLatest, call } from "redux-saga/effects";
import {
  FETCH_DEALS_SUCCESS,FETCH_DEALS_FAILED,FETCH_DEALS_REQUEST
} from "./types";
import { apiCallGet } from "../../../../common/axios";



async function callFetchDeals(data) {
    const res = await apiCallGet(data.payload, true);
    return res;
  }

function* fetchDeals(action) {
  const response = yield call(callFetchDeals, action);

  if (response && response.status === 200) {
   
  
    if (response.data) {
      action.callback(response.data);
      yield put({
        type: FETCH_DEALS_SUCCESS,
        deals: response.data.data,
      });
    }
  } else {
    yield put({ type: FETCH_DEALS_FAILED });
  }
}

export default function* DealsWatcher() {
  yield takeLatest(FETCH_DEALS_REQUEST, fetchDeals);
}
