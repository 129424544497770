import {
  FETCH_DOCTOR_DATA_BY_ID_REQUEST,
  FETCH_DOCTOR_DETAILS_REQUEST,
  FETCH_DOCTOR_REVIEWS_REQUEST,
  SAVE_DOCTOR_REVIEWS_REQUEST
} from "./types";

export const fetchDoctorsDataById = (data, callback) => {
  return {
    type: FETCH_DOCTOR_DATA_BY_ID_REQUEST,

    payload: data,
    callback,
  };
};

export const fetchDoctorDetails = (data, callback) => {
  return {
    type: FETCH_DOCTOR_DETAILS_REQUEST,

    payload: data,
    callback,
  };
};

export const fetchDoctorReviews = (data, callback) => {
  return {
    type: FETCH_DOCTOR_REVIEWS_REQUEST,
    payload: data,
    callback,
  };
};

export const saveDoctorReview = (url, data, callback) => {
  return {
    type: SAVE_DOCTOR_REVIEWS_REQUEST,
    payload: {url, data},
    callback,
  };
};