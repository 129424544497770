import React, { PureComponent } from 'react';
import Rating from 'react-rating';

export default class StarRating extends PureComponent {

	render() {
		const { className='', stop = 5, rating = 0, onChange = () => { }, handleWriteRating = () => {}, quiet = false, readonly = false } = this.props;
	
		return (
			<div className={`doctor-star-rating ${className}`} onClick={handleWriteRating}>
				<Rating
					stop={stop}
					fullSymbol={['fas fa-star', 'fas fa-star',
						'fas fa-star', 'fas fa-star',
						'fas fa-star', 'fas fa-star']}
					emptySymbol={['far fa-star', 'far fa-star',
						'far fa-star', 'far fa-star',
						'far fa-star', 'far fa-star']}
					initialRating={rating}
					onChange={onChange}
					quiet={quiet}
					readonly={readonly}
				/>
			</div>
		)
	}
}