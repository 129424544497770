import {
  FETCH_ALL_APPOINTMENTS_DATA_REQUEST,
  DELETE_APPOINTMENT_REQUEST,
  EDIT_APPOINTMENT_REQUEST,
  MY_APPOINTMENT_REQUEST,
  UPDATE_PATIENT_DETAILS_REQUEST,
  LAST_FEW_BOOKED_DOCTORS_REQUEST,
} from "./types";

export const fetchAllAppointmentsData = (data, callback) => ({
  type: FETCH_ALL_APPOINTMENTS_DATA_REQUEST,
  payload: data,
  callback,
});

export const deleteAppointment = (url, data, callback) => ({
  type: DELETE_APPOINTMENT_REQUEST,
  url,
  payload: data,
  callback,
});

export const editAppointment = (url, data, callback) => ({
  type: EDIT_APPOINTMENT_REQUEST,
  url,
  payload: data,
  callback,
});

export const updatePatientDetails = (url, data, callback) => ({
  type: UPDATE_PATIENT_DETAILS_REQUEST,
  url,
  payload: data,
  callback,
});

export const fetchMyAppointments = (data, callback) => ({
  type: MY_APPOINTMENT_REQUEST,
  payload: data,
  callback,
});

export const fetchLastFewBookedDoctors = (data, callback) => ({
  type: LAST_FEW_BOOKED_DOCTORS_REQUEST,
  payload: data,
  callback,
});
