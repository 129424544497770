import React, { useState, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
import { extractChar } from '../../../util/string';
import MetaTags from "react-meta-tags";
import Calendar from "../../../assets/images/dashboard/calendar.png";

import Hospital from "../../../assets/images/dashboard/home.png";
import Delete from "../../../assets/images/dashboard/delete-white.svg";
import Tick from "../../../assets/images/dashboard/tick.png";
import Pending from "../../../assets/images/dashboard/pending.png";
import VideoIcon from "../../../assets/images/dashboard/video-icon.png";
import { titleCase } from "../../../common/stringFunction";
import Avatar from "../../../assets/images/avatar.png";
import moment from "moment";
import { deleteAppointment } from "../../dashboard/store/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../loader";
import DeleteModal from "../../../common/deleteModal";
import { isProduction } from "../../../config";
import NotificationManager from "react-notifications/lib/NotificationManager";
import FileSaver from "file-saver";

import Arrows from "../../../assets/images/dashboard/arrow.png";
import { fetchMyAppointments } from "../../dashboard/store/action";

import Edit from "../../../assets/images/dashboard/edit-white.svg";
import {
  createPayment,
  capturePayment,
  couponCode,
  verifyCoupon,
} from "../../../components/modal/store/action";
import { fetchDoctorsDataById } from "../../../pages/doctorProfile/store/action";

const AppointmentDetails = (props) => {
  const [responseLoader, setResponseLoader] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [image, setImage] = useState(false);
  const [prescriptionImage, setPrescriptionImage] = useState("");
  const { myAppointment, callDisable, loading = false } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bookingIdDeleteAppointment, setBookingIdDeleteAppointment] = useState(
    ""
  );

  const [errorType, setErrortype] = useState("");
  const [errorText, setErrortext] = useState("");
  const [coupon, setCoupon] = useState("");

  const {
    wh_user,
    wh_patient_detail,
    wh_clinic,
    prescriptions,
    payment,
    clinic_id,
    id,
    date2,
    patient_id,
  } = myAppointment;

  let city =
    wh_user &&
    wh_user.wh_doctor_detail &&
    wh_user.wh_doctor_detail.wh_city &&
    wh_user.wh_doctor_detail.wh_city.name;
  let nameReplace = wh_user && wh_user.name.split(" ").join("-");

  let timeFormat = moment(myAppointment.time, "hh:mm a");
  let timeFormatInSmall = timeFormat.format("hh:mm A");
  const { callApiAppointmentDetails } = props;
  let loggedIn = localStorage && localStorage.getItem("isLoggedIn");

  let type =
    props && props.history.location.state && props.history.location.state.type;

  let profileImage =
    wh_user && wh_user.wh_doctor_detail && wh_user.wh_doctor_detail.profile_pic
      ? wh_user &&
        wh_user.wh_doctor_detail &&
        wh_user.wh_doctor_detail.profile_pic
      : Avatar;

  let degrees =
    wh_user &&
    wh_user.wh_doctor_qualifications &&
    wh_user.wh_doctor_qualifications.length > 0 &&
    wh_user.wh_doctor_qualifications.filter((item) => {
      return item.wh_qualification && item.wh_qualification.degree !== null;
    });

  let doctorDegree =
    degrees &&
    degrees.length > 0 &&
    degrees.map((item) => {
      return item.wh_qualification && item.wh_qualification.degree;
    });

  let specialities =
    wh_user &&
    wh_user.wh_doctor_specialities &&
    wh_user.wh_doctor_specialities.filter((item) => {
      return item.wh_speciality !== null;
    });

  let doctorsSpecialitiesArray =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      return item && item.wh_speciality && titleCase(item.wh_speciality.title);
    });

  let doctorsSpecialitiesReplaced =
    specialities &&
    specialities.length > 0 &&
    specialities.map((item) => {
      const data =
        item &&
        item.wh_speciality &&
        titleCase(item.wh_speciality.title).split(" ").join("-");
      return data.split("/").join("-");
    });

  let specialitiesReplace =
    doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");

  let d = moment(new Date());
  let n = moment(date2);
  let Current = d.utc().format();
  let Booked = n.utc().format();
  const handleDelete = (e, bookingId) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalOpen(!deleteModalOpen);
    setBookingIdDeleteAppointment(bookingId);
  };

  const handleDeleteAppointment = () => {
    setDeleteModalOpen(false);
    let obj = {
      booking_id: bookingIdDeleteAppointment,
      deleted_by: "patient",
    };

    props.deleteAppointment("booking/delete", obj, (response) => {
      if (response.message === "Deleted Successfully") {
        NotificationManager.success(response.message);
        props.history.push("/patient/dashboard/upcoming-appointments");
      }
    });
  };

  const imagePreview = (prescription) => {
    setImage(!image);
    setPrescriptionImage(prescription);
  };
  const downloadImage = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    FileSaver.saveAs(item.prescription, item.id + ".jpeg");
  };

  const handleBack = () => {
    let type =
      props &&
      props.history.location.state &&
      props.history.location.state.type;

    if (type === "past")
      props.history.push("/patient/dashboard/past-appointments");
    else {
      props.history.push("/patient/dashboard/upcoming-appointments");
    }
  };
  const paymentHandler = (e, id, wh_user, payment, clinic_id, type) => {
    let remainingAmount =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.doc_fees -
        wh_user.wh_doctor_detail.doc_advance_fees;
    e.preventDefault();
    setResponseLoader(true);
    let dataObj;
    if (type === "total") {
      if (clinic_id === 1) {
        dataObj = {
          amount:
            wh_user && wh_user.video_timings && wh_user.video_timings.fees,
        };
      } else {
        dataObj = {
          amount:
            wh_user &&
            wh_user.wh_doctor_detail &&
            wh_user.wh_doctor_detail.doc_fees !== null
              ? remainingAmount
              : 0,
        };
      }
    } else if (type === "additional") {
      dataObj = { amount: payment && payment.additional_charges };
    } else if (type === "overall") {
      let total_fees = 0,
        advance_fees = 0,
        additional_charges = 0;
      if (
        (clinic_id === 1 && payment && !payment.advance_fees_payment_id) ||
        !payment
      ) {
        advance_fees =
          payment && payment.advance_fees
            ? payment.advance_fees
            : clinic_id !== 1
            ? wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_advance_fees !== null
              ? wh_user.wh_doctor_detail.doc_advance_fees
              : 0
            : wh_user &&
              wh_user.video_timings &&
              wh_user.video_timings.length > 0 &&
              wh_user.video_timings[0].fees !== null
            ? wh_user.video_timings[0].fees
            : 0;
      }
      additional_charges =
        payment && payment.additional_charges ? payment.additional_charges : 0;
      total_fees =
        clinic_id !== 1
          ? wh_user &&
            wh_user.wh_doctor_detail &&
            wh_user.wh_doctor_detail.doc_fees !== null
            ? wh_user.wh_doctor_detail.doc_fees
            : 0
          : 0;

      dataObj = {
        amount:
          clinic_id !== 1
            ? parseInt(total_fees) + parseInt(additional_charges)
            : parseInt(advance_fees) + parseInt(additional_charges),
      };
    } else if (type === "total+additional") {
      dataObj = {
        amount:
          wh_user &&
          wh_user.wh_doctor_detail &&
          wh_user.wh_doctor_detail.doc_fees !== null
            ? remainingAmount + parseInt(payment.additional_charges)
            : 0,
      };
    } else {
      dataObj = { amount: payment && payment.advance_fees };
    }
    props.createPayment("order/", dataObj, (response) => {
      setResponseLoader(false);
      const data = response;

      const options = {
        key: !isProduction
          ? "rzp_test_5G5VyL9K8BPPNJ"
          : "rzp_live_OlRwttnj1mQtYv",
        name: "Wish Health",
        description: "Search and consult doctors anywhere anytime",
        order_id: data.id,
        handler: async (response) => {
          try {
            let objCreatePayment;

            if (type === "additional") {
              objCreatePayment = {
                fees_type: "additional",
                booking_id: id,
                additional_fees_payment_id: response.razorpay_payment_id,
                additional_fees: payment && payment.additional_charges,
              };
            } else if (type === "total") {
              objCreatePayment = {
                fees:
                  wh_user &&
                  wh_user.wh_doctor_detail &&
                  wh_user.wh_doctor_detail.doc_fees !== null
                    ? remainingAmount
                    : 0,
                fees_type: "fees",
                booking_id: id,
                fees_payment_id: response.razorpay_payment_id,
              };
            } else if (type === "overall") {
              objCreatePayment = {
                fees:
                  clinic_id !== 1
                    ? wh_user &&
                      wh_user.wh_doctor_detail &&
                      wh_user.wh_doctor_detail.doc_fees !== null
                      ? wh_user.wh_doctor_detail.doc_fees
                      : 0
                    : 0,
                additional_fees:
                  payment && payment.additional_charges
                    ? payment.additional_charges
                    : 0,

                fees_type: "overall",
                booking_id: id,
                payment_id: response.razorpay_payment_id,
                ignore_message: true,
              };
              if (
                clinic_id === 1 &&
                payment &&
                !payment.advance_fees_payment_id
              ) {
                objCreatePayment.advance_fees =
                  payment && payment.advance_fees
                    ? payment.advance_fees
                    : clinic_id !== 1
                    ? wh_user &&
                      wh_user.wh_doctor_detail &&
                      wh_user.wh_doctor_detail.doc_advance_fees !== null
                      ? wh_user.wh_doctor_detail.doc_advance_fees
                      : 0
                    : wh_user &&
                      wh_user.video_timings &&
                      wh_user.video_timings.length > 0 &&
                      wh_user.video_timings[0].fees !== null
                    ? wh_user.video_timings[0].fees
                    : 0;
              }
            } else if (type === "total+additional") {
              objCreatePayment = {
                fees:
                  wh_user &&
                  wh_user.wh_doctor_detail &&
                  wh_user.wh_doctor_detail.doc_fees !== null
                    ? remainingAmount + parseInt(payment.additional_charges)
                    : 0,
                fees_type: "total+additional_fees",
                booking_id: id,
                fees_payment_id: response.razorpay_payment_id,
              };
            } else {
              objCreatePayment = {
                advance_fees: payment.advance_fees,
                fees_type: "advance",
                booking_id: id,
                advance_fees_payment_id: response.razorpay_payment_id,
              };
            }

            props.capturePayment(
              "order/capturePayment",
              objCreatePayment,
              (captureResponse) => {
                if (captureResponse.message === "success") {
                  setResponseLoader(false);
                  NotificationManager.success("Payment Successful", "", 1000);
                  setTimeout(() => {
                    callApiAppointmentDetails();
                  }, 500);
                  setPaymentDone(true);
                } else {
                  setResponseLoader(false);
                  NotificationManager.warning("Payment Failed", "", 1000);
                }
              }
            );
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#389EEC",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
  };
  const handleRebook = (e) => {
    e.preventDefault();

    props.history.push({
      pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${wh_user && wh_user.user_id}`,
      search: `?specialization=${specialitiesReplace}`,
      // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${
      //   wh_user && wh_user.user_id
      // }`,
      state: { modalOpen: true },
    });
  };

  const handleEdit = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    const { wh_user, wh_clinic, id } = data;

    const { fetchDoctorsDataById } = props;
    fetchDoctorsDataById(
      `search/getDoctorClinics/${wh_user && wh_user.user_id}`,
      (value) => {
        let city =
          value && value.data && value.data.wh_city && value.data.wh_city.name;

        let obj;
        obj = {
          clinic_id: wh_clinic && wh_clinic.clinic_id,
          date: data && data.date,
          isBooked: true,
          selected: false,
          time: data && data.time,
          type: !wh_clinic ? "video" : "clinic",
        };

        props.history.push({
          pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${
            wh_user && wh_user.user_id
          }/bookingForm`,
          search: `?specialization=${specialitiesReplace}`,
          // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${
          //   wh_user && wh_user.user_id
          // }/bookingForm`,
          state: {
            clinic_details: !wh_clinic ? {} : wh_clinic,
            wh_doctor_detail: wh_user && wh_user.wh_doctor_detail,
            timeSlot: data && data.time,
            selectedDate: data && data.date,

            data: null,
            doctorDataById:
              value.data && value.data.clinics && value.data.clinics.length > 0
                ? value.data.clinics
                : [],
            videoConsultationBooking: wh_clinic
              ? []
              : value.data && value.data.length > 0
              ? value.data[0].video_timings
              : value.data.video_timings,
            video_timings: wh_clinic
              ? []
              : value.data && value.data.length > 0
              ? value.data[0].video_timings
              : value.data.video_timings,
            modalOpenForEditing: true,
            video_bookings: wh_clinic
              ? []
              : value.data && value.data.length > 0
              ? value.data[0].videobookings
              : value.data.videobookings,
            doctorInfoDetails: wh_user,
            selectedTimeSlot: obj,
            type: "edit",
            booking_id: id,
          },
        });
      }
    );
  };

  const handleInput = (e) => {
    setCoupon(e.target.value);

    setErrortext("");
    setErrortype("");
  };

  const handleCoupon = (e, id, clinic_id, wh_user, payment) => {
    let remainingAmount =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.doc_fees -
        wh_user.wh_doctor_detail.doc_advance_fees;

    e.preventDefault();

    setResponseLoader(true);
    if (coupon === "") {
      setErrortype("coupon");
      setErrortext(
        <p>
          <span className="text-danger">Coupon Code is empty</span>
        </p>
      );

      setResponseLoader(false);
      return;
    }

    let obj = {
      name: coupon,
      patient_id: props && props.saveId ? props.saveId : patient_id,
    };

    props.verifyCoupon("booking/verify-coupon", obj, (response) => {
      if (response.status === 400) {
        NotificationManager.info(response.message, "", 3000);

        setResponseLoader(false);
        setCoupon("");
      } else {
        let dataObj;
        if (clinic_id === 1) {
          dataObj = {
            name: coupon,
            booking_id: id,
            patient_id:
              props && props.savePatientId ? props.savePatientId : patient_id,

            advance_fees: payment && payment.advance_fees,

            fees: 0,
          };
        } else {
          dataObj = {
            name: coupon,
            booking_id: id,
            patient_id: props && props.saveId ? props.saveId : patient_id,
            advance_fees:
              wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_advance_fees !== 0
                ? wh_user.wh_doctor_detail.doc_advance_fees
                : 1,
            fees:
              wh_user &&
              wh_user.wh_doctor_detail &&
              wh_user.wh_doctor_detail.doc_fees !== null
                ? wh_user.wh_doctor_detail.doc_fees
                : 0,
          };
        }

        props.couponCode("booking/applyCoupon", dataObj, (response) => {
          if (response.status === 400) {
            NotificationManager.info(response.message, "", 1000);

            setResponseLoader(false);
            setCoupon("");
          } else {
            NotificationManager.success(
              "Coupon applied and appointment booked successfully",
              "",
              1000
            );

            setResponseLoader(false);
            setCoupon("");

            callApiAppointmentDetails();
          }
        });
      }
    });
  };

  return (
    <>
      <div className="appointments-details-wrapper">
        <MetaTags>
          <title>Appointment Details</title>
        </MetaTags>

        {(loading || responseLoader) && <Loader />}
        <div style={loading || responseLoader ? { filter: "blur(3px)" } : {}}>
          {loggedIn === "true" && (
            <div>
              <Button onClick={handleBack} className="back-btn">
                <i className="fas fa-chevron-left mr-2" /> Back
              </Button>
            </div>
          )}
          <div className="profile-details">
  {  wh_user && wh_user.wh_doctor_detail && wh_user.wh_doctor_detail.profile_pic?        <img
              src={wh_user && wh_user.wh_doctor_detail && wh_user.wh_doctor_detail.profile_pic}
              alt="profile"
              className="profile negative-margin"
            />:(<div className="dummy-text">
            <span>{extractChar(wh_user && wh_user.name)}</span>
          </div>)}
            <h2>
              {wh_user && wh_user.name}
              {doctorDegree && <span>{`(${doctorDegree})`}</span>}&nbsp;
            </h2>
            <p>
              {doctorsSpecialitiesArray &&
                doctorsSpecialitiesArray.length > 0 &&
                doctorsSpecialitiesArray.map((item, key) => {
                  const length = doctorsSpecialitiesArray.length;

                  return (
                    <Fragment key={key}>
                      {key === length - 1 ? item : `${item} , `}
                    </Fragment>
                  );
                })}
              &nbsp;
            </p>
          </div>
          <div
            className="visit-appointments"
            style={loading ? { opacity: 0 } : {}}
          >
            {clinic_id && clinic_id !== 1 && !loading ? (
              <h3>Clinic visit Appointment</h3>
            ) : (
              <h3>Video Appointment</h3>
            )}
            <div className="position-relative">
              <Row>
                {clinic_id && clinic_id !== 1 && (
                  <Col sm={6} md={4} className="mb-md-0 mb-3">
                    <div className="visit-appointment-col">
                      <div className="visit-icon">
                        <img src={Hospital} alt="calendar" />
                      </div>
                      <div>
                        <h4>Hospital Name & Address</h4>
                        <p className="address">{`${
                          wh_clinic && wh_clinic.name
                        } & ${wh_clinic && wh_clinic.address}`}</p>
                      </div>
                    </div>
                  </Col>
                )}

                <Col sm={6} md={4} className="mb-md-0 mb-3">
                  <div className="visit-appointment-col">
                    <div className="visit-icon">
                      <img src={Calendar} alt="calendar" />
                    </div>
                    <div className="mb-0">
                      <h4>Appointment Time</h4>
                      <p>{`${
                        myAppointment && myAppointment.date
                      }, ${timeFormatInSmall}`}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={6} md={4} className="mb-md-0 mb-3">
                  {payment &&
                    (payment.status === "Paid" ||
                      payment.status === "Paid By Coupon") && (
                      <div className="visit-appointment-col">
                        <div className="visit-icon">
                          <img src={Tick} alt="calendar" />
                        </div>
                        <div className="text-left">
                          <h4>Payment Status</h4>
                          <p>Completed</p>
                        </div>
                      </div>
                    )}
                  {payment &&
                    payment.status !== "Paid" &&
                    clinic_id !== 1 &&
                    !payment.fees_payment_id &&
                    payment.advance_fees_payment_id !== "" &&
                    payment.advance_fees_payment_id !== null &&
                    (!payment.additional_charges ||
                      parseInt(payment.additional_charges) === 0) &&
                    parseInt(payment.fees) !== 0 && (
                      <div className="visit-appointment-col">
                        <div className="visit-icon">
                          <img src={Tick} alt="calendar" />
                        </div>
                        <div className="advanced-payment p-0">
                          <h4 className="mb-0 advance-fees">
                            Advance Fee:<span className="pl-1">Paid</span>{" "}
                          </h4>
                          <p className="mb-0">
                            <label>Outstanding Fee:</label>
                            <span className="pl-1">
                              <a
                                href="#"
                                onClick={(e) =>
                                  paymentHandler(
                                    e,
                                    id,
                                    wh_user,
                                    payment,
                                    clinic_id,
                                    parseInt(payment.additional_charges)
                                      ? "overall"
                                      : "total"
                                  )
                                }
                              >
                                {" "}
                                Pay Now{" "}
                                <img alt="" src={Arrows} className="ml-2" />
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  {payment &&
                    payment.additional_charges &&
                    parseInt(payment.additional_charges) !== 0 &&
                    payment.status !== "Paid" &&
                    payment.fees_payment_id !== null &&
                    payment.advance_fees_payment_id !== null &&
                    // payment.advance_fees_payment_id !== "Paid" &&
                    (payment.additional_fees_payment_id === null ||
                      payment.additional_fees_payment_id === "") && (
                      <div className="visit-appointment-col">
                        <div className="visit-icon">
                          <img src={Tick} alt="calendar" />
                        </div>
                        <div className="advanced-payment p-0">
                          <h4 className="mb-0 advance-fees">
                            Outstanding Fee:<span className="pl-1">Paid</span>{" "}
                          </h4>
                          <p className="mb-0">
                            <label>Additional Fee:</label>
                            <span className="pl-1">
                              <a
                                href="#"
                                onClick={(e) =>
                                  paymentHandler(
                                    e,
                                    id,
                                    wh_user,
                                    payment,
                                    clinic_id,
                                    "additional"
                                  )
                                }
                              >
                                {" "}
                                Pay Now{" "}
                                <img
                                  src={Arrows}
                                  alt="play-now"
                                  className="ml-2"
                                />
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                  {payment && //new
                    payment.additional_charges &&
                    parseInt(payment.additional_charges) !== 0 &&
                    payment.status !== "Paid" &&
                    (payment.fees_payment_id !== null ||
                      payment.advance_fees_payment_id !== null) &&
                    // payment.advance_fees_payment_id !== "Paid" &&
                    (payment.additional_fees_payment_id === null ||
                      payment.additional_fees_payment_id === "") &&
                    !payment.fees_payment_id && (
                      <div className="visit-appointment-col">
                        <div className="visit-icon">
                          <img src={Tick} alt="calendar" />
                        </div>
                        <div className="advanced-payment p-0">
                          <h4 className="mb-0 advance-fees">
                            Advance Fee:<span className="pl-1">Paid</span>{" "}
                          </h4>
                          <p className="mb-0">
                            <label>Outstanding and Additional Fees:</label>
                            <span className="pl-1">
                              <a
                                href="#"
                                onClick={(e) =>
                                  paymentHandler(
                                    e,
                                    id,
                                    wh_user,
                                    payment,
                                    clinic_id,
                                    "total+additional"
                                  )
                                }
                              >
                                {" "}
                                Pay Now{" "}
                                <img
                                  src={Arrows}
                                  alt="play-now"
                                  className="ml-2"
                                />
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                  {payment === null && (
                    <div className="visit-appointment-col">
                      <div className="visit-icon">
                        <img src={Pending} alt="calendar" />
                      </div>
                      <div className="payment-pending">
                        <h4>Payment Status</h4>
                        <p>
                          (Pending){" "}
                          <a
                            href="#"
                            onClick={(e) =>
                              paymentHandler(
                                e,
                                id,
                                wh_user,
                                payment,
                                clinic_id,
                                "overall"
                              )
                            }
                          >
                            Pay Now
                          </a>
                        </p>
                      </div>
                    </div> //payment work
                  )}

                  {payment &&
                    (payment.advance_fees_payment_id === null ||
                      payment.advance_fees_payment_id === "") &&
                    (payment.fees_payment_id === null ||
                      payment.fees_payment_id === "") && (
                      <div className="visit-appointment-col">
                        <div className="visit-icon">
                          <img src={Pending} alt="calendar" />
                        </div>
                        <div className="payment-pending">
                          <h4>Payment Status</h4>
                          <p>
                            (Pending){" "}
                            <a
                              href="#"
                              onClick={(e) =>
                                paymentHandler(
                                  e,
                                  id,
                                  wh_user,
                                  payment,
                                  clinic_id,
                                  "overall"
                                )
                              }
                            >
                              Pay Now
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
            </div>
            {(!payment ||
              (payment &&
                payment.status === "" &&
                parseInt(payment.fees) !== 0)) && (
              <div className="apply-coupon-code">
                <div className="wrapper">
                  <FormGroup>
                    <Input
                      type="text"
                      name="coupon"
                      id={id}
                      placeholder="Enter coupon code"
                      onChange={handleInput}
                      value={coupon}
                      disabled={loading}
                    />
                  </FormGroup>
                  <Button
                    disabled={loading}
                    onClick={(e) =>
                      handleCoupon(e, id, clinic_id, wh_user, payment)
                    }
                    color="primary"
                  >
                    Apply
                  </Button>
                </div>
                {errorType && errorType === "coupon" && errorText}
              </div>
            )}
            <Row className="mt-3 text-center">
              <Col>
                <div className="d-flex justify-content-center">
                  {!callDisable && loggedIn === "true" && clinic_id === 1 && (
                    <Button
                      color="primary"
                      className="appointment-call"
                      disabled={
                        !payment ||
                        (payment &&
                          payment.status === "" &&
                          payment.advance_fees_payment_id === null)
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        props.history.push({
                          pathname: "/call",
                          state: {
                            patientDetails: wh_patient_detail,
                            doctorDetails: wh_user,
                          },
                        });
                      }}
                    >
                      <img src={VideoIcon} className="pr-2" alt="call" />
                      Call
                    </Button>
                  )}

                  {/* {Current > Booked && (
                    <Button
                      className="reBook-btn mr-1"
                      color="primary"
                      onClick={handleRebook}
                    >
                      Re-book
                    </Button>
                  )} */}

                  <Button
                    className="reBook-btn mr-1"
                    color="primary"
                    onClick={handleRebook}
                  >
                    Re-book
                  </Button>

                  {/* {Booked > Current && loggedIn === "true" && (
                    <Button
                      className="appointment-call mr-1"
                      color="primary"
                      onClick={(e) => handleEdit(e, myAppointment)}
                    >
                      <img src={Edit} className="pr-2" alt="edit" />
                      Edit
                    </Button>
                  )} */}

                  <Button
                    className="appointment-call mr-1"
                    color="primary"
                    onClick={(e) => handleEdit(e, myAppointment)}
                  >
                    <img src={Edit} className="pr-2" alt="edit" />
                    Edit
                  </Button>

                  {/* {type !== "past" && Booked > Current && loggedIn === "true" && (
                    <Button
                      color="danger"
                      className="appoint-delete-btn  text-white mr-2"
                      onClick={(e) => handleDelete(e, id)}
                    >
                      {" "}
                      <img
                        src={Delete}
                        alt="delete"
                        className="d-inline mr-1 position-relative"
                        style={{ top: "-2px", boxShadow: "none!important" }}
                      />
                      Delete Appointment
                    </Button>
                  )} */}

                  <Button
                    color="danger"
                    className="appoint-delete-btn  text-white mr-2"
                    onClick={(e) => handleDelete(e, id)}
                  >
                    {" "}
                    <img
                      src={Delete}
                      alt="delete"
                      className="d-inline mr-1 position-relative"
                      style={{ top: "-2px", boxShadow: "none!important" }}
                    />
                    Delete Appointment
                  </Button>
                </div>
                {/* <button className="delete-btn">
            <i className="fas fa-trash" />
          </button> */}
              </Col>
            </Row>
            <Row className="mt-4">
              {/* {loggedIn === "true" && ( */}
              <Col>
                <div className="prescription-wrapper">
                  {prescriptions && prescriptions.length > 0 && (
                    <h3>Prescriptions</h3>
                  )}
                  <div className="prescription-list">
                    {prescriptions && prescriptions.length > 0 ? (
                      prescriptions.map((item) => {
                        let formattedDate = moment(
                          item.prescription_date
                        ).format("DD/MM/YY");

                        return (
                          <div className="image text-center">
                            <div className="image-wrap">
                              <img
                                id={"img" + item.id}
                                src={item.prescription}
                                onClick={() => imagePreview(item.prescription)}
                                alt="Prescriptions"
                              />
                              <div className="download-btn">
                                download
                                <Button onClick={(e) => downloadImage(e, item)}>
                                  <i className="fas fa-download" />
                                </Button>
                              </div>
                            </div>

                            <p className="date mt-2">{`(${formattedDate})`}</p>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no-prescription">No Prescriptions</h3>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal
        isOpen={image}
        className="modal-dialog-centered prescription-modal"
      >
        <ModalBody>
          <div className="pres-images">
            <img
              src={prescriptionImage}
              onClick={() => imagePreview("")}
              alt="prescription"
            />
          </div>
        </ModalBody>
      </Modal>
      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={handleDelete}
        loading={loading}
        deleteAppointment={handleDeleteAppointment}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  savePatientId: state.BookAppointmentReducer.savePatientId,
  saveId: state.BookAppointmentReducer.saveId,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDoctorsDataById,
      deleteAppointment,
      createPayment,
      capturePayment,
      fetchMyAppointments,
      couponCode,
      verifyCoupon,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails)
);
