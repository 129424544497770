import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";

import { connect } from "react-redux";
import { titleCase } from "../../../common/stringFunction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { fetchLastFewBookedDoctors } from "../../dashboard/store/action";
import Avatar from "../../../assets/images/avatar.png";
import Loader from "../../../loader/index";
import { extractChar } from '../../../util/string';
const LastFewBookedDoctors = (props) => {
  const [lastFewBookedDoctors, setLastFewBookedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clinic_details, setClinic_details] = useState({});
  const [clinic_id, setClinic_id] = useState("");
  useEffect(() => {
    setLoading(true);
    let patient_id = props && props.savePatientId;

    props.fetchLastFewBookedDoctors(
      `patient/getLastBookedDoctors/${patient_id}`,
      (response) => {
        setLoading(false);
        setLastFewBookedDoctors(response && response.data);
      }
    );
  }, []);

  const handleRebook = (wh_user, user_id, name, clinic_details) => {
    let city =
      wh_user &&
      wh_user.wh_doctor_detail &&
      wh_user.wh_doctor_detail.wh_city &&
      wh_user.wh_doctor_detail.wh_city.name;

    let nameReplace = name.split(" ").join("-");

    let specialities =
      wh_user &&
      wh_user.wh_doctor_specialities &&
      wh_user.wh_doctor_specialities.filter((item) => {
        return item.wh_speciality !== null;
      });

    // let doctorsSpecialitiesArray =
    //   specialities &&
    //   specialities.length > 0 &&
    //   specialities.map((item) => {
    //     return (
    //       item && item.wh_speciality && titleCase(item.wh_speciality.title)
    //     );
    //   });

    let doctorsSpecialitiesReplaced =
      specialities &&
      specialities.length > 0 &&
      specialities.map((item) => {
        const data =
          item &&
          item.wh_speciality &&
          titleCase(item.wh_speciality.title).split(" ").join("-");
        return data.split("/").join("-");
      });

    let specialitiesReplace =
      doctorsSpecialitiesReplaced && doctorsSpecialitiesReplaced.join("-");

    setClinic_details(clinic_details);
    setClinic_id(clinic_details && clinic_details.clinic_id);
    props.history.push({
      pathname: `/${city}/doctor/${nameReplace}-${doctorsSpecialitiesReplaced[0]}/${user_id}`,
      search: `?specialization=${specialitiesReplace}`,
      // pathname: `/doctor/${nameReplace}/specialization=${specialitiesReplace}/${user_id}`,
      state: {
        modalOpen: true,
        clinic_details: clinic_details,
        clinic_id: clinic_id,
      },
    });
  };

  let lastFewBookedDoctors_clinic = lastFewBookedDoctors.filter((item) => {
    return item.wh_clinic !== null;
  });

  return (
    <>
      {lastFewBookedDoctors_clinic && lastFewBookedDoctors_clinic.length > 0 && (
        <div className="few-doctors-wrapper">
          <h2 className="text-center">Last Few Booked Doctors</h2>
          <div className="bookedDoctorsDetails">
            {loading ? (
              <Loader />
            ) : (
              <div className="">
                <Table responsive borderless className="bookedDoctors mb-0">
                  <thead>
                    <tr>
                      <th className="d-none d-md-table-cell" />
                      <th className="text-left">Doctors Name</th>
                      <th>Clinic Name</th>
                      <th>Book Here</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastFewBookedDoctors &&
                      lastFewBookedDoctors.length > 0 &&
                      lastFewBookedDoctors.map((data) => {
                        let profileImage =
                          data &&
                          data.wh_user &&
                          data.wh_user.wh_doctor_detail &&
                          data.wh_user.wh_doctor_detail.profile_pic
                            ? data.wh_user.wh_doctor_detail.profile_pic
                            : Avatar;

                        return (
                          <>
                            {/* {data.wh_clinic && data.wh_clinic !== null && ( */}
                            <tr>
                              <td className="d-none d-md-table-cell" />
                              <td>
                                <div className="d-flex align-items-center past-doctor-profile ">
                                 { data &&
                          data.wh_user &&
                          data.wh_user.wh_doctor_detail &&
                          data.wh_user.wh_doctor_detail.profile_pic?<img
                                    src={profileImage}
                                    alt="profile"
                                    className="img-fluid profile"
                                  />:(
                                  	<div className="dummy-text">
								<span>{extractChar(data.wh_user && data.wh_user.name)}</span>
							</div>  
                                  )}
                                  <h4 className="mb-0">
                                    {data.wh_user && data.wh_user.name}
                                  </h4>
                                </div>
                              </td>
                              <td>
                                {" "}
                                {data.wh_clinic && data.wh_clinic.name
                                  ? data.wh_clinic && data.wh_clinic.name
                                  : "-"}
                              </td>
                              <td>
                                <Button
                                  color="bordered"
                                  className="re-book-btn"
                                  onClick={() =>
                                    handleRebook(
                                      data.wh_user,
                                      data.wh_user && data.wh_user.user_id,
                                      data.wh_user && data.wh_user.name,
                                      data.wh_clinic
                                    )
                                  }
                                >
                                  Re-book
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            )}
            {lastFewBookedDoctors.length === 0 && !loading && (
              <p className="text-center py-4 mb-0">No Appointments</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  savePatientId: state.BookAppointmentReducer.savePatientId,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchLastFewBookedDoctors,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LastFewBookedDoctors)
);
