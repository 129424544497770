/* eslint-disable no-unused-expressions */
import axois from "axios";
import { config } from "../config";

export const apiCallPost = async (url, data, common_api, newUrl) => {
  let backendUrl = `${config.apiUrl}Common_API`;

  if (common_api) {
    // backendUrl = "https://prod.wishhealth.in";
    backendUrl = `${config.apiUrl}`;
    // backendUrl = "https://uat.wishhealth.in";
  }
  if (newUrl) {
    // backendUrl = "https://api.test.wishhealth.in";
    backendUrl = config.apiUrlInnow8; //"https://api.prod.wishhealth.in";
    // backendUrl = "http://192.168.1.38:7000";
    // backendUrl = "https://58a5387a0823.ngrok.io";
  }

  let content = "application/json";
  // let content = "application/json";
  console.log("apiCallPost-", { backendUrl, newUrl })
  return await axois
    .post(`${backendUrl}/${url}`, data, {
      headers: {
        "Content-Type": content,
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};
export const apiCallGet = async (url, newUrl) => {

  let backendUrl = `${config.apiUrl}Common_API`;
  if (newUrl) {
    // backendUrl = "https://api.test.wishhealth.in";
    backendUrl = config.apiUrlInnow8;
    //"https://api.prod.wishhealth.in";
    // backendUrl = "http://192.168.1.38:7000";
    // backendUrl = "https://58a5387a0823.ngrok.io";
  }
  console.log("apiCallGet-", { backendUrl, newUrl })
  return await axois
    .get(`${backendUrl}/${url}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    }).catch(err => {
      return err;
    })
};
