const {
  SEND_APP_LINK_REQUEST,
  SEND_APP_LINK_SUCCESS,
  SEND_APP_LINK_FAILED,
} = require("./types");
const {
  DOCTOR_LOCATION_REQUEST,
  DOCTOR_LOCATION_SUCCESS,
  DOCTOR_LOCATION_FAILED,
  SEARCH_SUGGESTIONS_FAILED,
  SEARCH_SUGGESTIONS_REQUEST,
  SEARCH_SUGGESTIONS_SUCCESS,
} = require("./types");
const initialState = {
  cities: [],
  search_suggestions: [],
  appLink: {},
};

const SearchDoctorFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCTOR_LOCATION_REQUEST: {
      return {
        ...state,
      };
    }
    case DOCTOR_LOCATION_SUCCESS: {
      return {
        cities: action.cities || [],
      };
    }
    case DOCTOR_LOCATION_FAILED: {
      return {
        ...state,
      };
    }
    case SEARCH_SUGGESTIONS_REQUEST: {
      return {
        ...state,
      };
    }
    case SEARCH_SUGGESTIONS_SUCCESS: {
      return {
        search_suggestions: action.search_suggestions || [],
      };
    }
    case SEARCH_SUGGESTIONS_FAILED: {
      return {
        ...state,
      };
    }
    case SEND_APP_LINK_REQUEST: {
      return {
        ...state,
      };
    }
    case SEND_APP_LINK_SUCCESS: {
      return {
        ...state,
        appLink: action.appLink || {},
      };
    }
    case SEND_APP_LINK_FAILED: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default SearchDoctorFormReducer;
