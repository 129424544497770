import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import AppoitmentTimeCard from "../../../AppoitmentTimeCard";

import moment from "moment";
import ClinicConsultation from "../../../../assets/images/icons/clinicConsultation.png";
import VideoConsultation from "../../../../assets/images/icons/videoConsultation.png";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../svgComponents";
import cx from "classnames";

const today = new Date();
let nextDay = new Date(today);
nextDay = new Date(nextDay.setDate(nextDay.getDate() + 1));

let nextDayAfterTomorrow = new Date(nextDay);
nextDayAfterTomorrow = new Date(
  nextDayAfterTomorrow.setDate(nextDayAfterTomorrow.getDate() + 1)
);

let currentDate = moment(today).format("DD-MMM-YYYY");
let currentDateDay = moment(currentDate).day();

let tomorrow = moment(nextDay).format("DD-MMM-YYYY");
let tomorrowDay = moment(tomorrow).day();
let datAfterTomorrow = moment(nextDayAfterTomorrow).format("DD-MMM-YYYY");
let datAfterTomorrowDay = moment(datAfterTomorrow).day();

let arrayOfDatesArray = [
  { date: currentDate, day: currentDateDay },
  { date: tomorrow, day: tomorrowDay },
  { date: datAfterTomorrow, day: datAfterTomorrowDay },
];

const DateTimeSlider = (props) => {
  const {
    doctorsDetails,
    clinic_details,
    toggle,
    isOpen,
    wh_doctor_detail,
    doctorDataById,
    doctorInfoDetails,
    video_timings,
    video_bookings,
    typeEdit,
    booking_id,
    paymentDetails,
  } = props;

  const [arrayOfDates, setarrayOfDates] = useState(arrayOfDatesArray);
  const [arrayOfDatesCount, setarrayOfDatesCount] = useState(0);
  const [selectedDate, setselectedDate] = useState(arrayOfDatesArray[0].date);
  const [selectedDay, setselectedDay] = useState(arrayOfDatesArray[0].day);
  const [bookedSlots, setbookedSlots] = useState(null);
  const [videoConsultationBooking, setvideoConsultationBooking] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const clinic_id =
      clinic_details && Object.keys(clinic_details).length > 0
        ? clinic_details.clinic_id
          ? clinic_details.clinic_id
          : clinic_details.wh_clinic
          ? clinic_details.wh_clinic.clinic_id
          : ""
        : "";

    const filteredData =
      doctorDataById &&
      doctorDataById.length > 0 &&
      doctorDataById.find((data) => {
        return data.wh_clinic && data.wh_clinic.clinic_id === clinic_id;
      });

    setbookedSlots(filteredData);

    const videoData = video_timings;

    if (videoData) {
      setvideoConsultationBooking(videoData);
    } else {
      setvideoConsultationBooking([]);
    }
  }, [doctorDataById, clinic_details]);

  const handleCurrentDate = (data) => {
    setselectedDate(data.date);
    setselectedDay(data.day);
  };
  let temp;
  const handleArrowButton = (type) => {
    if (type === "right") {
      setarrayOfDatesCount((previous) => previous + 3);

      if (arrayOfDatesCount + 3 === arrayOfDates.length) {
        let temp1_ist = new Date(arrayOfDates[arrayOfDates.length - 1].date);
        temp1_ist = new Date(temp1_ist.setDate(temp1_ist.getDate() + 1));

        let temp_1 = moment(temp1_ist).format("DD-MMM-YYYY");
        let temp2_ist = new Date(temp1_ist);
        temp2_ist = new Date(temp2_ist.setDate(temp2_ist.getDate() + 1));

        let temp_2 = moment(temp2_ist).format("DD-MMM-YYYY");

        let temp3_ist = new Date(temp2_ist);
        temp3_ist = new Date(temp3_ist.setDate(temp3_ist.getDate() + 1));

        let temp_3 = moment(temp3_ist).format("DD-MMM-YYYY");

        temp = [
          {
            date: temp_1,
            day: moment(temp_1).day(),
          },
          {
            date: temp_2,
            day: moment(temp_2).day(),
          },
          {
            date: temp_3,
            day: moment(temp_3).day(),
          },
        ];

        handleCurrentDate({
          date: temp_1,
          day: moment(temp_1).day(),
        });
        if (arrayOfDatesArray.length <= arrayOfDatesCount + 3) {
          setarrayOfDates([...arrayOfDates, ...temp]);
        }
      } else {
        let temp1_ist = new Date(arrayOfDates[arrayOfDatesCount + 2].date);
        temp1_ist = new Date(temp1_ist.setDate(temp1_ist.getDate() + 1));

        let temp_1 = moment(temp1_ist).format("DD-MMM-YYYY");
        handleCurrentDate({
          date: temp_1,
          day: moment(temp_1).day(),
        });
      }
    }
    if (type === "left") {
      const temp = [
        ...arrayOfDates.filter(
          (d, i) => i === ((arrayOfDatesCount - 3) / 3) * 3
        ),
      ];

      if (temp.length > 0) {
        handleCurrentDate({
          date: temp[0].date,
          day: temp[0].day,
        });
      }
      setarrayOfDatesCount((previous) => {
        return previous === 0 ? previous : previous - 3;
      });
    }
  };

  const SliderHandler = () => (
    <div className="appoitment-slider-handler">
      <button
        type="button"
        className="btn btn-arrow"
        onClick={() => handleArrowButton("left")}
      >
        <ArrowLeftIcon />
      </button>
      <ul className="d-flex flex-wrap p-0 m-0 btns">
        {arrayOfDates.map((data, index) => {
          if (
            index >= arrayOfDatesCount + 3 ||
            index <= arrayOfDatesCount - 1
          ) {
            return null;
          }

          return (
            <li key={index}>
              <button
                onClick={() => handleCurrentDate(data)}
                type="button"
                className={cx("btn btn-text", {
                  active: selectedDate === data.date,
                })}
              >
                {data.date}
              </button>
            </li>
          );
        })}
      </ul>
      <button
        type="button"
        className="btn btn-arrow"
        onClick={() => handleArrowButton("right")}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );

  const slotsAccordingToDates =
    bookedSlots &&
    bookedSlots.wh_clinic &&
    bookedSlots.wh_clinic.wh_patient_doctor_bookings &&
    bookedSlots.wh_clinic.wh_patient_doctor_bookings.filter((data) => {
      return arrayOfDates.map((e) => e.date).includes(data.date);
    });

  const slotsAccordingToDatesForVideo =
    video_bookings &&
    video_bookings.filter((data) => {
      return arrayOfDates.map((e) => e.date).includes(data.date);
    });

  let type = "";
  type =
    doctorDataById &&
    doctorDataById.length > 0 &&
    doctorDataById.map((item) => item.wh_clinic.clinic_type);

  return (
    <div className="dateModal-wrapper">
      <SliderHandler />

      <div className="doc-info text-center">
        <p>
          {clinic_details && clinic_details.wh_clinic ? (
            <span>{clinic_details.wh_clinic.name}</span>
          ) : (
            <span>{clinic_details && clinic_details.name}</span>
          )}
        </p>
        <p>
          {clinic_details && clinic_details.wh_clinic ? (
            <span>{clinic_details.wh_clinic.address}</span>
          ) : (
            <span>{clinic_details && clinic_details.address}</span>
          )}
        </p>
      </div>

      <div>
        <Row className="mx-0">
          <Col lg={12}>
            <AppoitmentTimeCard
              titleIcon={ClinicConsultation}
              title={"Book now"}
              bookedSlots={bookedSlots}
              clinic_details={clinic_details}
              slotsAccordingToDates={slotsAccordingToDates}
              selectedDate={selectedDate}
              selectedDay={selectedDay}
              videoConsultationBooking={videoConsultationBooking}
              loading={loading}
              doctorsDetails={doctorsDetails}
              wh_doctor_detail={wh_doctor_detail}
              toggle={toggle}
              isOpen={isOpen}
              typeOfClinic="Clinic"
              video_bookings={video_bookings}
              video_timings={video_timings}
              doctorDataById={doctorDataById}
              doctorInfoDetails={doctorInfoDetails}
              typeEdit={typeEdit}
              booking_id={booking_id}
              paymentDetails={paymentDetails}
            />
          </Col>
      {/*    <Col lg={6} className="mt-lg-0 mt-3">
            <AppoitmentTimeCard
              bookedSlots={bookedSlots}
              titleIcon={VideoConsultation}
              title={"Video Consultation"}
              clinic_details={clinic_details}
              slotsAccordingToDatesForVideo={slotsAccordingToDatesForVideo}
              selectedDate={selectedDate}
              selectedDay={selectedDay}
              typeOfClinic="Video"
              videoConsultationBooking={videoConsultationBooking}
              loading={loading}
              doctorsDetails={doctorsDetails}
              wh_doctor_detail={wh_doctor_detail}
              toggle={toggle}
              isOpen={isOpen}
              doctorDataById={doctorDataById}
              doctorInfoDetails={doctorInfoDetails}
              video_bookings={video_bookings}
              video_timings={video_timings}
              typeEdit={typeEdit}
              booking_id={booking_id}
              paymentDetails={paymentDetails}
            />
          </Col>*/}
        </Row>
      </div>

      <hr />
      <div className="date-footer">
        <div className="btnWrapper">
          <Button color="red-200" className="m-1">
            Already Booked
          </Button>
          <Button color="c-white" className="m-1">
            Available
          </Button>
          <Button color="success" className="m-1">
            You Selected
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateTimeSlider;
