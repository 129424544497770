export const FETCH_DOCTOR_DATA_BY_ID_REQUEST =
  "FETCH_DOCTOR_DATA_BY_ID_REQUEST";
export const FETCH_DOCTOR_DATA_BY_ID_SUCCESS =
  "FETCH_DOCTOR_DATA_BY_ID_SUCCESS";
export const FETCH_DOCTOR_DATA_BY_ID_FAILED = "FETCH_DOCTOR_DATA_BY_ID_FAILED";

export const FETCH_DOCTOR_DETAILS_REQUEST = "FETCH_DOCTOR_DETAILS_REQUEST";
export const FETCH_DOCTOR_DETAILS_SUCCESS = "FETCH_DOCTOR_DETAILS_SUCCESS";
export const FETCH_DOCTOR_DETAILS_FAILED = "FETCH_DOCTOR_DETAILS_FAILED";

export const FETCH_DOCTOR_REVIEWS_REQUEST = "FETCH_DOCTOR_REVIEWS_REQUEST";
export const FETCH_DOCTOR_REVIEWS_SUCCESS = "FETCH_DOCTOR_REVIEWS_SUCCESS";
export const FETCH_DOCTOR_REVIEWS_FAILED = "FETCH_DOCTOR_REVIEWS_FAILED";
export const SAVE_DOCTOR_REVIEWS_REQUEST = "SAVE_DOCTOR_REVIEWS_REQUEST";
export const SAVE_DOCTOR_REVIEW_SUCCESS = "SAVE_DOCTOR_REVIEW_SUCCESS";