import React from "react";
import { Modal, ModalBody } from "reactstrap";
import DateTimeSlider from "./DateTimeSlider";

const BookAppoitmentModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="appoitmentModal">
      <button className="close d-md-none" onClick={toggle}>
        &times;
      </button>
      <ModalBody className="p-0">
        <DateTimeSlider {...props} />
      </ModalBody>
    </Modal>
  );
};

export default BookAppoitmentModal;
