import {
  FETCH_BOOK_DOCTORS_DATA_SUCCESS,
  FETCH_BOOK_DOCTORS_DATA_FAILED,
  FETCH_BOOK_DOCTORS_DATA_REQUEST,
  SET_FILTERED_DOCTORS_DATA,
} from "./types";

const initialState = {
  doctors: [],
  filteredDoctors: [],
  count: "",
};

const BookDoctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOK_DOCTORS_DATA_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_BOOK_DOCTORS_DATA_SUCCESS: {
      return {
        ...state,
        doctors: action.doctors || [],
        count: action.count || "",
      };
    }
    case FETCH_BOOK_DOCTORS_DATA_FAILED: {
      return {
        ...state,
      };
    }

    case SET_FILTERED_DOCTORS_DATA: {
      return {
        ...state,
        filteredDoctors: action.payload || [],
      };
    }

    default: {
      return state;
    }
  }
};

export default BookDoctorsReducer;
