import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

let id = false;

export const notification = (data) => {
  const { message, status } = data;
  //   let id = false;
  //   const [notificationId, setNotificationId] = useState("");
  const timeLimit = 5000;
  if (id === false) {
    switch (status) {
      case "success":
        NotificationManager.success(message, "", timeLimit);
        break;
      case "warning":
        NotificationManager.warning(message, "", timeLimit);
        break;
    }
    id = true;
    setTimeout(() => {
      id = false;
    }, timeLimit);
  }
};
