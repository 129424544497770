import {
  SET_LOCATION_NAME_DATA,
  SET_ACTIVE_PAGE,
  SET_SEARCH_TYPE,
} from "./types";

export const saveLocationName = (data) => {
  return {
    type: SET_LOCATION_NAME_DATA,

    payload: data,
  };
};

export const saveActivePage = (data) => {
  return {
    type: SET_ACTIVE_PAGE,

    payload: data,
  };
};

export const saveSearchType = (data) => {
  return {
    type: SET_SEARCH_TYPE,

    payload: data,
  };
};
