import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { isProduction } from "../config";
import { rootReducer } from "./reducers/index";
import rootSaga from "./sagas/index";
import logger from "redux-logger";

const persistConfig = {
  debug: false,
  key: "root",
  keyPrefix: "v.1",
  storage,
  blacklist: ["HealthIssues"],
  whitelist: ["BookAppointmentReducer"]
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = isProduction
  ? createStore(persistedReducer, compose(applyMiddleware(sagaMiddleware)))
  : createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddleware, logger))
  );
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
export default { store, persistor };
