import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Geolocation from '../../../assets/images/autoDetectLocation.png';
import { ClickAwayListener } from '../../../util';
import Loader from '../../../loader/index';
import { useParams, withRouter } from 'react-router-dom';
import { doctorLocation, searchSuggestions } from '../store/action';
import { useDispatch } from 'react-redux';
import User from '../../../assets/images/profile/user.svg';
import logo from '../../../assets/images/logo.png';

import MetaTags from 'react-meta-tags';
import Geocode from 'react-geocode';
import GoogleLocationSearch from '../../googleLocation';

const searchData = [];
const SearchDoctorForm = (props) => {
	const params = useParams();
	// let location =
	//   props && props.searchLocation ? props.searchLocation : params.location;

	// let speciality = props && props.search ? props.search : params.speciality;
	// if (location) {
	//   location.split("-").join(" ");
	// }
	// if (speciality) {
	//   speciality.split("-").join(" ");
	// }
	const [cities, setCities] = useState([]);

	const [updatedData, setUpdatedData] = useState(searchData);
	const [updatedDataLocation, setUpdatedDataLocation] = useState([]);

	const [selectedLocation, setSelectedLocation] = useState(0);

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			doctorLocation('data/city', (value) => {
				setCities(value);
			})
		);
	}, []);
	const locationClickAway = () => {
		if (props.isLocationDropDown) {
			props.setIsLocationDropDown(false);
		}
	};
	const searchClickAway = () => {
		if (props.isSearchDropDown) {
			props.setIsSearchDropDown(false);
		}
	};
	const handleCurrentLocation = (setAddress) => {
		if (navigator.geolocation) {
			props.setIsLocationDropDown(false);

			navigator.geolocation.getCurrentPosition(
				(pos) => {
					let crd = pos.coords;

					props &&
						props.setLatLong({
							latitude: crd.latitude,
							longitude: crd.longitude,
						});
					setIsLoading(false);

					Geocode.setApiKey('AIzaSyCs_OJTcGMLlrSLz2VozERbeU2d5U8aOHU');

					Geocode.enableDebug();
					Geocode.fromLatLng(`${crd.latitude}`, `${crd.longitude}`).then(
						(response) => {
							const city = response.results.filter((item) => {
								return (
									item.types.includes('sublocality') ||
									item.types.includes('sublocality_level_2')
								);
							});

            
              let cityName = "";
              for (let addComp of city[0].address_components) {
                const isPresent = addComp.types.includes("locality");
                if (isPresent) cityName = addComp.long_name;
              }
              localStorage.setItem("liveCity", cityName);
          
              const areas = city[0].formatted_address.split(", ");
              let address = "";
              if (areas.length > 2) {
                address = areas.slice(0, areas.length - 2).join(", ");
              } else {
                address = city[0].formatted_address;
              }



							// const address = city[0].formatted_address;
							props.setSearchLocation(address);
							if (setAddress) {
								setAddress(address);
							}
							setIsLoading(false);
						},
						(error) => {}
					);

					props.setIsLocationDropDown(false);
					setIsLoading(false);
				},
				(err) => {
					console.log(err, 'error callback');
				},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0,
				}
			);
		} else {
			setTimeout(() => {
				alert('Browser not supported');
			}, 3000);
			setIsLoading(false);
		}
	};
	const handleLocationInput = (address) => {
		props.setSearchLocation(address);

		// const cityArray = cities.filter((data) => {
		//   return data.name
		//     .toLowerCase()
		//     .trim()

		//     .startsWith(e.target.value.toLowerCase().trim());
		// });

		// setUpdatedDataLocation(cityArray);
	};

	const getCitiesNames = () => {
		let location = props && props.searchLocation ? props.searchLocation : '';
		if (location === '') {
			if (cities && cities.length) {
				let arr = [];

				for (let i = 0; i < cities.length && i < 5; i++) {
					arr.push(cities[i]);
				}

				return arr.map((city, index) => {
					return (
						<li key={index}>
							<button onClick={() => handleDropDownList(city.id)}>
								{city.name || ''}
							</button>
						</li>
					);
				});
			} else {
				return null;
			}
		} else {
			if (updatedDataLocation && updatedDataLocation.length) {
				return updatedDataLocation.map((data, index) => {
					return (
						<li key={index}>
							<button onClick={() => handleDropDownList(data.id)}>
								{data.name || ''}
							</button>
						</li>
					);
				});
			} else {
				return null;
			}
		}
		return null;
	};
	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};

	const handleSearchInput = (e) => {
		props.setSearch(e.target.value);

		if (e.target.value.length >= 3) {
			props.setIsSearchDropDown(true);
			let obj = {
				search: e.target.value,
			};

			props.searchSuggestions('search/suggestions', obj, (value) => {
				let uniqueSuggestions = value.filter(onlyUnique);

				let uNames = new Map(
					uniqueSuggestions.map((s) => [s.toLowerCase(), s])
				);

				let suggestions = [...uNames.values()];

				setUpdatedData(suggestions);
			});
		}
	};
	React.useEffect(() => {
		const searchTerm =
			props && props.search && props.search.trim().replace(/ +/g, ' ');
		const results = searchData.filter((item) =>
			item.name.toLowerCase().includes(searchTerm)
		);
		setUpdatedData(results);
	}, [props.search]);

	const handleDropDownList = (id) => {
		const value = cities.find((data) => data.id === id);
		props.setSearchLocation(value.name);
		props.setIsLocationDropDown(false);
		setSelectedLocation(id);
	};
	const handleSearchList = (name) => {
		props.setSearch(name);
		props.setIsSearchDropDown(false);
	};
	const onKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();

			props && props.handleSearch(e);
		}
	};
	const routeFromHome = props.location.pathname == '/' ? true : false;

	return (
		<div className="search-wrapper">
			{!routeFromHome && (
				<MetaTags>
					<title>
						{props.searchLocation &&
							!props.search &&
							`Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`}
						{!props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr. ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`}
						{!props.searchLocation &&
							!props.search &&
							'WishHealth | Consultations with Best Doctors near you, Book Doctor Appointments, Procedures, Surgeries and lab Tests'}
						{props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr. ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio, Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`}
						{props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} in ${props.searchLocation} - Book Appointment with Doctor In-Clinic, Video and Audio, Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`}
						{!props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`}
					</title>
					<meta
						name="description"
						content={
							props.searchLocation &&
							!props.search &&
							`Find Best Doctors, Clinics, Hospitals, Labs & More, healthcare providers in ${props.searchLocation}. Search best doctors in ${props.searchLocation} providing In-Clinic, Video and Audio consultations.`
						}
					/>

					<meta
						name="description"
						content={
							!props.searchLocation &&
							!props.search &&
							'Best doctors of all specialities available for In-clinic, Video and Tele Consultations. Consult Now with Best Doctors, Hospitals, Book Lab Tests, Health Packages, Free Consultations and Much More.'
						}
					/>

					<meta
						name="description"
						content={
							props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr.${props.search} in ${props.searchLocation} - Book Appointment with Doctor In-Clinic, Video and Audio, Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`
						}
					/>

					<meta
						name="description"
						content={
							props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} in ${props.searchLocation}. Book Doctors Anytime Online or In-Clinic, Check the ${props.search} in ${props.searchLocation} | WishHealth`
						}
					/>

					<meta
						name="description"
						content={
							!props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr. ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`
						}
					/>
					<meta
						name="description"
						content={
							!props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`
						}
					/>
					<meta
						property="og:title"
						content={
							props.searchLocation &&
							!props.search &&
							`Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`
						}
					/>

					<meta
						property="og:title"
						content={
							!props.searchLocation &&
							!props.search &&
							'WishHealth | Consultations with Best Doctors near you, Book Doctor Appointments, Procedures, Surgeries and lab Tests'
						}
					/>

					<meta
						property="og:title"
						content={
							props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr. ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio, Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`
						}
					/>

					<meta
						property="og:title"
						content={
							!props.searchLocation &&
							props.search &&
							props.searchType === 'doctor' &&
							`Dr. ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`
						}
					/>

					<meta
						property="og:title"
						content={
							props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} in ${props.searchLocation} - Book Appointment with Doctor In-Clinic, Video and Audio, Find Best Doctors, Clinics, Hospitals, Labs & More - Healthcare in ${props.searchLocation} | WishHealth`
						}
					/>

					<meta
						property="og:title"
						content={
							!props.searchLocation &&
							props.search &&
							props.searchType === 'speciality' &&
							`Best ${props.search} - Book Appointment with Doctor In-Clinic, Video and Audio | WishHealth`
						}
					/>

					<meta property="og:image" content={logo} />
				</MetaTags>
			)}
			<FormGroup className="location">
				<div className="search-wrap">
					<ClickAwayListener onClickAway={locationClickAway}>
						{/* <Input
							onKeyDown={(e) => onKeyDown(e)}
							type="search"
							onChange={handleLocationInput}
							onClick={() => props.setIsLocationDropDown(true)}
							value={props.searchLocation}
							placeholder="Location"
						/> */}
						<GoogleLocationSearch
							locationOnChange={handleLocationInput}
							handleCurrentLocation={handleCurrentLocation}
							location={props.searchLocation}
						/>
						<button
							color="primary"
							onClick={() => handleCurrentLocation()}
							className="gps-input-btn"
						>
							<img src={Geolocation} alt="geoLocation" />
						</button>

						{props.isLocationDropDown && (
							<div className="search-list">
								{isLoading && (
									<div className="location-loader">
										<Loader />
									</div>
								)}
								<button
									color="primary"
									onClick={handleCurrentLocation}
									className="gps-btn"
								>
									<img src={Geolocation} alt="geoLocation" />{' '}
									<span className="pl-3">Use my location</span>
								</button>
								<ul>{getCitiesNames()}</ul>
							</div>
						)}
					</ClickAwayListener>
				</div>
			</FormGroup>

			<FormGroup className="search">
				<div className="search-wrap position-static">
					<ClickAwayListener onClickAway={searchClickAway}>
						<Input
							onKeyDown={(e) => onKeyDown(e)}
							type="search"
							placeholder="Enter name or speciality e.g. dentist"
							value={props.search}
							onChange={handleSearchInput}
							onClick={handleSearchInput}
						/>{' '}
						{props.isSearchDropDown && (
							<div className="search-list">
								<ul className="mb-0">
									{updatedData.map((name, index) => {
										return (
											<li className="search-dropdown-list" key={index}>
												<button
													type="button"
													psdetch01
													onClick={() => handleSearchList(name)}
												>
													<div className="list-content">
														<span className="image">
															<img src={User} alt={name} />
														</span>
														{name}
													</div>
												</button>
											</li>
										);
									})}
								</ul>
							</div>
						)}
					</ClickAwayListener>
				</div>

				<div className="position-relative">
					{isLoading && (
						<div className="search-loader">
							<Loader />
						</div>
					)}
					<Button
						color="primary"
						className="text-uppercase search-btn"
						onClick={(e) => props && props.handleSearch(e)}
					>
						<span style={isLoading ? { opacity: 0 } : {}}>Search</span>
					</Button>
				</div>
			</FormGroup>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { searchType: state.SearchReducer.searchType };
};
const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			searchSuggestions,
		},
		dispatch
	);
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SearchDoctorForm)
);
