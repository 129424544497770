import React from "react";
import TimeSelect from "./timeSelect";

import morningIcon from "../../assets/images/icons/morning.png";
import afternoonIcon from "../../assets/images/icons/afternoon.png";
import eveningIcon from "../../assets/images/icons/evening.png";
import moment from "moment";
import Loader from "../../loader/index";
const AppoitmentTimeCard = (props) => {
  const {
    title = "",
    titleIcon = "",
    // day = "",
    bookedSlots,
    clinic_details,
    slotsAccordingToDates,
    selectedDate,
    selectedDay,
    typeOfClinic,
    videoConsultationBooking,
    slotsAccordingToDatesForVideo,
    loading,
    doctorsDetails,
    toggle,
    isOpen,
    wh_doctor_detail,
    doctorInfoDetails,
    doctorDataById,
    video_bookings,
    video_timings,
    typeEdit,
    booking_id,
    paymentDetails,
  } = props;

  let currentDay = "sunday";
  if (selectedDay === 1) {
    currentDay = "monday";
  }

  if (selectedDay === 2) {
    currentDay = "tuesday";
  }
  if (selectedDay === 3) {
    currentDay = "wednesday";
  }
  if (selectedDay === 4) {
    currentDay = "thursday";
  }
  if (selectedDay === 5) {
    currentDay = "friday";
  }
  if (selectedDay === 6) {
    currentDay = "saturday";
  }

  let arrayVideoConsultationBooking =
    videoConsultationBooking &&
    videoConsultationBooking.length > 0 &&
    videoConsultationBooking.filter((item) => {
      return item && item.days && item.days.toLowerCase().includes(currentDay);
    });

  let videoData = videoConsultationBooking;
  if (
    arrayVideoConsultationBooking &&
    arrayVideoConsultationBooking.length > 0
  ) {
    videoData = arrayVideoConsultationBooking[0];
  } else {
    videoData = arrayVideoConsultationBooking;
  }

  const {
    start_time = "",
    end_time = "",
    break_start_time = "",
    break_end_time = "",
    doc_interval,
    days = "",
  } = videoData;

  const start_time_withoutSpace = start_time.replace(" ", "");

  let startTime = moment(start_time_withoutSpace, "HH:mm a");
  const end_time_withoutSpace = end_time.replace(" ", "");
  let endTime = moment(end_time_withoutSpace, "HH:mm a");
  const break_start_time_withoutSpace = break_start_time.replace(" ", "");
  let breakStartTime = moment(break_start_time_withoutSpace, "HH:mm a");

  const break_end_time_withoutSpace = break_end_time.replace(" ", "");
  let breakEndTime = moment(break_end_time_withoutSpace, "HH:mm a");

  let timeTwelve = moment("12:00 PM", "HH:mm a");
  let timeFour = moment("04:00 PM", "HH:mm a");
  let interval = doc_interval ? doc_interval : 15;

  let slotsBeforeBreakTime = [];
  let slotsAfterBreakTime = [];

  if (startTime < breakStartTime && breakEndTime < endTime) {
    slotsBeforeBreakTime = [start_time];
    for (let i = startTime; i < breakStartTime; ) {
      i = moment(i).add(interval, "minutes");
      let a = moment(i).format("h:mm a");
      slotsBeforeBreakTime.push(a);
    }
    slotsBeforeBreakTime.pop();

    slotsAfterBreakTime = [break_end_time];
    for (let i = breakEndTime; i < endTime; ) {
      i = moment(i).add(interval, "minutes");
      let a = moment(i).format("h:mm a");
      slotsAfterBreakTime.push(a);
    }
    slotsAfterBreakTime.pop();
  } else if (
    startTime < breakStartTime &&
    breakStartTime < endTime &&
    endTime < breakEndTime
  ) {
    slotsBeforeBreakTime = [start_time];
    for (let i = startTime; i < breakStartTime; ) {
      i = moment(i).add(interval, "minutes");
      let a = moment(i).format("h:mm a");
      slotsBeforeBreakTime.push(a);
    }
    slotsBeforeBreakTime.pop();
  } else if (breakStartTime < startTime && breakEndTime < endTime) {
    slotsBeforeBreakTime = [start_time];
    for (let i = startTime; i < endTime; ) {
      i = moment(i).add(interval, "minutes");
      let a = moment(i).format("h:mm a");
      slotsBeforeBreakTime.push(a);
    }
    slotsBeforeBreakTime.pop();
  } else if (breakStartTime > endTime && breakEndTime > endTime) {
    slotsBeforeBreakTime = [start_time];
    for (let i = startTime; i < endTime; ) {
      i = moment(i).add(interval, "minutes");
      let a = moment(i).format("h:mm a");
      slotsBeforeBreakTime.push(a);
    }
    slotsBeforeBreakTime.pop();
  }

  let slotsBeforeTwelve = [],
    slotsAfterTwelve = [],
    slotsAfterFour = [];

  if (typeOfClinic === "Clinic") {
    let slotsArray;
    if (selectedDay === 0) {
      const day =
        bookedSlots &&
        bookedSlots.available_timings.find((item) => item.day == "7");
      slotsArray = day;
    } else {
      const day =
        bookedSlots &&
        bookedSlots.available_timings.find((item) => item.day == selectedDay);
      slotsArray = day;
    }

    if (slotsArray) {
      const start_withoutSpace =
        slotsArray.start_time && slotsArray.start_time.replace(" ", "");

      let clinicstartTime = moment(start_withoutSpace, "HH:mm a");
      const end_withoutSpace =
        slotsArray.end_time && slotsArray.end_time.replace(" ", "");
      let clinicendTime = moment(end_withoutSpace, "HH:mm a");
      const break_start_withoutSpace =
        slotsArray.break_start_time &&
        slotsArray.break_start_time.replace(" ", "");
      let clinicbreakStartTime = moment(break_start_withoutSpace, "HH:mm a");
      const break_end_withoutSpace =
        slotsArray.break_end_time && slotsArray.break_end_time.replace(" ", "");
      let clinicbreakEndTime = moment(break_end_withoutSpace, "HH:mm a");
      let clinicBeforeBreakTime = [];
      if (clinicstartTime < clinicbreakStartTime) {
        clinicBeforeBreakTime = [clinicstartTime];
      } else {
        clinicbreakStartTime = clinicstartTime;
        if (clinicstartTime > clinicbreakEndTime) {
          clinicbreakEndTime = clinicstartTime;
        }
      }
      if (clinicendTime < clinicbreakStartTime) {
        clinicbreakStartTime = clinicendTime;
      }

      const breakSlots = [];
      for (let l = clinicbreakStartTime; l < clinicbreakEndTime; ) {
        l = moment(l).add(
          slotsArray.doc_interval && slotsArray.doc_interval,
          "minutes"
        );
        let a = moment(l).format("h:mm a");
        breakSlots.push(a);
      }
      for (let i = clinicstartTime; i < clinicbreakStartTime; ) {
        i = moment(i).add(
          slotsArray.doc_interval && slotsArray.doc_interval,
          "minutes"
        );
        let a = moment(i).format("h:mm a");
        if (!breakSlots.includes(a)) {
          clinicBeforeBreakTime.push(a);
        }
      }

      clinicBeforeBreakTime.pop();
      let clinicAfterBreakTime = [clinicbreakEndTime];
      for (let i = clinicbreakEndTime; i < clinicendTime; ) {
        i = moment(i).add(
          slotsArray.doc_interval && slotsArray.doc_interval,
          "minutes"
        );
        let a = moment(i).format("h:mm a");
        if (!breakSlots.includes(a)) {
          clinicAfterBreakTime.push(a);
        }
      }
      clinicAfterBreakTime.pop();
      let splitSlots = slotsArray
        ? [...clinicBeforeBreakTime, ...clinicAfterBreakTime]
        : [];

      if (splitSlots && splitSlots.length > 0) {
        splitSlots.forEach((abc, index) => {
          let a = moment(abc, "hh:mm a");

          let c = moment(abc, "hh:mm a").format("hh:mm a");
          const aLower = c.replace(" ", "");
          let bookedSlotIndex = -1;

          if (slotsAccordingToDates && slotsAccordingToDates.length > 0) {
            bookedSlotIndex = slotsAccordingToDates.findIndex((b) => {
              return (
                moment(b.time, "hh:mm a")
                  .format("hh:mm a")
                  .replace(" ", "")
                  .toLowerCase() === aLower && b.date === selectedDate
              );
            });
          }

          if (a._i !== "") {
            if (a < timeTwelve) {
              slotsBeforeTwelve.push({
                time: aLower,
                isBooked: bookedSlotIndex !== -1,
                selected: false,
                type: "clinic",
                date: selectedDate,
                clinic_id: (clinic_details && clinic_details.clinic_id) || "",
              });
            } else if (a >= timeTwelve && a <= timeFour) {
              slotsAfterTwelve.push({
                time: aLower,
                isBooked: bookedSlotIndex !== -1,
                selected: false,
                type: "clinic",
                date: selectedDate,
                clinic_id: (clinic_details && clinic_details.clinic_id) || "",
              });
            } else {
              slotsAfterFour.push({
                time: aLower,
                isBooked: bookedSlotIndex !== -1,
                selected: false,
                type: "clinic",
                date: selectedDate,
                clinic_id: clinic_details && clinic_details.clinic_id,
              });
            }
          }
        });
      }
    }
  } else {
    let currentDay = "sunday";
    if (selectedDay === 1) {
      currentDay = "monday";
    }

    if (selectedDay === 2) {
      currentDay = "tuesday";
    }
    if (selectedDay === 3) {
      currentDay = "wednesday";
    }
    if (selectedDay === 4) {
      currentDay = "thursday";
    }
    if (selectedDay === 5) {
      currentDay = "friday";
    }
    if (selectedDay === 6) {
      currentDay = "saturday";
    }

    const isVideo = days && days.toLowerCase().includes(currentDay);

    let splitSlots = isVideo
      ? [...slotsBeforeBreakTime, ...slotsAfterBreakTime]
      : [];

    if (splitSlots && splitSlots.length > 0) {
      splitSlots.forEach((abc, index) => {
        let a = moment(abc, "hh:mm a");

        let c = moment(abc, "hh:mm a").format("hh:mm a");
        const aLower = c.replace(" ", "");
        let bookedSlotIndex = -1;

        if (
          slotsAccordingToDatesForVideo &&
          slotsAccordingToDatesForVideo.length > 0
        ) {
          bookedSlotIndex = slotsAccordingToDatesForVideo.findIndex((b) => {
            return (
              moment(b.time, "hh:mm a")
                .format("hh:mm a")
                .replace(" ", "")
                .toLowerCase() === aLower && b.date === selectedDate
            );
          });
        }

        if (a._i !== "") {
          if (a < timeTwelve) {
            slotsBeforeTwelve.push({
              time: a._i,
              isBooked: bookedSlotIndex !== -1,
              selected: false,
              type: "video",
              date: selectedDate,
              clinic_id:
                clinic_details && clinic_details.clinic_id
                  ? clinic_details.clinic_id
                  : "1",
            });
          } else if (a >= timeTwelve && a <= timeFour) {
            slotsAfterTwelve.push({
              time: a._i,
              isBooked: bookedSlotIndex !== -1,
              selected: false,
              type: "video",
              date: selectedDate,
              clinic_id:
                clinic_details && clinic_details.clinic_id
                  ? clinic_details.clinic_id
                  : "1",
            });
          } else {
            slotsAfterFour.push({
              time: a._i,
              isBooked: bookedSlotIndex !== -1,
              selected: false,
              type: "video",
              date: selectedDate,
              clinic_id:
                clinic_details && clinic_details.clinic_id
                  ? clinic_details.clinic_id
                  : "1",
            });
          }
        }
      });
    }
  }
  let timeArr = [
    {
      shift: "Morning",
      icon: morningIcon,
      timeSchedule: slotsBeforeTwelve,
    },
    {
      shift: "Afternoon",
      icon: afternoonIcon,
      timeSchedule: slotsAfterTwelve,
    },
    {
      shift: "Evening",
      icon: eveningIcon,
      timeSchedule: slotsAfterFour,
    },
  ];
  const allCount =
    slotsBeforeTwelve.length + slotsAfterTwelve.length + slotsAfterFour.length;

  return (
    <div className="appoitment-time-card">
      <div className="m-title">
        <span>
          <img src={titleIcon} alt={title} className="mr-2" />
          {title}
        </span>

        {/* {day && <span>({day})</span>} */}
      </div>
      <div className="appointment-time-select">
        {loading ? (
          <Loader />
        ) : (
          <>
            {Object.keys(videoConsultationBooking).length === 0 &&
            title === "Video Consultation" ? (
              <p className="not-register">
                (This doctor has not yet registered for video consultation)
              </p>
            ) : allCount > 0 ? (
              timeArr &&
              timeArr.length > 0 &&
              timeArr.map((data, index) => {
                return (
                  <>
                    <TimeSelect
                      key={index}
                      {...data}
                      bookedSlots={bookedSlots}
                      clinic_details={clinic_details}
                      slotsAccordingToDates={slotsAccordingToDates}
                      doctorsDetails={doctorsDetails}
                      wh_doctor_detail={wh_doctor_detail}
                      selectedDate={selectedDate}
                      toggle={toggle}
                      isOpen={isOpen}
                      videoConsultationBooking={videoData}
                      doctorInfoDetails={doctorInfoDetails}
                      doctorDataById={doctorDataById}
                      video_timings={video_timings}
                      video_bookings={video_bookings}
                      typeEdit={typeEdit}
                      booking_id={booking_id}
                      paymentDetails={paymentDetails}
                    />
                  </>
                );
              })
            ) : (
              <p className="not-register">(Not Available)</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AppoitmentTimeCard;
