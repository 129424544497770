import {
   FETCH_DEALS_REQUEST,FETCH_DEALS_FAILED,FETCH_DEALS_SUCCESS
  } from "./types";
  
  const initialState = {
   deals:{}
  };
  
  const DealsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DEALS_REQUEST: {
        return {
          ...state,
        };
      }
      case FETCH_DEALS_SUCCESS: {
        return {
          ...state,
          deals: action.deals || {},
          
        };
      }
      case FETCH_DEALS_FAILED: {
        return {
          ...state,
        };
      }
  
    
  
      default: {
        return state;
      }
    }
  };
  
  export default DealsReducer;
  