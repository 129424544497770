import React from 'react';
import { Container } from 'reactstrap';
import Loader from '../../../loader/index';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Listing from './listing';
import { bindActionCreators } from 'redux';
import { fetchFilteredDoctorsData } from '../../home/BookOurDoctors/store/action';

import { fetchBookDoctorsData } from '../../../pages/home/BookOurDoctors/store/action';

import { saveSearchType } from '../../../pages/search/store/action';
import { diff } from '../../../util/object';

let searchDoctorsReqData = {};

class SearchResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filteredDoctors: [],
			search: '',
			location: '',
			clinic: false,
			video: false,
			loading: false,
			count: '',
			activePage: 1,
			videoBit: false,
			clinicBit: false,

			isEnablePrevious: false,
		};
	}

	callApi = () => {
		let search =
			this.props &&
			this.props.history.location.state &&
			this.props.history.location.state.search
				? this.props.history.location.state.search
				: '';

		this.setState({
			loading: true,
		});

		let clinic = this.props && this.props.clinicConsultation;

		let video = this.props && this.props.videoConsultation;
		let consult = this.props && this.props.consultLiveNow;
		let latitude = this.props && this.props.latLong.latitude;
		let longitude = this.props && this.props.latLong.longitude;
		let location =
			this.props &&
			this.props.history.location.state &&
			this.props.history.location.state.searchLocation
				? latitude === 0 && longitude === 0
					? this.props.history.location.state.searchLocation
					: this.props.history.location.state.searchLocation
				: '';

		this.setState({
			videoBit: this.props.videoConsultation,
			clinicBit: this.props.clinicConsultation,
		});

		let type = clinic && video ? '' : clinic ? 'clinic' : video ? 'video' : '';

		let formDataDoctors = {
			doctorParams: search,
			type: type,
			location: localStorage.getItem('liveCity') || location,
			offset: 0,
			limit: 10,
			consult: consult,
		};

		if (latitude !== 0 && longitude !== 0) {
			formDataDoctors.latitude = latitude;
			formDataDoctors.longitude = longitude;
		}

		const { fetchBookDoctorsData, saveSearchType } = this.props;

		fetchBookDoctorsData('search/searchDoctors', formDataDoctors, (doctors) => {
			saveSearchType(doctors.data.dataType);
			if (doctors.count > 0) {
				this.setState({
					loading: false,
					count: doctors.count,
				});
			}
			if (doctors.count > 0) {
				this.setState({
					filteredDoctors: doctors.data.arr,

					loading: false,
					count: doctors.count,
				});
			} else {
				this.setState({
					filteredDoctors: doctors.data.recommendations,

					loading: false,
					count: doctors.count,
				});
			}
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.videoConsultation !== this.state.videoBit ||
			this.state.clinicBit !== this.props.clinicConsultation
		) {
			this.setState(
				{
					videoBit: this.props.videoConsultation,
					clinicBit: this.props.clinicConsultation,
				},
				() => this.callApi()
			);
		}
		if (this.state.loading) {
			return;
		}

		let prevData = {
			clinicConsultation: prevProps.clinicConsultation,
			videoConsultation: prevProps.videoConsultation,
		};
		let currentData = {
			clinicConsultation: this.props.clinicConsultation,
			videoConsultation: this.props.videoConsultation,
		};

		if (
			JSON.stringify(prevData) !== JSON.stringify(currentData) ||
			(prevProps.searchClicked !== this.props.searchClicked &&
				this.props.searchClicked)
		) {
			const { videoConsultation, clinicConsultation, isSearchApplied } =
				prevProps;
			const { activePage } = this.state;
			const isAnyFilterApplied =
				videoConsultation || clinicConsultation || isSearchApplied;

			let search = this.props && this.props.search ? this.props.search : '';

			let clinic = this.props && this.props.clinicConsultation;

			let video = this.props && this.props.videoConsultation;
			let type =
				clinic && video ? '' : clinic ? 'clinic' : video ? 'video' : '';
			let consult = this.props && this.props.consultLiveNow;
			let latitude =
				this.props && this.props.latLong && this.props.latLong.latitude;
			let longitude =
				this.props && this.props.latLong && this.props.latLong.longitude;
			let location =
				this.props && this.props.searchLocation
					? latitude === 0 && longitude === 0
						? this.props.searchLocation
						: this.props.searchLocation
					: '';

			let formDataDoctors = {
				doctorParams: search,
				type: type,
				location: localStorage.getItem('liveCity') || location,
				offset: 0,
				limit: 10,
				consult: consult,
			};

			if (latitude !== 0 && longitude !== 0) {
				formDataDoctors.latitude = latitude;
				formDataDoctors.longitude = longitude;
			}
			const { fetchBookDoctorsData, saveSearchType } = this.props;
			const isNewFilter = diff(formDataDoctors, searchDoctorsReqData);
			if (Object.keys(isNewFilter).length === 0) {
				return;
			}
			searchDoctorsReqData = { ...formDataDoctors };
			this.setState({
				loading: true,
				activePage: isAnyFilterApplied ? 1 : activePage,
			});
			fetchBookDoctorsData(
				'search/searchDoctors',
				formDataDoctors,
				(doctors) => {
					saveSearchType(doctors.data.dataType);
					if (doctors.count > 0) {
						this.setState({
							loading: false,
							count: doctors.count,
						});
					}
					if (doctors.count > 0) {
						this.setState({
							filteredDoctors: doctors.data.arr,

							loading: false,
							count: doctors.count,
						});
					} else {
						this.setState({
							filteredDoctors: doctors.data.recommendations,

							loading: false,
							count: doctors.count,
						});
					}
				}
			);
		}
	}

	handlePageUpdate = (index, enablePrevious) => {
		let search = this.props && this.props.search ? this.props.search : '';

		let location =
			this.props &&
			this.props.history.location.state &&
			this.props.history.location.state.searchLocation
				? this.props.latLong &&
				  this.props.latLong.latitude === 0 &&
				  this.props.latLong &&
				  this.props.latLong.longitude === 0
					? this.props.history.location.state.searchLocation
					: this.props.history.location.state.searchLocation
				: '';
		let clinic = this.props && this.props.clinicConsultation;
		let video = this.props && this.props.videoConsultation;
		let type = clinic && video ? '' : clinic ? 'clinic' : video ? 'video' : '';
		let consult = this.props && this.props.consultLiveNow;
		let latitude =
			this.props && this.props.latLong && this.props.latLong.latitude;
		let longitude =
			this.props && this.props.latLong && this.props.latLong.longitude;
		let formDataDoctors = {
			doctorParams: search,
			type: type,
			location: localStorage.getItem('liveCity') || location,
			offset: (index - 1) * 10,
			limit: 10,
			consult: consult,
		};

		if (latitude !== 0 && longitude !== 0) {
			formDataDoctors.latitude = latitude;
			formDataDoctors.longitude = longitude;
		}

		const { fetchBookDoctorsData, saveSearchType } = this.props;
		this.setState({
			loading: true,
			activePage: index,
			isEnablePrevious: !!enablePrevious,
		});

		fetchBookDoctorsData('search/searchDoctors', formDataDoctors, (doctors) => {
			saveSearchType(doctors.data.dataType);
			if (doctors.count > 0) {
				this.setState({
					loading: false,
					count: doctors.count,
				});
			}
			if (doctors.count > 0) {
				this.setState({
					filteredDoctors: doctors.data.arr,

					loading: false,
					count: doctors.count,
				});
			} else {
				this.setState({
					filteredDoctors: doctors.data.recommendations,

					loading: false,
					count: doctors.count,
				});
			}
		});
	};
	render() {
		window.scrollTo(0, 0);
		const {
			filteredDoctors,

			count,
			loading,
			activePage,
			isEnablePrevious,
		} = this.state;
		const { searchLocation, search, match } = this.props;
		const searchParam = match && match.params[0] && match.params[0].split('/');

		return (
			<div className="search-results-wrapper">
				{loading ? (
					<div className="position-relative" style={{ minHeight: '100px' }}>
						<Loader />
					</div>
				) : (
					<Container fluid="xl">
						{((match && match.params.location) ||
							(match.params && match.params.speciality) ||
							(match.params[0] &&
								match.params[0].includes(searchLocation))) && (
							<div className="menu-breadcrum">
								<a href="/" className="nav-item">
									{' '}
									Home{' '}
								</a>

								{(match.params.location ||
									(match.params[0] && searchParam[1])) && (
									<>
										<i class="fas fa-chevron-right icons"></i>
										<a
											href={`/search/${
												(match.params.location &&
													match.params.location
														.replace('-', ' ')
														.replace('-', ', ')) ||
												searchParam[1].replace('-', ' ').replace('-', ', ')
											}`}
											className="nav-item"
										>
											{(match.params.location &&
												match.params.location
													.replace('-', ' ')
													.replace('-', ', ')) ||
												searchParam[1].replace('-', ' ').replace('-', ', ')}
										</a>
									</>
								)}
								{match.params.speciality && (
									<>
										{' '}
										<i class="fas fa-chevron-right icons"></i>{' '}
										<div className="nav-item-text d-inline">
											{' '}
											{match.params.speciality}
										</div>
									</>
								)}
							</div>
						)}

						{count > 0 && filteredDoctors.length !== 0 && (
							<h2>We have found {count} results</h2>
						)}

						{count === 0 && filteredDoctors.length === 0 && (
							<h2 className="text-center">No Results Found!</h2>
						)}
						{count === 0 && filteredDoctors.length !== 0 && (
							<h2>
								We did not find any doctors in your location as per your search
								criteria but you can still consult the following doctors through
								video consultation
							</h2>
						)}
						<Listing
							listingData={filteredDoctors}
							setActivePage={this.handlePageUpdate}
							count={count}
							activePage={activePage}
							isEnablePrevious={isEnablePrevious}
						/>
					</Container>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	SearchDoctors: state.BookOurDoctors,
	SearchReducer: state.SearchReducer,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchFilteredDoctorsData,
			fetchBookDoctorsData,
			saveSearchType,
		},
		dispatch
	);
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SearchResults)
);
