import React, { Component } from 'react';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import Loader from '../../../loader/index';
import Hospital from '../../../assets/images/home/hospital.jpeg';
import StarFilled from '../../../assets/images/home/star-filled.svg';
class HospitalsDeals extends Component {
	render() {
		const { hospitalDeals, loading } = this.props;

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow:
				hospitalDeals.length >= 4
					? 4
					: hospitalDeals.length >= 3
					? 3
					: hospitalDeals.length >= 2
					? 2
					: 1,
			slidesToScroll:
				hospitalDeals.length >= 4
					? 4
					: hospitalDeals.length >= 3
					? 3
					: hospitalDeals.length >= 2
					? 2
					: 1,

			responsive: [
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: hospitalDeals
							? hospitalDeals.length >= 3
								? 3
								: hospitalDeals.length
							: 1,
						slidesToScroll: hospitalDeals
							? hospitalDeals.length >= 3
								? 3
								: hospitalDeals.length
							: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: hospitalDeals
							? hospitalDeals.length >= 2
								? 2
								: hospitalDeals.length
							: 1,
						slidesToScroll: hospitalDeals
							? hospitalDeals.length >= 2
								? 2
								: hospitalDeals.length
							: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 767,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '60px',
						slidesToShow: 1,
						speed: 500,
					},
				},
				{
					breakpoint: 575,
					settings: {
						centerMode: true,
						infinite: true,
						centerPadding: '50px',
						slidesToShow: 1,
						speed: 500,
					},
				},
			],
		};

		return (
			<section className="hospitals-deals-wrapper">
				<Container>
					<h3 className="title text-center theme-title">
						<span>Hospitals</span> Deals
					</h3>
					<div className="doc-slider-wrapper">
						{loading ? (
							<Loader />
						) : (
							<Slider {...settings}>
								{hospitalDeals &&
									hospitalDeals.length > 0 &&
									hospitalDeals.map((item) => {
										const content = item.excerpt.rendered.replace(
											/(<([^>]+)>)/gi,
											''
										);

										let splitContent = content.split(':')[1];
										let splitByDiscount = splitContent.split('DISCOUNT');

										return (
											<div className="hospital-deals-content">
												<div className="hospital-image">
													<img
														style={{ cursor: 'pointer' }}
														src={
															item._embedded['wp:featuredmedia'][0].source_url
														}
														alt="hospital"
														onClick={() => {
															window.location.href = item.link;
														}}
													/>
													{/* <div className="discount-wrap">
                  <p className="discount mb-0">10%</p>
                  <p className="text mb-0">Discount</p>
                </div> */}
												</div>
												<div className="hospital-content">
													<h3>{item.title.rendered}</h3>
													<p
														className="mb-0"
														dangerouslySetInnerHTML={{
															__html: splitByDiscount[0],
														}}
													/>
													<div className="ratings-wrapper">
														<span>4.3</span>
														<div className="d-flex mx-1">
															<img src={StarFilled} alt="" />
															<img src={StarFilled} alt="" />
															<img src={StarFilled} alt="" />
															<img src={StarFilled} alt="" />
															<img src={StarFilled} alt="" />
														</div>
														<span className="google-reviews">
															85 Google Reviews
														</span>
													</div>
												</div>
											</div>
										);
									})}
							</Slider>
						)}
					</div>
				</Container>
			</section>
		);
	}
}
export default HospitalsDeals;
