import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyImage from "../../assets/images/privacy-policy/privacy-image.png";

class Aboutus extends React.Component {
  render() {
    return (
      <div className="privacy-policy-wrapper">
        <div className="privacy-banner">
          <Container>
            <Row className="align-items-center text-md-left text-center">
              <Col md={6}>
                <h1>
           About Us
                </h1>
              </Col>
              <Col md={6}>
                <img src={PrivacyImage} className="img-fluid privacy" alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="mb-5">

            <ul>
              <li>
              Wishhealth is your next avenue to access healthcare at the tip of the fingers. For doctors, this is an integrated platform with plenty of exposure. This will ensure reach beyond your physical clinic. For patients, professional healthcare has never been more accessible. With no compromise made in quality and service, Wishhealth opens up the most convenient means to reach out to your doctors.
              </li>
              <li>
              The whole interface is designed to ensure that the patient has a wide variety of professionals to choose from. Upon selecting a specialised doctor, the entire interaction occurs virtually. Over here, the video consultation feature enables both the doctor and the patient to communicate in a hassle-free manner. Likewise, the platform will facilitate ease of storage with regard to appointments, case-histories, and prescriptions. All of the above can be accessed at one place
              </li>
              <li>
              Through Wishhealth you will attain quick, reliable, and high-quality healthcare at an affordable price.              </li>
            </ul>






          </div>
        </Container>
      </div>
    );
  }
}

export default Aboutus;
