export const SEND_APP_LINK_REQUEST = "SEND_APP_LINK_REQUEST";
export const SEND_APP_LINK_SUCCESS = "SEND_APP_LINK_SUCCESS";
export const SEND_APP_LINK_FAILED = "SEND_APP_LINK_FAILED";

export const DOCTOR_LOCATION_REQUEST = "DOCTOR_LOCATION_REQUEST";
export const DOCTOR_LOCATION_SUCCESS = "DOCTOR_LOCATION_SUCCESS";
export const DOCTOR_LOCATION_FAILED = "DOCTOR_LOCATION_FAILED";

export const SEARCH_SUGGESTIONS_REQUEST = "SEARCH_SUGGESTIONS_REQUEST";
export const SEARCH_SUGGESTIONS_SUCCESS = "SEARCH_SUGGESTIONS_SUCCESS";
export const SEARCH_SUGGESTIONS_FAILED = "SEARCH_SUGGESTIONS_FAILED";
