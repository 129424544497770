import React from "react";
import Slider from "react-slick";

import { config } from "../../../config";
const ClinicImage = (props) => {
  const { clinic_details } = props;

  const { wh_clinic } = clinic_details && clinic_details;
  const { wh_clinic_images } = wh_clinic && wh_clinic;

  if (wh_clinic_images && wh_clinic_images.length === 0) {
    return null;
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: wh_clinic_images
      ? wh_clinic_images.length >= 4
        ? 4
        : wh_clinic_images.length
      : 1,
    slidesToScroll: wh_clinic_images
      ? wh_clinic_images.length >= 4
        ? 4
        : wh_clinic_images.length
      : 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: wh_clinic_images
            ? wh_clinic_images.length >= 3
              ? 3
              : wh_clinic_images.length
            : 1,
          slidesToScroll: wh_clinic_images
            ? wh_clinic_images.length >= 3
              ? 3
              : wh_clinic_images.length
            : 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "40px",
        },
      },
    ],
  };

  return (
    <div className="clinic-images py-md-5 py-sm-4 py-3">
      {wh_clinic_images && wh_clinic_images.length > 0 && <h2>Clinic Image</h2>}
      {wh_clinic_images && wh_clinic_images.length > 0 && (
        <Slider
          {...settings}
          className={`doc-gallery ${
            wh_clinic_images.length === 1 ? "image-size" : ""
          }`}
        >
          {wh_clinic_images &&
            wh_clinic_images.length > 0 &&
            wh_clinic_images.map((image, index) => {
              return (
                <div key={index} className="px-1 px-sm-2 px-md-3">
                  <div className="imageWrapper">
                    <img
                      src={`${config.oldWebsiteUrl}${image.image_url}`}
                      alt="Clinic Image"
                    />
                  </div>
                </div>
              );
            })}
        </Slider>
      )}
    </div>
  );
};

export default ClinicImage;
