import React from "react";
import { Row, Col } from "reactstrap";
import Pagination from "react-js-pagination";
import { DocResultCard } from "../../../components/card";

const Listing = (props) => {
  const { listingData = [], count, activePage, typeOfSearch } = props;

  const offsetLimit = 10;

  const handlePagination = (e, enablePrevious) => {
    props.setActivePage(e, enablePrevious);
  };

  return (
    <div className="px-2">
      <Row>
        {listingData &&
          listingData.length > 0 &&
          listingData.map((data) => {
            return (
              <Col lg="6" className="px-lg-1 mb-3">
                <DocResultCard {...data} />
              </Col>
            );
          })}
      </Row>
      {count > 10 && (
        <Pagination
          className="mt-3 mx-auto w-fit-content"
          itemClass="page-item"
          linkClass="page-link"
          activeClass="active"
          activePage={activePage}
          itemsCountPerPage={offsetLimit}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={handlePagination}
        />
      )}

      {/* {count === 10 && (
        <div className="search-pagination text-center">
          <Button
            onClick={(e) => handlePagination(e, false)}
            disabled={!isEnablePrevious}
            className="prev-btn"
          >
            <i className="fas fa-chevron-left mr-2" />
            Prev
          </Button>

          <Button
            onClick={(e) => handlePagination(e, true)}
            className="next-btn"
          >
            Next <i className="fas fa-chevron-right ml-2" />
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default Listing;
